import React, { useState } from "react";
import PropTypes from "prop-types";


const InputPassword = ({ icon, type, name, placeholder, iconPassword, valuePass, setValuePass }) => {

    const [valuePassword, setvaluePassword] = useState(valuePass);

    console.log(valuePass)
    console.log(valuePassword);

    const switchShow = () => {

        setvaluePassword(!valuePassword)
        setValuePass(!valuePassword)

    }
    return (
        <div className="container-input-component">
            <div className="btn-input-component-icouser" >
                <div className="subcontainer-img-left">
                    <img src={icon} alt="" />
                </div>
                <input
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    className="input-component-login"
                />
                {
                    iconPassword &&

                    <div className="subcontainer-img-right"
                        onClick={switchShow}
                        type='button'
                    >
                        <img src={iconPassword} />

                    </div>
                }
            </div>
        </div>
    );
}

InputPassword.propTypes = {
    props: PropTypes.any,
    color: PropTypes.any,
    icon: PropTypes.any,
    title: PropTypes.any,
    name: PropTypes.string,
    placeholder: PropTypes.string,
};

export default InputPassword;