/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button } from '../../../components/atoms';
import '../../../styles/stylesSteppers.scss';
import { deliveryFCL, modality } from '../../../constants/dummyData';
import arrow from './../../../assets/images/navieras/arrowButton.png'
import { useHistory } from 'react-router-dom';
import './RecordsData.scss';
import './DeliveryTypes.scss';
import { RECORDS } from '../../../routes/Paths';

const PickupAndDeliveryData = ({ recordId = '002', setStepper, stepper }) => {
    const [ status, setStatus ]= useState(null);
    const history = useHistory();
    const [ deliveryData, setDeliveryData ] = useState({
        mode: null,
        deliveryType: null,
        railArriveDate: null,
        railDeliveryDate: null,
        emptyReturnDate: null,
        pickUp: null,
        FPOD: null,
    });


    const changeStage = ()=>{
        history.push({
            pathname: RECORDS,
            state: {clientType: status},
        });
        // setStepper(stepper+1);
        console.warn(deliveryData);
    }

    return (
        <div className='main-container-generic-type'>
            <div className='header-form-generic-type'>
                <h2>Datos de recolección y entrega</h2>
                <h3>ID: {recordId}</h3>
            </div>
            <div className='body-form-generic-type-data pickup-delivery' style={{height: '45vh'}}>
                <div className='input-column-container delivery'>
                    <div className='input-container'>
                        <div className='label-container'>
                            <label className='container-label'>MODE</label>
                        </div>
                        <div className='value-container' >
                            <select className='input-select' value={deliveryData.mode} onChange={(e) => {
                                setDeliveryData({...deliveryData, mode: parseInt(e.target.value)});
                            }}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    modality &&
                                    modality.map(ele => {
                                        return <option value={ele.id}> {ele.name}</option>
                                    })
                                }
                            </select >
                        </div>
                    </div>
                    <div className='input-container'>
                        <div className='label-container'>
                            <label className='container-label'>Tipo de entrega</label>
                        </div>
                        <div className='value-container' >
                            <select className='input-select' value={deliveryData.deliveryType} onChange={(e) => {
                                setDeliveryData({...deliveryData, deliveryType: parseInt(e.target.value)});
                            }}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    deliveryFCL &&
                                    deliveryFCL.map(ele => {
                                        return <option value={ele.id}> {ele.name}</option>
                                    })
                                }
                            </select >
                        </div>
                    </div>
                    <div className='input-container'>
                        <div className='label-container'>
                            <label className='container-label'>Fecha de llegada ferrocarril</label>
                        </div>
                        <div className='value-container'>
                            <input 
                                className="input-generic date"
                                type="date"
                                placeholder="Agregar..."
                                value={deliveryData.railArriveDate}
                                onChange={(e) => setDeliveryData({...deliveryData, railArriveDate: e.target.value})}
                            />
                        </div>
                    </div>
                    <div className='input-container'>
                        <div className='label-container'>
                            <label className='container-label'>Fecha de entrega ferrocarril</label>
                        </div>
                        <div className='value-container'>
                            <input 
                                className="input-generic date"
                                type="date"
                                placeholder="Agregar..."
                                value={deliveryData.railDeliveryDate}
                                onChange={(e) => setDeliveryData({...deliveryData, railDeliveryDate: e.target.value})}
                            />
                        </div>
                    </div>
                    <div className='input-container'>
                        <div className='label-container'>
                            <label className='container-label'>Fecha de retorno de vacío</label>
                        </div>
                        <div className='value-container'>
                            <input 
                                className="input-generic date"
                                type="date"
                                placeholder="Agregar..."
                                value={deliveryData.emptyReturnDate}
                                onChange={(e) => setDeliveryData({...deliveryData, emptyReturnDate: e.target.value})}
                            />
                        </div>
                    </div>
                    <div className='input-container'>
                        <div className='label-container'>
                            <label className='container-label'>Pick Up</label>
                        </div>
                        <div className='value-container' >
                            <input
                                type='text'
                                value={deliveryData.pickUp}
                                onChange={(e) => setDeliveryData({...deliveryData, pickUp: e.target.value})}
                                className='input-generic'
                                placeholder='Nombre de la ciudad donde se recolecta'
                            />
                        </div>
                    </div>
                    <div className='input-container'>
                        <div className='label-container'>
                            <label className='container-label'>FPOD</label>
                        </div>
                        <div className='value-container' >
                            <input
                                type='text'
                                value={deliveryData.FPOD}
                                onChange={(e) => setDeliveryData({...deliveryData, FPOD: e.target.value})}
                                className='input-generic'
                                placeholder='Agregar...'
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-form-generic-type records'>
                <Button onClick={changeStage} className={'button-back'} style={{width: '28%'}}>CANCELAR</Button>
                <Button onClick={changeStage} className={'button-generate'} style={{width: '28%'}}>GUARDAR</Button>
                <Button onClick={changeStage} className={'button-next'} style={{width: '30%'}}>CERRAR SEGUIMIENTO</Button>
            </div>
        </div>
    )
}

export default PickupAndDeliveryData;
