/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button } from './../../../components/atoms';
import '../../../styles/stylesSteppers.scss';
import arrow from './../../../assets/images/navieras/arrowButton.png'
import { useHistory } from 'react-router-dom';
import './RecordsData.scss';

const ArrivalDates = ({ recordId = '002', setStepper, stepper }) => {
    const [ status, setStatus ]= useState(null);
    const history = useHistory();
    const [ freeDelay, setFreeDelay ] = useState(null);
    const [ delayDays, setDelayDays ] = useState(null);
    const [ arrivalDate, setArrivalDate ] = useState(null);
    const [ fourteenDaysBefore, setFourteenDaysBefore ] = useState(null);
    const [ sevenDaysBefore, setSevenDaysBefore ] = useState(null);
    const [ threeDaysBefore, setThreeDaysBefore ] = useState(null);


    const calculateDates = (date, subDays, setter, mode = 'sub') => {
        if (mode === 'sub') {
            const newDate = moment(date, 'YYYY-MM-DD').subtract(subDays, 'days');
            setter(moment(newDate._d).format('YYYY-MM-DD'));
        }
        else if (mode === 'add') {
            const newDate = moment(date, 'YYYY-MM-DD').add(subDays, 'days');
            setter(moment(newDate._d).format('YYYY-MM-DD'));
        }
    }


    useEffect(() => {
        calculateDates(arrivalDate, 14, setFourteenDaysBefore);
        calculateDates(arrivalDate, 7, setSevenDaysBefore);
        calculateDates(arrivalDate, 3, setThreeDaysBefore);
        if (delayDays != null) {
            calculateDates(arrivalDate, delayDays, setFreeDelay, 'add');
        }
    }, [arrivalDate, delayDays])


    const changeStage = () => {
        history.push({
                state: {clientType: status},
            });
        setStepper(stepper+1);
        console.warn(arrivalDate, sevenDaysBefore, threeDaysBefore, fourteenDaysBefore, freeDelay);
    }

    return (
        <div className='main-container-generic-type'>
            <div className='header-form-generic-type'>
                <h2>Fechas de arribo</h2>
                <h3>ID: {recordId}</h3>
            </div>
            <div className='body-form-generic-type-data record-data' style={{height: '45vh'}}>
                <div className='input-container-data records-arrival-dates'>
                    <div className='input-column-container records'>
                    <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Fecha de arribo</label>
                            </div>
                            <div className='value-container' >
                                <input value={arrivalDate} onChange={(e) => setArrivalDate(moment(e.target.value).format('YYYY-MM-DD'))} type='date' className='input-generic date'/>
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Fecha 7 días antes de arribo</label>
                            </div>
                            <div className='value-container' >
                                <input value={sevenDaysBefore} disabled type='date' className='input-generic date'/>
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Días de demora</label>
                            </div>
                            <div className='value-container arrival'>
                                <input name={'name'} value={delayDays} onChange={(e) => setDelayDays(e.target.value)} className="input-generic" type="number" min={0} placeholder="Agregar..."/>
                                <p>*En caso de que el embarque tenga retraso, agregar los días de demora en este campo.</p>
                            </div>
                        </div>
                    </div>
                    <div className='input-column-container records'>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Fecha 14 días antes de arribo</label>
                            </div>
                            <div className='value-container' >
                                <input value={fourteenDaysBefore} disabled type='date' className='input-generic date'/>
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Fecha 3 días antes de arribo</label>
                            </div>
                            <div className='value-container' >
                                <input value={threeDaysBefore} disabled type='date' className='input-generic date'/>
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Último día libre demora</label>
                            </div>
                            <div className='value-container arrival' >
                                <input type='date' value={freeDelay} className='input-time records' disabled/>
                                <p>*Esta fecha se llenará en automático en caso de haber días de demora, sumará campo de fecha de arribo + campo de días de demora.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-form-generic-type records'>
                <Button onClick={changeStage} className={'button-back'} style={{width: '28%'}}>CANCELAR</Button>
                <Button onClick={changeStage} className={'button-generate'} style={{width: '28%'}}>GUARDAR</Button>
                <Button onClick={changeStage} className={'button-next'} srcicon={arrow} style={{width: '30%'}}>SIGUIENTE</Button>
            </div>
        </div>
    )
}

export default ArrivalDates;
