/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import profile from "../../assets/images/perfil.png";
import edit from "../../assets/images/navieras/editIcon.png";
import { Button } from "../../components/atoms";
import "../../components/templates/BaseLayout/Profile/ProfileFormStyles.scss";
import "./OperativesDetailsView.scss";
import { deleteOperativeAction, enableOperativeAction, upsertOperativeAction } from "../../redux/actions/operativeAction";
import { OPERATIVES } from "../../routes/Paths";
import historyApiFallback from "connect-history-api-fallback";
import { url_api } from "../../config";
import { Rating } from "@mui/material";
import SeeDataPrinci from "./OperativeView/SeeDataPrinci";
import { StepperComponent } from "../../components/Stepper/Stepper";
import SeeDataIdentity from "./OperativeView/SeeDataIdentity";
import ControlledSwitches from "../../components/atoms/SwitchControl";

export const OperativeDetailsView = (props) => {
  const history = useHistory();
  let { userId } = useParams();
  const dispatch = useDispatch();
  const api = process.env.SERVER_API_DATA_URL;

  const { mode } = useParams();

  const currentData = _.get(props, "location.state", null);
  console.log("current data", currentData);

  //ADD IMG
  const [imageSrc, setImageSrc] = useState(profile);
  const [fileUrl, setFileUrl] = useState([]);
  const [imgBase64, setImgBase64] = useState(null);
  const [nameBase64, setNameBase64] = useState("");
  const [stepper, setStepper] = useState(0);


  //ESTE ES EL QUE SE VA A RECIBIR POR PARAMS
  const upsertSuccess = useSelector(
    (state) => state.operative.upsertOperative.isSuccess
  );

  // const admissionDate = currentData && currentData.operative.dateOfAdmission ? moment(currentData.operative.dateOfAdmission).format('yyyy-MM-DD') : moment(new Date()).format('yyyy-MM-DD') ;
  // const admissionDateTLP = currentData && currentData.operative.dateOfAdmissionTLP ? moment(currentData.operative.dateOfAdmissionTLP).format('yyyy-MM-DD') : moment(new Date()).format('yyyy-MM-DD') ;
  // console.log(admissionDate);
  // const defaultValues = {
  //     name : firstNameDefault,
  //     lastName: firstLastNameDefault,
  //     position: positionDefault,
  //     email: emailDefault,
  // };

  const id = userId;
  console.log(id)
  const imgDefault = _.get(currentData, "person.profileImageMedia.url", null);
  const [disabled, setDisabled] = useState(id ? true : false);

  const [idCust, setIdCust] = useState(
    !_.isNil(currentData.id) ? currentData.id : ""
  );

  const [firstName, setFirstName] = useState(
    !_.isNil(currentData.person.firstName) ? currentData.person.firstName : ""
  );

  const [firstLastName, setFirstLastName] = useState(
    !_.isNil(currentData.person.firstLastName)
      ? currentData.person.firstLastName
      : ""
  );

  const [email, setEmail] = useState(
    !_.isNil(currentData.user.email)
      ? currentData.user.email
      : ""
  );

  const [status, setStatus] = useState(
    !_.isNil(currentData.deletedAt)
      ? false
      : true
  );

  const [statusNew, setStatusNew] = useState()

  console.log(statusNew)

  useEffect(() => {
    if(!_.isNil(statusNew)){
      if(statusNew===true){
        console.log("entro?")
        dispatch(enableOperativeAction({customerId:idCust}))
        setStatus(true)
      }else{
        console.log("no entro")
        dispatch(deleteOperativeAction({ customerId: idCust }))
        setStatus(false)
  
      }
    }
   
    
   
  }, [statusNew])
  

  useEffect(() => {
    if (mode === "ver") setDisabled(true);
    else if (mode === "editar") setDisabled(false);
  }, []);

  const steps = [
    {
        label: "Datos Principales",
        step: 0,
        component: <SeeDataPrinci stepper={stepper} setStepper={setStepper} data={currentData}/>
    },
    {
        label: "Datos de identidad",
        step: 1,
        component: <SeeDataIdentity stepper={stepper} setStepper={setStepper} data={currentData}/>
    },
    // {
    //     label: "Datos Tarjeta Bancaria",
    //     step: 2,
    //     component: <OperativeDataBank stepper={stepper} setStepper={setStepper} validationStep={setInfoValidation}/>
    // }
]

const [render, setRender]= useState(null)

    useEffect(() => {
        setRender(steps[stepper].component)
    }, [stepper])

  const handleAdd = (item) => {
    let semanaCopy = JSON.parse(JSON.stringify(semana1));
    semanaCopy.map((el) => {
      if (item.id === el.id) {
        if (el.selected === true) {
          el.selected = false;
        } else {
          el.selected = true;
        }
      }
    });
    setSemana1(semanaCopy);
  };

  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        return resolve({
          src: reader.result,
          title: file.name,
        });
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const updateInfoFile = (event) => {
    const imageFile = event.target.files;
    const urlImages = [];
    const arrImgBase64 = [];
    Object.entries(imageFile).map(async (ele) => {
      urlImages.push(URL.createObjectURL(ele[1]));
      try {
        const base64 = await toBase64(ele[1]).then((img) => {
          return img;
        });
        if (base64) {
          arrImgBase64.push(base64);
        }
      } catch (err) {
        console.log(err);
        return err;
      }
    });
    setFileUrl(urlImages);
    setImgBase64(arrImgBase64);
    // setPicturesSelected(imageFile.name)
  };

  const profileImg = fileUrl[fileUrl.length - 1]
  ? fileUrl[fileUrl.length - 1]
  : imgDefault
  ? `${url_api}${'/'}${imgDefault}`
  : imageSrc;

  const setImgProfile = {
    background: `url(${profileImg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  //END ADD IMG

  const updateData = () => {
    dispatch(
      upsertOperativeAction({
        id: currentData.operative.id,
        user: {
          email: email,
        },
        operative: {
          media: !imgBase64
            ? null
            : {
                base64: {
                  src: imgBase64[0].src,
                  title: imgBase64[0].title.replace(/[{()}]/g, ""),
                },
              },
          job: job,
          workHourStartAt: horaInicio,
          workHourEndAt: horaFin,
          workInMonday: semana1[0].selected,
          workInTuesday: semana1[1].selected,
          workInWednesday: semana1[2].selected,
          workInThursday: semana1[3].selected,
          workInFriday: semana1[4].selected,
          workInSaturday: semana1[5].selected,
          workInSunday: semana1[6].selected,
        },
        person: {
          firstName: firstName,
          firstLastName: firstLastName,
        },
      })
    );
    if (upsertSuccess) history.push(OPERATIVES);
  };

  const cancelButton = () => {
    history.goBack();
  };

  return (
    <div className="main-container main-container-operative">
      <form
        className="form-admin-container" /* onSubmit={handleSubmit(onSubmit)} */
      >
        <div className="form-admin-profile-left form-admin-profile-cards">
          <div className="picture-container">
            <div className="preContainer">
              <div className="img-profile" 
              // style={setImgProfile}
              >
              <img src={profileImg}/>
              </div>
            </div>
          </div>
          <div className="text-container">
            <h2>{firstName + " " + firstLastName}</h2>
            <p>{email}</p>
            <Rating/>
          </div>
          <div className="bottom-text">
            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
              <p style={{display:"flex", justifyContent:"space-between"}}>Estatus:{status === true ? 
              <p style={{color:"green", marginLeft:"5%"}}>Activo</p> : <p style={{color:"red" ,marginLeft:"5%"}}>Desactivo</p>}</p>
              <ControlledSwitches status={status} setStatus={setStatusNew}/>
            </div>
            {props.type !== "create" && <p>ID : {idCust}</p>}
            <p>Fecha de ingreso:</p>
          </div>
        </div>
        <div style={{display: "flex", flexDirection: "column",width:"80%", alignItems:"center"}}>
          <StepperComponent step={stepper} steps={steps} setStepper={setStepper} />
          <div className="form-admin-profile-right form-admin-profile-cards">
          {
            render
          }
          {/* {
            <div className="footer-form operative-buttons-container">
              <Button
                type="button"
                onClick={cancelButton}
                className={"button-back"}
                style={{ marginTop: "0" }}
              >
                CANCELAR
              </Button>
              <Button
                type="button"
                onClick={() => {
                  updateData(), history.push(OPERATIVES);
                }}
                style={{ marginTop: "0" }}
              >
                {mode == "ver" ? "ACEPTAR" : "GUARDAR"}
              </Button>
            </div> */}
          {/* } */}
          </div>
        </div>
       
      </form>
    </div>
  );
};
