/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import "../../styles/stylesSteppers.scss";
import _ from "lodash";
// import { Button } from "../../components/atoms";
import { CardContainer } from "../../components/molecules/CardContainer/CardContainer";
import Modal from "../../components/organisms/Modal/Modal";
import "./RecordRegister.scss";
import arrow from "./../../assets/images/navieras/arrowButton.png";
import download from "../../assets/images/Icono_descarga_cashtrade.png"
import { RECORDS } from "../../routes/Paths";
import { useDispatch } from "react-redux";
import { getOneReportsAction } from "../../redux/actions/reportsAction";
import PaginatedDataGrid from "./pruebaGrid";
import Button from "@material-ui/core/Button";
import * as XLSX from "xlsx";


const RecordRegister = (props, { clientName = "Rodrigo Rodriguez" }) => {
  const [total, setTotal] = useState("000");

  const data = props.location.state.data;
  const id = data.data.id;
  const startDate = data.data.startDate;
  const endDate = data.data.endDate;
  const typeReport = data.data.typeReport.displayText;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOneReportsAction({ startDate, endDate, typeReport })).then(
      (data) => {
        console.log(data.data);
        setTotal(data.data);
      }
    );
  }, []);

  const excel = [
    {
      id: id,
      Total: total.totalFinal,
      FechaDeInicio: startDate,
      FechaDeTermino: endDate,
    }
  ];

  const handleExcelDownload = () => {
    
    // Crear hoja de cálculo a partir del objeto JSON
    const worksheet = XLSX.utils.json_to_sheet(excel);
  
    // Crear libro de trabajo y agregar hoja de cálculo
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `Reporte_${id}`);
  
    // Descargar archivo de Excel
    XLSX.writeFile(workbook, `Reporte_${id}.xlsx`);
  };

  let title = clientName;

  const history = useHistory();

  const useStyles = makeStyles({
    button: {
      backgroundColor: 'transparent',
      color: 'white',
      boxShadow: "none",
      '&:hover': {
        color: '#79DECA',
        backgroundColor: 'transparent',
        boxShadow: "none",
  
      },
    },

  })

  const classes = useStyles();


  return (
    <div className="records-documentation-container">
      <div className="record-documentation-header">
        <h2 style={{ color: "#ffff", fontFamily: "Open Sans" }}>
          Gestión de reportes | Detalle de reporte
        </h2>
      </div>
      {typeReport == "Movimientos realizados" ? (
        <div style={{display:"flex",flexDirection:"column", marginTop:"5%",alignItems:"center"}}>
          
       
        <PaginatedDataGrid
          data={total ? total : null}
          detailsReport={data}
        />
        
        </div>
      ) : (
        <div style={{display:'flex',alignItems:'center',justifyContent:'center', height: "70%",width:'100%'}}>
           <div className="recordContainer">
          <div className="idContainerRecord">
            <label className="labelId">ID del reporte: </label>
            <p className="titleId">{id}</p>
          </div>
          <div className="totalContainer">
            {typeReport == "Total generado" ? (
              <>
                <label className="titleTotal">Total generado</label>
                <h2 className="titleAmount">${total.totalFinal}</h2>
              </>
            ) : (
              <>
                <label className="titleTotal">Total de usuarios</label>
                <h2 className="titleAmount">{total.totalFinal}</h2>
              </>
            )}
          </div>
          <div className="bottomContainer">
            <div className="dateContainer">
              <label className="titlePeriod">Período de fecha </label>
              <p className="titleDate"> {startDate}</p>
              <label className="titlePeriod"> a </label>
              <p className="titleDate"> {endDate}</p>
            </div>
            <div className="downloadContainer">
              <Button className={classes.button}  onClick={handleExcelDownload}>
                <img src={download}/>
                Descargar
              </Button>
            </div>
          </div>
        </div>

        </div>
       
      )}
    </div>
  );
};

RecordRegister.propTypes = {
  toggleModal: PropTypes.func,
};

export default RecordRegister;
