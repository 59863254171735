/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { MenuItem }from '@material-ui/core';
import plus from '../../assets/images/navieras/plus-icon.png'
import '../../styles/tableViewStyles.scss';
import {
    Button,
    DataTable,
    ActionMenu,
    GlobalFilter,
    Link,
} from '../../components/atoms';
import {
    getAllPortsAction,
    deletePortAction,
} from '../../redux/actions/portAction';
import Modal from '../../components/organisms/Modal/Modal';
import { CardContainer } from '../../components/molecules/CardContainer/CardContainer';
import { DeleteBody } from '../../components/molecules/CancelFormBody/DeleteBody';
import trash from '../../assets/images/navieras/trash-can.png';
import view from '../../assets/images/navieras/view.png';
import edit from '../../assets/images/navieras/edit-icon.png';
import { PORTS_CREATE } from '../../routes/Paths';


const PortsDashboard = (props) => {
    useEffect(() => {
      console.log(props.port)
    }, [props])
    
    console.log(props.ports)
    const history = useHistory();
    const [searchObj,  setSearchObj] = useState({});
    const [searchCondition, setSearchCondition] = useState({});
    const [openModal, setOpenModal] = useState(false)
    const [itemSelected, setItemSelected] = useState(true)
    const toggleModal = ({type,id}) => {
        if(openModal===true){
            setItemSelected(null)
        }{
            setItemSelected({type,id})
        }
        setOpenModal(!openModal);
    }
    useEffect(()=>{
        props.getAllPortsAction(searchCondition);
    },[props.isSuccessDeletePort, searchCondition]);

    const onClickActionMenu = (actionSelected, payload) => {
        switch (actionSelected) {
            case 'showRecord':
                history.push({
                    pathname: `/dashboard/puertos/${payload.id}/ver`,
                    state: {payload, mode: true},
                })
                break;
            case 'editRecord':
                history.push({
                    pathname: `/dashboard/puertos/${payload.id}/ver`,
                    state: {payload, mode: false},
                })
                break;
            case 'deleteRecord':
                props.deletePortAction({id: payload.id});
                break;
            default:
                break;
        }
    }
    const deleteUser = () =>{
        props.deletePortAction({id: itemSelected.id});
        setOpenModal(false);
    }


    const columns = React.useMemo(
        () => [
            {
                id: 'operative.id',
                Header: 'ID',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '5%', 
                accessor: d => ({
                    id: _.get(d, 'id', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        id,
                    },
                }) {
                    return(<span>{`${id}`}</span>)
                }
            },
            {
                id: 'port.name',
                Header: 'Nombre',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '14%', 
                accessor: d => ({
                    port: _.get(d, 'port', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        port,
                    }, row
                }) {
                    return(<span>{port}</span>)
                }
            },
            // {
            //     id: "port.position",
            //     Header: 'Puesto',
            //     disableSortBy: true,
            //     disableFilters: true,
            //     width: '12%',
            //     accessor: d => ({
            //         job: _.get(d, 'job', ''),
            //     }),
            //     Cell: function cell ({ value: { job },  row  }) {
            //         return (<spam >{job}</spam>); 
            //     },
            // },
            // {
            //     id: "port.phone",
            //     Header: 'Teléfono',
            //     disableSortBy: true,
            //     disableFilters: true,
            //     width: '10%',
            //     accessor: d => ({
            //         phone: _.get(d, 'phone', ''),
            //     }),
            //     Cell: function cell ({ value: { phone },  row  }) {
            //         return (<spam >{phone}</spam>); 
            //     },
            // },
            // {
            //     id: "port.email",
            //     Header: 'Correo',
            //     disableSortBy: true,
            //     disableFilters: true,
            //     width: '15%',
            //     accessor: d => ({
            //         email: _.get(d, 'email', ''),
            //     }),
            //     Cell: function cell ({ value: { email },  row  }) {
            //         return (<spam >{email}</spam>); 
            //     },
            // },
            {
                id: "port.country",
                Header: 'País',
                disableSortBy: true,
                disableFilters: true,
                width: '10%',
                accessor: d => ({
                    country: _.get(d, 'country.name', ''),
                }),
                Cell: function cell ({ value: { country },  row  }) {
                    return (<spam >{country}</spam>); 
                },
            },
            {
                id: "port.state",
                Header: 'Estado/Provincia',
                disableSortBy: true,
                disableFilters: true,
                width: '10%',
                accessor: d => ({
                    state: _.get(d, 'state.name', ''),
                }),
                Cell: function cell ({ value: { state },  row  }) {
                    return (<spam >{state}</spam>); 
                },
            },
            {
                id: "port.ejecutive",
                Header: 'Nombre del ejecutivo',
                disableSortBy: true,
                disableFilters: true,
                width: '14%',
                accessor: d => ({
                    executiveName: _.get(d, 'executiveName', ''),
                }),
                Cell: function cell ({ value: { 
                    executiveName,
                }, row  }) {
                    return (<spam >{executiveName}</spam>); 
                },
            },
            {
                id: 'port.options',
                Header: 'Acciones',
                filter: "text",
                disableFilters: true,
                width: '10%',
                accessor: d => ({
                    id: d.id,
                    item: d,
                }),
                Cell: function cell ({ value: { id, item }, row }) {
                    return (
                        <ActionMenu id={id} >
                            <MenuItem onClick={ () => onClickActionMenu('showRecord', item)}><img src={view}/> Ver puerto</MenuItem>
                            <MenuItem onClick={ () => onClickActionMenu('editRecord', item)}><img src={edit}/> Editar puerto</MenuItem>
                            <MenuItem onClick={ () => toggleModal({type:'eliminar', id})}><img src={trash}/> Eliminar puerto</MenuItem>
                        </ActionMenu>
                    ); 
                }
            },
        ],
    );

    const getNewData = (searchCondition) => {
        setSearchCondition(searchCondition);
    };

    return (
        <>
        {
            openModal && itemSelected.type ==='eliminar' &&
            <Modal
                isOpen={toggleModal}
                toggle={toggleModal}
            >
                <CardContainer btnLeftAction={toggleModal} btnRightAction={()=>deleteUser()} body={<DeleteBody user={'puerto'}/>}/>
            </Modal>
        }
        <section className='ClientRecordAccountSection' >
            <div className='ClientRecordAccountSection__container'>
                <div className='ClientRecordAccountSection__headerContainer'>
                    <div className='HomeTableSection__containerFilters'>
                        <GlobalFilter 
                            filter={searchObj}
                            setFilter={setSearchObj}
                            showTextSearch
                            showDateRangeSearch={false}
                            placeholderSearch={'Buscar...'}
                        />
                    </div>
                    <div className='HomeTableSection__containerButtons'>
                        <Link
                        className={'linkClient'}
                        to={PORTS_CREATE}>
                            <Button className={'containerButtons__clientButton'} srcicon={plus}>CREAR</Button>        
                        </Link>
                    </div>
                </div>
                <div className='ClientRecordAccountSection__tableContainer'>
                    <DataTable
                        columns={columns}
                        data={{
                            data: _.get(props.port, 'data.data', []),
                            totalRecords: _.get(props.port, 'data.totalRecords', 0),
                        }}
                        getData={getNewData}
                        searchObj={searchObj}
                    />
                </div>
            </div>
        </section>
        </>
    );
}

PortsDashboard.propTypes = {
    history: PropTypes.any,
    dispatch: PropTypes.any,
    getAllPortsAction: PropTypes.any,
    port: PropTypes.any,
    deletePortAction: PropTypes.any,
    isSuccessDeletePort: PropTypes.any,
    value: PropTypes.any,
    row: PropTypes.any,
};

function mapStateToProps (state) {
    return {
        port: _.get(state, 'port.ports', {}),
        isSuccessDeletePort: _.get(state, 'port.deletePort.isSuccess', {}),
    };
}

const mapDispatchToProps = dispatch => ({
    getAllPortsAction: params => dispatch(getAllPortsAction(params)),
    deletePortAction: params => dispatch(deletePortAction(params)),
});

export default connect(mapStateToProps,mapDispatchToProps)(PortsDashboard);
