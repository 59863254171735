import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import './Stepper.css'


export const StepperComponent = (props) =>{
    const {steps, setStepper, step, client} = props;
    const stepSetter = (value)=>{
      if(client){
        if(step > value && value > 0){
          setStepper(value);
        }
      }else{
        if(step > value){
          setStepper(value);
        }
      }
    }
  return (
    <Box sx={{ width: '100%' }}>
      {/* <Stepper activeStep={props.step} alternativeLabel>  usar alternativeLabel para poner texto debajo*/}
      <Stepper activeStep={props.step} alternativeLabel> 
        {steps.map((item) => (
          <Step key={item.step} onClick={()=> stepSetter(item.step)}>
            <StepLabel style={{color:'white'}}>{item.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
