import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './AdminForm.scss';
import { ProfileForm } from '../../../components/templates/BaseLayout/Profile/ProfileForm';




const AdminSee = (props) => {
  const data = _.get(props, 'location.payload', null)

  return (
    <div className='main-container admin-profile'>
     
        <ProfileForm type={'see'} data={data}></ProfileForm>
    </div>
  )
}


AdminSee.propTypes = {
};

function mapStateToProps (state) {
    return {
    };
}

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps,mapDispatchToProps)(AdminSee);
