import request from "../../utils/request";
import { addNotification } from './notificationAction';
import {
    PORT_MODULE_GET_ALL_PORTS_BEGIN,
    PORT_MODULE_GET_ALL_PORTS_SUCCESS,
    PORT_MODULE_GET_ALL_PORTS_FAILURE,
    PORT_MODULE_UPSERT_PORT_BEGIN,
    PORT_MODULE_UPSERT_PORT_SUCCESS,
    PORT_MODULE_UPSERT_PORT_FAILURE,
    PORT_MODULE_DELETE_PORT_BEGIN,
    PORT_MODULE_DELETE_PORT_SUCCESS,
    PORT_MODULE_DELETE_PORT_FAILURE,
    PORT_MODULE_GET_ALL_PORTS_CLIENT_BEGIN,
    PORT_MODULE_GET_ALL_PORTS_CLIENT_SUCCESS,
    PORT_MODULE_GET_ALL_PORTS_CLIENT_FAILURE,
} from './../actionTypes';

// ACTIONS TO REDUCER
 
export const getAllPortsBegin = () => ({
    type: PORT_MODULE_GET_ALL_PORTS_BEGIN,
});

export const getAllPortsSuccess = (/* totalRecords, */ data) => ({
    type: PORT_MODULE_GET_ALL_PORTS_SUCCESS,
    // totalRecords,
    data,
});

export const getAllPortsFailure = (err) => ({
    type: PORT_MODULE_GET_ALL_PORTS_FAILURE,
    err
});

export const upsertPortBegin = () => ({
    type: PORT_MODULE_UPSERT_PORT_BEGIN,
});

export const upsertPortSuccess = ({totalRecords, data}) => ({
    type: PORT_MODULE_UPSERT_PORT_SUCCESS,
    data,
    totalRecords,
});

export const upsertPortFailure = (err) => ({
    type: PORT_MODULE_UPSERT_PORT_FAILURE,
    err
});

export const deletePortBegin = () => ({
    type: PORT_MODULE_DELETE_PORT_BEGIN,
});

export const deletePortSuccess = () => ({
    type: PORT_MODULE_DELETE_PORT_SUCCESS,
});

export const deletePortFailure = (err) => ({
    type: PORT_MODULE_DELETE_PORT_FAILURE,
    err,
});
export const getAllPortsClientBegin = () => ({
    type: PORT_MODULE_GET_ALL_PORTS_CLIENT_BEGIN,
});

export const getAllPortsClientSuccess = (/* totalRecords, */ data) => ({
    type: PORT_MODULE_GET_ALL_PORTS_CLIENT_SUCCESS,
    // totalRecords,
    data,
});

export const getAllPortsClientFailure = (err) => ({
    type: PORT_MODULE_GET_ALL_PORTS_CLIENT_FAILURE,
    err
});

// DISPATCH TO ACTIONSv

export function getAllPortsAction (data) {
    return (dispatch, getState) => {
        dispatch(getAllPortsBegin());
        return request({
            partialUrl: `/api/v1/ports/get-all-ports-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(getAllPortsSuccess(data.data));
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(getAllPortsFailure(err));
        });
    };
}

export function upsertPortAction (data) {
    return (dispatch, getState) => {
        dispatch(upsertPortBegin());
        return request({
            partialUrl: `/api/v1/ports/upsert-ports-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(upsertPortSuccess(data.data));
            return true;
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al actualizar la información',
            }));
            return dispatch(upsertPortFailure(err));
        });
    };
}

export function deletePortAction(data){
    return (dispatch, getState) => {
        dispatch(deletePortBegin());
        return request({
            partialUrl: `/api/v1/ports/delete-ports-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(deletePortSuccess(data.data));
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(deletePortFailure(err));
        });
    };
}
export function getAllPortsClientAction (data) {
    return (dispatch, getState) => {
        dispatch(getAllPortsClientBegin());
        return request({
            partialUrl: `/api/v1/ports/get-all-ports-client-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(getAllPortsClientSuccess(data.data));
            return(data.data)
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(getAllPortsClientFailure(err));
        });
    };
}