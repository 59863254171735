/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
// import './Quotation.scss';
import '../../styles/stylesSteppers.scss';
import arrow from '../../assets/images/navieras/arrowButton.png'
import add from '../../assets/images/navieras/AGREGAR.png'
import { useForm } from 'react-hook-form';
import _ from 'lodash'
import { UploadFile } from '../../components/organisms/UploadFile/UploadFile';
import { Button } from '../../components/atoms';
import { upsertQuotationAction } from '../../redux/actions/quotationAction';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';


export const QuotationDocuments = ({
    stepper,
    setStepper,
    toggleModal,
    formData,
    setFormData,
    FormClassName,
    onlyDocs,

    documents = [],
}) => {
    const methods = useForm({ defaultValues: defaultValues });
    const defaultValues = {
        // name : '',
    };

    const quotationData = formData && formData.quotationData ? JSON.stringify(formData.quotationData) : []

    const {
        clientId,
        modalityTypeId,
        providerId,
        serviceExport,
        serviceImport,
    } = formData;

    const quotationD = {
        clientId,
        modalityTypeId,
        providerId,
        serviceExport,
        serviceImport,
    }

    const quotation = JSON.stringify(quotationD);


    // console.warn(quotation)

    // console.warn(quotationData)

    const { register, handleSubmit, watch } = useForm();
    const [mediasDeleted, setMediasDeleted] = useState([]);

    const changeStage = () => {
        console.log('cambio de pestania')
        setStepper(stepper + 1)
    }
    const changeStageBack = () => {
        console.log('cambio de pestania')
        setStepper(stepper - 1)
    }

    // const selectFile = () => {
    //     const inputFile = document.getElementById('documentMedia');
    //         inputFile.click();
    //     }


    const [watchDocumentMedia, setWatchDocumentMedia] = useState(false);
    const [last, setLast] = useState(null);
    const [files, setFiles] = useState(
        [
            {
                name: 'RFC',
                id: 0,
                file: null,
                deletedAt: false,
                watch: false
            },
            {
                name: 'Situación fiscal',
                id: 1,
                file: null,
                deletedAt: false,
                watch: false
            },
            {
                name: 'INE, pasaporte, cédula profesional',
                id: 2,
                file: null,
                deletedAt: false,
                watch: false
            },
        ]
    )

    useEffect(() => {
        if (documents.length > 0) {
            let newFiles = documents.map((el) => {
                console.log(el);
                return {
                    id: el.id,
                    file: {
                        url: el.file.url,
                        name: el.file.url,
                        deletedAt: el.deletedAt,
                    },
                    watch: true
                }
            })
            setFiles(newFiles)
        }
    }, [])

    const [fileToSend, setFileToSend] = useState();
    useEffect(() => {
        let file = [];
        files.forEach((ele) => {
            if (!_.isNil(ele.file)) {
                file.push(ele.file)
            }
        })
        setFileToSend(file)
    }, [files])


    useEffect(() => {
        let final = [...files];
        setLast(final.pop())
    }, [files])

    const data = [
        { name: 'RFC' }, { name: 'Situacion  Fiscal' }, { name: 'INE, pasaporte, cédula profesional' }
    ]


    const handleAdd = () => {
        let arrayCopy = JSON.parse(JSON.stringify(files));
        let lastElement = files.pop();
        arrayCopy.push(
            {
                name: 'Archivo',
                id: lastElement.id + 1,
                file: null,
                watch: false,
                deletedAt: false,
                newAdd: true,
            }
        )
        setFiles(arrayCopy);
    }

    const history = useHistory();
    const dispatch = useDispatch();


    const submitData = async () => {

        // if (_.isEmpty(files)) { toggleModalNoFiles() }

        const formData_ = new FormData();

        formData_.append("quotation", quotation);
        formData_.append("quotationData", quotationData);
        // formData_.append('id', JSON.stringify(quotationData.id));
        formData_.append("mediasDeleted", JSON.stringify(mediasDeleted));

        const arrFiles = files.map(file => file.file != null ? file : false).filter(file => file != false)
        for (let i = 0; i < arrFiles.length; i++) {

            formData_.append("File", arrFiles[i].file);
        }
        const res = await dispatch(upsertQuotationAction(formData_))
        if (res) {
            history.push('/dashboard/cotizacion');
        }

    }


    return (
        <form className={`main-container-generic-type ${FormClassName}`}>
            <div className='header-form-generic-type'>
                <h2>Documentos</h2>
            </div>
            <div className='body-form-generic-type-docs'>
                {
                    files.map((ele, i) => {
                        return (
                            <UploadFile
                                key={i}
                                files={ele}
                                last={last}
                                setFiles={setFiles}
                                arrayComplete={files}
                                hideTitle={true}
                                setArrayDeleted={setMediasDeleted}
                                arrayDeleted={mediasDeleted}
                            />
                        )
                    })
                }
                <div className='add-button-container' style={{ justifyContent: 'center' }}>
                    <img className='add-card' src={add} onClick={handleAdd} />
                </div>
            </div>
            <div className='footer-form-generic-type' style={{ justifyContent: 'space-between' }}>
                <Button type='button' onClick={toggleModal} className={'button-back generate'} style={{ width: '30%' }}>{onlyDocs ? 'REGRESAR' : 'CANCELAR'}</Button>
                {
                    !onlyDocs &&
                    <Button type='button' onClick={toggleModal} className={'button-generate'} style={{ width: '30%' }}>GENERAR EXPEDIENTE</Button>
                }
                <Button type='button' onClick={submitData} className={'button-next'} /* srcicon={arrow} */ style={{ width: '30%' }}>GUARDAR</Button>
            </div>
        </form>
    )
}
