/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Button } from '../../../components/atoms';
import _ from 'lodash';
// import './CustomAgent.scss';
// import './ClientRegister.scss';
import '../../../styles/stylesSteppers.scss'
import arrow from '../../../assets/images/navieras/arrowButton.png'
import camera from '../../../assets/images/navieras/camara.png'
import { useForm } from 'react-hook-form';
import profile from '../../../assets/images/perfil.png';
import { useHistory, useParams } from 'react-router-dom';
import { CUSTOMS_AGENT } from '../../../routes/Paths';

export const PrincipalDataCustomAgent = ({stepper, setStepper, toggleModal, formData, setFormData, edit, setSelectedModal, disabled}) => {

    const { mode } = useParams();
    const [ isDisabled, setIsDisabled ] = useState(mode == 'ver' ? true : false);
    const history = useHistory();
    const imgDefault = _.get(formData, 'media.media.url', null);
    const api = process.env.SERVER_API_DATA_URL;
    // const imgDefault = '1666364783361Lionel_Messi_20180626.jpg';
    const nameDefault = _.get(formData, 'agentname', '')
    const phoneDefault = _.get(formData, 'phone', '')
    const emailDefault = _.get(formData, 'email', '')
    const tradenameDefault = _.get(formData, 'tradename', '')
    const files = _.get(formData, 'file', [])

    // const customsBrokerDefault = _.get(formData, 'customsBroker', '')
    // const patentDefault = _.get(formData, 'patent', '')
    const defaultValues = {
        name : nameDefault,
        phone : phoneDefault,
        email : emailDefault,
        tradename: tradenameDefault,
        files: files,
        // customsBroker: customsBrokerDefault,
        // patent: patentDefault
    };
    const methods = useForm({ defaultValues: defaultValues});
    const { register, handleSubmit, control, reset, formState: { errors }, } = methods;
    const [clientType, setClientType]= useState(null);
    const clientTypes = [
        {
        id : 1,
        name :'Persona fisica'
        },
        {
        id : 2,
        name :'Persona moral'
        },
    ]
    const changeStage = ()=>{
        setStepper(stepper+1)
    }
    const changeStageBack = ()=>{
        setStepper(stepper-1)
    }
    // ---- AGREGAR IMAGEN 
    const [imageSrc, setImageSrc] = useState(profile);
    const [fileUrl, setFileUrl] = useState([]);
    const [imgBase64, setImgBase64] = useState([]);
    const toBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                return resolve({
                    src: reader.result,
                    title: file.name
                });
            }
            reader.onerror = error => reject(error);
        });
    };
    const unpdateInfoFile = (event) => {
        const imageFile = event.target.files;
        const urlImages = [];
        const arrImgBase64 = [];
        Object.entries(imageFile).map(async ele => {

            urlImages.push(URL.createObjectURL(ele[1]));

            try {
                const base64 = await toBase64(ele[1])
                    .then(img => {
                        return img;
                    })

                if (base64) {
                    arrImgBase64.push(base64);
                }
            } catch (err) {
                console.log(err)
                return err
            }

        }
        );


        setFileUrl(
            urlImages,
        )

        setImgBase64(
            arrImgBase64,
        )
        // setPicturesSelected(imageFile.name)

    };

    
    const profileImg = fileUrl[fileUrl.length - 1] ? fileUrl[fileUrl.length - 1] : (imgDefault ? `${api}/${imgDefault}` : imageSrc);

    const setImgProfile = {
        backgroundImage:  `url(${profileImg})`,
        backgroundSize:     "cover",                      
        backgroundRepeat:   "no-repeat",
    }
    //---------
    const onSubmit = async ({name, tradename, phone, email}) => {
        let data;
        if(edit){
            const {portsAgend} = formData;
            data = {
                agend:
                    {   
                    media: imgBase64.length < 1 ? null : {
                        base64: {
                            src: imgBase64[0].src,
                            title: imgBase64[0].title.replace(/[{()}]/g, ''),
                        }
                    },
                    id: formData.id,
                    agentname: name,
                    tradename,
                    phone,
                    files,
                    email,
                    customsBroker:'Mexbuy',
                    patent:'000000',
                    },
                    ports : portsAgend,
            }
        }else{
            data = {
                agend:
                    {
                    media: imgBase64.length < 1 ? null : {
                        base64: {
                            src: imgBase64[0].src,
                            title: imgBase64[0].title.replace(/[{()}]/g, ''),
                        }
                    },
                    files,
                    agentname: name,
                    tradename,
                    phone,
                    email,
                    customsBroker:'Mexbuy',
                    patent:'000000',
                    }
            }
        }
        setFormData(data);
        changeStage()
    }
    //---------
  return (
    <form className='main-container-generic-type' onSubmit={handleSubmit(onSubmit)}>
        <div className='header-form-generic-type'>
            <h2>Datos principales</h2>
        </div>
        <div className='body-form-generic-type-data body-form-generic-type-client'>
            {/* -- */}
            <div className='picture-container'>
                <div className="preContainer">  
                        <div className='container-input'>
                            <input disabled={isDisabled} type='file' id='carga' accept='image/*' className='file' name='image' onChange={unpdateInfoFile} />
                            <label className="LeftInfoComponent-button" htmlFor='carga'>
                                <img className='img-add' src={camera} />
                            </label>
                        </div>
                        <div className='img-profile'style={setImgProfile}>
                        </div>
                </div>
            </div>
            <div className='input-container'>
                <div className='label-container'>
                    <label className='container-label'>Nombre(s)*</label>
                </div>
                <div className='value-container'>
                    <input disabled={isDisabled} control={control} name={'name'} {...register('name')} className="input-generic" type="text" placeholder="Nombre del agente"/>
                </div>
            </div>
            <div className='input-container'>
                <div className='label-container'>
                    <label className='container-label'>Nombre comercial*</label>
                </div>
                <div className='value-container'>
                    <input disabled={isDisabled} control={control} name={'tradename'} {...register('tradename')} className="input-generic" type="text" placeholder="Nombre comercial"/>
                </div>
            </div>
            <div className='input-container'>
                <div className='label-container'>
                    <label className='container-label'>Telefono*</label>
                </div>
                <div className='value-container'>
                    <input disabled={isDisabled} control={control} name={'phone'} {...register('phone')} className="input-generic" type="text" placeholder="Telefono"/>
                </div>
            </div>
            <div className='input-container'>
                <div className='label-container'>
                    <label className='container-label'>Correo*</label>
                </div>
                <div className='value-container'>
                    <input disabled={isDisabled} control={control} name={'email'} {...register('email')} className="input-generic" type="text" placeholder="Correo"/>
                </div>
            </div>
        </div>
        <div className='footer-form-generic-type' style={{justifyContent: 'space-between'}}>
            <Button type='button' onClick={() => mode == 'ver' || mode == 'editar' ? history.push(CUSTOMS_AGENT) : (toggleModal(), setSelectedModal('cancel'))} className={'button-back'} style={{width: '40%'}}>CANCELAR</Button>
            <Button type='submit' className={'button-next'} srcicon={arrow} style={{width: '40%'}}>SIGUIENTE</Button>
            {/* <Button type='button' onClick={changeStage} className={'button-next'} srcicon={arrow} style={{width: '40%'}}>SIGUIENTE</Button> */}
        </div>
    </form>
  )
}
