import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import DetailsViewLayout from "../../../components/molecules/DetailsViewLayout/QuotationDetailsView";

import '../QuotationForms.scss';
import '../../../components/molecules/DetailsViewLayout/DetailsView.scss';
import AerialQuotationForm from '../AerialQuotationForm';
import _ from 'lodash';
import LandQuotationForm from '../LandQuotationForm';
import MaritimeFCLQuotationForm from '../MaritimeFCLQuotationForm';
import MaritimeLCLQuotationForm from '../MaritimeLCLQuotationForm';
import { getAllPortsClientAction } from '../../../redux/actions/portAction';
export const SeeQuotationRegister = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const data = _.get(props, 'location.state', null)
    const [formData, setFormData] = useState(null)
    const [client, setClient] = useState(3);
    const [ countriesSelect, setCountriesSelect ] = useState(null);
    const [clientPorts, setClientPorts] = useState(null);
    const modalityType = data.modalityTypeId;
    useEffect(() => {
        if(data.modalityAir){
            setFormData(data.modalityAir)
        }else if(data.modalityGround){
            setFormData(data.modalityGround)
        }else if(data.modalityMarine){
            setFormData(data.modalityMarine)
        }
    }, [data])
    
    useEffect(() => {   
        const getPorts = async () => {
            const res = await dispatch(getAllPortsClientAction({clientId : client}))
            if(res){ 
                setClientPorts(res)
            }
        }
        getPorts();
    }, [client])



    

    useEffect(() => {
        let countries = [];
        let puertos;
        
        if(clientPorts!==null && clientPorts.data.length > 0){
            clientPorts && clientPorts.data.map((ele, index) => {
                puertos = 
                    { ...puertos,
                    [ele.countryId]:{
                    countryName: ele.countryName,countryId:ele.countryId}
                    }
                })
                Object.entries(puertos).map(([key, value]) => {
                countries.push(value)
                });
                setCountriesSelect(countries)
        }
    }, [clientPorts])
    
    
    const modalityDisplay = ()=>{
        if(modalityType===1){
            return <AerialQuotationForm principalDataAerialForm={formData} setPrincipalDataAerialForm={setFormData}/>
        }else if(modalityType===2){
            return <LandQuotationForm principalDataLandForm={formData} setPrincipalDataLandForm={setFormData}/>
        }else if(modalityType===3 && countriesSelect){
            let valor  = _.get(data, 'modalityMarine.fcl');
            let displayValue = valor === true ?  
            <MaritimeFCLQuotationForm principalDataMaritimeForm={formData} setPrincipalDataMaritimeForm={setFormData} clientPorts={clientPorts}
            countriesSelect={countriesSelect}/>
            : 
            <MaritimeLCLQuotationForm principalDataMaritimeForm={formData} setPrincipalDataMaritimeForm={setFormData} clientPorts={clientPorts}
            countriesSelect={countriesSelect}/>
            
            return displayValue
        }
    }
    return (
        <>
        {
            formData &&
            <>
                <DetailsViewLayout
                    data={data}
                    formData={formData}
                >
                    {modalityDisplay()}
                    {/* <AerialQuotationForm principalDataAerialForm={formData} setPrincipalDataAerialForm={setFormData}/> */}
                </DetailsViewLayout>
            
            </>
        }
        </>
        
    )
}
