/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../../components/organisms/PortsForm/PortsForm.scss'
import deleteIcon from '../../../assets/images/navieras/deleteTrash2.png';
import { getAllPortsAction } from '../../../redux/actions/portAction';
import _ from 'lodash';

export const DesviationsForm = ({setCountries, ports, arrayComplete, setFormsBorrados, formsBorrados}) => {
  
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getAllPortsAction());
    },[]);

    const portsAvailable = useSelector(state => state.port.ports.data);
    console.warn('puertos disponibles: ', portsAvailable);

    const handleAddFile = (e) => {
        let arrayCopy = JSON.parse(JSON.stringify(arrayComplete));
        arrayCopy.map( (el) => {
            if(ports.id === el.id){
                    el[e.target.name] = e.target.value;
            }
        })
        setCountries(arrayCopy)
    }

    const handleDelete = () =>{
        let arrayCopy = JSON.parse(JSON.stringify(arrayComplete));
        let borrado = [];
        let arreglo = [];
        arrayCopy.forEach((i) => (i.id !== ports.id ? arreglo.push(i) : borrado.push({
            id: i.id,
            deletedAt: new Date(),
        })));
        setCountries(arreglo);
        if(formsBorrados.length > 0){
            setFormsBorrados([...formsBorrados,...borrado]);
        }else{
            setFormsBorrados([...borrado]);
    }}
    
    const [ countrySelected, setCountrySelected ] = useState({
        name: '',
        id: '',
    });

    return (
        <>
            <div className='record-form-container'>
                    {
                        arrayComplete.length !== 1 && 
                        <div className='delete-container-button'>
                            <img src={deleteIcon} onClick={()=> handleDelete()} alt="" />
                        </div>
                    }
                <div className='inputs-container'>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>País</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={countrySelected.port} onChange={(e) => {
                                setCountrySelected(parseInt({...countrySelected, id: e.target.value})), handleAddFile(e)
                            }}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    _.get(portsAvailable, 'data.rows') &&
                                    portsAvailable.data.rows.map((ele, i) => {
                                        return <option key={i} value={ele.id}>{ele.port}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>     
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Estado</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={countrySelected.port} onChange={(e) => {
                                setCountrySelected(parseInt({...countrySelected, id: e.target.value})), handleAddFile(e)
                            }}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    _.get(portsAvailable, 'data.rows') &&
                                    portsAvailable.data.rows.map((ele, i) => {
                                        return <option key={i} value={ele.id}>{ele.port}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>    
            </div>
        </>
    )   
}

