import request from "../../utils/request";
import { addNotification } from './notificationAction';
import {
    PROVIDER_MODULE_GET_ALL_PROVIDERS_BEGIN,
    PROVIDER_MODULE_GET_ALL_PROVIDERS_SUCCESS,
    PROVIDER_MODULE_GET_ALL_PROVIDERS_FAILURE,
    PROVIDER_MODULE_UPSERT_PROVIDER_BEGIN,
    PROVIDER_MODULE_UPSERT_PROVIDER_SUCCESS,
    PROVIDER_MODULE_UPSERT_PROVIDER_FAILURE,
    PROVIDER_MODULE_DELETE_PROVIDER_BEGIN,
    PROVIDER_MODULE_DELETE_PROVIDER_SUCCESS,
    PROVIDER_MODULE_DELETE_PROVIDER_FAILURE,
} from './../actionTypes';

// ACTIONS TO REDUCER

export const getAllProvidersBegin = () => ({
    type: PROVIDER_MODULE_GET_ALL_PROVIDERS_BEGIN,
});

export const getAllProvidersSuccess = (data) => ({
    type: PROVIDER_MODULE_GET_ALL_PROVIDERS_SUCCESS,
    data,
});

export const getAllProvidersFailure = (err) => ({
    type: PROVIDER_MODULE_GET_ALL_PROVIDERS_FAILURE,
    err
});

export const upsertProviderBegin = () => ({
    type: PROVIDER_MODULE_UPSERT_PROVIDER_BEGIN,
});

export const upsertProviderSuccess = () => ({
    type: PROVIDER_MODULE_UPSERT_PROVIDER_SUCCESS,
});

export const upsertProviderFailure = (err) => ({
    type: PROVIDER_MODULE_UPSERT_PROVIDER_FAILURE,
    err
});

export const deleteProviderBegin = () => ({
    type: PROVIDER_MODULE_DELETE_PROVIDER_BEGIN,
});

export const deleteProviderSuccess = () => ({
    type: PROVIDER_MODULE_DELETE_PROVIDER_SUCCESS,
});

export const deleteProviderFailure = (err) => ({
    type: PROVIDER_MODULE_DELETE_PROVIDER_FAILURE,
    err,
});


// DISPATCH TO ACTIONSv

export function getAllProvidersAction (data) {
    return (dispatch, getState) => {
        dispatch(getAllProvidersBegin());
        return request({
            partialUrl: `/api/v1/provider/get-all-provider-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(getAllProvidersSuccess(data.data));
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(getAllProvidersFailure(err));
        });
    };
}

export function upsertProviderAction (data) {
    console.log("seen data provider");
    console.log(data);
    return (dispatch, getState) => {
        dispatch(upsertProviderBegin());
        return request({
            partialUrl: `/api/v1/provider/upsert-provider-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
            headers: {
                //'Content-Type': 'multipart/form-data; boundary=—-WebKitFormBoundaryfgtsKTYLsT7PNUVD',
                //Pragma: 'no-cache',
                // 'Cache-control': 'no-store',
                // 'Access-Control-Allow-Origin': '*',
                // 'access-control-allow-origin': '*',
            },
            isFileUpload: true,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(upsertProviderSuccess(data.data));
            return true;
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
            }));
            return dispatch(upsertProviderFailure(err));
        });
    };
}

export function deleteProviderAction(data){
    return (dispatch, getState) => {
        dispatch(deleteProviderBegin());
        return request({
            partialUrl: `/api/v1/provider/delete-provider-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(deleteProviderSuccess(data.data));
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(deleteProviderFailure(err));
        });
    };
}
