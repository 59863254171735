import React, { useEffect, useState } from "react";
import "./ModificationCurrency.scss";
import USD from "../../assets/images/USD.png";
import EUR from "../../assets/images/EUR.png";
import CAD from "../../assets/images/CAD.png";
import GBP from "../../assets/images/GBP.png";
import ControlledRadioButtonsGroup from "../../components/atoms/RadioGroup";
import { useDispatch, useSelector } from "react-redux";
import { getAllForexAction, upsertForexAction } from "../../redux/actions/forexAction";
import { SettingsApplicationsSharp } from "@material-ui/icons";
import _ from "lodash";
import ControlledSwitches from "../../components/atoms/SwitchControl";
import { Button } from "../../components/atoms";
import Modal from "../../components/organisms/Modal/Modal";
import { CardContainer } from "../../components/molecules/CardContainer/CardContainer";
import { ForexFormBody } from "../../components/molecules/CancelFormBody/ForexFormBody";
import { url_api } from "../../config";


const ModificationCurrency = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllForexAction());
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const priceForex = useSelector((state) => state.forex.allForex.data);
  const [priceUSD, setPriceUSD] = useState()
  const [priceCAD, setPriceCAD] = useState()
  const [priceEuro, setPriceEUR] = useState()
  const [priceLibra, setPriceGBP] = useState()
  const [status,setStatus] = useState(false)
  const [cents,setCents] = useState('0.1');
  const [flagName,setFlagName] = useState('eu')

  const changePrice = () => {

    let forexValues = []

    let arrayForex = ['USD','CAD','EUR','GBP']

   
    let newUSD = priceUSD-cents
    newUSD = newUSD.toFixed(4)
    setPriceUSD(newUSD)

    let newCAD = priceCAD-cents
    newCAD = newCAD.toFixed(4)
    setPriceCAD(newCAD)

    
    let newEUR = priceEuro-cents
    newEUR = newEUR.toFixed(4)
    setPriceEUR(newEUR)

    
    let newGBP = priceLibra-cents
    newGBP = newGBP.toFixed(4)
    setPriceGBP(newGBP)


    arrayForex.forEach(element => {
        let newObj = {}
  
        switch (element) {
            case 'USD':
                newObj.id = 'USD'
                newObj.valueCoin = newUSD
                forexValues.push(newObj)
                
            break;
            case 'CAD':
                newObj.id = 'CAD'
                newObj.valueCoin = newCAD
                forexValues.push(newObj)
                
            break;
            case 'EUR':
                newObj.id = 'EUR'
                newObj.valueCoin = newEUR
                forexValues.push(newObj)
                
            break;
            case 'GBP':
                newObj.id = 'GBP'
                newObj.valueCoin = newGBP
                forexValues.push(newObj)
                
            break;
        
            default:
                break;
        }
     
    });
  
    const data = {
        valueModified: {
            forexValues: forexValues
        } 
    }

    dispatch(upsertForexAction(data))
  }
  
  useEffect(() => {
    
    priceForex.forEach(element => {

        switch (element.displayName) {
            case 'USD':
                setPriceUSD(element.valueCoin)
                break;
            case 'CAD':
                setPriceCAD(element.valueCoin)
            break;
            case 'EUR':
                setPriceEUR(element.valueCoin)
            break;
            case 'GBP':
                setPriceGBP(element.valueCoin) 
            break;
            default:
                break;
        }

        if(element.valueCoin === element.valueCoinModified){
            setStatus(false)
        }

    });
   
  }, [priceForex])

  const toggleModal = () => {
   
    setStatus(false);
  };

  const activateModifiedForex = () => {
   
    setStatus(true);
  };
  

  return (
    <>
    {status && (
        <Modal isOpen={toggleModal} toggle={toggleModal}>
          <CardContainer
            btnLeftAction={toggleModal}
            btnRightAction={() => activateModifiedForex()}
            body={<ForexFormBody />}
          />
        </Modal>
      )}
    <div className="ModificationCurrencyMainCointainer">
      <div className="ModificationCurrencyHeader">
        <h2 className="TitleUsers">Cambio de denominación</h2>
      </div>
      <div className="ModificationCurrencyCointainer">
        <div className="ColumnsContainer">
          <div>
            <h3 style={{ color: "#79DECA" }}>Precio publicado por AWS</h3>
            {priceForex.map((item,i) => (
              <div className="ForexAWS">
                <div
                  className="ContainerLabelOne"
                  style={{ backgroundColor: "#36383e" }}
                  key={i}
                >
                  <label>$ {item.valueCoin} MXN</label>
                </div>
                <div
                  className="ContainerLabel"
                  style={{ backgroundColor: "#36383e" }}
                >
                  <img style={{ width: "35%" }} src={`${url_api}${"/forex/"}${item.displayName}.png`} />
                  <label>{item.displayName}</label>
                </div>
              </div>
            ))}
          </div>
          <div>
            <h3 style={{ color: "#79DECA" }}>Modificar Precio</h3>
            <div className="changePriceContainer">
              <div className="descriptionContainer">
                <p>
                  Selecciona el valor por el cual modificaras el valor final de
                  la moneda publicado por AWS
                </p>
              </div>
              <div className="checkboxContainer">
                <ControlledRadioButtonsGroup value={cents} setCents={setCents}/>
              </div>
              <div className="btnContainerCurrency">
                <Button className={'btnCurrency'} onClick={()=> changePrice()}>MODIFICAR PRECIO</Button>

              </div>
            </div>
          </div>
          <div>
            <h3 style={{ color: "#79DECA" }}>Precio modificado</h3>
            <div className="ForexAWS">
              <div
                className="ContainerLabelOne"
                style={{ backgroundColor: "#36383e" }}
              >
                <label>$ {priceUSD} MXN</label>
              </div>
              <div
                className="ContainerLabel"
                style={{ backgroundColor: "#36383e" }}
              >
                <img style={{ width: "35%" }} src={USD} />
                <label>USD</label>
              </div>
              <div
                className="ContainerLabelOne"
                style={{ backgroundColor: "#36383e" }}
              >
                <label>$ {priceCAD} MXN</label>
              </div>
              <div
                className="ContainerLabel"
                style={{ backgroundColor: "#36383e" }}
              >
                <img style={{ width: "35%" }} src={CAD} />
                <label>CAD</label>
              </div>
              <div
                className="ContainerLabelOne"
                style={{ backgroundColor: "#36383e" }}
              >
                <label>$ {priceEuro} MXN</label>
              </div>
              <div
                className="ContainerLabel"
                style={{ backgroundColor: "#36383e" }}
              >
                <img style={{ width: "35%" }} src={EUR} />
                <label>EUR</label>
              </div>
              <div
                className="ContainerLabelOne"
                style={{ backgroundColor: "#36383e" }}
              >
                <label>$ {priceLibra} MXN</label>
              </div>
              <div
                className="ContainerLabel"
                style={{ backgroundColor: "#36383e" }}
              >
                <img style={{ width: "35%" }} src={GBP} />
                <label>GBP</label>
              </div>
            </div>
            <div style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
                <h3>Activar precio modificado</h3>
                <ControlledSwitches status={status} setStatus={setStatus} setOpenModal={setStatus} onClick={() =>toggleModal()}/>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  
  );
};

export default ModificationCurrency;
