import * as React from 'react';
import Switch from '@mui/material/Switch';

export default function ControlledSwitches(props) {

  const [checked, setChecked] = React.useState(props.status);

  const handleChange = (event) => {
   
    setChecked(event.target.checked);
    props.setStatus(event.target.checked)
    
  };
  console.log(checked)

  return (
    <Switch
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />
  );
}
