/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';
import { Button } from '../../../components/atoms';
import { getAllCountriesAction, getAllStatesAction } from '../../../redux/actions/addressAction';
import './RecordManualCapture.scss';

const RecordManualCapture = ({ setDeliveryHistory, deliveryHistory, toggleModal, setLatPosition, setLngPosition }) => {

    const countries = useSelector(state => state.address.countries.data);
    const states = useSelector(state => state.address.states.data);

    const [ date, setDate ] = useState('');
    const [ action, setAction ] = useState('');
    const [ countrySelected, setCountrySelected ] = useState('');
    const [ countrySelectedName, setCountrySelectedName ] = useState('');
    const [ lat, setLat ] = useState('');
    const [ lng, setLng ] = useState('');

    const [ stateSelected, setStateSelected ] = useState('');

    const nameCountry = (e) => {
        const currentCountry = countries.data.find( country => country.id == e.target.value);
        setCountrySelectedName(currentCountry.name);
    }

    const latLngState = (e) => {
        const currentState = states.data.find( state => state.name == e.target.value);
        setLat(currentState.latitude && currentState.latitude);
        setLng(currentState.longitude && currentState.longitude);
    }

    const saveDeliveryStatus = (data) => {
        setDeliveryHistory([...deliveryHistory, data]);
        console.warn(deliveryHistory);
        toggleModal();
    }

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getAllCountriesAction({}))
    },[]);

    return (
        <div className='capture-modal-container'>
            <div className='capture-title-container'>
                <h4>Ingresar posición:</h4>
                <div className='inputs-container'>
                    <div className='input-container-record'>
                        <div className='label-container'>
                            <label className='container-label'>País</label>
                        </div>
                        <div className='value-container' >
                            <select required className='input-select' value={countrySelected} onChange={(e) => {
                                setCountrySelected(e.target.value);
                                dispatch(getAllStatesAction(
                                    {
                                        where: {
                                            countryId: parseInt(e.target.value)
                                        }
                                    }
                                ));
                                nameCountry(e)
                            }}>
                                <option className='option-selected' value={null} hidden>{countrySelected.name != null ? countrySelected.name : "Seleccionar"}</option>
                                {
                                    _.get(countries, 'data') &&
                                    countries.data.map(country => {
                                        return <option value={country.id}>{country.name}</option>
                                    })
                                }
                            </select >
                        </div>
                    </div>   
                    <div className='input-container-record'>
                        <div className='label-container'>
                            <label className='container-label'>Estado</label>
                        </div>
                        <div className='value-container' >
                            <select required className='input-select' value={stateSelected} onChange={(e) => {
                                setStateSelected(e.target.value);
                                latLngState(e);
                            }}>
                                <option className='option-selected' value={null} selected hidden>{stateSelected.name != null ? stateSelected.name : "Seleccionar"}</option>
                                {
                                    _.get(states, 'data') &&
                                    states.data.map((ele, i) => {
                                        return <option key={i} value={ele.name}> {ele.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>   
                <div className='capture-date-action'>
                    <div className='input-container-record'>
                        <div className='label-container'>
                            <label className='container-label'>Ingresar fecha:</label>
                        </div>
                        <div className='value-container'>
                            <input type='date' value={date ? date : '' } onChange={(e) => setDate(e.target.value)} className='input-time operative'/>
                        </div>
                    </div>
                    <div className='input-container-record'>
                        <div className='label-container'>
                            <label className='container-label'>Ingresar acción:</label>
                        </div>
                        <div className='value-container'>
                            <input value={action ? action : null} onChange={(e) => setAction(e.target.value)} placerholder={'Agregar...'} className="input-generic" type="text"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-form-generic-type' style={{justifyContent: 'space-between'}}>
                <Button type='button' onClick={toggleModal} className={'button-back'} style={{width: '40%'}}>CANCELAR</Button>
                <Button type='submit' className={'button-next'} style={{width: '40%'}} onClick={() => {
                    saveDeliveryStatus({
                        country: {name: countrySelectedName},
                        state: {name: stateSelected},
                        date: date,
                        status: action,
                        lat: lat,
                        lng: lng
                    });
                    setLatPosition(lat);
                    setLngPosition(lng);
                }}>
                    GUARDAR
                </Button>
            </div>
        </div>
    )
}

export default RecordManualCapture;
