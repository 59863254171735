import React, { useState, useEffect } from "react";
import "./chat.css";
import axios from "axios";
import sailsIOClient from 'sails.io.js';
import socketIOClient from 'socket.io-client'



// var sailsIOClient = require("sails.io.js");
// var io = sailsIOClient(socketIOClient);

function chat() {
   
    var ioClient = sailsIOClient(socketIOClient);
    ioClient.sails.useCORSRouteToGetCookie = false;
    const socket = ioClient.connect('ws://localhost:1337',{transports: ['websocket']});
    // const socket = io.connect('http://localhost:1337');

  const [text, setText] = useState("");
  const [messages, setMessages] = useState([]);
  function sendMessage() {
    axios.post("http://localhost:1337/send-message", { text }).then((res) => {
      console.log(res);
    });
  }

  useEffect(() => {
    console.log("entra?")
    ioClient.socket.get("/sub-chat", (response) => {

        console.log(response);
      });
   
    }, []);

     //   return () => {
    //     io.socket.disconnect();
    //   };


    // function serverResponded(body, JWR) {
    //     console.log("entra?2")
    //   io.socket.on("message", function (message) {
    //     messages.push(message);
    //     console.log("lohace")
    //     setMessages([...messages]);
    //   });
    // }
    
    

  return (
    <div className="chat-wrapper">
      <div className="chat-header">
        <h2>Chat general</h2>
      </div>
      <div className="chat-content">
        {messages.map((message, index) => (
          <div className="chat-message" key={index}>
            <p className="chat-text">{message}</p>
          </div>
        ))}
      </div>
      <div className="chat-footer">
        <input
          value={text}
          onChange={(e) => setText(e.target.value)}
          type="text"
        />
        <button onClick={() => sendMessage()}>Enviar</button>
      </div>
    </div>
  );
}
export default chat;
