import React, { useState } from "react";
import "../../styles/stylesSteppers.scss";
import "../ProvidersDashboard/ProviderPrincipalData.scss";
import "./OperativePrincipalDataForm.scss";
import "../../components/templates/BaseLayout/Profile/ProfileFormStyles.scss";
import "./OperativeIdentity.scss";
import { Button } from "../../components/atoms";
import { useForm } from "react-hook-form";
import _, { isNil } from 'lodash';


const OperativeIdentity = (props, { setStepper, stepper, dataSecondStep }) => {
  const [curp, setCurp] = useState();
  const [identificationNumber, setIdentificationNumber] = useState();
  const [fileUrl, setFileUrl] = useState([]);
  const [media, setImgBase64] = useState([]);
  let arrayFiles = [];
  const [profilePhoto, setProfilePhoto] = useState({
    name: "Subir archivo",
    url: "",
  });
  const [profilePhoto1, setProfilePhoto1] = useState({  name: "Subir archivo", url: '' });
  const [profilePhoto2, setProfilePhoto2] = useState({ name: "Subir archivo", url: '' });
  const [profilePhoto3, setProfilePhoto3] = useState({ name: "Subir archivo", url: '' });

  const changeStage = () => {
    props.setStepper(props.stepper + 1);
  };
  const changeStageBack = () => {
    props.setStepper( props.stepper - 1);
  };

  const defaultValues = {};

  const onClickInput = (inputId) => {
    const inputFile = document.getElementById(`input-logo${inputId}`);
    inputFile.click();
  };

  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        return resolve({
          src: reader.result,
          title: file.name,
        });
      };
      reader.onerror = (error) => reject(error);
    });
  };
  const updateInfoFile = (event,inputId) => {
    let profilePhoto = URL.createObjectURL(event.target.files[0]);
    // setProfilePhoto({ ...profilePhoto, url: profilePhoto });
    // setProfilePhoto({ ...profilePhoto, name: event.target.files[0].name });
    if (inputId === 1) {
      console.log("entro a 1")
      setProfilePhoto1({ name: event.target.files[0].name, url:profilePhoto });
    } else if (inputId === 2) {
      console.log("entro a 2")

      setProfilePhoto2({ name: event.target.files[0].name, url:profilePhoto });
    } else if (inputId === 3) {
      console.log("entro a 3")

      setProfilePhoto3({ name: event.target.files[0].name, url:profilePhoto });
    }
    const imageFile = event.target.files;
    console.log("---imageFile---",imageFile)
    const urlImages = [];
    const arrImgBase64 = [];
    let object;
    Object.entries(imageFile).map(async (ele) => {
      urlImages.push(URL.createObjectURL(ele[1]));

      try {
        const base64t = await toBase64(ele[1]).then((img) => {
          return img;
        });

        if (base64t) {
          arrImgBase64.push(base64t);
          const base64 ={
            base64: base64t
          }
          setImgBase64(media => [...media,base64])
        }
       
      } catch (err) {
        console.log(err);
        return err;
      }
    });

    setFileUrl(urlImages);
    arrayFiles.push(object)
    

    // setImgBase64(arrImgBase64);
    // setPicturesSelected(imageFile.name)
  };

  console.log(media)

  


  const methods = useForm({ defaultValues: defaultValues });
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = async () => {

    

    const data = {
      person: {
        identificationNumber: identificationNumber,
        curp: curp,
      },
      files: 
       media.length < 1 ?
       null
       : {
          media: media
       },
      
    };
    

    console.log(media,"si los mete en el array?")

    props.dataSecondStep(data);
    props.setStepper(props.stepper + 1);
   
  };

  return (
    <>
      <div className="main-container-generic-date-identity">
        <div className="headerIdentity">
          <h2>Datos de identidad</h2>
        </div>
        <form
          className="form-admin-container-second"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div
            className="body-form-generic-type-data"
            style={{ height: "70%" }}
          >
            <div
              className="body-form"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                width: "100%",
              }}
            >
              {/* cada input-container es un renglon */}
              <div className="input-container">
                <div className="ContainerForm">
                  <div className="labelContainerIdentity">
                    <label className="containerLabelIdentity">CURP</label>
                  </div>
                  <div className="value-container">
                    <input
                      className="input-generic"
                      required
                      control={control}
                      name={"curp"}
                      type="text"
                      placeholder="Agregar..."
                      onChange={(e) => setCurp(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="input-container">
                <div className="ContainerForm">
                  <div className="labelContainerIdentity">
                    <label className="containerLabelIdentity">
                       Número de identificación 
                    </label>
                  </div>
                  <div className="value-container">
                    <input
                      className="input-generic"
                      required
                      control={control}
                      name={"identificationNumber"}
                      type="text"
                      placeholder="Agregar..."
                      onChange={(e) => setIdentificationNumber(e.target.value)}
                    />
                  </div>
                </div>
              </div>
          {}    <div className="input-container">
                <div className="input-container provider div-input">
                  <div className="input-label-container">
                    <div className="label-container">
                      <label className="container-label">INE (Frente)</label>
                    </div>
                    <div className="value-container div-input">
                      <div
                        className="input-generic div-input"
                        onClick={() => onClickInput(1)}
                      >
                        {/* <p>{profilePhoto1.name}</p> */}
                        <div className="icon-container">
                          {/* <img src={file} /> */}
                          SUBIR
                        </div>
                      </div>
                      <input
                        className="input-files"
                        id={"input-logo1"}
                        type="file"
                        accept="image/png, image/jpg, image/jpeg"
                        placeholder="Subir archivo"
                        onChange={(event) => updateInfoFile(event, 1)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-container">
                <div className="input-container provider div-input">
                  <div className="input-label-container">
                    <div className="label-container">
                      <label className="container-label">INE (Reverso)</label>
                    </div>
                    <div className="value-container div-input">
                      <div
                        className="input-generic div-input"
                        onClick={() => onClickInput(2)}
                      >
                        {/* <p>{profilePhoto2.name}</p> */}
                        <div className="icon-container">
                          {/* <img src={file} /> */}
                          SUBIR
                        </div>
                      </div>
                      <input
                        className="input-files"
                        id={"input-logo2"}
                        type="file"
                        accept="image/png, image/jpg, image/jpeg"
                        placeholder="Subir archivo"
                        onChange={(event) => updateInfoFile(event, 2)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-container">
                <div className="input-container provider div-input">
                  <div className="input-label-container">
                    <div className="label-container">
                      <label className="container-label">Comprobante de domicilio</label>
                    </div>
                    <div className="value-container div-input">
                      <div
                        className="input-generic div-input"
                        onClick={() => onClickInput(3)}
                      >
                        {/* <p>{profilePhoto3.name}</p> */}
                        <div className="icon-container">
                          {/* <img src={file} /> */}
                          SUBIR
                        </div>
                      </div>
                      <input
                        className="input-files"
                        id={"input-logo3"}
                        type="file"
                        accept="image/png, image/jpg, image/jpeg"
                        placeholder="Subir archivo"
                        onChange={(event) => updateInfoFile(event, 3)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="BtnContainer">
            <Button
              type="button"
              onClick={changeStageBack }
              className={"buttonNextStep"}
              style={{ width: "40%" }}
            >
              ANTERIOR
            </Button>
            <Button
              type="submit"
              onClick={changeStage + onSubmit}
              className={"buttonNextStep"}
              style={{ width: "40%" }}
            >
              SIGUIENTE
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default OperativeIdentity;
