import _ from 'lodash';
import {
    COMMISSION_MODULE_GET_ALL_COMMISSION_BEGIN,
    COMMISSION_MODULE_GET_ALL_COMMISSION_SUCCESS,
    COMMISSION_MODULE_GET_ALL_COMMISSION_FAILURE,
 } from '../actionTypes';

import initialState from '../initialState';

export default function chargesReducer(state=initialState.commission, action){
    switch (action.type) {
        case COMMISSION_MODULE_GET_ALL_COMMISSION_BEGIN:
            return {
                ...state,
                allCommission: {
                    
                    isLoading: true,
                    isSuccess: false,
                    err: null, 
                    totalRecords: 0,
                    data: [],
                }
            };
        case COMMISSION_MODULE_GET_ALL_COMMISSION_SUCCESS:
            return {
                ...state,
                allCommission: {
                    
                    data: action.data,
                },
            };
        case COMMISSION_MODULE_GET_ALL_COMMISSION_FAILURE:
            return {
                ...state,
                allCommission: {
                   
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
                },
            };
            // ---

        default:
            return state;
    }
}
