import _ from 'lodash';
import {
    REPORT_TYPE_MODULE_GET_ALL_REPORT_TYPE_BEGIN,
    REPORT_TYPE_MODULE_GET_ALL_REPORT_TYPE_SUCCESS,
    REPORT_TYPE_MODULE_GET_ALL_REPORT_TYPE_FAILURE,
    REPORT_MODULE_UPSERT_REPORT_BEGIN,
    REPORT_MODULE_UPSERT_REPORT_SUCCESS,
    REPORT_MODULE_UPSERT_REPORT_FAILURE,
    REPORT_MODULE_GET_ALL_REPORT_BEGIN,
    REPORT_MODULE_GET_ALL_REPORT_SUCCESS,
    REPORT_MODULE_GET_ALL_REPORT_FAILURE,
    REPORT_MODULE_DELETE_REPORT_BEGIN,
    REPORT_MODULE_DELETE_REPORT_SUCCESS,
    REPORT_MODULE_DELETE_REPORT_FAILURE
 } from '../actionTypes';

import initialState from '../initialState';

export default function chargesReducer(state=initialState.reports, action){
    switch (action.type) {
        case REPORT_TYPE_MODULE_GET_ALL_REPORT_TYPE_BEGIN:
            return {
                ...state,
                allReportType: {
                    
                    isLoading: true,
                    isSuccess: false,
                    err: null, 
                    totalRecords: 0,
                    data: [],
                }
            };
        case REPORT_TYPE_MODULE_GET_ALL_REPORT_TYPE_SUCCESS:
            return {
                ...state,
                allReportType: {
                    
                    data: action.data,
                },
            };
        case REPORT_TYPE_MODULE_GET_ALL_REPORT_TYPE_FAILURE:
            return {
                ...state,
                allReportType: {
                   
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
                },
            };
        case  REPORT_MODULE_UPSERT_REPORT_BEGIN:
            return {
                ...state,
                upsertReport: {
                   
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
                },
            };
        case  REPORT_MODULE_UPSERT_REPORT_FAILURE:
                return {
                    ...state,
                    upsertReport: {
                       
                        isLoading: false,
                        isSuccess: false,
                        err: action.err,
                        totalRecords: 0,
                        data: [],
                    },
            };
            case REPORT_MODULE_UPSERT_REPORT_SUCCESS:
                    return {
                        ...state,
                        upsertReport: {
                           
                            
                            data: action.data,
                        },
                };
                case REPORT_MODULE_GET_ALL_REPORT_BEGIN:
                    return {
                        ...state,
                        allReports: {
                           
                            isLoading: false,
                            isSuccess: false,
                            err: action.err,
                            totalRecords: 0,
                            data: [],
                        },
                };
                case REPORT_MODULE_GET_ALL_REPORT_SUCCESS:
                    return {
                        ...state,
                        allReports: {
                            data: action.data,
                        },
                };
                case REPORT_MODULE_GET_ALL_REPORT_FAILURE:
                    return {
                        ...state,
                        allReports: {
                            isLoading: false,
                            isSuccess: false,
                            err: action.err,
                            totalRecords: 0,
                            data: [],
                        },
                };
                case REPORT_MODULE_DELETE_REPORT_BEGIN:
                    return {
                        ...state,
                        deleteReport: {
                            isLoading: false,
                            isSuccess: false,
                            err: action.err,
                            totalRecords: 0,
                            data: [],
                        },
                };
                case REPORT_MODULE_DELETE_REPORT_SUCCESS:
                    return {
                        ...state,
                        deleteReport: {
                            data: action.data,
                        },
                };
                case REPORT_MODULE_DELETE_REPORT_FAILURE:
                    return {
                        ...state,
                        deleteReport: {
                            isLoading: false,
                            isSuccess: false,
                            err: action.err,
                            totalRecords: 0,
                            data: [],
                        },
                };
            // ---

        default:
            return state;
    }
}
