/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import '../../styles/stylesSteppers.scss';
import _ from 'lodash'
import { Button } from '../../components/atoms';
import { CardContainer } from '../../components/molecules/CardContainer/CardContainer';
import Modal from '../../components/organisms/Modal/Modal';
import './Records.scss';
import './RecordRegister.scss';
import { RECORDS } from '../../routes/Paths';
import { Checkbox } from '@mui/material';

const RecordsDelay = () => {

    const [ delays, setDelays ] = useState({
        emptyReturnDate: '',
        lastDayDelays: '',
        emptyReturnDaysDelay: '',
        delayReasons: '',
        delayTotalPayment: '',
        paymentSettlementDate: '', 
        paymentDone: false,
    })

    const history = useHistory();
    const cancel = () => {
        history.push(RECORDS);
    }

    let recordId = 'E001';

    return (
        <div className='records-documentation-container'>
            <form className='main-container-generic-type records'>
                <div className='header-form-generic-type'>
                    <h2>Demoras</h2>
                    <h4>ID: {recordId}</h4>
                </div>
                <div className='body-form-generic-type-register'>
                    <div className='record-register-general-data'>                    
                        <div className='register-inputs-container'>
                            <div className='input-container-record delay-date'>
                                <div className='label-container'>
                                    <label className='container-label'>Regreso de vacío</label>
                                </div>
                                <div className='value-container'>
                                    <input 
                                        type='date' 
                                        value={delays.emptyReturnDate}
                                        onChange={(e) => setDelays({...delays, emptyReturnDate: e.target.value})}
                                        className='input-time records'
                                    />
                                </div>
                            </div>
                            <div className='input-container-record delay-date'>
                                <div className='label-container'>
                                    <label className='container-label'>Último día de demoras</label>
                                </div>
                                <div className='value-container'>
                                    <input 
                                        type='date' 
                                        value={delays.lastDayDelays}
                                        onChange={(e) => setDelays({...delays, lastDayDelays: e.target.value})}
                                        className='input-time records'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='register-inputs-container'>
                            <div className='delay-title-container'>
                                <div className='label-container'>
                                    <label className='container-label'>Días de demora regreso de vacío</label>
                                </div>
                                <div className='input-number-days-container'>
                                    <input 
                                        type={'number'}
                                        min={0}
                                        placeholder={0}
                                        className={'days-delay-input'}
                                        value={delays.emptyReturnDaysDelay}
                                        onChange={(e) => setDelays({...delays, emptyReturnDaysDelay: e.target.value})} 
                                    />
                                    <p className='days-text'>Días</p>
                                </div>
                            </div>
                            <div className='delay-reason'>
                                <p>Motivo de la demora:</p>
                                <input 
                                    className='reasons-delay-input' 
                                    type='textarea' 
                                    placeholder='Escriba aquí descripción...'
                                    value={delays.delayReasons}
                                    onChange={(e) => setDelays({...delays, delayReasons: e.target.value})}
                                />
                            </div>
                        </div>
                        <div className='text-area-container title'>
                            <h4>Disputa</h4>
                            <p>En caso de haber demora. Por cada día de demora se debe efectuar un pago.</p>
                        </div>    
                        <div className='register-inputs-container'>
                            <div className='delay-title-container'>
                                <div className='label-container'>
                                    <label className='container-label'>Total del pago por demoras</label>
                                </div>
                                <div className='input-number-days-container' >
                                    <input 
                                        type={'number'}
                                        min={0}
                                        placeholder={'$'}
                                        className={'days-delay-input'}
                                        value={delays.delayTotalPayment}
                                        onChange={(e) => setDelays({...delays, delayTotalPayment: e.target.value})} 
                                    />
                                </div>
                            </div>
                            <div className='input-container-record delay-date'>
                                <div className='label-container'>
                                    <label className='container-label'>Fecha de liquidación del pago</label>
                                </div>
                                <div className='value-container' >
                                    <input 
                                        type='date' 
                                        value={delays.paymentSettlementDate}
                                        onChange={(e) => setDelays({...delays, paymentSettlementDate: e.target.value})}
                                        className='input-time records'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='text-area-container checkbox'>
                            <p>Confirma si el pago por demoras se ha liquidado.</p>
                            <Checkbox onChange={() => setDelays({...delays, paymentDone: !delays.paymentDone})} />
                        </div> 
                    </div>
                </div>
                <div className='footer-form-generic-type record-register' style={{justifyContent: 'space-between'}}>
                    <Button type='button' onClick={cancel} className={'button-back generate'} style={{width: '40%'}}>CANCELAR</Button>
                    <Button type='button' onClick={() => {cancel(), console.warn(delays)}} className={'button-next'} style={{width: '40%'}}>GUARDAR</Button>
                </div>
            </form>
        </div>
    )
}

RecordsDelay.propTypes = {
    toggleModal: PropTypes.func,
}

export default RecordsDelay;
