// HOME
export const HOME = '/dashboard/inicio';
export const CUSTOMSAGENT = '/dashboard/agentes-aduanales';

export const PROFILE = '/dashboard/perfil';

export const ADMIN = '/dashboard/admin';

export const CUSTOMERMANAGEMENT = '/dashboard/gestion-clientes';
export const CUSTOMER_CREATE = '/dashboard/gestion-clientes/crear';

export const QUOTATION = '/dashboard/cotizacion';
export const QUOTATION_DOCUMENTS = '/dashboard/cotizacion/:id/documentos';

export const RECORDS = '/dashboard/expedientes';
export const RECORDS_DOCUMENTATION = '/dashboard/expedientes/documentacion/:id';
export const RECORDS_VIEW = '/dashboard/expedientes/:id/ver';
export const RECORDS_ROUTE = '/dashboard/expedientes/:id/ruta';
export const RECORDS_CLOSURE = '/dashboard/expedientes/:id/cierre-expediente';
export const RECORDS_REGISTER = '/dashboard/expedientes/:id/registro';
export const RECORDS_DELAY = '/dashboard/expedientes/:id/demora';

export const OPERATIVES = '/dashboard/operativos';
export const OPERATIVES_CREATE = '/dashboard/operativos/crear';
export const OPERATIVES_PROFILE = '/dashboard/operativos/:id/:mode';

export const PROVIDERS = '/dashboard/proveedores';
export const PROVIDER_PROFILE = '/dashboard/proveedores/:id/:mode';
export const PROVIDER_CREATE = '/dashboard/proveedores/:mode';

export const PORTS = '/dashboard/puertos';
export const PORTS_CREATE = '/dashboard/puertos/crear';
export const PORTS_EDIT = '/dashboard/puertos/:id/ver';

export const CUSTOMS_AGENT_PROFILE = '/dashboard/agentes-aduanales/:userId/:mode';
export const CUSTOMS_AGENT = '/dashboard/agentes-aduanales';

export const CLIENT_PROFILE = '/dashboard/gestion-clientes/:userId/:mode';
export const CLIENT_CREATE = '/dashboard/gestion-clientes/:mode';

export const MODIFICATION_CURRENCY = '/dashboard/divisas';

