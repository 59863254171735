import React from 'react';
import Footer from '../../../components/templates/BaseLayout/Footer/Footer';
import FullScreenLayout from '../../../components/templates/FullScreenLayout/FullScreenLayout';
import bambu1 from './../../../assets/images/bambu1.png';
import './ChangePassword.scss';
import RecoverPasswordForm from './ChangePasswordForm';

const ChangePassword = () => {
    return (
        <>
        <main id="changePassword">
            <FullScreenLayout image={bambu1}>
                <RecoverPasswordForm />
            </FullScreenLayout>
        </main>
        <Footer />
        </>
    )
}

export default ChangePassword;
