/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import '../../styles/stylesSteppers.scss';
import { useForm } from 'react-hook-form';
import _ from 'lodash'
import { UploadFile } from '../../components/organisms/UploadFile/UploadFile';
import { Button } from '../../components/atoms';
import { CardContainer } from '../../components/molecules/CardContainer/CardContainer';
import Modal from '../../components/organisms/Modal/Modal';
import { BaseModalBody } from '../../components/molecules/ModalBody/BaseModalBody';
import { cancelUploadOfDocs, noDocumentsUploded } from '../../components/molecules/ModalBody/modalBodyVaribles';
import './Records.scss';
import { RECORDS } from '../../routes/Paths';

const RecordDocumentation = () => {

    const methods = useForm({ defaultValues: defaultValues});
    const defaultValues = {
        // name : '',
    };
    const {register, handleSubmit, watch } = useForm();

    // const selectFile = () => {
    //     const inputFile = document.getElementById('documentMedia');
    //         inputFile.click();
    //     }
    const [watchDocumentMedia, setWatchDocumentMedia] = useState(false);
    const [last,setLast] = useState(null);
    const [files, setFiles] = useState(
        [
            {
                name: 'HBL',
                id:0,
                file: null,
                deletedAt:false,
                watch: false
            },
            {
                name: 'MBL',
                id:1,
                file: null,
                deletedAt:false,
                watch: false
            },
            {
                name: 'ETA',
                id:2,
                file: null,
                deletedAt:false,
                watch: false
            },
            {
                name: 'TELEX',
                id:3,
                file: null,
                deletedAt:false,
                watch: false
            },
            {
                name: 'RELEASE',
                id:4,
                file: null,
                deletedAt:false,
                watch: false
            },
            {
                name: 'PÓLIZA DE SEGURO',
                id:5,
                file: null,
                deletedAt:false,
                watch: false
            },
        ]
    )
    const [openModal, setOpenModal] = useState(false);
    const [openModal2, setOpenModal2] = useState(false);

    const toggleModal = () => {
        setOpenModal(!openModal);
    }

    const toggleModal2 = () => {
        setOpenModal2(!openModal);
    }

    const history = useHistory();

    useEffect(() => {
        let final = [...files];
        setLast(final.pop())
    }, [files])
    const data =  [
        {name : 'RFC'}, {name : 'Situacion  Fiscal'}, {name : 'INE, pasaporte, cédula profesional'}
    ]
    useEffect(() => {
    //   console.log(_.get(files, 'name', 'Archivo'))
        console.log(files)
    }, [files])
    
    let recordId = 'E001';

    const cancel = () => {
        history.push(RECORDS);
    }

    return (
        <div className='records-documentation-container'>
            {
                openModal &&
                <Modal
                isOpen={openModal}
                toggle={toggleModal}
                >
                    <CardContainer 
                        btnLeftAction={toggleModal} 
                        btnRightAction={cancel} 
                        body={<BaseModalBody data={cancelUploadOfDocs} />}
                        btnLeftText={'REGRESAR'}
                    />
                </Modal>
            }
            {
                openModal2 &&
                <Modal
                isOpen={openModal2}
                toggle={toggleModal2}
                >
                    <CardContainer 
                        btnLeftAction={toggleModal2} 
                        btnRightAction={cancel} 
                        body={<BaseModalBody data={noDocumentsUploded} />}
                        btnLeftText={'REGRESAR'}
                    />
                </Modal>
            }
            <form className='main-container-generic-type records'>
                <div className='header-form-generic-type'>
                    <h2>Documentación</h2>
                    <h4>ID: {recordId}</h4>
                </div>
                <div className='body-form-generic-type-docs record-documentation'>
                    {
                        files.map((ele, i)=>{
                        return(
                            <UploadFile 
                                hideTitle={false} 
                                key={i} 
                                files={ele} 
                                last={last}
                                setFiles={setFiles} 
                                arrayComplete={files}
                                noSee
                            />
                        )
                    })
                    }
                </div>
                <div className='footer-form-generic-type' style={{justifyContent: 'space-between'}}>
                    <Button type='button' onClick={toggleModal} className={'button-back generate'} style={{width: '30%'}}>CANCELAR</Button>
                    <Button type='button' onClick={toggleModal2} className={'button-next'} style={{width: '30%'}}>GUARDAR</Button>
                </div>
            </form>
        </div>
    )
}

RecordDocumentation.propTypes = {
    toggleModal: PropTypes.func,
}

export default RecordDocumentation;
