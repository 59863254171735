import _ from 'lodash';
import {
   POSTS_MODULES_GET_ALL_POSTS_BEGIN,
   POSTS_MODULES_GET_ALL_POSTS_SUCCESS,
   POSTS_MODULES_GET_ALL_POSTS_FAILURE,
 } from '../actionTypes';

import initialState from '../initialState';

export default function permissionsReducer(state=initialState.posts, action){
    switch (action.type) {
        case POSTS_MODULES_GET_ALL_POSTS_BEGIN:
            return {
                ...state,
                allPosts: {
                    
                    isLoading: true,
                    isSuccess: false,
                    err: null, 
                    totalRecords: 0,
                    data: [],
                }
            };
        case POSTS_MODULES_GET_ALL_POSTS_SUCCESS:
            return {
                ...state,
                allPosts: {
                    
                    data: action.data,
                },
            };
        case POSTS_MODULES_GET_ALL_POSTS_FAILURE:
            return {
                ...state,
                allPosts: {
                   
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
                },
            };
            // ---

        default:
            return state;
    }
}
