/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Button } from '../../../components/atoms';
// import './CustomAgent.scss';
// import './ClientRegister.scss';
import '../../../styles/stylesSteppers.scss'
import arrow from '../../../assets/images/navieras/arrowButton.png'
import docIcon from '../../../assets/images/navieras/docIcon.png'
import { PORTS } from '../../../routes/Paths';
import plus from '../../../assets/images/navieras/plus-icon.png';
import add from '../../../assets/images/navieras/agregar_red.png'
import { useForm } from 'react-hook-form';
import _ from 'lodash'
import { UploadFile } from '../../../components/organisms/UploadFile/UploadFile';
import { PortsForm } from '../../../components/organisms/PortsForm/PortsForm';
export const CustomAgentPorts = ({stepper, setStepper, toggleModal, formData, setFormData, edit, setSelectedModal}) => {
    console.log(formData);
    const { mode } = useParams();
    const [ isDisabled, setIsDisabled ] = useState(mode === 'ver' ? true : false);

    const methods = useForm({ defaultValues: defaultValues});
    const defaultValues = {
        // name : '',
    };
    const {register, handleSubmit, watch } = useForm();
    const changeStage = ()=>{
        setStepper(stepper+1)
    }
    const changeStageBack = ()=>{
        setStepper(stepper-1)
    }
    const [watchDocumentMedia, setWatchDocumentMedia] = useState(false);
    const [portsBorrados, setPortsBorrados] = useState([]);
    const [last,setLast] = useState(null);
    const [ports, setPorts] = useState(
        [
        {
            id:0,
        },
        ]
    )
            // ESTO ES PARA RECUPERAR LOS PUERTOS
        // useEffect(() => {
        //     let ports = _.get(formData, 'ports', null)
        //     if(ports){
        //         let portsSet=[];
        //         ports.forEach(i => {
        //             let port = {
        //                 id: i.id,
        //             }
        //             portsSet.push(port)
        //         });
        //         console.log(portsSet);
        //         setPorts(portsSet);
        //     }
        // }, [])
        
        useEffect(() => {
            let final = [...ports];
            setLast(final.pop())
        }, [ports])
        const data =  [
            {name : 'RFC'}, {name : 'Situacion  Fiscal'}, {name : 'INE, pasaporte, cédula profesional'}
        ]
        
        const handleAdd = () =>{
            console.log('add')
            let arrayCopy = JSON.parse(JSON.stringify(ports));
            let lastElement = ports.pop();
             arrayCopy.push(
                {
                    id:  null,
                    newAdd: true,
                }
            )

            setPorts(arrayCopy); 
        }
        const onSubmit = async () => {
            // console.log(formData)
            let port;
            let portsSet=[];
            let deletePorts=[];
            if(edit){

                ports.forEach((i)=>{
                    if(i.hasOwnProperty("newAdd")){
                        port = {
                            port: i.port,
                            executiveName: i.executiveName,
                            job: i.job,
                            phone: i.phone,
                            email: i.email,
                        }
                        portsSet.push(port)
                    }
                    else{
                        port = {
                            id: i.id,
                            port: i.port,
                            executiveName: i.executiveName,
                            job: i.job,
                            phone: i.phone,
                            email: i.email,
                        }
                        portsSet.push(port)
                    }
                    
                })
                portsBorrados.forEach((i)=>{
                        deletePorts.push(i.id)
                        port = {
                            id: i.id,
                            port: i.port,
                            executiveName: i.executiveName,
                            job: i.job,
                            phone: i.phone,
                            email: i.email,
                        }
                        portsSet.push(port)
                })
            }else{
                ports.forEach((i)=>{
                    // port = {
                    //     id: 0,
                    //     port: i.port,
                    //     executiveName: i.executiveName,
                    //     job: i.job,
                    //     phone: i.phone,
                    //     email: i.email,
                    // }
                    portsSet.push(i.id)
                })
            }
            const data = {
                ...formData,
                ports: portsSet,
                deletePorts
            }
            setFormData(data);
            changeStage()
            console.log(data);
        }
  return (
    <form className='main-container-generic-type' onSubmit={handleSubmit(onSubmit)}>
        <div className='header-form-generic-type'>
            <h2>Datos de puerto</h2>
        </div>
        <div className='body-form-generic'>
            <div className='create-port-container'>
                <Button 
                    className={'createPortButton'} 
                    srcicon={plus}
                    onClick={() => history(PORTS)}
                >
                    CREAR
                </Button>        
            </div>
            <div className='port-data-container'>
                {
                    ports.map((ele, index)=>{
                        return(
                            <PortsForm index={index} disabled={isDisabled} ports={ele} last={last} setPorts={setPorts} arrayComplete={ports} portsBorrados={portsBorrados} setPortsBorrados={setPortsBorrados}/>
                        )
                    })
                }
            </div>
            {
                !isDisabled &&
                <div className='add-button-container' style={{justifyContent: 'flex-end'}}>
                    <img className='add-card' src={add} onClick={handleAdd}/>
                </div>
            }
        </div>
        <div className='footer-form-generic-type' style={{justifyContent: 'space-between'}}>
            <Button type='button' onClick={() => mode == 'ver' || mode == 'editar' ? changeStageBack() : (toggleModal(), setSelectedModal('cancel'))} className={'button-back'} style={{width: '40%'}}>CANCELAR</Button>
            <Button type='submit' className={'button-next'} srcicon={arrow} style={{width: '40%'}}>SIGUIENTE</Button>
            {/* <Button type='button' onClick={changeStage} className={'button-next'} srcicon={arrow} style={{width: '40%'}}>SIGUIENTE</Button> */}
        </div>
    </form>
  )
}
