import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import download from "../../assets/images/Icono_descarga_cashtrade.png"
// import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";

/*  */
const columns = [
  { id: "firstName", label: "Nombre", minWidth: 20, width: 20 },
  { id: "firstLastName", label: "Apellido", minWidth: 20, width: 20 },
  { id: "transaction", label: "Movimiento", minWidth: 20, width: 20 },
  { id: "amountOffer", label: "Monto", minWidth: 10, width: 20 },
  {
    id: "displayName",
    label: "Divisa",
    minWidth: 10,
    width: 10,
  },
  {
    id: "date",
    label: "Fecha",

    width: 20,
  },
];

const rows = [
  { userName: 1, lastName: "González", firstName: "Juan", age: 25 },
  { userName: 2, lastName: "Rodríguez", firstName: "María", age: 36 },
  { userName: 3, lastName: "Pérez", firstName: "Luis", age: 42 },
  { userName: 4, lastName: "López", firstName: "Ana", age: 31 },
  { userName: 5, lastName: "García", firstName: "Pedro", age: 27 },
];

const useStyles = makeStyles({
  root: {
    width: "95%",
    backgroundColor: "#232427",

    boxShadow: "inset 0px 0px 10px #00000033",
    borderRadius: 10,
    opacity: 1,
    backdropFilter: "blur(30px)",
    webkitBackdropFilter: "blur(30px)",
    borderRadius: 15,
    color: "#FFFFFF",
  },
  container: {
    maxHeight: 440,
    color: "#FFFFFF",
  },
  stickyHeader: {
    // <-- añade estos estilos
    background: "linear-gradient(180deg, #FFFFFF 0%, #E6E6E6 100%)",
    color: "#FFFFFF",
    fontWeight: "bold",
  },
  button: {
    backgroundColor: 'transparent',
    color: 'white',
    boxShadow: "none",
    '&:hover': {
      color: '#79DECA',
      backgroundColor: 'transparent',
      boxShadow: "none",

    },
  },
});

const PaginatedTable = (props) => {
  const data = _.get(props, "data", null);
  const details = _.get(props, "detailsReport.data", null);
  const commission = _.get(data, "commissions", []);

  const id = details.id;
  const startDate = details.startDate;
  const endDate = details.endDate;
  const typeReport = details.typeReport.displayText;

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const startRow = page * rowsPerPage;
  const endRow = startRow + rowsPerPage;

  const csvHeaders = columns.map((column) => column.label);
  const csvData = rows
    .slice(startRow, endRow)
    .map((row) => columns.map((column) => row[column.id]));

  const handleExcelDownload = () => {
    const worksheet = XLSX.utils.json_to_sheet(commission);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "data.xlsx");
  };

  return (
    <div className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableBody>
            <TableRow>
              <TableCell style={{ width: "25%", textAlign: "center" ,color: "#ffff"}}>
                {typeReport}
              </TableCell>
              <TableCell style={{ width: "25%", textAlign: "center" ,color: "#ffff"}}>
                ID del reporte: {id}
              </TableCell>
              <TableCell style={{ width: "25%", textAlign: "center" ,color: "#ffff"}}>
                Periodo de fecha: {startDate} a {endDate}
              </TableCell>
              <TableCell style={{ width: "25%", textAlign: "center" ,color: "#ffff"}}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={handleExcelDownload}
                >
                    <img src={download}/>
                  Descargar en Excel
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: "#232427",
                    width: column.width,
                    textAlign: "center",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {commission.slice(startRow, endRow).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ color: "#ffff", textAlign: "center" }}
                      >
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {/* <Button variant="contained" color="primary" onClick={handleExcelDownload}>
        Descargar en Excel
      </Button> */}
      {/* <CSVLink data={csvData} headers={csvHeaders} filename={"data.csv"}>
        <Button variant="contained" color="secondary">
          Descargar en CSV
        </Button>
      </CSVLink> */}
    </div>
  );
};

export default PaginatedTable;
