import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes, { element } from "prop-types";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import "./AdminForm.scss";
import {
  getAllAdministratorsAction,
  deleteAdministratorAction,
  upsertAdministratorAction,
  uploadAdministratorAction,
} from "../../../redux/actions/adminAction";

import profile from "../../../assets/images/perfil.png";
import edit from "../../../assets/images/navieras/editIcon.png";
import { Button } from "../../../components/atoms";
import { ProfileForm } from "../../../components/templates/BaseLayout/Profile/ProfileForm";
import "../../../styles/stylesSteppers.scss";
import MultipleSelectCheckmarks from "../../../components/organisms/Checkmarks/CheckMark";
import "../../ProvidersDashboard/ProviderPrincipalDataForm.scss";
import { getAllCitiesAction, getAllCountriesAction, getAllStatesAction } from "../../../redux/actions/addressAction";
import { getAllPermissionsAction } from "../../../redux/actions/permissionsAction";
import { updatePasswordRequestAction } from "../../../redux/actions/userActions";
import { addNotification } from "../../../redux/actions/notificationAction";

const AdminForm = (props) => {
  const [firstName, setFirstName] = useState();
  const [firstLastName, setFirstLastName] = useState();
  const [nickname, setNickName] = useState();
  const [email, setEmail] = useState();
  const [primaryPhone, setPrimaryPhone] = useState();
  const [postalCode, setPostalCode] = useState();
  const [address, setAddress] = useState();
  const [outdoorNumber, setOutdoorNumber] = useState();
  const [indoorNumber, setIndoorNumber] = useState();
  const [curp, setCurp] = useState();
  const [identificationNumber, setIdentificationNumber] = useState();
  const [permisos, setPermisos] = useState([]);
  const [imgBase64, setImgBase64] = useState([]);

  const history = useHistory();
  

  const [ countrySelected, setCountrySelected ] = useState('');
  const [stateSelected, setStateSelected] = useState('');
  const [citySelected, setCitySelected] = useState('');


  const mainData = _.get(props, "data", null);
  const firstNameDefault = _.get(mainData, "person.firstName", "");
  const firstLastNameDefault = _.get(mainData, "person.firstLastName", "");
  const emailDefault = _.get(mainData, "email", "");

  
  

  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getAllPermissionsAction());
    dispatch(getAllCountriesAction());
},[]);

  // traer info address
  const countries = useSelector(state => state.address.countries.data);
  console.log(countries,"soy coun")
  const states = useSelector(state => state.address.states.data);
  const cities = useSelector(state => state.address.cities.data);

  //traer info permisos
  const permissions = useSelector(state => state.permission.permissions.data)

  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        return resolve({
          src: reader.result,
          title: file.name,
        });
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const unpdateInfoFile = (event) => {
    const profilePhoto = URL.createObjectURL(event.target.files[0]);
    setProfilePhoto({ ...profilePhoto, url: profilePhoto });
    setProfilePhoto({ ...profilePhoto, name: event.target.files[0].name });
    const imageFile = event.target.files;
    const urlImages = [];
    const arrImgBase64 = [];
    Object.entries(imageFile).map(async (ele) => {
      urlImages.push(URL.createObjectURL(ele[1]));

      try {
        var base64 = await toBase64(ele[1]).then((img) => {
          return img;
        });

        if (base64) {
          base64.src = base64.src.split(":")[1]
          arrImgBase64.push(base64);
          setImgBase64(base64)
        }
        setImgBase64(base64)
      } catch (err) {
        console.log(err);
        return err;
      }
    });

    // setFileUrl(urlImages);
    // setImgBase64(arrImgBase64);
    // setPicturesSelected(imageFile.name)
  };
  

  const defaultValues = {
    name: firstNameDefault,
    lastName: firstLastNameDefault,
    email: emailDefault,
    primaryPhone: primaryPhone
  };
  const methods = useForm({ defaultValues: defaultValues });

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = async () => {
    // let idMedia;
    // idMedia = localStorage.getItem("idMedia");
    let newPermissions = []

    permisos.forEach(element => {
      console.log(element,"soy element")
      let idPermission
      switch (element) {
        case 'Gestion de usuarios':
          newPermissions.push(1)
        break;
        case 'Gestion de publicaciones':
          newPermissions.push(2)
        break;
        case 'Gestion de cobros':
          newPermissions.push(3)
        break;
        case 'Gestion de reportes':
          newPermissions.push(4)
        break;
        case 'Gestion de administradores':
          newPermissions.push(5)
        break;
        default:
        break;
      }
    })
    

    const data = {
      user: {
        email: email,
      },
      person: {
        firstName: firstName,
        firstLastName: firstLastName,
        primaryPhone: primaryPhone,
        secondayPhone: primaryPhone,
        primaryEmail: email,
        secondayEmail: email,
        // profileImageMediaId: idMedia,
      },
      address: {
        countryId: countrySelected,
        stateId: stateSelected,
        cityId: citySelected,
      },
      userPermission: {
        sendPermission: newPermissions
      },
      profilePhoto: 
      imgBase64.length < 1
      ? null
      : {
          base64: {
            src: imgBase64.src,
            title: imgBase64.title.replace(/[{()}]/g, ""),
          },
        },
 
    };

    const res = await dispatch(upsertAdministratorAction(data));
    if (res) {
      const res2 = await dispatch(updatePasswordRequestAction({email}));
      console.log(res2);
      if (res2) {
        dispatch(
          addNotification({
            toastType: "success",
            toastTitle:
              "Se han envido instrucciones para concluir la acción a su email",
          })
        );
        // history.push(OPERATIVES);
      }
      history.push('/dashboard/admin');
    }
  };

  const [profilePhoto, setProfilePhoto] = useState({
    name: "Subir archivo",
    url: "",
  });

  const onClickInput = () => {
    const inputFile = document.getElementById("input-logo");
    inputFile.click();
  };

  const formData_ = new FormData();


  return (
    <div className="main-container admin">
      <div
        style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}
      >
        <h1
          style={{
            color: "#ffff",
            fontFamily: "Inter",
            fontWeight: "300",
            marginLeft: "2%",
            fontSize: "18px",
            marginBottom: "1%",
          }}
        >
          Gestion de administradores | Nuevo Administrador
        </h1>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: "flex", justifyContent: "center", width: "50%" }}
      >
        <div className="divStructure">
          <h2 className="labelPrincipal">Datos Principales</h2>
          <div className="dataAdminContainer">
            <h2 className="labelSections">Información personal</h2>
            <div className="sectionOneAdmin">
              <div className="inputContainer">
                <label className="labeInputAdmin">Nombre(s)*</label>
                <input
                  className="inputAdmin"
                  required
                  control={control}
                  name={"name"}
                  type="text"
                  placeholder="Agregar..."
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="inputContainer">
                <label className="labeInputAdmin">Apellido(s)*</label>
                <input
                  className="inputAdmin"
                  required
                  control={control}
                  name={"lastName"}
                  type="text"
                  placeholder="Agregar..."
                  onChange={(e) => setFirstLastName(e.target.value)}
                />
              </div>
              <div className="inputContainer">
                <label className="labeInputAdmin">Permisos*</label>
                <MultipleSelectCheckmarks setPermisos={setPermisos} data={permissions} />
              </div>
              <div className="inputContainer">
                <label className="labeInputAdmin">Foto de perfil*</label>
                <div className="inputProfilePhoto">
                  <div className="inputProfile" onClick={onClickInput}>
                    <p>{profilePhoto.name}</p>
                    <div className="icon-container">
                      {/* <img src={file} /> */}
                      SUBIR
                    </div>
                  </div>
                  <input
                    className="input-files"
                    id={"input-logo"}
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    placeholder="Subir archivo"
                    onChange={unpdateInfoFile}
                  />
                </div>
              </div>
            </div>
            <h2 className="labelSections">Información de contacto</h2>
            <div className="sectionOneAdmin">
              <div className="inputContainer">
                <label className="labeInputAdmin">Correo *</label>
                <input
                  className="inputAdmin"
                  required
                  control={control}
                  name={"email"}
                  type="text"
                  placeholder="Agregar..."
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="inputContainer">
                <label className="labeInputAdmin">Teléfono *</label>
                <input
                  className="inputAdmin"
                  required
                  control={control}
                  name={"primaryPhone"}
                  type="text"
                  placeholder="Agregar..."
                  onChange={(e) => setPrimaryPhone(e.target.value)}
                />
              </div>
              <div className="inputContainer">
                <label className="labeInputAdmin">País *</label>
                <select
                  className="inputAdmin"
                  required
                  control={control}
                  name={'country'}
                  value={countrySelected.name}
                  onChange={(e) => {
                    setCountrySelected(e.target.value);
                    dispatch(getAllStatesAction(
                      {where: {
                        countryId: parseInt(e.target.value)
                      }}
                    ))
                  }}>
                    <option value={null} selected hidden>{countrySelected.name != null ? countrySelected.name : "Seleccionar"}</option>
                    {
                      _.get(countries,'data') &&
                      countries.data.map((item,i) => {
                        return <option key={i} value={item.id}>{item.name}</option>
                      })
                    }
                </select>
              </div>
              <div className="inputContainer">
                <label className="labeInputAdmin">Estado *</label>
                <select className="inputAdmin"
                  required
                  control={control}
                  name={'state'}
                  value={stateSelected}
                  onChange={(e) => {
                    setStateSelected(e.target.value);
                    dispatch(getAllCitiesAction(
                      {where: {
                        stateId: parseInt(e.target.value)
                      }}
                    ))

                  }}
                >
                  <option value={null} selected hidden>
                    {stateSelected.name != null ? stateSelected.name : "Seleccionar"}
                  </option>
                  {
                    _.get(states,'data') &&
                    states.data.map(ele => {
                      return <option value={ele.id}>{ele.name}</option>
                    })
                  }
                </select>
                </div>
              <div className="inputContainer">
                <label className="labeInputAdmin">Ciudad *</label>
                <select className="inputAdmin"
                  required
                  control={control}
                  name={'city'}
                  value={citySelected}
                  onChange={(e) => {
                    setCitySelected(e.target.value);

                  }}
                >
                  <option value={null} selected hidden>
                    {citySelected.name != null ? citySelected.name : "Seleccionar"}
                  </option>
                  {
                    _.get(cities,'data') &&
                    cities.data.map(ele => {
                      return <option value={ele.id}>{ele.name}</option>
                    })
                  }
                </select>
                
              </div>
            </div>
          </div>
          <div className="btnContainer">
            <Button className="btnAdminRegister">REGISTRAR</Button>
          </div>
        </div>
      </form>
    </div>
  );
};

AdminForm.propTypes = {};

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AdminForm);
