/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import moment from "moment";
import _, { zip } from "lodash";
import { Button } from "../../components/atoms";
// import './Quotation.scss';
import "../../styles/stylesSteppers.scss";
import "../ProvidersDashboard/ProviderPrincipalData.scss";
import "./OperativePrincipalDataForm.scss";
import "../../components/templates/BaseLayout/Profile/ProfileFormStyles.scss";
import file from "../../assets/images/navieras/upload-file.png";
import profile from "../../assets/images/perfil.png";
// import arrow from '../../assets/images/navieras/arrowButton.png'
import { OPERATIVES } from "../../routes/Paths";
import { upsertOperativeAction } from "../../redux/actions/operativeAction";
import { addNotification } from "../../redux/actions/notificationAction";
import { updatePasswordRequestAction } from "../../redux/actions/userActions";
import { getAllCitiesAction, getAllCountriesAction, getAllStatesAction } from "../../redux/actions/addressAction";

const OperativePrincipalData = (
  props,
  { toggleModal, setValue, stepper, setStepper, dataFirstStep }
) => {
  let { userId } = useParams();
  const dispatch = useDispatch();
  //ADD IMG
  const [imageSrc, setImageSrc] = useState(profile);
  const [fileUrl, setFileUrl] = useState([]);
  const [imgBase64, setImgBase64] = useState([]);

  const createOperativeSuccess = useSelector(
    (state) => state.operative.upsertOperative.isSuccess
  );

  //ESTE ES EL QUE SE VA A RECIBIR POR PARAMS
  const mainData = _.get(props, "data", null);
  const emailDefault = _.get(mainData, "email", "");
  const firstNameDefault = _.get(mainData, "person.firstName", "");
  const firstLastNameDefault = _.get(mainData, "person.firstLastName", "");
  const positionDefault = _.get(mainData, "administrator.job", "");
  const dateOfAdmissionTLPDefault = _.get(
    mainData,
    "administrator.dateOfAdmissionTLP",
    null
  );
  const userRolDefault =
    _.get(mainData, "administrator.isSuperAdmin") === true ? 1 : 2;

  const [fechaIngresoDefault, setFechaIngresoDefault] = useState(null);

  const defaultValues = {
    name: firstNameDefault,
    lastName: firstLastNameDefault,
    position: positionDefault,
    email: emailDefault,
    dateOfAdmissionTLP: dateOfAdmissionTLPDefault,
  };
  const methods = useForm({ defaultValues: defaultValues });
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = methods;

  const imgDefault = 0;
  const id = userId;
  const [disabled, setDisabled] = useState(id ? true : false);


  const history = useHistory();
  const returnTable = () => {
    history.goBack();
  };

  const [profilePhoto, setProfilePhoto] = useState({
    name: "Subir archivo",
    url: "",
  });

  const formDataImage = new FormData();

  const [firstName, setFirstName] = useState();
  const [firstLastName, setFirstLastName] = useState();
  const [nickname, setNickName] = useState();
  const [email, setEmail] = useState();
  const [primaryPhone, setPrimaryPhone] = useState();
  const [postalCode, setPostalCode] = useState();
  const [street1, setStreet1] = useState();
  const [outdoorNumber, setOutdoorNumber] = useState();
  const [indoorNumber, setIndoorNumber] = useState();
  const [curp, setCurp] = useState();
  const [identificationNumber, setIdentificationNumber] = useState();

  
  const [countrySelected, setCountrySelected ] = useState('');
  const [stateSelected, setStateSelected] = useState('');
  const [citySelected, setCitySelected] = useState('');

  // obtener pais,estado,ciudad
  useEffect(() => {
    dispatch(getAllCountriesAction())
  }, []);

  const countries = useSelector(state => state.address.countries.data);
  const states = useSelector(state => state.address.states.data);
  const cities = useSelector(state => state.address.cities.data);

  console.log(countrySelected,",",stateSelected,",",citySelected)

  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        return resolve({
          src: reader.result,
          title: file.name,
        });
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const addImage = (event)  => {
    const image = URL.createObjectURL(event.target.files[0]);
    formDataImage.append('image',image)
    
  }

  const unpdateInfoFile = (event) => {
    const profilePhoto = URL.createObjectURL(event.target.files[0]);
    setProfilePhoto({ ...profilePhoto, url: profilePhoto });
    setProfilePhoto({ ...profilePhoto, name: event.target.files[0].name });
    const imageFile = event.target.files;
    const urlImages = [];
    const arrImgBase64 = [];
    Object.entries(imageFile).map(async (ele) => {
      urlImages.push(URL.createObjectURL(ele[1]));

      try {
        const base64 = await toBase64(ele[1]).then((img) => {
          return img;
        });

        if (base64) {
          arrImgBase64.push(base64);
        }
        console.log(base64)
      } catch (err) {
        console.log(err);
        return err;
      }
    });

    setFileUrl(urlImages);

    setImgBase64(arrImgBase64);
    // setPicturesSelected(imageFile.name)
  };

  //   CAMBIAR STEPPER
  const changeStage = () => {
    // console.log(country, 'cambio de pestania')
    props.setStepper(props.stepper + 1);
  };
  const changeStageBack = () => {
    // console.l"og(country, 'cambio de pestania')

    setStepper(stepper - 1);
  };

  const onClickInput = () => {
    const inputFile = document.getElementById("input-logo");
    inputFile.click();
  };

  const handleChange = (event) => {
    const profilePhoto = URL.createObjectURL(event.target.files[0]);
    setProfilePhoto({ ...profilePhoto, url: profilePhoto });
    setProfilePhoto({ ...profilePhoto, name: event.target.files[0].name });
    // const image = event.target.files[0];
  };

  const profileImg = fileUrl[fileUrl.length - 1]
    ? fileUrl[fileUrl.length - 1]
    : imgDefault
    ? `${api}/${imgDefault}`
    : imageSrc;

  const setImgProfile = {
    backgroundImage: `url(${profileImg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  //END ADD IMG

  const onSubmit = async () => {
    const data = {
      user: {
        email: email,
      },
      person: {
        firstName: firstName,
        firstLastName: firstLastName,
        primaryPhone: primaryPhone,
        secondayPhone: primaryPhone,
        primaryEmail: email,
        secondayEmail: email,
        curp: curp,
        identificationNumber: identificationNumber,
        
      },
      customer: {
        nickname: nickname,
      },
      address: {
        zipCode: postalCode,
        street1: street1,
        outdoorNumber: outdoorNumber,
        indoorNumber: indoorNumber,
        countryId: countrySelected,
        stateId: stateSelected,
        cityId: citySelected,
      },
      profilePhoto:
      imgBase64.length < 1
        ? null
        : {
            base64: {
              src: imgBase64[0].src,
              title: imgBase64[0].title.replace(/[{()}]/g, ""),
            },
          },
      files:{
       
      },

    };
    props.dataFirstStep(data);
    props.setStepper(props.stepper + 1);
  };


  return (
    <>
      <div className="main-container-generic-type-quotation operative">
        <div className="quotation-options-container operative">
          <div className="header-form-generic-type">
            <h2>Datos principales</h2>
          </div>
          <div className="body-form-generic-type-data">
            <form
              className="form-admin-container-second"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div
                className="body-form"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <h3 style={{ color: "#79DECA", fontWeight: "300" }}>
                  Información Personal
                </h3>
                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                >
                  <div className="input-container">
                    <div className="ContainerForm">
                      <div className="label-container">
                        <label className="container-label">Nombre(s) *</label>
                      </div>
                      <div className="value-container">
                        <input
                          required
                          disabled={disabled}
                          control={control}
                          name={"name"}
                          className="input-generic"
                          type="text"
                          placeholder="Agregar..."
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-container">
                    <div className="ContainerForm">
                      <div className="label-container">
                        <label className="container-label">Apellidos *</label>
                      </div>
                      <div className="value-container">
                        <input
                          required
                          disabled={disabled}
                          control={control}
                          name={"lastName"}
                          className="input-generic"
                          type="text"
                          placeholder="Agregar..."
                          onChange={(e) => setFirstLastName(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-container">
                    <div className="ContainerForm">
                      <div className="label-container">
                        <label className="container-label">Nickname</label>
                      </div>
                      <div className="value-container">
                        <input
                          required
                          disabled={disabled}
                          control={control}
                          name={"nickname"}
                          className="input-generic"
                          type="text"
                          placeholder="Agregar..."
                          onChange={(e) => setNickName(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-container">
                    <div className="input-container provider div-input">
                      <div className="input-label-container">
                        <div className="label-container">
                          <label className="container-label">
                            Foto de perfil
                          </label>
                        </div>
                        <div className="value-container div-input">
                          <div
                            className="input-generic div-input"
                            onClick={onClickInput}
                          >
                            <p>{profilePhoto.name}</p>
                            <div className="icon-container">
                              {/* <img src={file} /> */}
                              SUBIR
                            </div>
                          </div>
                          <input
                            className="input-files"
                            id={"input-logo"}
                            type="file"
                            accept="image/png, image/jpg, image/jpeg"
                            placeholder="Subir archivo"
                            onChange={unpdateInfoFile}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h3 style={{ color: "#79DECA", fontWeight: "300" }}>
                  Información del contacto
                </h3>
                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                >
                  <div className="input-container">
                    <div className="ContainerForm">
                      <div className="label-container">
                        <label className="container-label">Correo *</label>
                      </div>
                      <div className="value-container">
                        <input
                          required
                          disabled={disabled}
                          control={control}
                          name={"email"}
                          className="input-generic"
                          type="text"
                          placeholder="Agregar..."
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-container">
                    <div className="ContainerForm">
                      <div className="label-container">
                        <label className="container-label">Teléfono *</label>
                      </div>
                      <div className="value-container">
                        <input
                          required
                          disabled={disabled}
                          control={control}
                          name={"primaryPhone"}
                          className="input-generic"
                          type="text"
                          placeholder="Agregar..."
                          onChange={(e) => setPrimaryPhone(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-container">
                    <div className="ContainerForm">
                      <div className="label-container">
                        <label className="container-label">País *</label>
                      </div>
                      <div className="value-container">
                      <select
                        className="inputAdmin"
                        required
                        control={control}
                        name={'country'}
                        value={countrySelected.name}
                        onChange={(e) => {
                          setCountrySelected(e.target.value);
                          dispatch(getAllStatesAction(
                          {where: {
                            countryId: parseInt(e.target.value)
                          }}
                        ))
                      }}>
                        <option value={null} selected hidden>{countrySelected.name != null ? countrySelected.name : "Seleccionar"}</option>
                        {
                          _.get(countries,'data') &&
                          countries.data.map((item,i) => {
                            return <option key={i} value={item.id}>{item.name}</option>
                          })
                        }
                      </select>
                      </div>
                    </div>
                  </div>
                  <div className="input-container">
                    <div className="ContainerForm">
                      <div className="label-container">
                        <label className="container-label">Estado *</label>
                      </div>
                      <div className="value-container">
                      <select className="inputAdmin"
                        required
                        control={control}
                        name={'state'}
                        value={stateSelected}
                        onChange={(e) => {
                          setStateSelected(e.target.value);
                          dispatch(getAllCitiesAction(
                          { where: {
                            stateId: parseInt(e.target.value)
                          }}
                          ))
                        }}
                      >
                      <option value={null} selected hidden>
                        {stateSelected.name != null ? stateSelected.name : "Seleccionar"}
                      </option>
                  {
                    _.get(states,'data') &&
                    states.data.map(ele => {
                      return <option value={ele.id}>{ele.name}</option>
                    })
                  }
                </select>
                        
                      </div>
                    </div>
                  </div>
                  <div className="input-container">
                    <div className="ContainerForm">
                      <div className="label-container">
                        <label className="container-label">Ciudad *</label>
                      </div>
                      <div className="value-container">
                        <select className="inputAdmin"
                          required
                          control={control}
                          name={'city'}
                          value={citySelected}
                          onChange={(e) => {
                            setCitySelected(e.target.value);
                          }}
                        >
                        <option value={null} selected hidden>
                          {citySelected.name != null ? citySelected.name : "Seleccionar"}
                        </option>
                        {
                          _.get(cities,'data') &&
                          cities.data.map(ele => {
                            return <option value={ele.id}>{ele.name}</option>
                          })
                        }
                      </select>
                      </div>
                    </div>
                  </div>
                  <div className="input-container">
                    <div className="ContainerForm">
                      <div className="label-container">
                        <label className="container-label">
                          Código Postal *
                        </label>
                      </div>
                      <div className="value-container">
                        <input
                          required
                          disabled={disabled}
                          control={control}
                          name={"postalCode"}
                          className="input-generic"
                          type="text"
                          placeholder="Agregar..."
                          onChange={(e) => setPostalCode(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-container">
                    <div className="ContainerForm">
                      <div className="label-container">
                        <label className="container-label">Calle *</label>
                      </div>
                      <div className="value-container">
                        <input
                          required
                          disabled={disabled}
                          control={control}
                          name={"street1"}
                          className="input-generic"
                          type="text"
                          placeholder="Agregar..."
                          onChange={(e) => setStreet1(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-container">
                    <div className="ContainerForm">
                      <div className="label-container">
                        <label className="container-label">
                          No. Exterior *
                        </label>
                      </div>
                      <div className="value-container">
                        <input
                          required
                          disabled={disabled}
                          control={control}
                          name={"outdoorNumber"}
                          className="input-generic"
                          type="text"
                          placeholder="Agregar..."
                          onChange={(e) => setOutdoorNumber(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-container">
                    <div className="ContainerForm">
                      <div className="label-container">
                        <label className="container-label">
                          No. Interior (Opc)
                        </label>
                      </div>
                      <div className="value-container">
                        <input
                          required
                          disabled={disabled}
                          control={control}
                          name={"indoorNumber"}
                          className="input-generic"
                          type="text"
                          placeholder="Agregar..."
                          onChange={(e) => setIndoorNumber(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="footer-form-generic-type operative"
              >
                <Button
                  type="submit"
                  onClick={changeStage + onSubmit}
                  className={"buttonNextStep"}
                  style={{ width: "40%" }}
                >
                  SIGUIENTE
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

OperativePrincipalData.propTypes = {
  toggleModal: PropTypes.any,
  setValue: PropTypes.func,
  isSuccessUpsertOperative: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    isSuccessUpsertOperative: _.get(
      state,
      "operative.upsertOperative.isSuccess",
      {}
    ),
  };
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OperativePrincipalData);
