import React from 'react';
import PropTypes from 'prop-types';


const SimpleFooter = (props) => {
    return (
        <>
            
            <img src={props.imageFooter}/>
        </>
    );
}

SimpleFooter.propTypes = {
    text: PropTypes.string,
}

export default SimpleFooter;
