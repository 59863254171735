import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./ModalComent.scss";
import { Button } from "../../../../atoms";
import { useForm } from "react-hook-form";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor:
    "transparent linear-gradient(180deg, #000000 0%, #5169C3E6 100%) 0% 0% no-repeat padding-box",
  borderRadius: "20px",
  boxShadow: "0px -15px 10px #00000033",
  opacity: 1,
  backdropFilter: "blur(35px)",
  webkitBackdropFilter: "blur(35px)",
  p: 4,
};

const ModalComent = (props,{ stateModal, setOpenModal }) => {

  const [concept,setConcept] = React.useState();
  const [reasons,setReasons] = React.useState();

  const defaultValues = {
    conceptSs: '',
    warningReasons: '',
  };
  const methods = useForm({ defaultValues: defaultValues });
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = methods;

  const id = _.get(props,"infoComent.id",null)
  const isStrike = _.get(props,"infoComent.isStrike",null)

  

  const sendData = async () => {
    const data = {
      id: id,
      concept: concept,
      reasons: reasons,
      isStrike: true,
    }

    props.dataModal(data)
    setOpen(false);
    props.setOpenModal(false);

  };

  // abrir cerrar modal
  const [open, setOpen] = React.useState(props.stateModal);
  const handleClose = () => {
    setOpen(false);
    props.setOpenModal(false);
  };

  return (
    <div>
      <Modal
        open={props.stateModal}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit(sendData)}>
            <h2 id="modal-modal-title" className="titleWarning">
              Advertencia o Strike
            </h2>
            <div className="sectionOne">
              <p className="labelModal">Concepto del strike o Captura</p>
              <input className="inputStrike" 
                required
                control={control}
                name={"conceptSs"}
                onChange={(e) => setConcept(e.target.value)}
              />
            </div>
            <div className="sectionTwo">
              <p className="labelModal">
                Escribir advertencia o Razones del strike
              </p>
              <textarea className="inputComent" 
                required
                control={control}
                name={"warningReasons"}
                onChange={(e) => setReasons(e.target.value)}
              />
            </div>
            <div className="sectionThree">
              {/* {/* <ButtonAtom>Cancelar</ButtonAtom> */}
              <Button className="btnCancel">CANCELAR</Button>
              <Button className="btnSend" type="submit">ENVIAR</Button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalComent;
