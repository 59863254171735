import React from 'react';
import SimpleFooter from '../../../organisms/Footer1/SimpleTextFooter';
import footerImage from '../../../../assets/images/FOOTER.png';
import './Footer.scss';

const Footer = () => {
    return (
        <footer>
            <SimpleFooter className='footer' 
                imageFooter={footerImage}
            />
        </footer>
    );              
}

export default Footer;
