/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button } from '../../../components/atoms';
import './RecordDelays.scss';

const RecordDelays = ({toggleModal}) => {

    const [ delay, setDelay ] = useState({
        daysOfDelay: 0,
        delayReason: '',
    });

    const saveChanges = () => { 
        console.warn(delay);
    }

    return (
        <div className='delay-modal-container'>
            <div className='delay-title-container'>
                <h3>Retrasos</h3>
                <h4>Capturar los días de retraso:</h4>
                <div className='input-number-days-container'>
                    <input 
                        type={'number'} 
                        placeholder={0}
                        className={'days-delay-input'}
                        value={delay.daysOfDelay}
                        onChange={(e) => setDelay({...delay, daysOfDelay: e.target.value})} 
                    />
                    <p className='days-text'>Días</p>
                </div>
            </div>
            <div className='delay-reason'>
                <p>Motivo del retraso:</p>
                <input 
                    className='reasons-delay-input' 
                    type='textarea' 
                    placeholder='Escriba aquí los motivos del retraso...'
                    value={delay.delayReason}
                    onChange={(e) => setDelay({...delay, delayReason: e.target.value})}
                />
            </div>
            <div className='footer-form-generic-type' style={{justifyContent: 'space-between'}}>
                <Button type='button' onClick={toggleModal} className={'button-back'} style={{width: '40%'}}>CANCELAR</Button>
                <Button type='submit' onClick={saveChanges} className={'button-next'} style={{width: '40%'}}>GUARDAR</Button>
            </div>
        </div>
    )
}

export default RecordDelays;
