import React, { useEffect } from "react";
import {
  Switch,
  withRouter,
  Redirect,
  HashRouter,
  Route,
} from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { userLoginSuccess } from "./redux/actions/userActions";

import Login from "./pages/AuthModule/LogIn/Login";
import ChangePassword from "./pages/AuthModule/ChangePassword/ChangePassword";
import SetNewPassword from "./pages/AuthModule/ChangePassword/SetNewPassword";
import NotFoundPage from "./components/templates/NotFound/NotFoundPage";
import BaseLayout from "./components/templates/BaseLayout/BaseLayout";
import ClienteLayout from "./components/templates/BaseLayout/ClientBaseLayout";
import { PrivateRoute, PublicRoute } from "./utils/routeAccessHelper";
import chat from "./pages/ModificationCurrency/chat";
// class App extends React.Component {

const App = (props) => {
  useEffect(() => {
    let user = localStorage.getItem("user");
    if (!_.isNil(user)) {
      try {
        user = JSON.parse(user);
        props.userLoginSuccess(user);
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  // render() {
  return (
    <>
      <ToastContainer toastStyle={{ backgroundColor: "#040505" }} />
      <HashRouter>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
          <PublicRoute exact path="/app/:action" component={Login} />
          <PublicRoute
            exact
            path={"/app/chat"}
            component={chat}
            isAllowed={true}
          />
          <PublicRoute
            exact
            path="/app/recuperar-password"
            component={ChangePassword}
          />

          <PublicRoute
            exact
            path="/app/recuperar-password/:token"
            component={SetNewPassword}
          />

          <PrivateRoute
            path="/dashboard"
            component={BaseLayout}
            isAllowed={props.isAuthenticated}
          />

          <PrivateRoute
            path="/dashboard-cliente/"
            component={ClienteLayout}
            isAllowed={props.isAuthenticated}
          />

          <PublicRoute component={NotFoundPage} />
        </Switch>
      </HashRouter>
    </>
  );
};

App.propTypes = {
  isAuthenticated: PropTypes.any,
  user: PropTypes.any,
  userLoginSuccess: PropTypes.any,
  isAdministrator: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.user.isAuthenticated || false,
    user: state.user,
  };
}

const mapDispatchToProps = (dispatch) => ({
  userLoginSuccess: (params) => dispatch(userLoginSuccess(params)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
