import React from 'react';
import homeBG from '../../assets/images/navieras/HomeBGNs.png';
import bg from '../../assets/images/fondoLogin.png';
import logo from '../../assets/images/navieras/LOGO@2x.png';
import './Home.scss';

const Home = () => {
    return (
        <div 
            className='homeBackgroundContainer'
            style={{backgroundImage: `url(${homeBG})`}}
        >
            <div 
                className='backgroundcover'
                style={{backgroundImage: `url(${bg})`}}
            >
                <img src={logo} />
            </div>
        </div>
    );
}

export default Home;
