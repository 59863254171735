import * as React from 'react';
import PropTypes from 'prop-types';
import {
    MenuItem,
    Menu,
    IconButton,
}from '@material-ui/core';
import { MoreVert as MoreIcon } from "@material-ui/icons";
import './ActionMenu.scss';


const ActionMenu = ({
    callback,
    id,
    plan, 
    children
}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null); 
    };
    return (
        <div>
        <IconButton
        aria-label="more"
        id="basic-button"
        aria-controls="basic-menu"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        >
        <MoreIcon />
        </IconButton>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
        >   
            {
                plan === true ? 
                <MenuItem
                    className='menuOptions'
                    onClick={ () => callback(id, 2)}>Editar</MenuItem> 
                :
                <div className='menuOptions'>
                    {children}
                </div>
            } 
        </Menu>
        </div>
    );
}

ActionMenu.propTypes = {
    callback: PropTypes.any,
    id: PropTypes.any,
    plan: PropTypes.any,
    children: PropTypes.any,
}

export default ActionMenu;