import request from "../../utils/request";
import { addNotification } from './notificationAction';

import {
    ADDRESS_MODULE_GET_ALL_COUNTRIES_BEGIN,
    ADDRESS_MODULE_GET_ALL_COUNTRIES_SUCCESS,
    ADDRESS_MODULE_GET_ALL_COUNTRIES_FAILURE,
    ADDRESS_MODULE_GET_ALL_STATES_BEGIN,
    ADDRESS_MODULE_GET_ALL_STATES_SUCCESS,
    ADDRESS_MODULE_GET_ALL_STATES_FAILURE,
    ADDRESS_MODULE_GET_ALL_CITIES_BEGIN,
    ADDRESS_MODULE_GET_ALL_CITIES_SUCCESS,
    ADDRESS_MODULE_GET_ALL_CITIES_FAILURE,
} from '../actionTypes';

// ACTIONS TO REDUCER

export const getAllCountriesBegin = () => ({
    type: ADDRESS_MODULE_GET_ALL_COUNTRIES_BEGIN,
});

export const getAllCountriesSuccess = (data) => ({
    type: ADDRESS_MODULE_GET_ALL_COUNTRIES_SUCCESS,
    data,
});

export const getAllCountriesFailure = (err) => ({
    type: ADDRESS_MODULE_GET_ALL_COUNTRIES_FAILURE,
    err,
});
export const getAllStatesBegin = () => ({
    type: ADDRESS_MODULE_GET_ALL_STATES_BEGIN,
});

export const getAllStatesSuccess = (data, target) => ({
    type: ADDRESS_MODULE_GET_ALL_STATES_SUCCESS,
    data,
    target,
});

export const getAllStatesFailure = (err) => ({
    type: ADDRESS_MODULE_GET_ALL_STATES_FAILURE,
    err,
});
export const getAllCitiesBegin = () => ({
    type: ADDRESS_MODULE_GET_ALL_CITIES_BEGIN,
});

export const getAllCitiesSuccess = (data) => ({
    type: ADDRESS_MODULE_GET_ALL_CITIES_SUCCESS,
    data,
});

export const getAllCitiesFailure = (err) => ({
    type: ADDRESS_MODULE_GET_ALL_CITIES_FAILURE,
    err,
});


// DISPATCH TO ACTIONS

export function getAllCountriesAction(data){
    return (dispatch, getState) => {
        dispatch(getAllCountriesBegin());
        return request({
            partialUrl: `/api/v1/address/get-all-country-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log('this is data response');
            console.log(data.data);
            dispatch(getAllCountriesSuccess(data.data));
            return data.data
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
            }));
            return dispatch(getAllCountriesFailure(err));
        });
    };
}
export function getAllStatesAction(data){
    console.log("get all states");
    console.warn(data);
    return (dispatch, getState) => {
        dispatch(getAllStatesBegin());
        return request({
            partialUrl: `/api/v1/address/get-all-state-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data_ => {
            console.log('this is data response');
            console.log(data_.data);
            dispatch(getAllStatesSuccess(data_.data, data.target));
            return data.data
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
            }));
            return dispatch(getAllStatesFailure(err));
        });
    };
}
export function getAllCitiesAction(data){
    return (dispatch, getState) => {
        dispatch(getAllCitiesBegin());
        return request({
            partialUrl: `/api/v1/address/get-all-city-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log('this is data response');
            console.log(data.data);
            dispatch(getAllCitiesSuccess(data.data));
            return data.data
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
            }));
            return dispatch(getAllCitiesFailure(err));
        });
    };
}
