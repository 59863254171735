/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '../../components/atoms';    
import { upsertProviderAction } from '../../redux/actions/providerAction';
import '../../styles/stylesSteppers.scss';
import './ProviderPrincipalData.scss';
import arrow from '../../assets/images/navieras/arrowButton.png'
import file from '../../assets/images/navieras/upload-file.png';
import './ProviderPrincipalDataForm.scss';
import { getAllCountriesAction } from '../../redux/actions/addressAction';
import { PROVIDERS } from '../../routes/Paths';
import { useHistory } from 'react-router-dom';

export const ProviderPrincipalData = ({
    stepper, 
    setStepper, 
    toggleModal, 
    setName, 
    name, 
    setTradeName,
    tradeName,
    setLogo,
    logo,
    mode,
    formData,
    setFormData,
    setProviderData,
    extraFooter = null,
    extraQuotation = null,
    optionExtra = null,
}) => {

    useEffect(() => {
        console.warn('id de country', countrySelected);
    }, countryDefault)
    const dispatch = useDispatch();
    const history = useHistory();
    // const { mode } = useParams();
    const [ isDisabled, setIsDisabled ] = useState(mode === 'ver' ? true : false);
    
    const providerId = _.get(formData, 'id', '');
    const nameDefault = _.get(formData, 'name', '');
    const businessNameDefault = _.get(formData, 'businessname', '');
    const tradeNameDefault = _.get(formData, 'tradename', '');
    const commercialBusinessDefault = _.get(formData, 'commercialbusiness', '');
    const countryIdDefault = _.get(formData, 'countryId' , null);
    const countryDefault = _.get(formData, 'country.name', '');
    const mediaDefault = _.get(formData, 'media.filename', '');
    
    const defaultValues = {
        id : providerId,
        name : nameDefault,
        businessname : businessNameDefault,
        tradename : tradeNameDefault,
        commercialbusiness : commercialBusinessDefault,
        country : countryDefault ? countryDefault : countrySelected,
        countryId: countryIdDefault,
        media : mediaDefault,
    };
    
    const [countrySelected, setCountrySelected] = useState(countryIdDefault);
    const methods = useForm({ defaultValues: defaultValues});
    const { register, handleSubmit, control, reset, formState: { errors }, } = methods;

    // const changeData = (ele, value) => {
    //     const data = { 
    //         ...formData,
    //         [value] : ele,
    //     }
    //     setFormData(data)
    // }

    useEffect(() => {
        dispatch(getAllCountriesAction());
    }, [])

    const countries = useSelector(state => state.address.countries.data);
    const onClickInput = () => {
        const inputFile = document.getElementById('input-logo');
        inputFile.click();
    }
    const [fileUrl, setFileUrl] = useState([]);
    const [imgBase64, setImgBase64] = useState([]);
    const [ profilePhoto, setProfilePhoto ] = useState({
        name: 'Subir archivo',
        url: ''
    })
    
    const toBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                return resolve({
                    src: reader.result,
                    title: file.name
                });
            }
            reader.onerror = error => reject(error);
        });
    };
    const unpdateInfoFile = (event) => {
        const profilePhoto = URL.createObjectURL(event.target.files[0])
        setProfilePhoto({...profilePhoto, url: profilePhoto});
        setProfilePhoto({...profilePhoto, name: event.target.files[0].name})
        const imageFile = event.target.files;
        const urlImages = [];
        const arrImgBase64 = [];
        Object.entries(imageFile).map(async ele => {

            urlImages.push(URL.createObjectURL(ele[1]));

            try {
                const base64 = await toBase64(ele[1])
                    .then(img => {
                        return img;
                    })

                if (base64) {
                    arrImgBase64.push(base64);
                }
            } catch (err) {
                console.log(err)
                return err
            }

        }
        );


        setFileUrl(
            urlImages,
        )

        setImgBase64(
            arrImgBase64,
        )
        setLogo(arrImgBase64)
        // setPicturesSelected(imageFile.name)

    };
    const handleChange = (event) => {
        const logo = URL.createObjectURL(event.target.files[0])
        setLogo({...logo, logo: logo});
        setLogo({...logo, logoName: event.target.files[0].name})
        // const image = event.target.files[0];
    }
    
    const changeStage = ()=>{
        // setProviderData({...formData})
        setStepper(stepper+1);
    }
    const changeStageBack = ()=>{
        history.push(PROVIDERS);
    }

    const onSubmit = async ({ 
        name, 
        businessname, 
        tradename, 
        commercialbusiness, 
        country,
        media 
    }) => {
        
        const data = {
            ...formData,
            name,
            businessname,
            tradename,
            commercialbusiness,
            countryId: countrySelected,
            media,
        }
        // if(mode !== 'crear'){
        //     delete data.countryId;
        // }
        setFormData(data);
        changeStage();
    }

    const idQuotation = 'UP0001';
    
    return (
        <>
            <form className='main-container-generic-type-quotation' onSubmit={handleSubmit(onSubmit)}>
                    <div className='header-form-generic-type'>
                        <h2>Datos principales</h2>
                        <h3 className='id'>ID: {idQuotation}</h3>
                    </div>
                    <div className='body-form-generic-type-data'>
                    <div className='quotation-form-general-container'>
                                <div className='input-container provider'>
                                    <div className='label-container'>
                                        <label className='container-label'>Nombre proveedor*</label>
                                    </div>
                                    <div className='value-container'>
                                        <input 
                                            className="input-generic" 
                                            disabled={isDisabled}
                                            type="text" 
                                            placeholder="Nombre proveedor"
                                            required
                                            control={control}
                                            {...register('name')}
                                        />
                                    </div>
                                </div>
                                <div className='input-container provider'>
                                    <div className='label-container'>
                                        <label className='container-label'>Nombre comercial*</label>
                                    </div>
                                    <div className='value-container'>
                                        <input  
                                            className="input-generic" 
                                            type="text" 
                                            placeholder="Nombre comercial"
                                            required
                                            control={control}
                                            {...register('businessname')}
                                            disabled={isDisabled}
                                        />
                                    </div>
                                </div>
                                <div className='input-container provider'>
                                    <div className='label-container'>
                                        <label className='container-label'>Razón social*</label>
                                    </div>
                                    <div className='value-container'>
                                        <input  
                                            className="input-generic" 
                                            type="text" 
                                            placeholder="Razón social"
                                            required
                                            control={control}
                                            {...register('tradename')}    
                                            disabled={isDisabled}
                                        />
                                    </div>
                                </div>
                                <div className='input-container provider'>
                                    <div className='label-container'>
                                        <label className='container-label'>Giro comercial*</label>
                                    </div>
                                    <div className='value-container'>
                                        <input  
                                            className="input-generic" 
                                            type="text" 
                                            placeholder="Giro comercial"
                                            required
                                            control={control}
                                            {...register('commercialbusiness')}    
                                            disabled={isDisabled}
                                        />
                                    </div>
                                </div>
                                <div className='input-container'>
                                    <div className='label-container'>
                                        <label className='container-label'>País*</label>
                                    </div>
                                    <div className='value-container' >
                                    <select required disabled={isDisabled} className='input-select' value={countryIdDefault} onChange={(e) => {
                                                setCountrySelected(parseInt(e.target.value));
                                            }}>
                                                <option className='option-selected' value={null} selected hidden> {"Seleccionar país"}</option>
                                                {
                                                    _.get(countries, 'data') &&
                                                    countries.data.map((ele, i) => {
                                                        return <option key={i} value={ele.id}> {ele.name}</option>
                                                    })
                                                }
                                </select >
                                </div>
                            </div>
                            {
                                mode == 'crear' &&
                                <>
                                    <div className='input-container provider div-input'>
                                        <div className='input-label-container'>
                                            <div className='label-container'>
                                                <label className='container-label'>Foto de perfil</label>
                                            </div>
                                            <div className='value-container div-input'>
                                                <div 
                                                    className='input-generic div-input'
                                                    onClick={onClickInput}
                                                >
                                                    <p>{profilePhoto.name}</p>
                                                    <div className='icon-container'><img src={file}/></div>
                                                </div>
                                                <input 
                                                    className="input-files"
                                                    id={'input-logo'}
                                                    type="file"
                                                    accept='image/png, image/jpg, image/jpeg'
                                                    placeholder="Subir archivo"
                                                    onChange={unpdateInfoFile}
                                                />
                                            </div>
                                        </div>
                                    </div>          
                                    <p className='instructions'>Estensiones de archivo permitidas JPG & PNG</p>
                                </>
                            }
                            </div>
                </div>
                <div className={`footer-form-generic-type ${extraFooter ?'extraFooter' :''}`} style={{justifyContent: 'space-between'}}>
                    <Button type='button' onClick={toggleModal ? toggleModal : changeStageBack} className={'button-back'} style={{width: '40%'}}>CANCELAR</Button>
                    <Button type='submit' className={'button-next'} srcicon={arrow} style={{width: '40%'}}>SIGUIENTE</Button>
                </div>
            </form>
        </>
    )
}

ProviderPrincipalData.propTypes = {
    stepper: PropTypes.any,
    setStepper: PropTypes.func,
    toggleModal: PropTypes.any,
    setName: PropTypes.func,
    name: PropTypes.any,
    setTradeName: PropTypes.func,
    tradeName: PropTypes.any,
    setLogo: PropTypes.func,
    logo: PropTypes.any,
    register: PropTypes.func,
    handleSubmit: PropTypes.any,
    onSubmit: PropTypes.func,
}
