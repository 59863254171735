import React, { useEffect, useState } from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { MenuItem }from '@material-ui/core';
import plus from '../../../assets/images/navieras/plus-icon.png'
import '../../../styles/tableViewStyles.scss';
import {
    Button,
    DataTable,
    ActionMenu,
    GlobalFilter,
    Link,
} from '../../../components/atoms';
import Modal from '../../../components/organisms/Modal/Modal';
import { CardContainer } from '../../../components/molecules/CardContainer/CardContainer';
import { DeleteBody } from '../../../components/molecules/CancelFormBody/DeleteBody';
import { AssignClient } from '../../../components/molecules/AssingForms/AssignClient';
import { addNotification } from '../../../redux/actions/notificationAction';
import { getAllCustomAgentsAction, deleteCustomAgentAction } from '../../../redux/actions/customsAgentAction';
import { getAllClientsAction, getAllAvailableClientsAction } from '../../../redux/actions/clientAction';
import { upsertCostumerCustomAgentAction } from '../../../redux/actions/CostumerCustomAgent';


const CustomAgent = (props) => {
    const history = useHistory();
    const [searchObj,  setSearchObj] = useState({});
    const [searchCondition, setSearchCondition] = useState({});
    const [openModal, setOpenModal] = useState(false)
    const [itemSelected, setItemSelected] = useState(true)
    const [itemDeleted, setItemDeleted] = useState(true)

    const toggleModal = (num, id) => {
        if(openModal===true){
            setItemSelected('')
            setOpenModal(false);
        } else {
            setItemSelected([num, id])
            setOpenModal(true);
        }
    }
    useEffect(()=>{
        props.getAllCustomAgentsAction(searchCondition);
        // props.getAllAdministratorsAction(searchCondition);
    },[itemDeleted, searchCondition]);
    useEffect(()=>{
        console.log(props.clients);
    },[props.clients]);

    const onClickActionMenu = (actionSelected, payload) => {
        switch (actionSelected) {
            case 'showRecord':
                history.push(
                {
                    pathname:`/dashboard/agentes-aduanales/${payload.id}/ver`,
                    state: payload
                });
                break;
            case 'editRecord':
                history.push(
                {
                    pathname:`/dashboard/agentes-aduanales/${payload.id}/editar`,
                    state: payload
                });
                break;
            default:
                break;
        }
    }
    const deleteUser = async ()  =>{
        const res = await props.deleteCustomAgentAction({id: itemSelected.id})
        if(res){
            setOpenModal(false)
            setItemDeleted(itemSelected)
            setItemSelected(null)
        }
    }

    const dispatch = useDispatch();
    
    const preAssingClient = async (id) =>{
        const res =  await props.getAllAvailableClientsAction({customAgendId: id});
        if(res){
            toggleModal(1, id);
        }else{
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de clientes, favor de intentarlo de nuevo',
            }));
        }
    }


    const columns = React.useMemo(
        () => [
            {
                id: 'id',
                Header: 'ID',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '15%', 
                accessor: d => ({
                    id: _.get(d, 'id', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        id,
                    },
                    row
                }) {
                    // return (<Link to={`/app/horarios/${id}`}>{id}</Link>); 
                    return(<span>{`${id}`}</span>)
                }
            },
            {
                id: 'agentname',
                Header: 'Agente aduanal',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '15%', 
                accessor: d => ({
                    agentname: _.get(d, 'agentname', 'N/A'),
                }),
                Cell: function cell ({ 
                    value: {
                        agentname,
                    },
                    row
                }) {
                    // return (<Link to={`/app/horarios/${id}`}>{id}</Link>); 
                    return(<span>{`${agentname}`}</span>)
                }
            },
            {
                id: 'customsBroker',
                Header: 'Agencia aduanal',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '15%', 
                accessor: d => ({
                    tradename: _.get(d, 'tradename', 'N/A'),
                }),
                Cell: function cell ({ 
                    value: {
                        tradename,
                    },
                    row
                }) {
                    // return (<Link to={`/app/horarios/${id}`}>{id}</Link>); 
                    return(<span>{`${tradename}`}</span>)
                }
            },
            {
                id: 'patent',
                Header: 'No. Patente',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '15%', 
                accessor: d => ({
                    patent: _.get(d, 'patent', 'N/A'),
                }),
                Cell: function cell ({ 
                    value: {
                        patent,
                    },
                    row
                }) {
                    // return (<Link to={`/app/horarios/${id}`}>{id}</Link>); 
                    return(<span>{`${patent}`}</span>)
                }
            },
            {
                id: 'options.name',
                Header: '',
                filter: "text",
                disableFilters: true,
                width: '3%',
                accessor: d => ({
                    id: d.id,
                    item: d
                }),
                Cell: function cell ({ value: { id, item }, row }) {
                    return (
                        <ActionMenu id={id} >
                            <MenuItem onClick={ () => onClickActionMenu('showRecord', item)}>Ver registro</MenuItem>
                            <MenuItem onClick={ () => onClickActionMenu('editRecord', item)}>Editar registro</MenuItem>
                            <MenuItem onClick={ () => toggleModal(2, id)}>Eliminar</MenuItem>
                            <MenuItem onClick={ () => preAssingClient(id)}>Asignar cliente</MenuItem>
                        </ActionMenu>
                    ); 
                }
            },
        ],
    );

    const getNewData = (searchCondition) => {
        // TODO: Restrucutrar vs Filtros*
        setSearchCondition(searchCondition);
    };

    const [clientAssigned, setClientAssigned] = useState(null);

    const assignClient = async () =>{
        if(clientAssigned){
            const data ={ 
                idAgend: itemSelected[1],
                idClient : clientAssigned,
            }
            // console.log(data)
            let res = await props.upsertCostumerCustomAgentAction(data);
            if(res){
                setOpenModal(false);
            } 
        }else{
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Debe seleccionar algún cliente',
            }));
        }
    }
    return (
        <>
        {
            openModal && itemSelected[0] === 1 &&
            <Modal
                isOpen={toggleModal}
                toggle={toggleModal}
            >
                <CardContainer btnLeftAction={toggleModal} btnRightText={'ASIGNAR'} btnRightAction={()=>assignClient()} title={'Clientes'} body={<AssignClient clients={!_.isNil(props.clients) && _.get(props,'clients.data', [])} setClientAssigned={setClientAssigned} clientAssigned={clientAssigned}/>}/>
            </Modal>
        }
        {
            openModal && itemSelected[0] === 2 &&
            <Modal
                isOpen={toggleModal}
                toggle={toggleModal}
            >
                <CardContainer btnLeftAction={toggleModal} btnRightAction={()=>deleteUser()} body={<DeleteBody user={'agente aduanal'}/>}/>
            </Modal>
        }
        <section className='ClientRecordAccountSection' >
            <div className='ClientRecordAccountSection__container'>
                <div className='ClientRecordAccountSection__headerContainer'>
                    <div className='HomeTableSection__containerFilters'>
                        <GlobalFilter 
                            filter={searchObj}
                            setFilter={setSearchObj}
                            showTextSearch
                            showDateRangeSearch={false}
                            placeholderSearch={'Buscar por ID o Nombre...'}
                        />
                    </div>
                    <div className='HomeTableSection__containerButtons'>
                        <Link
                        className={'linkClient'}
                        to={`/dashboard/agentes-aduanales/crear`}>
                            <Button className={'containerButtons__clientButton'} srcicon={plus}>CREAR</Button>        
                        </Link>
                    </div>
                </div>
                <div className='ClientRecordAccountSection__tableContainer'>
                    <DataTable
                        columns={columns}
                        // data={{...props.companies}}
                        data={{
                            data: _.get(props, 'customsAgents.data', {}),
                            totalRecords: _.get(props, 'customsAgents.totalRecords', 0),
                        }}
                        getData={getNewData}
                        searchObj={searchObj}
                    />
                </div>
            </div>
        </section>
        </>
    );
}

CustomAgent.propTypes = {
    history: PropTypes.any,
    dispatch: PropTypes.any,
    getAllAdministratorsAction: PropTypes.any,
    admins: PropTypes.any,
    deleteAdministratorAction: PropTypes.any,
    isSuccessDeleteAdmin: PropTypes.any,
    getAllAvailableClientsAction: PropTypes.func,
};

function mapStateToProps (state) {
    return {
        clients: _.get(state, 'client.clientsAvailableByCustomerAgent', []),
        customsAgents: _.get(state, 'customAgent.customAgents', {}),
        // admins: _.get(state, 'admin.admins', {}),
        // isSuccessDeleteAdmin: _.get(state, 'admin.deleteAdmin.isSuccess', {}),
    };
}

const mapDispatchToProps = dispatch => ({
    upsertCostumerCustomAgentAction: params => dispatch(upsertCostumerCustomAgentAction(params)),
    getAllClientsAction: params => dispatch(getAllClientsAction(params)),
    deleteCustomAgentAction: params => dispatch(deleteCustomAgentAction(params)),
    getAllCustomAgentsAction: params => dispatch(getAllCustomAgentsAction(params)),
    getAllAvailableClientsAction: params => dispatch(getAllAvailableClientsAction(params)),
});

export default connect(mapStateToProps,mapDispatchToProps)(CustomAgent);
