import React, { useEffect, useState } from 'react'
import strikeImg from "../../../assets/images/imgStrike.png"
import _ from 'lodash';


const ShowStrikes = (props) => {
  const [allStrike,setAllStrike] = useState()
  useEffect(() => {
    if(_.isEmpty(allStrike)){
      console.log("me dejaron vacio")
    setAllStrike(props.numberOfStrikes)

    }else{
      

    }
  }, [])
  
  console.log(allStrike)

  return (
    <div style={{display: "flex", flexDirection:"row", justifyContent:"center"}}>
    {
      !_.isEmpty(allStrike) && 
      
      allStrike.map(strike => {
        return  (
             <img src={strikeImg} style={{width: "5%", marginLeft:"3%"}}/>
        )
      })
     
    }

    </div>
  )
}

export default ShowStrikes