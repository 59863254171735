/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useDispatch, connect, useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory } from 'react-router-dom'
import { CancelFormBody } from '../../../../components/molecules/CancelFormBody/CancelFormBody'
import { CardContainer } from '../../../../components/molecules/CardContainer/CardContainer'
import Modal from '../../../../components/organisms/Modal/Modal'
import { StepperComponent } from '../../../../components/Stepper/Stepper'
import { ClientDocuments } from '../../../AdminDashboard/HomeModule/CustomerManagement/ClientDocuments'
import { SeeClientDocuments } from '../../../AdminDashboard/HomeModule/CustomerManagement/ClientRegister/SeeClientDocuments'
import { PrincipalDataClient } from '../../../AdminDashboard/HomeModule/CustomerManagement/PrincipalDataClient'
import '../../../../styles/stylesSteppers.scss';
import { CustomAgentDocuments } from '../CustomAgentDocuments'
import { CustomAgentPorts } from '../CustomAgentPorts'
import { PrincipalDataCustomAgent } from '../PrincipalDataCustomAgent'
import { ClientsCustomAgents } from './ClientsCustomAgents'
import { AssignClient } from '../../../../components/molecules/AssingForms/AssignClient';
import { addNotification } from '../../../../redux/actions/notificationAction';

export const seeCustomAgentRegister = (props) => {
    const data = _.get(props, 'location.state', null)
    const [formData, setFormData] = useState(data);

    const history = useHistory();
    const dispatch = useDispatch();
    const [stepper, setStepper] = useState(0);
    const [ itemSelected, setItemSelected ] = useState();
    const [openModal, setOpenModal] = useState(false)
    const toggleModal = () => {
        setOpenModal(!openModal);
    }
    const [clientAssigned, setClientAssigned] = useState(null);
    const [clientsAvailable, setClientsAvailables] = useState([])

    const assignClient = async () =>{
        if(clientAssigned){
            const data ={ 
                idAgend: itemSelected[1],
                idClient : clientAssigned,
            }
            // console.log(data)
            let res = await props.upsertCostumerCustomAgentAction(data);
            if(res){
                setOpenModal(false);
            } 
        }else{
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Debe seleccionar algún cliente',
            }));
        }
    } 

    const steps = [
        {
            label: 'Datos principales',
            step:0,
            component: 
                <PrincipalDataCustomAgent 
                    stepper={stepper} 
                    setStepper={setStepper} 
                    formData={formData} 
                    setFormData={setFormData} 
                    edit={true} 
                    toggleModal={toggleModal} 
                    setSelectedModal={setItemSelected}
                />
        },
        {
            label: 'Datos de puerto',
            step:1,
            component: 
                <CustomAgentPorts 
                    stepper={stepper} 
                    setStepper={setStepper} 
                    formData={formData} 
                    setFormData={setFormData} 
                    edit={true} 
                    setSelectedModal={setItemSelected}
                    toggleModal={toggleModal} 
                />
        },
        {
            label: 'Documentación',
            step:2,
            component: 
                <CustomAgentDocuments 
                    stepper={stepper} 
                    setStepper={setStepper} 
                    formData={formData} 
                    setFormData={setFormData} 
                    setSelectedModal={setItemSelected}
                    toggleModal={toggleModal} 
                />
        },
        {
            label: 'Clientes asignados',
            step:3,
            component: 
                <ClientsCustomAgents
                    stepper={stepper} 
                    setStepper={setStepper} 
                    formData={formData} 
                    setFormData={setFormData} 
                    toggleModal={toggleModal}
                    setSelectedModal={setItemSelected}
                    setClientsAvailables={setClientsAvailables}
                />
        },
    ] 

    const [render, setRender]= useState(null)
    useEffect(() => {
        setRender(steps[stepper].component)
    }, [stepper])
    
    const renders = steps.map((ele)=>{
        return(
            ele.component
        )
    })
    const cancel = ()=>{
        history.push('/dashboard/gestion-clientes')
    }
    
    return (
        <>
        {
            openModal && itemSelected === 'cancel' &&
            <Modal
                isOpen={toggleModal}
                toggle={toggleModal}
            >
                <CardContainer btnLeftAction={toggleModal} btnRightAction={cancel} body={<CancelFormBody role={'agente aduanal'} />}/>
            </Modal>
        }
        {
            openModal && itemSelected === 'assign client' &&
            <Modal
                isOpen={toggleModal}
                toggle={toggleModal}
            >
                <CardContainer 
                    btnLeftAction={toggleModal}
                    btnRightText={'ASIGNAR'} 
                    btnRightAction={()=>assignClient()} 
                    title={'Clientes'} 
                    body={<AssignClient 
                        clients={clientsAvailable} 
                        setClientAssigned={setClientAssigned} 
                        clientAssigned={clientAssigned}
                    />}
                />
            </Modal>
        }
        <div className='main-container'>
            <div className='stepper-container'>
            <StepperComponent step={stepper} steps={steps} setStepper={setStepper}/>
            </div>
            <div className='card-container-stepper'>
                {
                render
                }
            </div>
        </div>
        </>
    )
}
