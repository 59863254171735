import { NOTIFICATION_ADD } from '../actionTypes';
import { toast } from 'react-toastify';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import initialState from './../initialState';
import './notificationReducer.scss';
const styles = {display: 'flex', alignItems: 'center', justifyContent: 'space-between'}

export default function notificationReducer(state=initialState.notification, action){
    switch (action.type) {
        case NOTIFICATION_ADD:
            switch (action.toastType) {
                case 'error':
                    toast[action.toastType](<div style={styles}><ErrorIcon style={{marginRight: '10px', color:'#D7091B'}}/>{action.toastTitle}</div>, {
                        ...state,
                        ...action.toastConfig,
                    });
                    break;
                case 'success':
                    toast[action.toastType](<div style={styles}><CheckCircleIcon style={{marginRight: '10px', color:'#01A864'}}/>{action.toastTitle}</div>, {
                        ...state,
                        ...action.toastConfig,
                    });
                    break;
                case 'warning':
                toast[action.toastType](<div style={styles}><WarningIcon style={{marginRight: '10px', color:'#D7091B'}}/>{action.toastTitle}</div>, {
                    ...state,
                    ...action.toastConfig,
                });
                    break;
                case 'delete':
                toast.info(<div style={styles}><DeleteIcon style={{marginRight: '10px', color: '#D7091B'}}/>{action.toastTitle}</div>, {
                    ...state,
                    ...action.toastConfig,
                });
                break;
            
                default:
                    break;
            }
            return {
                ...state,
                toastTitle: action.toastTitle,
                toastType: action.toastType,
            }
        default:
            return state;
    }
}
