import React from 'react';
import './CancelFormBody.scss'
export const DeleteBody = ({user}) => {
  return (
    <div className='cancel-body-container'>
        <h2>Desactivar {user}</h2>
        <p>Al ejecutar esta acción el usuario no podrá hacer uso de la aplicacion hasta que vuelvas a activar
          su cuenta.</p>
    </div>
  )
}
