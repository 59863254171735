/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import _, { forEach, isEmpty } from 'lodash';
import { Button } from '../../components/atoms';
import { UploadFile } from '../../components/organisms/UploadFile/UploadFile';
import './ProviderDetailsView.scss';
import profile from '../../assets/images/perfil.png';
import { upsertProviderAction } from '../../redux/actions/providerAction';
import moment from 'moment';
import { StepperComponent } from '../../components/Stepper/Stepper';
import { ProviderPrincipalData } from './ProviderPrincipalData';
import { ProviderContactData } from './ProviderContactData';
import { ProvidersDocuments } from './ProvidersDocuments';


const ProviderDetailsView = (props, {
    date = '12/11/2020',
    id = 'UP0001',
}) => {
    
    const [stepper, setStepper] = useState(0);
    const api = process.env.SERVER_API_DATA_URL;
    // const [imageSrc, setImageSrc] = useState(profile);
    const [fileUrl, setFileUrl] = useState([]);
    const [imgBase64, setImgBase64] = useState([]);
    const currentData = _.get(props, 'location.state', null);
    const [logoImg, setLogoImg] = useState();

    const imgDefault = _.get(currentData,"media.media.url", null)
    const dispatch = useDispatch();
    const history = useHistory();
    const { mode } = useParams();
    const [ isDisabled, setIsDisabled ] = useState(mode === 'ver' ? true : false);
    const [formData, setFormData] = useState(currentData);
    const [ mediasDeleted, setMediasDeleted ] = useState([]);
    const admissionDate = moment(currentData.createdAt).format('DD/MM/YYYY');

    const [providerData, setProviderData] = useState({
        id: currentData.id,
        tradename: currentData.tradename,
        name: currentData.name,
        logo: `${api}/${imgDefault}`,
        files: currentData && currentData.file,
    })

    const showImage = `${api}/${providerData.logo}`

    const saveChanges = async () => {

        const formData = new FormData();

        formData.append("id", JSON.stringify(providerData.id))
        formData.append("provider", JSON.stringify(
            {...providerData, 
            media: imgBase64.length < 1 ? null : {
                base64: {
                    src: imgBase64[0].src,
                    title: imgBase64[0].title.replace(/[{()}]/g, ''),
                }
                }
            }
        ))
        formData.append("mediasDeleted", JSON.stringify(mediasDeleted));

        const arrFiles = files.map(file => file.file != null ? file : false).filter(file => file != false)

        for (let i = 0; i < arrFiles.length; i++) {

            formData.append("File", arrFiles[i].file);
        }
        dispatch(upsertProviderAction(formData));
        history.goBack();
    }

    useEffect(() => {
        const logoName = `http://localhost:1337/${providerData.logo}`;
        setLogoImg(logoName);
    }, [logoImg]);


    const [last, setLast] = useState(null);
    const providerFiles = providerData.files && providerData.files;
    
    useEffect(() => {
        let filesLng = providerFiles.length;
        let providerFilesNew = providerFiles;
        let newFiles = [];
        if (filesLng <= 3) {
            for (let i = 0; i < 3; i++) {
                if (providerFilesNew[i]) {
                    newFiles.push({
                        id: providerFilesNew[i].id,
                        file: {
                            url : providerFilesNew[i].file.url,
                            name: providerFilesNew[i].file.url,
                            deletedAt: providerFilesNew[i].deletedAt,
                        },
                        watch: true
                    })
                    
                } else {
                    newFiles.push(
                        {
                            id: i + 1,
                            file: null,
                            deletedAt: false,
                            watch: false
                        }
                    )
                }
            }
        }
        setFiles(newFiles);
    }, []);


    const [files, setFiles] = useState(
        [
            {
                name: 'RFC',
                id: 0,
                file: null,
                deletedAt: false,
                watch: false
            },
            {
                name: 'Situación fiscal',
                id: 1,
                file: null,
                deletedAt: false,
                watch: false
            },
            {
                name: 'INE, pasaporte, cédula profesional',
                id: 2,
                file: null,
                deletedAt: false,
                watch: false
            },
        ]
    )

    const selectFile = () => {
        const inputLogo = document.getElementById('input-upload-logo');
        inputLogo.click();
    }

    const handleChange = (event) => {
        const logo = URL.createObjectURL(event.target.files[0])
        setProviderData({ ...providerData, logo: logo });
        // const image = event.target.files[0];
    }

    const steps = [
        {
            label: 'Datos principales',
            step:0,
            component: 
                <ProviderPrincipalData 
                    stepper={stepper} 
                    setStepper={setStepper} 
                    formData={formData} 
                    setFormData={setFormData}
                    mode={mode}
                />

        },
        {
            label: 'Datos de contacto',
            step:1,
            component: 
                <ProviderContactData 
                    stepper={stepper} 
                    setStepper={setStepper} 
                    formData={formData} 
                    setFormData={setFormData}
                    mode={mode}
                />
        },
        {
            label: 'Documentación',
            step:2,
            component: 
                <ProvidersDocuments 
                    stepper={stepper} 
                    setStepper={setStepper} 
                    formData={formData} 
                    setFormData={setFormData} 
                    mode={mode}
                />
        },
    ] 

    const [render, setRender]= useState(null)
    useEffect(() => {
        setRender(steps[stepper].component)
    }, [stepper])
    
    const renders = steps.map((ele)=>{
        return(
            ele.component
        )
    })

    useEffect(() => {
        let final = [...files];
        setLast(final.pop())
    }, [files])
    
    const data = [
        { name: 'RFC' }, { name: 'Situacion  Fiscal' }, { name: 'INE, pasaporte, cédula profesional' }
    ]


    const handleAdd = () => {

        let arrayCopy = JSON.parse(JSON.stringify(files));
        let lastElement = files.pop();
        arrayCopy.push(
            {
                name: 'Archivo',
                id: lastElement.id + 1,
                file: null,
                watch: false,
                deletedAt: false,
                newAdd: true,
            }
        )
        setFiles(arrayCopy);
    }

    const toBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                return resolve({
                    src: reader.result,
                    title: file.name
                });
            }
            reader.onerror = error => reject(error);
        });
    };

    const unpdateInfoFile = (event) => {
        const imageFile = event.target.files;
        const urlImages = [];
        const arrImgBase64 = [];
        Object.entries(imageFile).map(async ele => {

            urlImages.push(URL.createObjectURL(ele[1]));

            try {
                const base64 = await toBase64(ele[1])
                    .then(img => {
                        return img;
                    })

                if (base64) {
                    arrImgBase64.push(base64);
                }
            } catch (err) {
                console.log(err)
                return err
            }

        }
        );


        setFileUrl(
            urlImages,
        )
        console.log(arrImgBase64);
        setImgBase64(
            arrImgBase64,
        )
        // setPicturesSelected(imageFile.name)
    };

    const profileImg = providerData.logo ? providerData.logo : profile;

    const style = {
        backgroundImage:  `url(${profileImg})`,
        backgroundSize:     "cover",                      
        backgroundRepeat:   "no-repeat",
    }
    return (
        <div className='view-details-general-container provider'>
            <div className='card-data-container provider'>
                <div className='provider-data-container'>
                    <div className='logo-provider-container' style={ style}>
                        {/* <img src={providerData.logo} /> */}
                    </div>
                    <div className='one-data-container'>
                        <h4 className='data-title'>{providerData.tradename}</h4>
                        <p className='client-info'>{providerData.name}</p>
                    </div>
                </div>
                <div className='buttons-container provider'>
                    <Button
                        className={'button-upload-logo provider'}
                        onClick={selectFile}
                        disabled={isDisabled}
                    >
                        SUBIR IMAGEN
                    </Button>
                    <input
                        type={'file'}
                        accept={'image/png, image/jpeg, image/jpg'}
                        className={'input-upload-logo'}
                        id={'input-upload-logo'}
                        onChange={handleChange}
                        disabled={isDisabled}
                    />
                </div>
                <div className='id-data-container'>
                    <div className='id-provider'>
                        <p className='title-text'>ID:</p>
                        <p className='id'>{providerData.id}</p>
                    </div>
                    <div className='id-provider'>
                        <p className='title-text'>Fecha de ingreso:</p>
                        <p className='id'>{admissionDate}</p>
                    </div>
                </div>
            </div>
            <div className='main-container provider-stepper'>
                <div className='stepper-container'>
                <StepperComponent step={stepper} steps={steps} setStepper={setStepper}/>
                </div>
                <div className='card-container-stepper'>
                    {
                    render
                    }
                </div>
            </div>
        </div>
    );
}

ProviderDetailsView.propTypes = {
    date: PropTypes.any,
    id: PropTypes.any,
    isSuccessUpsertProvider: PropTypes.any,
    comercialName: PropTypes.any,
    modality: PropTypes.any,
    providerLogo: PropTypes.any,
}

function mapStateToProps(state) {
    return {
        isSuccessUpsertProvider: _.get(state, 'provider.upsertProvider.isSuccess', {}),
    };
}

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ProviderDetailsView);
