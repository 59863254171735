import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import './../../../components/templates/FullScreenLayout/FullScreenLayout.scss';
import { InputPasswordImage } from '../../../components/atoms/InputPasswordImage';
import  viewPass from '../../../assets/images/icon-view-pass.png';
import user from '../../../assets/images/icon_user.png';
import icon from '../../../assets/images/password.png';
import { ButtonComponent } from '../../../components/atoms/buttonComponent';
import { Link, useHistory, useParams } from 'react-router-dom';
import { setNewPasswordAction } from '../../../redux/actions/userActions';
import { useDispatch } from 'react-redux';
import { Button } from '../../../components/atoms';
import { addNotification } from '../../../redux/actions/notificationAction';
import password from '../../../assets/images/passLogin.png';
import showPassword from '../../../assets/images/iconEye.png';
import showPasswordTwo from '../../../assets/images/iconEyeShow.png';


export const FormRestorePass = (props) => {
    const { register, handleSubmit } = useForm();
    const dispatch = useDispatch();
    const history = useHistory();
    const {token} = useParams();
    const [showPass, setshowPass] = useState(false)
    const [showPass2, setshowPass2] = useState(false)
    const [show, setShow] = useState(false);


    const onSubmit = async ({password, passwordRepit}) => {

        const redirect = ()=>{
            history.push('/app/iniciar-sesion')
        }
        console.log(token)
        // dispatch(setNewPasswordAction(formData));
        if(password === passwordRepit){
            const data = {
                token,
                password
            }
            // console.log(data)
            const res = await dispatch(setNewPasswordAction(data))
            if(res){
                dispatch(addNotification({
                    toastType: 'success',
                    toastTitle: 'La contraseña ha sido actualizada, en breve sera redirigido al inicio de sesión',
                }));
                setTimeout(redirect, 5000);
            }
        }else{
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Las contraseñas no coinciden',
            }));
        }
    }
    
    return (
        <>
            <form
                name='login'
                onSubmit={handleSubmit(onSubmit)}
                className='form-restore'>


                <p className='txt-login'>INGRESE SU NUEVA CONTRASEÑA</p>

                <div className={'log-in'}>
                {/* --------------------- */}
                <div className="container-input-component">
                    <div className="btn-input-component-icouser" >
                        <div className='subcontainer-img-left'>
                            <img className='img-left' src={password} alt="" />
                        </div>
                        <input name={'password'} {...register('password')} className="input-component-login" type={!showPass ? 'password' : 'text' } placeholder="Contraseña"/>

                        <div onClick={()=>setshowPass(!showPass)} className='subcontainer-img-right'>

                            {viewPass &&

<img src={showPass ? showPasswordTwo : showPassword} />

                            }
                        </div>

                    </div>
                </div>
                <div className="container-input-component">
                    <div className="btn-input-component-icouser" >
                        <div className='subcontainer-img-left'>
                            <img className='img-left' src={password} alt="" />
                        </div>
                        <input name={'passwordRepit'} {...register('passwordRepit')} className="input-component-login" type={!showPass2 ? 'password' : 'text' } placeholder="Repetir contraseña"/>

                        <div onClick={()=>setshowPass2(!showPass2)} className='subcontainer-img-right'>

                            {viewPass &&

<img src={show ? showPasswordTwo : showPassword} />

                            }
                        </div>

                    </div>
                   
                </div>
                {/* --------------------- */}
                    {/* <InputPasswordImage
                        img={viewPass}
                        className='email-input'
                        type={'password'}
                        inputName={'passwordRepit'}
                        {...register('passwordRepit')}
                        placeholder={'Repetir contraseña'}
                        icon={password}
                    /> */}
                    {/* <ButtonComponent text={'CAMBIAR CONTRASEÑA'} /> */}
                    <Button type='submit' className={"btnRecoveryPassword"}>CAMBIAR CONTRASEÑA</Button>
                </div>
            </form>
        </>
    );
}

