/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { CancelFormBody } from '../../components/molecules/CancelFormBody/CancelFormBody';
import { CardContainer } from '../../components/molecules/CardContainer/CardContainer';
import Modal from '../../components/organisms/Modal/Modal';
import { BaseModalBody } from '../../components/molecules/ModalBody/BaseModalBody';
import { continueWithoutUploadFilesProvider } from '../../components/molecules/ModalBody/modalBodyVaribles';
import { StepperComponent } from '../../components/Stepper/Stepper';
import '../../styles/stylesSteppers.scss';
import { ProviderPrincipalData } from './ProviderPrincipalData';
import { ProvidersDocuments } from './ProvidersDocuments';
import { upsertProviderAction } from '../../redux/actions/providerAction';
import { PROVIDERS } from '../../routes/Paths';
import { ProviderContactData } from './ProviderContactData';

const ProviderRegister = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { mode } = useParams();
    const [ stepper, setStepper ] = useState(0);

    const [ openModal, setOpenModal ] = useState(false);
    const [ openModal2, setOpenModal2 ] = useState(false);
    const [ providerName, setProviderName ] = useState('');
    const [ providerTradeName, setProviderTradeName ] = useState('');
    const [ imgBase64, setImgBase64 ] = useState([]);
    const [ formData, setFormData ] = useState([]);
    const [ providerLogo, setProviderLogo ] = useState(
        {
            logo: '',
            logoName: 'Subir archivo',
        }
    );
    
    
    const [ providerData, setProviderData ] = useState();
    const toggleModal = () => {
        setOpenModal(!openModal);
    }
    const toggleModal2 = () => {
        setOpenModal2(!openModal2);
    }
    useEffect(() => {
        console.log(providerData);
    }, [providerData])
  
    console.log(props)
    const steps = [
        {
            label: 'Datos principales',
            step:0,
            component: 
                <ProviderPrincipalData 
                    stepper={stepper} 
                    setStepper={setStepper} 
                    toggleModal={toggleModal}
                    setName={setProviderName}
                    name={providerName}
                    setTradeName={setProviderTradeName}
                    tradeName={providerTradeName}
                    logo={providerLogo}
                    setLogo={setProviderLogo}
                    imgBase64={imgBase64} setImgBase64={setImgBase64}
                    setProviderData={setProviderData}
                    extraFooter={true}
                    extraQuotation={true}
                    setFormData={setFormData}
                    formData={formData}
                    mode={mode}
                />
        },
        {
            label: 'Datos de contacto',
            step:1,
            component: 
                <ProviderContactData
                    stepper={stepper} 
                    setStepper={setStepper} 
                    toggleModal={toggleModal}
                    setProviderData={setProviderData}
                    providerData={providerData}
                    extraFooter={true}
                    extraQuotation={true}
                    optionExtra={true}
                    mode={mode}
                    setFormData={setFormData}
                    formData={formData}
                />
        },
        {
            label: 'Documentación',
            step:2,
            component: 
                <ProvidersDocuments 
                    stepper={stepper} 
                    setStepper={setStepper} 
                    toggleModal={toggleModal} 
                    toggleModalNoFiles={toggleModal2}
                    providerLogo={providerLogo}
                    providerData={providerData}
                    mode={mode}
                    setFormData={setFormData}
                    formData={formData}
                />
        },
    ] 

    const [render, setRender]= useState(null)
    useEffect(() => {
        setRender(steps[stepper].component)
    }, [stepper])
    
    const renders = steps.map((ele)=>{
        return(
            ele.component
        )
    })
    
    const cancel = () => {
        history.push(PROVIDERS)
    }

    return (
        <>
        {
            openModal &&
            <Modal
                isOpen={toggleModal}
                toggle={toggleModal}
            >
                <CardContainer btnLeftAction={toggleModal} btnRightAction={cancel} body={<CancelFormBody/>}/>
            </Modal>
        }
        {
            openModal2 &&
            <Modal
                isOpen={openModal2}
                toggle={toggleModal2}
            >
                <CardContainer 
                    btnLeftAction={toggleModal2} 
                    btnRightAction={/* sendProviderData */ ''}
                    body={<BaseModalBody data={continueWithoutUploadFilesProvider} />}
                    btnLeftText={'REGRESAR'}
                />
            </Modal>
        }
        <div className='main-containerAuto'>
            <div className='stepper-container'>
            <StepperComponent step={stepper} steps={steps} setStepper={setStepper}/>
            </div>
            <div className='card-container-stepper' style={stepper===2 ? {width: '80%'} : {}}>
                {
                render
                }
            </div>
        </div>
        </>
    )
}

ProviderRegister.propTypes = {
    isSuccessUpsertProvider: PropTypes.any,
}

function mapStateToProps (state) {
    return {
        isSuccessUpsertProvider: _.get(state, 'provider.upsertProvider.isSuccess', {}),
    };
}

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps,mapDispatchToProps)(ProviderRegister);
