// COMPANY Module
export const COMPANY_MODULE_GET_ALL_COMPANIES_BEGIN = 'COMPANY_MODULE_GET_ALL_COMPANIES_BEGIN';
export const COMPANY_MODULE_GET_ALL_COMPANIES_SUCCESS = 'COMPANY_MODULE_GET_ALL_COMPANIES_SUCCESS';
export const COMPANY_MODULE_GET_ALL_COMPANIES_FAILURE = 'COMPANY_MODULE_GET_ALL_COMPANIES_FAILURE';
export const COMPANY_MODULE_UPSERT_COMPANY_BEGIN = 'COMPANY_MODULE_UPSERT_COMPANY_BEGIN';
export const COMPANY_MODULE_UPSERT_COMPANY_SUCCESS = 'COMPANY_MODULE_UPSERT_COMPANY_SUCCESS';
export const COMPANY_MODULE_UPSERT_COMPANY_FAILURE = 'COMPANY_MODULE_UPSERT_COMPANY_FAILURE';
export const COMPANY_MODULE_GET_ONE_COMPANY_RECORD_BEGIN = 'COMPANY_MODULE_GET_ONE_COMPANY_RECORD_BEGIN';
export const COMPANY_MODULE_GET_ONE_COMPANY_RECORD_SUCCESS = 'COMPANY_MODULE_GET_ONE_COMPANY_RECORD_SUCCESS';
export const COMPANY_MODULE_GET_ONE_COMPANY_RECORD_FAILURE = 'COMPANY_MODULE_GET_ONE_COMPANY_RECORD_FAILURE';
export const COMPANY_MODULE_GET_ALL_COMPANY_ACCOUNTS_BEGIN = 'COMPANY_MODULE_GET_ALL_COMPANY_ACCOUNTS_BEGIN';
export const COMPANY_MODULE_GET_ALL_COMPANY_ACCOUNTS_SUCCESS = 'COMPANY_MODULE_GET_ALL_COMPANY_ACCOUNTS_SUCCESS';
export const COMPANY_MODULE_GET_ALL_COMPANY_ACCOUNTS_FAILURE = 'COMPANY_MODULE_GET_ALL_COMPANY_ACCOUNTS_FAILURE';
export const COMPANY_MODULE_CONNECT_WITH_STORE_BEGIN = 'COMPANY_MODULE_CONNECT_WITH_STORE_BEGIN';
export const COMPANY_MODULE_CONNECT_WITH_STORE_SUCCESS = 'COMPANY_MODULE_CONNECT_WITH_STORE_SUCCESS';
export const COMPANY_MODULE_CONNECT_WITH_STORE_FAILURE = 'COMPANY_MODULE_CONNECT_WITH_STORE_FAILURE';
export const COMPANY_MODULE_UPLOAD_COMPANY_ACCOUNT_FILE_BEGIN = 'COMPANY_MODULE_UPLOAD_COMPANY_ACCOUNT_FILE_BEGIN';
export const COMPANY_MODULE_UPLOAD_COMPANY_ACCOUNT_FILE_SUCCESS = 'COMPANY_MODULE_UPLOAD_COMPANY_ACCOUNT_FILE_SUCCESS';
export const COMPANY_MODULE_UPLOAD_COMPANY_ACCOUNT_FILE_FAILURE = 'COMPANY_MODULE_UPLOAD_COMPANY_ACCOUNT_FILE_FAILURE';
export const COMPANY_MODULE_UPSERT_COMPANY_ACCOUNT_BEGIN = 'COMPANY_MODULE_UPSERT_COMPANY_ACCOUNT_BEGIN';
export const COMPANY_MODULE_UPSERT_COMPANY_ACCOUNT_SUCCESS = 'COMPANY_MODULE_UPSERT_COMPANY_ACCOUNT_SUCCESS';
export const COMPANY_MODULE_UPSERT_COMPANY_ACCOUNT_FAILURE = 'COMPANY_MODULE_UPSERT_COMPANY_ACCOUNT_FAILURE';

// Administrator Module
export const ADMINISTRATOR_MODULE_GET_ONE_ADMINISTARTOR_BEGIN = "ADMINISTRATOR_MODULE_GET_ONE_ADMINISTARTOR_BEGIN";
export const ADMINISTRATOR_MODULE_GET_ONE_ADMINISTARTOR_SUCCESS = "ADMINISTRATOR_MODULE_GET_ONE_ADMINISTARTOR_SUCCESS";
export const ADMINISTATOR_MODULE_GET_ONE_ADMINISTRATOR_FAILURE = "ADMINISTATOR_MODULE_GET_ONE_ADMINISTRATOR_FAILURE";
export const ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_BEGIN = "ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_BEGIN";
export const ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_SUCCESS = "ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_SUCCESS";
export const ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_FAILURE = "ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_FAILURE";
export const ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_BEGIN = "ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_BEGIN";
export const ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_SUCCESS = "ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_SUCCESS";
export const ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_FAILURE = "ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_FAILURE";
export const ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_BEGIN = 'ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_BEGIN';
export const ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_SUCCESS = 'ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_SUCCESS';
export const ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_FAILURE = 'ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_FAILURE';
export const ADMINISTRATOR_MODULE_UPLOAD_PROFILE_IMAGE_BEGIN = 'ADMINISTRATOR_MODULE_UPLOAD_PROFILE_IMAGE_BEGIN';
export const ADMINISTRATOR_MODULE_UPLOAD_PROFILE_IMAGE_SUCCESS = 'ADMINISTRATOR_MODULE_UPLOAD_PROFILE_IMAGE_SUCCESS';
export const ADMINISTRATOR_MODULE_UPLOAD_PROFILE_IMAGE_FAILURE = 'ADMINISTRATOR_MODULE_UPLOAD_PROFILE_IMAGE_FAILURE';



// Dashboard Module


// Notification Module
export const NOTIFICATION_ADD = 'NOTIFICATION_ADD';
export const NOTIFICATION_CLEAR = 'NOTIFICATION_CLEAR';

// User Interface Module
export const TOGGLE_SIDEBAR_SUCCESS = 'TOGGLE_SIDEBAR_SUCCESS';

// User Module
export const USER_MODULE_USER_LOGIN_BEGIN = 'USER_MODULE_USER_LOGIN_BEGIN';
export const USER_MODULE_USER_LOGIN_SUCCESS = 'USER_MODULE_USER_LOGIN_SUCCESS';
export const USER_MODULE_USER_LOGIN_FAILURE = 'USER_MODULE_USER_LOGIN_FAILURE';
export const USER_MODULE_USER_LOGOUT_BEGIN = 'USER_MODULE_USER_LOGOUT_BEGIN';
export const USER_MODULE_USER_LOGOUT_SUCCESS = 'USER_MODULE_USER_LOGOUT_SUCCESS';
export const USER_MODULE_USER_LOGOUT_FAILURE = 'USER_MODULE_USER_LOGOUT_FAILURE';
export const USER_MODULE_USER_REGISTER_BEGIN = 'USER_MODULE_USER_REGISTER_BEGIN';
export const USER_MODULE_USER_REGISTER_SUCCESS = 'USER_MODULE_USER_REGISTER_SUCCESS';
export const USER_MODULE_USER_REGISTER_FAILURE = 'USER_MODULE_USER_REGISTER_FAILURE';
export const USER_MODULE_USER_UPDATE_PASSWORD_REQUEST_BEGIN = 'USER_MODULE_USER_UPDATE_PASSWORD_REQUEST_BEGIN';
export const USER_MODULE_USER_UPDATE_PASSWORD_REQUEST_SUCCESS = 'USER_MODULE_USER_UPDATE_PASSWORD_REQUEST_SUCCESS';
export const USER_MODULE_USER_UPDATE_PASSWORD_REQUEST_FAILURE = 'USER_MODULE_USER_UPDATE_PASSWORD_REQUEST_FAILURE';
export const USER_MODULE_USER_SET_NEW_PASSWORD_BEGIN = 'USER_MODULE_USER_SET_NEW_PASSWORD_BEGIN';
export const USER_MODULE_USER_SET_NEW_PASSWORD_SUCCESS = 'USER_MODULE_USER_SET_NEW_PASSWORD_SUCCESS';
export const USER_MODULE_USER_SET_NEW_PASSWORD_FAILURE = 'USER_MODULE_USER_SET_NEW_PASSWORD_FAILURE';

// Client Module
export const CLIENT_MODULE_GET_ALL_CLIENTS_BEGIN = 'CLIENT_MODULE_GET_ALL_CLIENTS_BEGIN';
export const CLIENT_MODULE_GET_ALL_CLIENTS_SUCCESS = 'CLIENT_MODULE_GET_ALL_CLIENTS_SUCCESS';
export const CLIENT_MODULE_GET_ALL_CLIENTS_FAILURE = 'CLIENT_MODULE_GET_ALL_CLIENTS_FAILURE';
export const CLIENT_MODULE_UPSERT_CLIENT_BEGIN = 'CLIENT_MODULE_UPSERT_CLIENT_BEGIN';
export const CLIENT_MODULE_UPSERT_CLIENT_SUCCESS = 'CLIENT_MODULE_UPSERT_CLIENT_SUCCESS';
export const CLIENT_MODULE_UPSERT_CLIENT_FAILURE = 'CLIENT_MODULE_UPSERT_CLIENT_FAILURE';

// Custom Agent Module
export const CUSTOM_AGENT_MODULE_GET_ALL_CUSTOM_AGENTS_BEGIN = 'CUSTOM_AGENT_MODULE_GET_ALL_CUSTOM_AGENTS_BEGIN';
export const CUSTOM_AGENT_MODULE_GET_ALL_CUSTOM_AGENTS_SUCCESS = 'CUSTOM_AGENT_MODULE_GET_ALL_CUSTOM_AGENTS_SUCCESS';
export const CUSTOM_AGENT_MODULE_GET_ALL_CUSTOM_AGENTS_FAILURE = 'CUSTOM_AGENT_MODULE_GET_ALL_CUSTOM_AGENTS_FAILURE';
export const CUSTOM_AGENT_MODULE_UPSERT_CUSTOM_AGENT_BEGIN = 'CUSTOM_AGENT_MODULE_UPSERT_CUSTOM_AGENT_BEGIN';
export const CUSTOM_AGENT_MODULE_UPSERT_CUSTOM_AGENT_SUCCESS = 'CUSTOM_AGENT_MODULE_UPSERT_CUSTOM_AGENT_SUCCESS';
export const CUSTOM_AGENT_MODULE_UPSERT_CUSTOM_AGENT_FAILURE = 'CUSTOM_AGENT_MODULE_UPSERT_CUSTOM_AGENT_FAILURE';

//Association Client/Costumer and Custom Agent
export const COSTUMER_CUSTOM_AGENT_MODULE_UPSERT_COSTUMER_CUSTOM_AGENT_BEGIN = 'COSTUMER_CUSTOM_AGENT_MODULE_UPSERT_COSTUMER_CUSTOM_AGENT_BEGIN';
export const COSTUMER_CUSTOM_AGENT_MODULE_UPSERT_COSTUMER_CUSTOM_AGENT_SUCCESS = 'COSTUMER_CUSTOM_AGENT_MODULE_UPSERT_COSTUMER_CUSTOM_AGENT_SUCCESS';
export const COSTUMER_CUSTOM_AGENT_MODULE_UPSERT_COSTUMER_CUSTOM_AGENT_FAILURE = 'COSTUMER_CUSTOM_AGENT_MODULE_UPSERT_COSTUMER_CUSTOM_AGENT_FAILURE';

// Provider Module
export const PROVIDER_MODULE_GET_ALL_PROVIDERS_BEGIN = 'PROVIDER_MODULE_GET_ALL_PROVIDERS_BEGIN';
export const PROVIDER_MODULE_GET_ALL_PROVIDERS_SUCCESS = 'PROVIDER_MODULE_GET_ALL_PROVIDERS_SUCCESS';
export const PROVIDER_MODULE_GET_ALL_PROVIDERS_FAILURE = 'PROVIDER_MODULE_GET_ALL_PROVIDERS_FAILURE';
export const PROVIDER_MODULE_UPSERT_PROVIDER_BEGIN = 'PROVIDER_MODULE_UPSERT_PROVIDER_BEGIN';
export const PROVIDER_MODULE_UPSERT_PROVIDER_SUCCESS = 'PROVIDER_MODULE_UPSERT_PROVIDER_SUCCESS';
export const PROVIDER_MODULE_UPSERT_PROVIDER_FAILURE = 'PROVIDER_MODULE_UPSERT_PROVIDER_FAILURE';
export const PROVIDER_MODULE_DELETE_PROVIDER_BEGIN = 'PROVIDER_MODULE_DELETE_PROVIDER_BEGIN';
export const PROVIDER_MODULE_DELETE_PROVIDER_SUCCESS = 'PROVIDER_MODULE_DELETE_PROVIDER_SUCCESS';
export const PROVIDER_MODULE_DELETE_PROVIDER_FAILURE = 'PROVIDER_MODULE_DELETE_PROVIDER_FAILURE';


// Quotation Module
export const QUOTATION_MODULE_GET_ALL_QUOTATIONS_BEGIN = 'QUOTATION_MODULE_GET_ALL_QUOTATIONS_BEGIN';
export const QUOTATION_MODULE_GET_ALL_QUOTATIONS_SUCCESS = 'QUOTATION_MODULE_GET_ALL_QUOTATIONS_SUCCESS';
export const QUOTATION_MODULE_GET_ALL_QUOTATIONS_FAILURE = 'QUOTATION_MODULE_GET_ALL_QUOTATIONS_FAILURE';
export const QUOTATION_MODULE_UPSERT_QUOTATION_BEGIN = 'QUOTATION_MODULE_UPSERT_QUOTATION_BEGIN';
export const QUOTATION_MODULE_UPSERT_QUOTATION_SUCCESS = 'QUOTATION_MODULE_UPSERT_QUOTATION_SUCCESS';
export const QUOTATION_MODULE_UPSERT_QUOTATION_FAILURE = 'QUOTATION_MODULE_UPSERT_QUOTATION_FAILURE';
export const QUOTATION_MODULE_DELETE_QUOTATION_BEGIN = 'QUOTATION_MODULE_DELETE_QUOTATION_BEGIN';
export const QUOTATION_MODULE_DELETE_QUOTATION_SUCCESS = 'QUOTATION_MODULE_DELETE_QUOTATION_SUCCESS';
export const QUOTATION_MODULE_DELETE_QUOTATION_FAILURE = 'QUOTATION_MODULE_DELETE_QUOTATION_FAILURE';

// Operative Module
export const PORT_MODULE_GET_ALL_PORTS_BEGIN = 'PORT_MODULE_GET_ALL_PORTS_BEGIN';
export const PORT_MODULE_GET_ALL_PORTS_SUCCESS = 'PORT_MODULE_GET_ALL_PORTS_SUCCESS';
export const PORT_MODULE_GET_ALL_PORTS_FAILURE = 'PORT_MODULE_GET_ALL_PORTS_FAILURE';
export const PORT_MODULE_UPSERT_PORT_BEGIN = 'PORT_MODULE_UPSERT_PORT_BEGIN';
export const PORT_MODULE_UPSERT_PORT_SUCCESS = 'PORT_MODULE_UPSERT_PORT_SUCCESS';
export const PORT_MODULE_UPSERT_PORT_FAILURE = 'PORT_MODULE_UPSERT_PORT_FAILURE';
export const PORT_MODULE_DELETE_PORT_BEGIN = 'PORT_MODULE_DELETE_PORT_BEGIN';
export const PORT_MODULE_DELETE_PORT_SUCCESS = 'PORT_MODULE_DELETE_PORT_SUCCESS';
export const PORT_MODULE_DELETE_PORT_FAILURE = 'PORT_MODULE_DELETE_PORT_FAILURE';
export const PORT_MODULE_GET_ALL_PORTS_CLIENT_BEGIN = "PORT_MODULE_GET_ALL_PORTS_CLIENT_BEGIN";
export const PORT_MODULE_GET_ALL_PORTS_CLIENT_SUCCESS = "PORT_MODULE_GET_ALL_PORTS_CLIENT_SUCCESS";
export const PORT_MODULE_GET_ALL_PORTS_CLIENT_FAILURE = "PORT_MODULE_GET_ALL_PORTS_CLIENT_FAILURE";

// Operative Module
export const OPERATIVE_MODULE_GET_ALL_OPERATIVES_BEGIN = 'OPERATIVE_MODULE_GET_ALL_OPERATIVES_BEGIN';
export const OPERATIVE_MODULE_GET_ALL_OPERATIVES_SUCCESS = 'OPERATIVE_MODULE_GET_ALL_OPERATIVES_SUCCESS';
export const OPERATIVE_MODULE_GET_ALL_OPERATIVES_FAILURE = 'OPERATIVE_MODULE_GET_ALL_OPERATIVES_FAILURE';
export const OPERATIVE_MODULE_GET_ONE_OPERATIVE_BEGIN = 'OPERATIVE_MODULE_GET_ONE_OPERATIVE_BEGIN';
export const OPERATIVE_MODULE_GET_ONE_OPERATIVE_SUCCESS = 'OPERATIVE_MODULE_GET_ONE_OPERATIVE_SUCCESS';
export const OPERATIVE_MODULE_GET_ONE_OPERATIVE_FAILURE = 'OPERATIVE_MODULE_GET_ONE_OPERATIVE_SUCCESS';
export const OPERATIVE_MODULE_UPSERT_OPERATIVE_BEGIN = 'OPERATIVE_MODULE_UPSERT_OPERATIVE_BEGIN';
export const OPERATIVE_MODULE_UPSERT_OPERATIVE_SUCCESS = 'OPERATIVE_MODULE_UPSERT_OPERATIVE_SUCCESS';
export const OPERATIVE_MODULE_UPSERT_OPERATIVE_FAILURE = 'OPERATIVE_MODULE_UPSERT_OPERATIVE_FAILURE';
export const OPERATIVE_MODULE_DELETE_OPERATIVE_BEGIN = 'OPERATIVE_MODULE_DELETE_OPERATIVE_BEGIN';
export const OPERATIVE_MODULE_DELETE_OPERATIVE_SUCCESS = 'OPERATIVE_MODULE_DELETE_OPERATIVE_SUCCESS';
export const OPERATIVE_MODULE_DELETE_OPERATIVE_FAILURE = 'OPERATIVE_MODULE_DELETE_OPERATIVE_FAILURE';
export const OPERATIVE_MODULE_ENABLE_PROVIDER_SUCCESS = 'PROVIDER_MODULE_ENABLE_PROVIDER_SUCCESS';
export const OPERATIVE_MODULE_ENABLE_PROVIDER_BEGIN = 'PROVIDER_MODULE_ENABLE_PROVIDER_BEGIN';
export const OPERATIVE_MODULE_ENABLE_PROVIDER_FAILURE = 'PROVIDER_MODULE_ENABLE_PROVIDER_FAILURE';
export const OPERATIVE_MODULE_GET_ALL_STRIKE_OPERATIVE_BEGIN = 'OPERATIVE_MODULE_GET_ALL_STRIKE_OPERATIVE_BEGIN';
export const OPERATIVE_MODULE_GET_ALL_STRIKE_OPERATIVE_SUCCESS = 'OPERATIVE_MODULE_GET_ALL_STRIKE_OPERATIVE_SUCCESS';
export const OPERATIVE_MODULE_GET_ALL_STRIKE_OPERATIVE_FAILURE = 'OPERATIVE_MODULE_GET_ALL_STRIKE_OPERATIVE_FAILURE';
export const OPERATIVE_MODULE_UPSERT_STRIKE_OPERATIVE_BEGIN = 'OPERATIVE_MODULE_UPSERT_STRIKE_OPERATIVE_BEGIN';
export const OPERATIVE_MODULE_UPSERT_STRIKE_OPERATIVE_SUCCESS = 'OPERATIVE_MODULE_UPSERT_STRIKE_OPERATIVE_SUCCESS';
export const OPERATIVE_MODULE_UPSERT_STRIKE_OPERATIVE_FAILURE = 'OPERATIVE_MODULE_UPSERT_STRIKE_OPERATIVE_FAILURE';


//Delete Association
export const DELETE_ASSOCIATION_BEGIN = 'DELETE_ASSOCIATION_BEGIN';
export const DELETE_ASSOCIATION_SUCCESS = 'DELETE_ASSOCIATION_SUCCESS';
export const DELETE_ASSOCIATION_FAILURE = 'DELETE_ASSOCIATION_FAILURE';


//Get all client belong custom Agent
export const GET_ALL_CLIENTS_BELONGS_CUSTOM_AGENT_BEGIN = 'GET_ALL_CLIENTS_BELONGS_CUSTOM_AGENT_BEGIN';
export const GET_ALL_CLIENTS_BELONGS_CUSTOM_AGENT_SUCCESS = 'GET_ALL_CLIENTS_BELONGS_CUSTOM_AGENT_SUCCESS';
export const GET_ALL_CLIENTS_BELONGS_CUSTOM_AGENT_FAILURE = 'GET_ALL_CLIENTS_BELONGS_CUSTOM_AGENT_FAILURE';
export const GET_ALL_AVAILABLE_CLIENTS_BY_CUSTOM_AGENT_BEGIN = 'GET_ALL_AVAILABLE_CLIENTS_BY_CUSTOM_AGENT_BEGIN';
export const GET_ALL_AVAILABLE_CLIENTS_BY_CUSTOM_AGENT_SUCCESS = 'GET_ALL_AVAILABLE_CLIENTS_BY_CUSTOM_AGENT_SUCCESS';
export const GET_ALL_AVAILABLE_CLIENTS_BY_CUSTOM_AGENT_FAILURE = 'GET_ALL_AVAILABLE_CLIENTS_BY_CUSTOM_AGENT_FAILURE';

//Get all custom agent belong client
export const GET_ALL_CUSTOM_AGENT_BELONGS_CLIENT_BEGIN = 'GET_ALL_CUSTOM_AGENT_BELONGS_CLIENT_BEGIN';
export const GET_ALL_CUSTOM_AGENT_BELONGS_CLIENT_SUCCESS = 'GET_ALL_CUSTOM_AGENT_BELONGS_CLIENT_SUCCESS';
export const GET_ALL_CUSTOM_AGENT_BELONGS_CLIENT_FAILURE = 'GET_ALL_CUSTOM_AGENT_BELONGS_CLIENT_FAILURE';
export const GET_ALL_AVAILABLE_CUSTOM_AGENT_BY_CLIENTS_BEGIN = 'GET_ALL_AVAILABLE_CUSTOM_AGENT_BY_CLIENTS_BEGIN';
export const GET_ALL_AVAILABLE_CUSTOM_AGENT_BY_CLIENTS_SUCCESS = 'GET_ALL_AVAILABLE_CUSTOM_AGENT_BY_CLIENTS_SUCCESS';
export const GET_ALL_AVAILABLE_CUSTOM_AGENT_BY_CLIENTS_FAILURE = 'GET_ALL_AVAILABLE_CUSTOM_AGENT_BY_CLIENTS_FAILURE';


//ADDRESSES

export const ADDRESS_MODULE_GET_ALL_COUNTRIES_BEGIN = 'ADDRESS_MODULE_GET_ALL_COUNTRIES_BEGIN';
export const ADDRESS_MODULE_GET_ALL_COUNTRIES_SUCCESS = 'ADDRESS_MODULE_GET_ALL_COUNTRIES_SUCCESS';
export const ADDRESS_MODULE_GET_ALL_COUNTRIES_FAILURE = 'ADDRESS_MODULE_GET_ALL_COUNTRIES_FAILURE';
export const ADDRESS_MODULE_GET_ALL_STATES_BEGIN = 'ADDRESS_MODULE_GET_ALL_STATES_BEGIN';
export const ADDRESS_MODULE_GET_ALL_STATES_SUCCESS = 'ADDRESS_MODULE_GET_ALL_STATES_SUCCESS';
export const ADDRESS_MODULE_GET_ALL_STATES_FAILURE = 'ADDRESS_MODULE_GET_ALL_STATES_FAILURE';
export const ADDRESS_MODULE_GET_ALL_CITIES_BEGIN = 'ADDRESS_MODULE_GET_ALL_CITIES_BEGIN';
export const ADDRESS_MODULE_GET_ALL_CITIES_SUCCESS = 'ADDRESS_MODULE_GET_ALL_CITIES_SUCCESS';
export const ADDRESS_MODULE_GET_ALL_CITIES_FAILURE = 'ADDRESS_MODULE_GET_ALL_CITIES_FAILURE';

//PERMISSIONS
export const PERMISSIONS_MODULE_GET_ALL_PERMISSIONS_BEGIN = 'PERMISSIONS_MODULE_GET_ALL_PERMISSIONS_BEGIN';
export const PERMISSIONS_MODULE_GET_ALL_PERMISSIONS_SUCCESS = 'PERMISSIONS_MODULE_GET_ALL_PERMISSIONS_SUCCESS';
export const PERMISSIONS_MODULE_GET_ALL_PERMISSIONS_FAILURE = 'PERMISSIONS_MODULE_GET_ALL_PERMISSIONS_FAILURE';


// USER PERMISOS
export const USER_PERMISSION_MODULE_GET_ALL_USER_PERMISSION_BEGIN = 'USER_PERMISSION_MODULE_GET_ALL_USER_PERMISSION_BEGIN';
export const USER_PERMISSION_MODULE_GET_ALL_USER_PERMISSION_SUCCESS = 'USER_PERMISSION_MODULE_GET_ALL_USER_PERMISSION_SUCCESS';
export const USER_PERMISSION_MODULE_GET_ALL_USER_PERMISSION_FAILURE = 'USER_PERMISSION_MODULE_GET_ALL_USER_PERMISSION_FAILURE';

// PUBLICACIONES
export const POSTS_MODULES_GET_ALL_POSTS_BEGIN = 'POSTS_MODULES_GET_ALL_POSTS_BEGIN';
export const POSTS_MODULES_GET_ALL_POSTS_SUCCESS = 'POSTS_MODULES_GET_ALL_POSTS_SUCCESS';
export const POSTS_MODULES_GET_ALL_POSTS_FAILURE = 'POSTS_MODULES_GET_ALL_POSTS_FAILURE';

// FOREX
export const FOREX_MODULE_GET_ALL_FOREX_BEGIN = 'FOREX_MODULE_GET_ALL_FOREX_BEGIN';
export const FOREX_MODULE_GET_ALL_FOREX_SUCCESS = 'FOREX_MODULE_GET_ALL_FOREX_SUCCESS';
export const FOREX_MODULE_GET_ALL_FOREX_FAILURE = 'FOREX_MODULE_GET_ALL_FOREX_FAILURE';
export const FOREX_MODULE_UPSERT_FOREX_BEGIN = 'FOREX_MODULE_UPSERT_FOREX_BEGIN';
export const FOREX_MODULE_UPSERT_FOREX_SUCCESS = 'FOREX_MODULE_UPSERT_FOREX_SUCCESS';
export const FOREX_MODULE_UPSERT_FOREX_FAILURE = 'FOREX_MODULE_UPSERT_FOREX_FAILURE';


// CHARGES
export const CHARGES_MODULE_GET_ALL_CHARGES_BEGIN = 'CHARGES_MODULE_GET_ALL_CHARGES_BEGIN';
export const CHARGES_MODULE_GET_ALL_CHARGES_SUCCESS = 'CHARGES_MODULE_GET_ALL_CHARGES_SUCCESS';
export const CHARGES_MODULE_GET_ALL_CHARGES_FAILURE = 'CHARGES_MODULE_GET_ALL_CHARGES_FAILURE';
export const COMMISSION_MODULE_GET_ALL_COMMISSION_BEGIN = 'COMMISSION_MODULE_GET_ALL_COMMISSION_BEGIN';
export const COMMISSION_MODULE_GET_ALL_COMMISSION_SUCCESS = 'COMMISSION_MODULE_GET_ALL_COMMISSION_SUCCESS';
export const COMMISSION_MODULE_GET_ALL_COMMISSION_FAILURE = 'COMMISSION_MODULE_GET_ALL_COMMISSION_FAILURE';

// reports
export const REPORT_TYPE_MODULE_GET_ALL_REPORT_TYPE_BEGIN = 'REPORT_TYPE_MODULE_GET_ALL_REPORT_TYPE_BEGIN';
export const REPORT_TYPE_MODULE_GET_ALL_REPORT_TYPE_SUCCESS = 'REPORT_TYPE_MODULE_GET_ALL_REPORT_TYPE_SUCCESS';
export const REPORT_TYPE_MODULE_GET_ALL_REPORT_TYPE_FAILURE = 'REPORT_TYPE_MODULE_GET_ALL_REPORT_TYPE_FAILURE';
export const REPORT_MODULE_UPSERT_REPORT_BEGIN = 'REPORT_MODULE_UPSERT_REPORT_BEGIN';
export const REPORT_MODULE_UPSERT_REPORT_SUCCESS = 'REPORT_MODULE_UPSERT_REPORT_SUCCESS';
export const REPORT_MODULE_UPSERT_REPORT_FAILURE = 'REPORT_MODULE_UPSERT_REPORT_FAILURE';
export const REPORT_MODULE_GET_ALL_REPORT_BEGIN = 'REPORT_MODULE_GET_ALL_REPORT_BEGIN';
export const REPORT_MODULE_GET_ALL_REPORT_SUCCESS = 'REPORT_MODULE_GET_ALL_REPORT_SUCCESS';
export const REPORT_MODULE_GET_ALL_REPORT_FAILURE = 'REPORT_MODULE_GET_ALL_REPORT_FAILURE';
export const REPORT_MODULE_GET_ONE_REPORT_BEGIN = 'REPORT_MODULE_GET_ONE_REPORT_BEGIN';
export const REPORT_MODULE_GET_ONE_REPORT_SUCCESS = 'REPORT_MODULE_GET_ONE_REPORT_SUCCESS';
export const REPORT_MODULE_GET_ONE_REPORT_FAILURE = 'REPORT_MODULE_GET_ONE_REPORT_FAILURE';
export const REPORT_MODULE_DELETE_REPORT_BEGIN = 'REPORT_MODULE_DELETE_REPORT_BEGIN';
export const REPORT_MODULE_DELETE_REPORT_SUCCESS = 'REPORT_MODULE_DELETE_REPORT_SUCCESS';
export const REPORT_MODULE_DELETE_REPORT_FAILURE = 'REPORT_MODULE_DELETE_REPORT_FAILURE';



