/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import docIcon from '../../../assets/images/navieras/docIcon.png';
import deleteIcon from '../../../assets/images/navieras/deleteTrash.png';
import seeIcon from '../../../assets/images/navieras/seeButton.png';
import { useForm } from 'react-hook-form';
import './UploadFile.scss'
import { DeleteBody } from '../../molecules/CancelFormBody/DeleteBody';
import Modal from '../Modal/Modal';
import { CardContainer } from '../../molecules/CardContainer/CardContainer';

export const UploadFile = ({index, setFiles, files, arrayComplete, noDelete, noSee, setArrayDeleted, arrayDeleted, disabled = false, hideTitle = true}) => {
    const {register, handleSubmit, watch } = useForm();
    const [openModal, setOpenModal] = useState(false)
    const toggleModal = () => {
        setOpenModal(!openModal);
    }
    const seeFile = () =>{
        console.log('see File')
    }
    const deleteFile = () => {
        let arrayCopy = [...arrayComplete];
        let arrDeleteCopy = [...arrayDeleted];
        arrayCopy.map( (el) => {
            if(files.id === el.id){
                    el.file = null;
                    el.watch = false;
                arrDeleteCopy.push(el.id);
            }
        })
        setFiles(arrayCopy);
        setArrayDeleted(arrDeleteCopy);
        toggleModal();
        }
        
    const handleAddFile = (e) => {
        let arrayCopy = [...arrayComplete]; 
        arrayCopy.map( (el) => {
            if(files.id === el.id){
                el.file = e.target.files[0];
                el.watch = true;
                el.newAdd = true;
            }
        })
        setFiles(arrayCopy);
    }
    
  return (
    <>
    {
        openModal &&
        <Modal
            isOpen={toggleModal}
            toggle={toggleModal}
        >
            <CardContainer btnLeftAction={toggleModal} btnRightAction={deleteFile} body={<DeleteBody user={'documento'}/>}/>
        </Modal>
    }
    <div className='uploadFileCardContainer'>
                { 
                    !hideTitle &&
                    <div className='titleCardFiles'>
                        <p>{files.name}</p>
                        {/* <button type='button' onClick={()=>{deleteFile()}}>delete</button> */}
                    </div>
                }
                <div  className={!files.watch ? 'uploadFileArea' : 'uploadedFileArea'}>
                    {/* <input type='file'/> */}
                        {
                            files.watch && 
                            <>
                            <div className='deleteFile'> 
                            {
                            !noDelete &&
                            <img src={deleteIcon} alt="" onClick={toggleModal}/> 
                            }
                            {
                            !noSee &&
                            <img src={seeIcon} alt="" onClick={()=>seeFile()}/>  
                            }
                            </div>
                            
                            <div className='uploadedFileView'>
                                <img src={docIcon} alt="" />
                            </div>
                            <div className='uploadedFileText'>
                                <p>{_.get(files, 'file.name', 'Archivo')}</p>
                            </div>
                            </>
                        }
                        {
                            !files.watch && 
                            <>
                                <div className='headerCardFiles'>
                                    <p className='p1'>Subir archivo</p>
                                    <p className='p2'>El archivo debe ser PDF, JPG, PNG</p>
                                </div>
                                <div className='bodyCardFiles'>
                                    <input
                                        className='inputUploadFileArea'
                                        id='documentMedia'
                                        {...register('documentMedia')}
                                        type='file'
                                        onChange={(e) => handleAddFile(e)}
                                        disabled={disabled}
                                    />
                                    <div className='drag-area'>
                                        <img src={docIcon} alt="" />
                                        <p>Seleccione su archivo aquí</p>
                                    </div>
                                </div>
                            </>
                        }
                </div>
        </div>
    </>
  )
}

UploadFile.propTypes = {
    files: PropTypes.any,
    setFiles: PropTypes.func,
    arrayComplete: PropTypes.any,
    hideTitle: PropTypes.bool,
    noDelete: PropTypes.bool,
    noSee: PropTypes.bool,
}
