import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

export default function ControlledRadioButtonsGroup(props) {
  const [value, setValue] = React.useState(props.value);

  const handleChange = (event) => {
    setValue(event.target.value);
    props.setCents(event.target.value)
  };

  console.log(value)

  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        <div style={{display:"grid", gridTemplateColumns:"1fr 1fr"}}>
            <FormControlLabel value={0.1} labelPlacement='start' control={<Radio />} label="-0.1 centavos" />
            <FormControlLabel value={0.2} labelPlacement='start' control={<Radio />} label="-0.2 centavos" />
        </div>
        <div style={{display:"grid", gridTemplateColumns:"1fr 1fr"}}>
            <FormControlLabel value={0.3} labelPlacement='start' control={<Radio />} label="-0.3 centavos" />
            <FormControlLabel value={0.4} labelPlacement='start' control={<Radio />} label="-0.4 centavos" />
        </div>
        <div style={{display:"grid", gridTemplateColumns:"1fr 1fr"}}>
            <FormControlLabel value={0.5} labelPlacement='start' control={<Radio />} label="-0.5 centavos" />
            <FormControlLabel value={0.6} labelPlacement='start' control={<Radio />} label="-0.6 centavos" />
        </div>
        <div style={{display:"grid", gridTemplateColumns:"1fr 1fr"}}>
            <FormControlLabel value={0.7} labelPlacement='start' control={<Radio />} label="-0.7 centavos" />
        </div>
      </RadioGroup>
    </FormControl>
  );
}
