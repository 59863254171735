import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    input: {
        background: 'transparent linear-gradient(255deg, #3F3F3F80 0%, #D8D8D826 100%) 0% 0% no-repeat padding-box',
        boxShadow: 'inset 0px 0px 10px #00000033',
        borderRadius: '30px',
        opacity: '1',
        backdropFilter: 'blur(20px)',
        webkitBackdropFilter: 'blur(20px)',
        '& .MuiInputBase-input': {
          padding: '10px',
          color: '#D8D8D880',
          fontSize: '18px',
          fontWeight: '500',
          letterSpacing: '-0.5px'
        },
        '& .MuiInputBase-root': {
          color: '#fff',
        },
        '& .MuiInputBase-root:before': {
          content: 'none'
        }
      }
});

const CustomDatePicker = (props) => {

  const classes = useStyles();

  return (
    <TextField
        label={null}
      type="date"
      InputLabelProps={{
        shrink: true,
      }}
      onChange={(e) => {
        props.setValue(e.target.value);
      }}
      InputProps={{
        disableUnderline: true, 
        className: classes.input,
      }}
    />
  );
}

export default CustomDatePicker;
