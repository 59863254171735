/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import './Quotation.scss';
import '../../styles/stylesSteppers.scss';
import arrow from '../../assets/images/navieras/arrowButton.png'
import docIcon from '../../assets/images/navieras/docIcon.png'
import add from '../../assets/images/navieras/AGREGAR.png'
import { useForm } from 'react-hook-form';
import _ from 'lodash'
import { UploadFile } from '../../components/organisms/UploadFile/UploadFile';
import { Button } from '../../components/atoms';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { upsertProviderAction } from '../../redux/actions/providerAction';
import { PROVIDERS } from '../../routes/Paths';

export const ProvidersDocuments = ({ stepper, setStepper, toggleModal, toggleModalNoFiles, registerProvider, setterFiles, fileSend,
    setFileSend, providerData, providerLogo, formData, setFormData }) => {
    const methods = useForm({ defaultValues: defaultValues });
    const history = useHistory();
    const dispatch = useDispatch();
    const { mode } = useParams();
    const [ isDisabled, setIsDisabled ] = useState(mode === 'ver' ? true : false);
    const defaultValues = {
        // name: values.name,
        // tradename: values.tradename,
    };

    const { register, handleSubmit, watch } = useForm();

    const changeStageBack = () => {
        setStepper(stepper - 1)
    }

    const [ mediasDeleted, setMediasDeleted ] = useState([]);
    const [watchDocumentMedia, setWatchDocumentMedia] = useState(false);
    const [last, setLast] = useState(null);
    const [files, setFiles] = useState(
        [
            {
                name: 'RFC',
                id: 0,
                file: null,
                deletedAt: false,
                watch: false
            },
            {
                name: 'Situación fiscal',
                id: 1,
                file: null,
                deletedAt: false,
                watch: false
            },
            {
                name: 'INE, pasaporte, cédula profesional',
                id: 2,
                file: null,
                deletedAt: false,
                watch: false
            },
        ]
    )

    const nextAction = async () => {
        if (_.isEmpty(files)) { toggleModalNoFiles() }
        else {
            registerProvider()

        }
    }

    const [fileToSend, setFileToSend] = useState();
    useEffect(() => {
        let file = [];
        files.forEach((ele) => {
            if (!_.isNil(ele.file)) {
                file.push(ele.file)
            }
        })
        setFileToSend(file)
    }, [files])


    useEffect(() => {
        let final = [...files];
        setLast(final.pop())
    }, [files])
    const data = [
        { name: 'RFC' }, { name: 'Situacion  Fiscal' }, { name: 'INE, pasaporte, cédula profesional' }
    ]

    const handleAdd = () => {
        let arrayCopy = [...files];
        let lastElement = _.last(files);
        arrayCopy.push(
            {
                name: 'Archivo',
                id: lastElement.id + 1,
                file: null,
                watch: false,
                deletedAt: false,
                newAdd: true,
            }
        )
        setFiles(arrayCopy);
    }


    const sendProviderData = async () => {
        if (_.isEmpty(files)) { toggleModalNoFiles() }
        const newFormData = new FormData();
        if (mode == 'editar') newFormData.append('id', JSON.stringify(formData.id))
        let providerD = {
            ...formData,
            // media: providerLogo[0] && providerLogo[0].hasOwnProperty('src') ? {
            //     base64: {
            //         src: providerLogo[0].src,
            //         title: providerLogo[0].title.replace(/[{()}]/g, ''),
            //     }
            // } : null,
        }
        newFormData.append("provider", JSON.stringify(providerD))
        newFormData.append("mediasDeleted", JSON.stringify(mediasDeleted))

        const arrFiles = files.map(file => file.file != null ? file : false).filter( file => file != false )

        for(let i = 0; i < arrFiles.length; i++){

            newFormData.append("File", arrFiles[i].file);
        }
        dispatch(upsertProviderAction(newFormData));
        history.push(PROVIDERS);
    }
    
    return (
        <form className='main-container-generic-type'>
            <div className='header-form-generic-type'>
                <h2>Documentos</h2>
            </div>
            <div className='body-form-generic-type-docs'>
                {
                    files.map((ele, i) => {
                        return (
                            <UploadFile 
                                key={i} 
                                files={ele} 
                                last={last} 
                                setFiles={setFiles} 
                                arrayComplete={files} 
                                hideTitle={true} 
                                setArrayDeleted={setMediasDeleted}
                                arrayDeleted={mediasDeleted}
                                disabled={isDisabled}
                            />
                        )
                    })
                }
                {
                    mode != 'ver' && 
                    <div className='add-button-container' style={{ justifyContent: 'center' }}>
                        <img className='add-card' src={add} onClick={handleAdd} />
                    </div>
                }
            </div>
            <div className='footer-form-generic-type' style={{ justifyContent: 'space-between' }}>
                <Button type='button' onClick={changeStageBack} className={'button-back generate'} style={{ width: '30%' }}>CANCELAR</Button>
                <Button type='button' onClick={sendProviderData} className={'button-next'} style={{ width: '30%' }}>REGISTRAR</Button>
            </div>
        </form>
    )
}

ProvidersDocuments.propTypes = {
    stepper: PropTypes.any,
    setStepper: PropTypes.any,
    toggleModal: PropTypes.any,
    toggleModalNoFiles: PropTypes.any,
    registerProvider: PropTypes.func,
    values: PropTypes.any,
}
