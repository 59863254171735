import request from "../../utils/request";
import { addNotification } from './notificationAction';

import {
    CHARGES_MODULE_GET_ALL_CHARGES_BEGIN,
    CHARGES_MODULE_GET_ALL_CHARGES_SUCCESS,
    CHARGES_MODULE_GET_ALL_CHARGES_FAILURE,
} from '../actionTypes';

export const getAllChargesBegin = () => ({
    type: CHARGES_MODULE_GET_ALL_CHARGES_BEGIN,
});

export const getAllChargesSuccess = (data) => ({
    type: CHARGES_MODULE_GET_ALL_CHARGES_SUCCESS,
    data,
});

export const getAllChargesFailure = (err) => ({
    type: CHARGES_MODULE_GET_ALL_CHARGES_FAILURE,
    err,
});

export function getAllChargesAction(data){
    return (dispatch, getState) => {
        dispatch(getAllChargesBegin());
        return request({
            partialUrl: `/api/v1/charges/get-all-charges-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log('this is data response');
            console.log(data.data);
            dispatch(getAllChargesSuccess(data.data));
            return data.data
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
            }));
            return dispatch(getAllChargesFailure(err));
        });
    };
}