import _ from 'lodash';
import {
    ADDRESS_MODULE_GET_ALL_COUNTRIES_BEGIN,
    ADDRESS_MODULE_GET_ALL_COUNTRIES_SUCCESS,
    ADDRESS_MODULE_GET_ALL_COUNTRIES_FAILURE,
    ADDRESS_MODULE_GET_ALL_STATES_BEGIN,
    ADDRESS_MODULE_GET_ALL_STATES_SUCCESS,
    ADDRESS_MODULE_GET_ALL_STATES_FAILURE,
    ADDRESS_MODULE_GET_ALL_CITIES_BEGIN,
    ADDRESS_MODULE_GET_ALL_CITIES_SUCCESS,
    ADDRESS_MODULE_GET_ALL_CITIES_FAILURE,
 } from '../actionTypes';

import initialState from '../initialState';

export default function addressReducer(state=initialState.address, action){
    let stateName = 'states';
    if(_.get(action, 'target')){
        stateName = stateName+action.target;
    }
    switch (action.type) {
        case ADDRESS_MODULE_GET_ALL_COUNTRIES_BEGIN:
            return {
                ...state,
                countries: {
                    ...state.countries,
                    loadingGetAllCountries: true,
                    errorGetAllCountries: null,
                    totalRecords: 0,
                    data: [],
                }
            };
        case ADDRESS_MODULE_GET_ALL_COUNTRIES_SUCCESS:
            return {
                ...state,
                countries: {
                    data: action.data,
                }
            };
        case ADDRESS_MODULE_GET_ALL_COUNTRIES_FAILURE:
            return {
                ...state,
                countries: {
                    ...state.countries,
                    loadingGetAllCountries: false,
                    errorGetAllCountries: action.err,
                    totalRecords: 0,
                    data: [],
                }
            };
        case ADDRESS_MODULE_GET_ALL_STATES_BEGIN:
            return {
                ...state,
                states: {
                    ...state.states,
                    loadingGetAllCountries: true,
                    errorGetAllCountries: null,
                    totalRecords: 0,
                    data: [],
                }
            };
        case ADDRESS_MODULE_GET_ALL_STATES_SUCCESS:
            return {
                ...state,
                [stateName]: {
                    data: action.data,
                }
            };
        case ADDRESS_MODULE_GET_ALL_STATES_FAILURE:
            return {
                ...state,
                states: {
                    ...state.states,
                    loadingGetAllCountries: false,
                    errorGetAllCountries: action.err,
                    totalRecords: 0,
                    data: [],
                }
            };
        case ADDRESS_MODULE_GET_ALL_CITIES_BEGIN:
            return {
                ...state,
                cities: {
                    ...state.cities,
                    loadingGetAllCountries: true,
                    errorGetAllCountries: null,
                    totalRecords: 0,
                    data: [],
                }
            };
        case ADDRESS_MODULE_GET_ALL_CITIES_SUCCESS:
            return {
                ...state,
                cities: {
                    data: action.data,
                }
            };
        case ADDRESS_MODULE_GET_ALL_CITIES_FAILURE:
            return {
                ...state,
                cities: {
                    ...state.cities,
                    loadingGetAllCountries: false,
                    errorGetAllCountries: action.err,
                    totalRecords: 0,
                    data: [],
                }
            };
            // ---

        default:
            return state;
    }
}
