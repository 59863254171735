/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Button } from '../../../../components/atoms';
// import './ClientRegister.scss';
import '../../../../styles/stylesSteppers.scss'
import arrow from '../../../../assets/images/navieras/arrowButton.png'
import docIcon from '../../../../assets/images/navieras/docIcon.png'
import add from '../../../../assets/images/navieras/AGREGAR.png'
import { useForm } from 'react-hook-form';
import _ from 'lodash'
import { UploadFile } from '../../../../components/organisms/UploadFile/UploadFile';
import { upsertClientAction } from '../../../../redux/actions/clientAction';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';


export const ClientDocuments = ({ stepper, setStepper, toggleModal, formData, setFormData, mode }) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const typeAction = useSelector(state => state.router.location.hash)
    const methods = useForm({ defaultValues: defaultValues });
    const [ mediasDeleted, setMediasDeleted ] = useState([]);
    const defaultValues = {
        // name : '',
    };
    const { register, handleSubmit, watch } = useForm();
    const changeStage = () => {
        setStepper(stepper + 1)
    }
    const changeStageBack = () => {
        setStepper(stepper - 1)
    }

    const clientInfo = useSelector(state => state.client.clients)

    const agent = JSON.stringify(formData && formData.agent ? formData.agent : [])
    const person = JSON.stringify(formData.person);

    const {
        id,
        businessname,
        country,
        creatorId,
        direction,
        isfisica,
        ismoral,
        paymentmethods,
        postcode,
        rfc,
        state,
        tradename,
        zone,
        file,
    } = formData;

    const client = {
        businessname,
        country,
        creatorId,
        direction,
        isfisica,
        ismoral,
        paymentmethods,
        postcode,
        rfc,
        state,
        tradename,
        zone,
        file
    }
        console.log("🚀 ~ file: ClientDocuments.js:72 ~ ClientDocuments ~ file guardados", file)


    const clientD = JSON.stringify(client);


    const [watchDocumentMedia, setWatchDocumentMedia] = useState(false);
    const [deletedFiles, setDeletedFiles] = useState([]);
    const [last, setLast] = useState(null);
    const [files, setFiles] = useState(
        [
            {
                name: 'RFC',
                id: 0,
                file: null,
                deletedAt: false,
                watch: false
            },
            {
                name: 'Situación fiscal',
                id: 1,
                file: null,
                deletedAt: false,
                watch: false
            },
            {
                name: 'INE, pasaporte, cédula profesional',
                id: 2,
                file: null,
                deletedAt: false,
                watch: false
            },
        ] 
    )
   
    useEffect(() => {
        if (mode != 'crear') {
            if (file && file != null && file != undefined && file.length >= 1) {
                let newFiles = file.map((el, i) => {
                    return {
                            id: el.fileId,
                            file: {
                                url : el.file.url,
                                name: el.file.url,
                                deletedAt: el.deletedAt,
                            },
                            watch: true
                        }
                    })
                setFiles(newFiles)
            }
    }}, []);

    const [fileToSend, setFileToSend] = useState([]);

    const data = [
        { name: 'RFC' }, { name: 'Situacion  Fiscal' }, { name: 'INE, pasaporte, cédula profesional' }
    ]

    const handleAdd = () => {
        let arrayCopy = [...files];
        let lastElement = _.last(files);
        arrayCopy.push(
            {
                name: 'Archivo',
                id: lastElement.id + 1,
                file: null,
                watch: false,
                deletedAt: false,
                newAdd: true,
            }
        )
        console.log(arrayCopy);
        setFiles(arrayCopy);
    }



    const submitData = async () => {

        if (_.isEmpty(files)) { toggleModalNoFiles() }
        const formData_ = new FormData();


        if(typeAction !== "#/dashboard/gestion-clientes/crear"){
            formData_.append("id",formData.id)
        }

        formData_.append("client", clientD);
        formData_.append("person", person);
        formData_.append("agent", agent);
        formData_.append("mediasDeleted", JSON.stringify(mediasDeleted));

        const arrFiles = files.map(file => file.file != null ? file : false).filter(file => file != false)

        for (let i = 0; i < arrFiles.length; i++) {
            formData_.append("File", arrFiles[i].file);
        }
 
        const res = await dispatch(upsertClientAction(formData_))
        console.warn("res", res)
        if (res) {
             history.push('/dashboard/gestion-clientes');
        }
    }
    return (
        <form className='main-container-generic-type'>
            <div className='header-form-generic-type'>
                <h2>Documentos</h2>
            </div>
            <div className='body-form-generic-type-docs'>
                {
                    files.map((ele, i) => {
                        return (
                            <UploadFile 
                                setArrayDeleted={setMediasDeleted}
                                arrayDeleted={mediasDeleted}
                                key={i} 
                                files={ele} 
                                last={last} 
                                setFiles={setFiles} 
                                arrayComplete={files} 
                                arrToSend={fileToSend} 
                                setArrToSend={setFileToSend} 
                            />
                        )
                    })
                }
                <div className='add-button-container'>
                    {
                        mode != 'ver' &&
                        <img className='add-card' src={add} onClick={handleAdd} />
                    }
                </div>
            </div>
            <div className='footer-form-generic-type' style={{ justifyContent: 'space-between' }}>
                <Button type='button' onClick={toggleModal ? toggleModal : changeStageBack} className={'button-back'} style={{ width: '40%' }}>CANCELAR</Button>
                <Button type='button' onClick={submitData} className={'button-next'} style={{ width: '40%' }}>{mode != 'ver' ? 'GUARDAR' : 'ACEPTAR'}</Button>
            </div>
        </form>
    )
}
