/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './../../../components/templates/FullScreenLayout/FullScreenLayout.scss';
import Link from '../../../components/atoms/Link1';
import InputPassword from '../../../components/atoms/InputPassword';
import { userLogin } from '../../../redux/actions/userActions';
import { getOneCompanyRecordAction } from '../../../redux/actions/companyAction';
import user from '../../../assets/images/iconUser.png';
import password from '../../../assets/images/passLogin.png';
import showPassword from '../../../assets/images/iconEye.png';
import showPasswordTwo from '../../../assets/images/iconEyeShow.png';
import { ButtonComponent } from '../../../components/atoms/buttonComponent';
import { Button } from '../../../components/atoms';



const FormLogIn = (props) => {

    const [openModal, setOpenModal] = useState(false)
    const [userValue, setUserValue] = useState(null)
    const [contraValue, serContraValue] = useState(null)
    const [show, setShow] = useState(false);
    const history = useHistory();
    const { register, handleSubmit } = useForm();

    console.log(show);





    // useEffect(() => {
    //     if (props.isAuthenticated) {
    //         if (props.typeUser !== "administrator") {

    //             const validation = async () => {

    //                 const res = await dispatch(getOneCompanyRecordAction({ id: userId }));
    //                 setUserValue(res);
    //                 // console.log(userValue);
    //                 if (userValue !== null) {
    //                     toggleModal();
    //                 }
    //             }
    //             validation();
    //         } else {
    //             history.push('/dashboard');
    //         }
    //     }
    // }, [props.isAuthenticated]);
    // const toggleModal = () => {
    //     setOpenModal(!openModal);
    // }

    const switchShow = () => {

        setShow(!show)
       

    }

    const onSubmitLoginForm = async (formData) => {
        console.log('fomr',formData)
        const result = await props.userLogin(formData);

        result &&
            history.push('/dashboard');
    }

    return (
        <>
            <form
                name='login'
                onSubmit={handleSubmit(onSubmitLoginForm)}
                className='form-logIn'>


                <p className='txt-login'>INICIAR SESIÓN</p>

                <div className={'log-in'}>
                    <div className="container-input-component">
                        <div className="btn-input-component-icouser" >
                            <div className="subcontainer-img-left">
                             <img src={user} alt="" />
                        </div>
                        <input
                            className="input-component-login"
                            name='email'
                            type={'email'}
                            {...register('email')}
                            placeholder="Usuario"
                            />
                        </div>
                    </div>

                    <div className="container-input-component">
                        <div className="btn-input-component-icouser" >
                            <div className="subcontainer-img-left">
                                <img src={password} alt="" />
                            </div>
                            <input
                                name='password'
                                className="input-component-login"
                                type={show ? 'text' : 'password'}
                                {...register('password')}
                                placeholder="Contraseña" 
                            />
                            <div className="subcontainer-img-right"
                                onClick={switchShow}
                                type='button'
                            >
                                <img src={show ? showPasswordTwo : showPassword} />
                            </div>
                            
                        </div>
                    </div>
                      
                    <Link className={'link'} to={props.to}>¿Olvidaste tu contraseña?</Link>
                    <Button type='submit' className="btnLogin">INICIAR SESIÓN</Button>



                </div>
            </form>
        </>
    );
}

FormLogIn.propTypes = {
    to: PropTypes.any,
    isAuthenticated: PropTypes.bool,
    userLogin: PropTypes.func,
}

function mapStateToProps(state) {
    return {
        isAuthenticated: _.get(state.user, 'isAuthenticated', false),
        typeUser: _.get(state.user, 'user.roles[0]', false),
    };
}

const mapDispatchToProps = dispatch => ({
    userLogin: params => dispatch(userLogin(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FormLogIn);
