import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';
// import Input from './Input';



//import uploadIconBlue from '../../assets/images/HotelyHabitaciones_Assets/512px/Icono_UpLoadAzul_512PX.png'

const Button = (props) => {
    return (
        <button 
            {...props}
            className={props.className ? props.className : 'button-default'}
            onClick={props.onClick}
            type={props.type}
            style={props.style}
            disabled={props.disabled}
        >
            {
                props && props.srcicon &&
                <img className={props.classNameImg} 
                src={props.srcicon} />
            }
            {props.children}
        </button>
    );
}

Button.propTypes = {
    onClick: PropTypes.any,
    type: PropTypes.string,
    srcicon: PropTypes.any,
    className: PropTypes.any,
    classNameImg: PropTypes.any,
    children: PropTypes.any,
    style: PropTypes.any,
    disabled: PropTypes.bool,
};

export default Button;
