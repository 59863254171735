import React, { useEffect, useState } from "react";
import { Button } from "../../atoms";
import "./ComponentComent.scss";
import ModalComent from "./ModalComents/ModalComents/ModalComent";
import _, { forEach } from 'lodash';
import { useDispatch } from "react-redux";
import { upsertStrikeAction } from "../../../redux/actions/operativeAction";
import strikeComent from "../../../assets/images/strikeComent.png"
import moment from "moment";


const ComponentComent = (props,{ data }) => {
  const dispatch = useDispatch()
  const [dataUser, setDataUser] = useState(props.data)
  const [dataModal, setDataModal] = useState()
  const [dataStrike,setDataStrike] = useState()
  const [openModal, setOpenModal] = useState(false);

  const mainData = _.get(props,"data", null)
  let dateCorrect

  useEffect(() => {
    setDataUser(props.data)
    console.log(mainData)

    if(!_.isNil(mainData)){
      mainData.forEach(element => {
        dateCorrect = moment(element.createdAt).format("yyyy-MM-DD")
        
      });

      console.log(dateCorrect)

    }
    
  }, [])
  
  const handleOpenModal = (item) => {
    setDataStrike(item)
    setOpenModal(!openModal);

  };

  useEffect(() => {

    if(!_.isNil(dataModal)){
      dispatch(upsertStrikeAction(dataModal))
      props.setChangeComent(true)
    }

  }, [dataModal])
  

  return (
    <>
      {
     
      props.data.map((item, i) => (
        <div className="ComentMainContainer" key={i}>
          <div className="LeftComentContainer">
            <p className="nameComent">{`${item.customerR.person.firstName}${" "}${item.customerR.person.firstLastName}`}</p>
            <textarea value={item.description} className="inputComent" />
            <p className="dateComent">
             {/* {moment({item.createdAt}).format("yyyy-MM-DD")}. */}
             {dateCorrect}
            </p>
          </div>
          <div className="RightComentContainer">
            {
              item.isStrike === true ? 
              (
              <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20%"}}>
                <img src={strikeComent}/>
              </div>
              )
              :
              item.concept === null ?
              (
                <>
                  <Button onClick={()=>handleOpenModal(item)} className="BtnStrikes">
                    STRIKE
                  </Button>
                  {/* <Button className="BtnStrikesNone">
                    SIN STRIKE
                  </Button> */}
                </>
              )
              :
              (
                <h3>SIN STRIKE</h3>
              )
            }
            
            
          </div>
        
          
        </div>

      ))
     
    }
        <ModalComent
                dataModal={setDataModal}
                stateModal={openModal}
                setOpenModal={setOpenModal}
                infoComent={dataStrike}
            />
      
    </>
  );
};

export default ComponentComent;
