/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button } from '../../atoms';
import './DetailsView.scss';
import dropdown from '../../../assets/images/navieras/icon-dropdown.png';
import moment from 'moment';
import { upsertQuotationAction } from '../../../redux/actions/quotationAction';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { QUOTATION_DOCUMENTS } from '../../../routes/Paths';

const DetailsViewLayout = ({ children, data, formData }) => {
console.log("🚀 ~ file: QuotationDetailsView.js:15 ~ DetailsViewLayout ~ formData", formData)
console.log("🚀 ~ file: QuotationDetailsView.js:15 ~ DetailsViewLayout ~ data", data)

    const date = _.get(data, 'createdAt', '');
    const idQuotation = _.get(data, 'id', '');
    const clientName = _.get(data, 'client.tradename', '');
    const supplier = _.get(data, 'provider.name', '');
    const modality = _.get(data, 'modalityType.name', '');
    const service = _.get(data, 'serviceImport', '');

    const serviceDisplay = service === true ? "Importación" : "Exportación"

    const modalityDisplay = (modality) => {
        if (modality === "Marine") {
            let valor = _.get(data, 'modalityMarine.fcl');
            let displayValue = valor === true ? "Marina FCL" : "Marina LCL"
            return displayValue
        } else if (modality === "Air") {
            return "Aérea"
        } else if (modality === "Ground") {
            return "Terrestre"
        }
    }
    const history = useHistory();
    const dispatch = useDispatch();


    //Quotation
    const {
        modalityTypeId,
        clientId,
        providerId,
        serviceImport,
        serviceExport,
    } = data;

    const quotation_ = {
        modalityTypeId,
        clientId,
        providerId,
        serviceImport,
        serviceExport,
    }

    const quotation = JSON.stringify(quotation_);


    // QuotationData
    const {
        modality: modality_,
        destinyLocalChargesCbm,
        destinySeaFreight,
        destinyStateId,
        factor,
        insurance,
        invoice,
        name,
        officeMx,
        originCountryId,
        originLandFreight,
        originLocalCharges,
        originSeaFreight,
        originStateId,
        total,
        validity,
        weight,
        destinyCountryId,
        bussines,
        delivery,
        cbms,
        destinyLocalChargesBl,
        destinyLandFreight,
        coordination,
        platform,
        service:service_,
        landFreight,
        overweight,
        maneuvers,
        dropOff,
        intercom,
        pickup,
        originPortId,
        originCharges,
        destinyPortId,
        destinyCharges,
        agency,
        lcl,
        fcl,
        containerType,
        containersNumber,
        delayDays,
        storageDays,
        destinyLocalChargesContainer,
        servicest3,
        aditionalService,
        other,
        officeMxTlp, } = formData

    const quotationD = {
        destinyLocalChargesCbm,
        destinySeaFreight,
        destinyStateId,
        factor,
        insurance,
        invoice,
        name,
        officeMx,
        originCountryId,
        originLandFreight,
        originLocalCharges,
        originSeaFreight,
        originStateId,
        total,
        validity,
        weight,
        destinyCountryId,
        bussines,
        modality_,
        delivery,
        cbms,
        destinyLocalChargesBl,
        destinyLandFreight,
        coordination,
        platform,
        service_,
        landFreight,
        overweight,
        maneuvers,
        dropOff,
        intercom,
        pickup,
        originPortId,
        originCharges,
        destinyPortId,
        destinyCharges,
        agency,
        lcl,
        fcl,
        containerType,
        containersNumber,
        delayDays,
        storageDays,
        destinyLocalChargesContainer,
        servicest3,
        aditionalService,
        other,
        officeMxTlp,
    }

    const quotationData = JSON.stringify(quotationD);




    // const submitData = async ()=>{
    //     // console.log(formData);
    //     const res = await dispatch(upsertQuotationAction(formData)) 
    //     if(res){
    //         history.push('/dashboard/cotizacion');
    //     }
    // }


    const submit = async () => {
        const formData_ = new FormData();

        formData_.append("id", data.id);
        formData_.append("quotation", quotation);
        formData_.append("quotationData", quotationData);
        formData_.append("mediasDeleted", '[]')

        formData_.append("File", '[]');

        const res = await dispatch(upsertQuotationAction(formData_))
        if (res) {
            history.push('/dashboard/cotizacion');
        }
    }


    return (
        <div className='view-details-general-container'>
            <div className='general-data-container'>
                <div className='client-data-container'>
                    <div className='header-data-container'>
                        <p className='title-text'>{moment(date).format('l')}</p>
                        <div className='id-quotation'>
                            <p className='title-text'>ID Cotización:</p>
                            <p className='id'>{idQuotation}</p>
                        </div>
                    </div>
                    <div className='one-data-container'>
                        <h4 className='data-title'>Cliente</h4>
                        <p className='client-info'>{clientName}</p>
                    </div>
                    <div className='one-data-container'>
                        <h4 className='data-title'>Proveedor</h4>
                        <p className='client-info'>{supplier}</p>
                    </div>
                    <div className='one-data-container'>
                        <h4 className='data-title'>Modalidad</h4>
                        <p className='client-info'>{modalityDisplay(modality)}</p>
                    </div>
                    <div className='one-data-container'>
                        <h4 className='data-title'>Servicio</h4>
                        <p className='client-info'>{serviceDisplay}</p>
                    </div>
                </div>
                <div className='buttons-container'>
                    <Button className={'button-next quotation'} onClick={() => submit()}>GUARDAR</Button>
                    <Button className={'button-generate quotation'} onClick={() => history.push({
                        pathname: QUOTATION_DOCUMENTS.replace(':id', clientId),
                        payload: data,
                    })}>VER DOCUMENTOS</Button>
                    <Button className={'button-next quotation'}>GENERAR COMO EXPEDIENTE</Button>
                </div>
            </div>
            <div className='quotation-container'>
                <h3>Datos de cotización</h3>
                <div className='quotation-data'>
                    {children}
                </div>
                <div className='dropdown-container'>
                    <img src={dropdown} />
                </div>
            </div>
        </div>
    );
}

DetailsViewLayout.propTypes = {
    children: PropTypes.any,
    date: PropTypes.any,
    idQuotation: PropTypes.any,
    clientName: PropTypes.any,
    supplier: PropTypes.any,
    modality: PropTypes.any,
    service: PropTypes.any,
}

export default DetailsViewLayout;
