/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { DesviationsForm } from './DesviationsForm';
import { Button } from '../../../components/atoms';
import './RecordDeviations.scss';
import add from '../../../assets/images/navieras/agregar_red.png'

const RecordDeviations = ({toggleModal}) => {

    const [countries, setCountries] = useState([
        {
            country: '',
            countryId: '',
            state: '',
            stateId: '',
        },
    ])
    const [ formsDeleted, setFormsDeleted ] = useState([]);
    const [ last, setLast ] = useState(null);
    const [ delayReason, setDelayReason ] = useState('');

    const handleAdd = () =>{
        console.log('add')
        let arrayCopy = JSON.parse(JSON.stringify(countries));
        let lastElement = countries.pop();
         arrayCopy.push(
            {
                countryId:  lastElement.id + 1,
                country: '',
            }
        )
        setCountries(arrayCopy); 
    }

    useEffect(() => {
        let final = [...countries];
        setLast(final.pop())
    }, [countries]);

    return (
        <div className='deviations-modal-container'>
            <div className='deviations-title-container'>
                <h3>DESVÍOS</h3>
                <h4>Ingresar lugar de desvío:</h4>
            </div>
            <div className='deviations-select-container'>
                {
                    countries.map((ele, i)=>{
                        return(
                            <DesviationsForm key={i} ports={ele} last={last} setCountries={setCountries} arrayComplete={countries} formsBorrados={formsDeleted} setFormsBorrados={setFormsDeleted}/>
                        )
                    })
                }
            </div>
            <div className='add-button-container' style={{justifyContent: 'flex-end'}}>
                <img className='add-card' src={add} onClick={handleAdd}/>
            </div>
            <div className='deviation-reason'>
                <label>Motivo del desvío:</label>
                <input 
                    className='reasons-deviations-input' 
                    type='textarea' 
                    placeholder='Escriba aquí sus comentarios...'
                    value={delayReason}
                    onChange={(e) => setDelayReason(e.target.value)}
                />
            </div>
            <div className='footer-form-generic-type' style={{justifyContent: 'space-between'}}>
                <Button type='button' onClick={toggleModal} className={'button-back'} style={{width: '40%'}}>CANCELAR</Button>
                <Button type='submit' onClick={toggleModal} className={'button-next'} style={{width: '40%'}}>GUARDAR</Button>
            </div>
        </div>
    )
}

export default RecordDeviations;
