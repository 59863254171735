import request from "../../utils/request";
import { addNotification } from './notificationAction';

import {
    FOREX_MODULE_GET_ALL_FOREX_BEGIN,
    FOREX_MODULE_GET_ALL_FOREX_SUCCESS,
    FOREX_MODULE_GET_ALL_FOREX_FAILURE,
    FOREX_MODULE_UPSERT_FOREX_BEGIN,
    FOREX_MODULE_UPSERT_FOREX_SUCCESS,
    FOREX_MODULE_UPSERT_FOREX_FAILURE
} from '../actionTypes';

// ACTIONS TO REDUCER

export const getAllForexBegin = () => ({
    type: FOREX_MODULE_GET_ALL_FOREX_BEGIN,
});

export const getAllForexSuccess = ({data,totalRecords}) => ({
    type: FOREX_MODULE_GET_ALL_FOREX_SUCCESS,
    totalRecords,
    data,
});

export const getAllForexFailure = (err) => ({
    type: FOREX_MODULE_GET_ALL_FOREX_FAILURE,
    err,
});

export const upsertForexBegin = () => ({
    type: FOREX_MODULE_UPSERT_FOREX_BEGIN,
});

export const upsertForexSuccess = ({data,totalRecords}) => ({
    type: FOREX_MODULE_UPSERT_FOREX_SUCCESS,
    totalRecords,
    data,
});

export const upsertForexFailure = (err) => ({
    type: FOREX_MODULE_UPSERT_FOREX_FAILURE,
    err,
});


// DISPATCH TO ACTIONS

export function getAllForexAction(data){
    return (dispatch, getState) => {
        dispatch(getAllForexBegin());
        return request({
            partialUrl: `/api/v1/forex/get-all-forex-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log('this is data response');
            console.log(data.data);
            dispatch(getAllForexSuccess(data.data));
            return data.data
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
            }));
            return dispatch(getAllForexFailure(err));
        });
    };
}

export function upsertForexAction(data){
    debugger
    return (dispatch, getState) => {
        dispatch(upsertForexBegin());
        return request({
            // partialUrl: `/api/v1/administrator/upsert-administrator-action`,
            partialUrl: `/api/v1/forex/upsert-forex-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(upsertForexSuccess(data.data));
            return true;
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(upsertForexFailure(err));
        });
    };
}

