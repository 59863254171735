import _ from 'lodash';
import {
    CHARGES_MODULE_GET_ALL_CHARGES_BEGIN,
    CHARGES_MODULE_GET_ALL_CHARGES_SUCCESS,
    CHARGES_MODULE_GET_ALL_CHARGES_FAILURE,
 } from '../actionTypes';

import initialState from '../initialState';

export default function chargesReducer(state=initialState.charges, action){
    switch (action.type) {
        case CHARGES_MODULE_GET_ALL_CHARGES_BEGIN:
            return {
                ...state,
                allCharges: {
                    
                    isLoading: true,
                    isSuccess: false,
                    err: null, 
                    totalRecords: 0,
                    data: [],
                }
            };
        case CHARGES_MODULE_GET_ALL_CHARGES_SUCCESS:
            return {
                ...state,
                allCharges: {
                    
                    data: action.data,
                },
            };
        case CHARGES_MODULE_GET_ALL_CHARGES_FAILURE:
            return {
                ...state,
                allCharges: {
                   
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
                },
            };
            // ---

        default:
            return state;
    }
}
