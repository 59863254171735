import React, { useState } from 'react';
import RadioButton from '../RadioButtons/RadioButtons';
import './AssingForms.scss';

export const TypeOfService = ({service, setService}) => {
    const [provider, setProvider]= useState(null);
    const services = [
        {
            keyName: 'Importación',
            id: 1,
            serviceImport: true
        },
        {
            keyName: 'Exportación',
            id: 2,
            serviceExport: true,
        },
    ]
  return (
    <div className='assign-form'>
        <div className='input-container-data-form'>
            <div className='label-container'>
                <label className='container-label'>Seleccionar servicio</label>
            </div>
            <div className='value-container' >
                <div className='value-container-radio-buttons'>
                    <RadioButton 
                        data={services}
                        row={true}
                        setValue={setService}
                    />
                </div>
            </div>
        </div>
    </div>
  )
}
