/* eslint-disable react/prop-types */
import React from "react";
import {Route, Redirect } from "react-router-dom";

export function PrivateRoute({
    component,
    isAllowed=false,
    redirectPath='/app/iniciar-sesion',
    ...rest
}) {

    return (
      <Route
        {...rest}
        render={props =>
          isAllowed ? (
            React.createElement(component, props)) : (
            <Redirect
              to={{
                //pathname: "/login",
                pathname: redirectPath,
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
}

export function PublicRoute({
    component,
    ...rest 
}) {
    return (
      <Route
        {...rest}
        render={props =>
          React.createElement(component, props)
        }
      />
    );
}
