import React, { useState } from 'react'
import { Button } from '../../../../components/atoms';
// import './ClientRegister.scss';
import '../../../../styles/stylesSteppers.scss'
import arrow from '../../../../assets/images/navieras/arrowButton.png';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

export const LegalRepresentative = ({ stepper, setStepper, toggleModal, formData, setFormData }) => {
    console.log(formData);



    const namesDefault = _.get(formData, 'agent','')
    const surnames = _.get(formData, 'agent','')
    const phoneDefault = _.get(formData, 'agent','')
    const emailDefault = _.get(formData, 'agent','')
    const addressDefault = _.get(formData, 'agent','')
    const zoneDefault = _.get(formData, 'agent','')
    const stateDefault = _.get(formData, 'agent','')


    const defaultValues = {
        names: namesDefault,
        surnames: surnames,
        phone: phoneDefault,
        email: emailDefault,
        address: addressDefault,
        zone: zoneDefault,
        state: stateDefault,

    };



    const methods = useForm({ defaultValues: defaultValues });
    const { register, handleSubmit, control, reset, formState: { errors }, } = methods;
    //const [clientType, setClientType] = useState(_.get(formData, 'clientType', null));
    const [country, setClientType] = useState(_.get(formData, 'country', null));
    const [payForm, setPayForm] = useState(_.get(formData, 'paymentmethods', null));

    const countrys = useSelector(state => state.address.states.data);


    // const countrys = [
    //     {
    //         id: 1,
    //         name: 'Mexico'
    //     },
    //     {
    //         id: 2,
    //         name: 'EUU'
    //     },
    // ]

    const changeStage = () => {
        // console.log(clientType, 'cambio de pestania')
        setStepper(stepper + 1)
    }

    const changeStageBack = () => {
        // console.log(clientType, 'cambio de pestania')
        setStepper(stepper - 1)

    }

    const onSubmit = async ({ names, surnames, phone, email, address, zone }) => {
        console.log('onsu', formData)
        const data = {
            ...formData,
            agent: {
                names,
                surnames,
                phone,
                email,
                zone,
                state: country,
                direction: address,
            }
        }
        setFormData(data);
        console.log('data',data);
        changeStage()
    }

    return (
        <form className='main-container-generic-type' onSubmit={handleSubmit(onSubmit)}>
            <div className='header-form-generic-type'>
                <h2>Datos del representante legal</h2>
            </div>
            <div className='body-form-generic-type-data body-form-generic-type-client'>
                {/* -- */}
                <div className='input-container'>
                    <div className='label-container'>
                        <label className='container-label'>Nombre(s)*</label>
                    </div>
                    <div className='value-container'>
                        <input control={control} name={'names'} {...register('names')} className="input-generic" type="text" placeholder="Nombre(s)" />
                    </div>
                </div>
                <div className='input-container'>
                    <div className='label-container'>
                        <label className='container-label'>Apellido(s)*</label>
                    </div>
                    <div className='value-container'>
                        <input control={control} name={'surnames'} {...register('surnames')} className="input-generic" type="text" placeholder="Apellido(s)" />
                    </div>
                </div>
                <div className='input-container'>
                    <div className='label-container'>
                        <label className='container-label'>Telefono*</label>
                    </div>
                    <div className='value-container'>
                        <input control={control} name={'phone'} {...register('phone')} className="input-generic" type="text" placeholder="Telefono" />
                    </div>
                </div>
                <div className='input-container'>
                    <div className='label-container'>
                        <label className='container-label'>Correo*</label>
                    </div>
                    <div className='value-container'>
                        <input control={control} name={'email'} {...register('email')} className="input-generic" type="text" placeholder="Correo" />
                    </div>
                </div>

                <div className='input-container'>
                    <div className='label-container'>
                        <label className='container-label'>Dirección*</label>
                    </div>
                    <div className='value-container'>
                        <input control={control} name={'address'} {...register('address')} className="input-generic" type="text" placeholder="Dirección" />
                    </div>
                </div>

                <div className='input-container'>
                    <div className='label-container'>
                        <label className='container-label'>Zona*</label>
                    </div>
                    <div className='value-container'>
                        <input control={control} name={'zone'} {...register('zone')} className="input-generic" type="text" placeholder="Zona" />
                    </div>
                </div>
                {/* -- */}
                <div className='input-container'>
                    <div className='label-container'>
                        <label className='container-label'>Estado*</label>
                    </div>
                    <div className='value-container' >
                        <select className='input-select' value={country} onChange={(e) => {
                            setClientType(parseInt(e.target.value));
                        }}>
                            <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                            {
                                _.get(countrys, 'data') &&
                                _.get(countrys, 'data').map(ele => {
                                    return <option value={ele.id}> {ele.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>


            </div>
            <div className='footer-form-generic-type' style={{ justifyContent: 'space-between' }}>
                <Button type='button' onClick={toggleModal ? toggleModal : changeStageBack} className={'button-back'} style={{ width: '40%' }}>CANCELAR</Button>
                <Button type='submit' className={'button-next'} srcicon={arrow} style={{ width: '40%' }}>SIGUIENTE</Button>
            </div>
        </form>
    )
}
