/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import './RadioButtons.scss';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';


const RadioButton = ({
  data,
  setValue,
//   onClickFunction,
  classNameRadio,
  row = false,
}) => {

    const handleClick = (item) => {
        setValue(item.id);
    }

    const useStyles = makeStyles({
        root: {
          '&:hover': {
            backgroundColor: '#f5f8fa',
          '.MuiIconButton-label': {
            backgroundColor: 'transparent',
          }
          },
        },
        icon: {
          borderRadius: '50%',
          width: 16,
          height: 16,
          boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
          backgroundColor: '#f5f8fa',
          backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
          '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
          },
          'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
          },
          'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
          },
        },
        checkedIcon: {
          backgroundColor: '#137cbd',
          backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
          '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
          },
          'input:hover ~ &': {
            backgroundColor: '#106ba3',
          },
        },
      });
      
    const StyledRadio = (props) => {
        const classes = useStyles();
      
        return (
          <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
          />
        );
      }

    return (
        <FormControl>
            <RadioGroup row={row}>
                {
                    data.map((elem, i) => (
                        <FormControlLabel
                            onClick={() => handleClick(elem)}
                            value={elem.keyName}
                            control={<StyledRadio />}
                            color="success"
                            label={elem.keyName}
                            className={classNameRadio}
                            key={i}
                            id={elem.id}
                        />
                    ))
                }
            </RadioGroup>
        </FormControl>
    );
}

export default RadioButton;
