import React, { useState } from 'react'
import "./cardCharges.scss"
import _ from "lodash";
import { url_api } from "../../../config";
import phto from "../../../assets/images/navieras/Icono_cobro_color.png"

const cardCharges = (props) => {

  const mainData = _.get(props,"data",null)
  
  return (
   <>
   {
      _.get(mainData,'data') &&
    mainData.data.map((item,i) => (
    <div className='cardChargesMainContainer' key={i}>
      <div className='cardChargesContainer'>
          <label className='labelFeatured'>{item.title}</label>
          
          <img src={`${url_api}${"/"}${item.img}.png`} style={{width:"40%"}}/>
          <p style={{color:"#ffff",fontSize:"25px"}}>{item.data}</p>
      </div>
    </div>
    ))
   } 
   </>
  )
}

export default cardCharges