import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {connect} from 'react-redux';
import Link from  '../../../atoms/Link1';
import home from './../../../../assets/images/home1.png';
import graphic from './../../../../assets/images/estadis.png';
import list from './../../../../assets/images/list.png';
import done from './../../../../assets/images/done.png';
import './Sidebar.scss';

const Sidebar = (props) => {   
    const activeIconClassName = 'selectedIcon';
   const changeActiveIcon = (newActiveIcon) => {
        const currentSelectedIcon = document.querySelector(`.${activeIconClassName}`);
        currentSelectedIcon.classList.remove(activeIconClassName);
        newActiveIcon.classList.add(activeIconClassName);
   }
   
    const clickMenu = (id) => {
        const menu = document.getElementById(id);
        changeActiveIcon(menu)
        menu.click();
   }
    let mainClass = ['sidebar'];
    let span = '';
    let text = '';
    let icon = '';
    let sidebar= '';

    if (!props.isSidebarOpened) {
        mainClass.push('navMin');
        span = 'iconContainer';
        text = 'text';
        icon = 'icon';
        sidebar ='panel';
    } else {
        span = 'activeIconContainer';
        text = 'activeText';
        icon ='activeIcon';
        sidebar = 'activePanel';
    }

    return (
        <aside>
            <nav className={mainClass.join(' ')} >
            <div className={sidebar}>
                    <div
                        onClick={() => clickMenu('inicio')}
                        className={`${span} ${activeIconClassName}`}>
                        <div className={icon}><img className='home' src={home}/></div>
                        <div
                            className={text}
                            >
                            <Link
                                id={'inicio'}
                                to={'/dashboard-cliente/clientes'}
                                className={'link'}>INICIO</Link>
                        </div>
                    </div>
                    <div
                        onClick={() => clickMenu('reporteVentas')}
                        className={span}>
                        <div className={icon}><img className='estadistica' src={graphic}/></div>
                        <div
                            className={text}
                        >
                            <Link
                                id={'reporteVentas'}
                                to={'/dashboard-cliente/reportes-de-ventas'}
                                className={'link'}>REPORTE VENTAS</Link>
                        </div>
                    </div>
                    <div
                        onClick={() => clickMenu('reporteInventarios')}
                        className={span}>    
                        <div className={icon}><img className='lista' src={list}/></div>
                        <div className={text}
                        >
                            <Link
                                id={'reporteInventarios'}
                                to={'/dashboard-cliente/reportes-de-inventarios'}
                                className={'link'}>REPORTE DE INVENTARIOS</Link>
                        </div>
                    </div>
                    <div
                        onClick={() => clickMenu('reporteResultados')}
                        className={span}>
                        <div className={icon}><img className='hecho' src={done}/></div>
                        <div
                            className={text}
                        >
                            <Link
                                id={'reporteResultados'}
                                to={'/dashboard-cliente/estados-de-resultados'}
                                className={'link'}>REPORTE DE RESULTADOS</Link>
                        </div>
                    </div>
                </div>
                {/* <div className='imgFondo'>
                    <img 
                        src={bambu3}
                        className='imgFondo__img' />
                </div> */}
            </nav>
        </aside>
    );
}

Sidebar.propTypes = {
    isSidebarOpened: PropTypes.any,
}

function mapStateToProps (state) {
    return {
        isSidebarOpened: _.get(state.userInterface, 'isSidebarOpened', false),
    };
}

// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = dispatch => ({
    // userLogout: () => dispatch(userLogout()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
