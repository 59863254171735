import request from "../../utils/request";
import { addNotification } from './notificationAction';

import {
    REPORT_TYPE_MODULE_GET_ALL_REPORT_TYPE_BEGIN,
    REPORT_TYPE_MODULE_GET_ALL_REPORT_TYPE_SUCCESS,
    REPORT_TYPE_MODULE_GET_ALL_REPORT_TYPE_FAILURE,
    REPORT_MODULE_UPSERT_REPORT_BEGIN,
    REPORT_MODULE_UPSERT_REPORT_SUCCESS,
    REPORT_MODULE_UPSERT_REPORT_FAILURE,
    REPORT_MODULE_GET_ALL_REPORT_BEGIN,
    REPORT_MODULE_GET_ALL_REPORT_SUCCESS,
    REPORT_MODULE_GET_ALL_REPORT_FAILURE,
    REPORT_MODULE_GET_ONE_REPORT_BEGIN,
    REPORT_MODULE_GET_ONE_REPORT_SUCCESS,
    REPORT_MODULE_GET_ONE_REPORT_FAILURE,
    REPORT_MODULE_DELETE_REPORT_BEGIN,
    REPORT_MODULE_DELETE_REPORT_SUCCESS,
    REPORT_MODULE_DELETE_REPORT_FAILURE
} from '../actionTypes';

export const getAllReportTypeBegin = () => ({
    type: REPORT_TYPE_MODULE_GET_ALL_REPORT_TYPE_BEGIN,
});

export const getAllReportTypeSuccess = (data) => ({
    type: REPORT_TYPE_MODULE_GET_ALL_REPORT_TYPE_SUCCESS,
    data,
});

export const getAllReportTypeFailure = (err) => ({
    type:REPORT_TYPE_MODULE_GET_ALL_REPORT_TYPE_FAILURE,
    err,
});

export const upsertReportBegin = () => ({
    type: REPORT_MODULE_UPSERT_REPORT_BEGIN,
 
});

export const upsertReportSuccess = ({data}) => ({
    type:REPORT_MODULE_UPSERT_REPORT_SUCCESS,
    data,
});

export const upsertReportFailure = (err) => ({
    type: REPORT_MODULE_UPSERT_REPORT_FAILURE,
    err,
});

export const getAllReportsBegin = () => ({
    type: REPORT_MODULE_GET_ALL_REPORT_BEGIN,
});

export const getAllReportsSuccess = (data) => ({
    type: REPORT_MODULE_GET_ALL_REPORT_SUCCESS,
    data
});

export const getAllReportsFailure = (err) => ({
    type: REPORT_MODULE_GET_ALL_REPORT_FAILURE,
    err
});

export const getOneReportsBegin = () => ({
    type: REPORT_MODULE_GET_ONE_REPORT_BEGIN,
});

export const getOneReportsSuccess = (data) => ({
    type: REPORT_MODULE_GET_ONE_REPORT_SUCCESS,
    data
});

export const getOneReportsFailure = (err) => ({
    type: REPORT_MODULE_GET_ONE_REPORT_FAILURE,
    err
});

export const deleteReportsBegin = () => ({
    type:REPORT_MODULE_DELETE_REPORT_BEGIN,
});

export const deleteReportsSuccess = () => ({
    type:REPORT_MODULE_DELETE_REPORT_SUCCESS,
});

export const deleteReportsFailure = () => ({
    type:REPORT_MODULE_DELETE_REPORT_FAILURE,
});

export function getAllReportTypeAction(data){
    return (dispatch, getState) => {
        dispatch(getAllReportTypeBegin());
        return request({
            partialUrl: `/api/v1/report-type/get-all-report-type-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            dispatch(getAllReportTypeSuccess(data.data));
            return data.data
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
            }));
            return dispatch(getAllReportTypeFailure(err));
        });
    };
}

export function upsertReportAction (data) {
    return (dispatch, getState) => {
        dispatch(upsertReportBegin());
        return request({
            partialUrl: `/api/v1/report/upsert-report-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(upsertReportSuccess(data.data));
            return true
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al actualizar la información',
            }));
            return dispatch(uupsertReportFailure(err));
        });
    };
}

export function getAllReportsAction(data){
    return (dispatch, getState) => {
        dispatch(getAllReportsBegin());
        return request({
            partialUrl: `/api/v1/report/get-all-report-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            dispatch(getAllReportsSuccess(data.data));
            return data.data
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
            }));
            return dispatch(getAllReportsFailure(err));
        });
    };
}

export function getOneReportsAction(data){
    return (dispatch, getState) => {
        dispatch(getOneReportsBegin());
        return request({
            partialUrl: `/api/v1/report/get-one-report-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            dispatch(getOneReportsSuccess(data.data));
            return data.data
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
            }));
            return dispatch(getOneReportsFailure(err));
        });
    };
}

export function deleteReportsAction(data){
    return (dispatch, getState) => {
        dispatch(deleteReportsBegin());
        return request({
            partialUrl: `/api/v1/report/delete-report-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            dispatch(deleteReportsSuccess(data.data));
            return data.data
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
            }));
            return dispatch(deleteReportsFailure(err));
        });
    };
}
