/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import _, { forEach, isEmpty } from "lodash";
import { MenuItem } from "@material-ui/core";
import "../../styles/tableViewStyles.scss";
import {
  Button,
  DataTable,
  ActionMenu,
  GlobalFilter,
  Link,
} from "../../components/atoms";
import {
  getAllOperativesAction,
  getOneOperativeAction,
  deleteOperativeAction,
  enableOperativeAction,
} from "../../redux/actions/operativeAction";
import Modal from "../../components/organisms/Modal/Modal";
import { CardContainer } from "../../components/molecules/CardContainer/CardContainer";
import { DeleteBody } from "../../components/molecules/CancelFormBody/DeleteBody";
import trash from "../../assets/images/Icono_block_color.png";
import viewComents from "../../assets/images/Icono_Comentarios_Color.png";
import view from "../../assets/images/iconEyeShow.png";
import strike from "../../assets/images/imgStrike.png";
import enable from "../../assets/images/enable_icon.png";
import ShowStrikes from "./ComentsStrikes/ShowStrikes";


const OperativesDashboard = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const formDataCustomer = new FormData();
  const [searchObj, setSearchObj] = useState({});
  const [searchCondition, setSearchCondition] = useState({});
  const [buscador, setBuscador] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [searchObject, setSearchObject] = useState();
  const [itemSelected, setItemSelected] = useState(true);
  const toggleModal = ({ type, id }) => {
    if (openModal === true) {
      setItemSelected(null);
    }
    {
      setItemSelected({ type, id });
    }
    setOpenModal(!openModal);
  };

  useEffect(() => {
    props.getAllOperativesAction(searchCondition);
  }, [
    props.isSuccessDeleteOperative,
    props.isSuccessUpsertOperative,
    searchCondition,
  ]);

  const onClickActionMenu = (actionSelected, payload) => {
    switch (actionSelected) {
      case "showRecord":
        history.push({
          pathname: `/dashboard/operativos/${payload.id}/ver`,
          state: payload,
        });
        break;
      case "editRecord":
        history.push({
          pathname: `/dashboard/operatives/comentarios`,
          state: payload,
        });
        break;
      case "deleteRecord":
        props.deleteOperativeAction({ userId: payload.id });
        break;
      case "enableRecord":
        props.enableOperativeAction({ customerId: payload.id });
        break;
      default:
        break;
    }
  };

  const deleteUser = () => {
    props.deleteOperativeAction({ customerId: itemSelected.id });
    setOpenModal(false);
  };

  let mainData;

  // let queHay = ._get()

  console.log("soy los props operatives", props);

  const columns = React.useMemo(() => [
    {
      id: "operative.id",
      Header: "ID",
      disableSortBy: true,
      disableFilters: true,
      filter: "text",
      width: "6%",
      accessor: (d) => ({
        id: _.get(d, "id", ""),
      }),
      Cell: function cell({ value: { id } }) {
        return <span>{`${id}`}</span>;
      },
    },
    {
      id: "operative.name",
      Header: "Nombre",
      disableSortBy: true,
      disableFilters: true,
      filter: "text",
      width: "6%",
      accessor: (d) => ({
        firstName: _.get(d, "person.firstName", ""),
      }),
      Cell: function cell({ value: { firstName }, row }) {
        return <span>{firstName}</span>;
      },
    },
    {
      id: "operative.lastName",
      Header: "Apellido",
      disableSortBy: true,
      disableFilters: true,
      width: "6%",
      accessor: (d) => ({
        firstLastName: _.get(d, "person.firstLastName", ""),
      }),
      Cell: function cell({ value: { firstLastName }, row }) {
        return <spam>{firstLastName}</spam>;
      },
    },
    {
      id: "operative.nickName",
      Header: "Nickname",
      disableSortBy: true,
      disableFilters: true,
      width: "6%",
      accessor: (d) => ({
        nickname: _.get(d, "nickname", ""),
      }),
      Cell: function cell({ value: { nickname }, row }) {
        return <spam>{nickname}</spam>;
      },
    },
    {
      id: "operative.telefono",
      Header: "Teléfono",
      disableSortBy: true,
      disableFilters: true,
      width: "6%",
      accessor: (d) => ({
        job: _.get(d, "person.primaryPhone", ""),
      }),
      Cell: function cell({ value: { job }, row }) {
        return <spam>{job}</spam>;
      },
    },
    {
      id: "operative.correo",
      Header: "Correo",
      disableSortBy: true,
      disableFilters: true,
      width: "6%",
      accessor: (d) => ({
        job: _.get(d, "person.primaryEmail", ""),
      }),
      Cell: function cell({ value: { job }, row }) {
        return <spam>{job}</spam>;
      },
    },
    {
      id: "operative.strikes",
      Header: "Strikes",
      disableSortBy: true,
      disableFilters: true,
      width: "6%",
      accessor: (d) => ({
        strikes: _.get(d, "Strike", ""),
      }),
      Cell: function cell({ value: { strikes } }) {
        let arrayIsStrike = [];
        strikes.forEach((element) => {
          if (element.isStrike === true) {
            arrayIsStrike.push(element);
          }
        });
        return (
          <>
            {_.isEmpty(arrayIsStrike) ? (
              <p>Sin strikes</p>
            ) : (
              <ShowStrikes numberOfStrikes={arrayIsStrike} />
            )}
          </>
        );
      },
    },
    {
      id: "options.name",
      Header: "Acciones",
      filter: "text",
      disableFilters: true,
      width: "6%",
      accessor: (d) => ({
        id: d.id,
        item: d,
        operativeId: _.get(d, "user.id", ""),
        disable: _.get(d, "deletedAt", ""),
      }),

      Cell: function cell({ value: { id, item, operativeId, disable }, row }) {
        return (
          <ActionMenu id={id}>
            <MenuItem onClick={() => onClickActionMenu("showRecord", item)}>
              <img src={view} /> Ver
            </MenuItem>
            <MenuItem onClick={() => onClickActionMenu("editRecord", item)}>
              <img src={viewComents} /> Comentarios & Strikes
            </MenuItem>
            {/* Bton Activar */}
            {_.isNil(disable) ? (
              <MenuItem
                onClick={() =>
                  toggleModal({ type: "eliminar", id: operativeId })
                }
              >
                <img src={trash} /> Desactivar
              </MenuItem>
            ) : (
              <MenuItem onClick={() => onClickActionMenu("enableRecord", item)}>
                <img src={enable} /> Activar
              </MenuItem>
            )}
          </ActionMenu>
        );
      },
    },
  ]);

  const obtenerAdmin = () => {
    let value = document.getElementById("searchAdmin").value

    console.log(value)
    const condition = {
      where: {
        "customerId": value,
      }
    }
    if(_.isEmpty(value)){
      props.getAllOperativesAction();
    }else{
      dispatch(getAllOperativesAction(condition))
    }
   
    
  }

  // console.log(searchCondition)

  const getNewData = (searchCondition) => {
    setSearchCondition(searchCondition);
  };

  return (
    <>
      {openModal && itemSelected.type === "eliminar" && (
        <Modal isOpen={toggleModal} toggle={toggleModal}>
          <CardContainer
            btnLeftAction={toggleModal}
            btnRightAction={() => deleteUser()}
            body={<DeleteBody user={"operador"} />}
          />
        </Modal>
      )}
      <section className="ClientRecordAccountSection">
        <div className="ClientRecordAccountSection__container">
          <div className="TitleContainer">
            <h2 className="TitleUsers">Gestión de usuarios | Listado</h2>
          </div>

          <div className="ClientRecordAccountSection__headerContainer">
            <div className="HomeTableSection__containerFilters">
              <div className="searchPost">
                <input
                  className="searchPostInputBtn"
                  placeholder="Buscar ID,nombre..."
                  id="searchAdmin"
                />
                <div className="btnSearch" onClick={obtenerAdmin}>
                  <label>Buscar</label>
                </div>
              </div>
            </div>
            <div className="HomeTableSection__containerButtons">
              <Link className={"linkClient"} to={`/dashboard/operativos/crear`}>
                <Button className={"containerButtons__clientButton"}>
                  NUEVO
                </Button>
              </Link>
            </div>
          </div>
          <div className="ClientRecordAccountSection__tableContainer">
            <DataTable
              columns={columns}
              data={{
                data: _.get(props.operative, "data", []),
                totalRecords: _.get(props.operative, "totalRecords", 0),
              }}
              // idSearch={searchObject}
              searchObj={searchObj}
              getData={getNewData}
            />
          </div>
        </div>
      </section>
    </>
  );
};

OperativesDashboard.propTypes = {
  history: PropTypes.any,
  dispatch: PropTypes.any,
  getAllOperativesAction: PropTypes.any,
  operative: PropTypes.any,
  deleteOperativeAction: PropTypes.any,
  isSuccessDeleteOperative: PropTypes.any,
  value: PropTypes.any,
  row: PropTypes.any,
  isSuccessUpsertOperative: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    operative: _.get(state, "operative.operatives", {}),
    isSuccessDeleteOperative: _.get(
      state,
      "operative.deleteOperative.isSuccess",
      {}
    ),
    isSuccessUpsertOperative: _.get(
      state,
      "operative.upsertOperative.isSuccess",
      {}
    ),
  };
}

const mapDispatchToProps = (dispatch) => ({
  getAllOperativesAction: (params) => dispatch(getAllOperativesAction(params)),
  deleteOperativeAction: (params) => dispatch(deleteOperativeAction(params)),
  enableOperativeAction: (params) => dispatch(enableOperativeAction(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OperativesDashboard);
