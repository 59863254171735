/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import _, { isEmpty } from "lodash";
import { ButtonBase, MenuItem } from "@material-ui/core";
import plus from "../../assets/images/navieras/plus-icon.png";
import "../../styles/tableViewStyles.scss";
import {
  Button,
  DataTable,
  ActionMenu,
  GlobalFilter,
  Link,
  Select,
} from "../../components/atoms";
import {
  getAllProvidersAction,
  deleteProviderAction,
} from "../../redux/actions/providerAction";
import Modal from "../../components/organisms/Modal/Modal";
import { CardContainer } from "../../components/molecules/CardContainer/CardContainer";
import { DeleteBody } from "../../components/molecules/CancelFormBody/DeleteBody";
import trash from "../../assets/images/navieras/trash-can.png";
import view from "../../assets/images/navieras/view.png";
import edit from "../../assets/images/navieras/edit-icon.png";
import search from "../../assets/images/Icono_Buscar.png";
import selectImg from "../../assets/images/iconoflecha.png";
import CardPost from "../../components/organisms/CardPosts/CardPost";
import { getAllPostsAction } from "../../redux/actions/postsAction";
import { getAllForexAction } from "../../redux/actions/forexAction";
import CustomDatePicker from "../../components/molecules/InputDate/InputDate";

const ProvidersDashboard = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchObj, setSearchObj] = useState({});
  const [searchCondition, setSearchCondition] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [itemSelected, setItemSelected] = useState(true);
  const [coinSelected, setCoinSelected] = useState("");
  const [date,setDate] = useState();

  const toggleModal = ({ type, id }) => {
    if (openModal === true) {
      setItemSelected(null);
    }
    {
      setItemSelected({ type, id });
    }
    setOpenModal(!openModal);
  };

  useEffect(() => {
    dispatch(getAllPostsAction());
    dispatch(getAllForexAction());
  }, []);

  const posts = useSelector((state) => state.posts.allPosts.data);
  const forex = useSelector((state) => state.forex.allForex.data);

  // useEffect(() => {
  //   props.getAllProvidersAction(searchCondition);
  // }, [props.isSuccessDeleteProvider, searchCondition]);

  // useEffect(()=>{
  //     props.getAllAdministratorsAction(searchCondition);
  // },[itemDeleted, searchCondition]);

  const onClickActionMenu = (actionSelected, payload) => {
    switch (actionSelected) {
      case "showRecord":
        history.push({
          pathname: `/dashboard/proveedores/${payload.id}/ver`,
          state: payload,
        });
        break;
      case "editRecord":
        history.push({
          pathname: `/dashboard/proveedores/${payload.id}/editar`,
          state: payload,
        });
        break;
      case "deleteRecord":
        props.deleteProviderAction({ id: payload.id });
        break;
      default:
        break;
    }
  };
  const deleteUser = () => {
    props.deleteProviderAction({ id: itemSelected.id });
    setOpenModal(!openModal);
  };

  const columns = React.useMemo(() => [
    {
      id: "provider.id",
      Header: "ID",
      disableSortBy: true,
      disableFilters: true,
      filter: "text",
      width: "8%",
      accessor: (d) => ({
        id: _.get(d, "id", ""),
      }),
      Cell: function cell({ value: { id }, row }) {
        return <span>{`${id}`}</span>;
      },
    },
    {
      id: "comercial.name",
      Header: "Nombre Comercial",
      disableSortBy: true,
      disableFilters: true,
      width: "18%",
      accessor: (d) => ({
        businessname: _.get(d, "businessname", ""),
      }),
      Cell: function cell({ value: { businessname }, row }) {
        return <span>{`${businessname}`}</span>;
      },
    },
    {
      id: "commercial.business",
      Header: "Giro comercial",
      disableSortBy: true,
      disableFilters: true,
      filter: "text",
      width: "18%",
      accessor: (d) => ({
        commercialbusiness: _.get(d, "commercialbusiness", ""),
      }),
      Cell: function cell({ value: { commercialbusiness }, row }) {
        return <span>{`${commercialbusiness}`}</span>;
      },
    },
    {
      id: "provider.country",
      Header: "País",
      disableSortBy: true,
      disableFilters: true,
      filter: "text",
      width: "18%",
      accessor: (d) => ({
        country: _.get(d, "country.name", ""),
      }),
      Cell: function cell({ value: { country }, row }) {
        return <span>{`${country}`}</span>;
      },
    },
    {
      id: "provider.phone",
      Header: "Teléfono",
      disableSortBy: true,
      disableFilters: true,
      filter: "text",
      width: "18%",
      accessor: (d) => ({
        phone: _.get(d, "phone", ""),
      }),
      Cell: function cell({ value: { phone }, row }) {
        return <span>{`${phone}`}</span>;
      },
    },
    {
      id: "options.name",
      Header: "Acciones",
      filter: "text",
      disableFilters: true,
      width: "15%",
      accessor: (d) => ({
        id: d.id,
        item: d,
      }),
      Cell: function cell({ value: { id, item }, row }) {
        return (
          <ActionMenu id={id}>
            <MenuItem onClick={() => onClickActionMenu("showRecord", item)}>
              <img src={view} /> Ver proveedor
            </MenuItem>
            <MenuItem onClick={() => onClickActionMenu("editRecord", item)}>
              <img src={edit} /> Editar proveedor
            </MenuItem>
            <MenuItem onClick={() => toggleModal({ type: "eliminar", id })}>
              <img src={trash} /> Eliminar proveedor
            </MenuItem>
          </ActionMenu>
        );
      },
    },
  ]);

  const getNewData = (searchCondition) => {
    setSearchCondition(searchCondition);
  };

  const getPosts = () => {
    let value = document.getElementById("searchAdmin").value;
    let forexId = document.getElementById("selectCoin").value;
    let datePost = date;
    let createdAt;
    if (forexId == "Filtrar por denominación") {
      forexId = "";
    }

    if (!_.isEmpty(datePost)) {
      const dateInit = datePost;
      const hour = "T06:00:00.000Z";
      createdAt = `${dateInit}${hour}`;
    }

    const condition = {
      where: {
        amountOffer: value,
        forexId: forexId,
        createdAt: createdAt,
      },
    };

    dispatch(getAllPostsAction(condition));
  };

  const clearFilter = () => {
    const inputDOM = document.getElementById("searchAdmin");
    inputDOM.value = "";
    const inputDOM2 = document.getElementById("selectCoin");
    inputDOM2.value = "Filtrar por denominación";
    setCoinSelected("Filtrar por denominación");
    dispatch(getAllPostsAction());
  };

  console.log(date)

  return (
    <>
      {openModal && itemSelected.type === "eliminar" && (
        <Modal isOpen={toggleModal} toggle={toggleModal}>
          <CardContainer
            btnLeftAction={toggleModal}
            btnRightAction={() => deleteUser()}
            body={<DeleteBody user={"proveedor"} />}
          />
        </Modal>
      )}
      <section className="ClientRecordAccountSection">
        <div className="ClientRecordAccountSection__container">
          <div className="TitleContainer">
            <h2 className="TitleUsers">Gestión de publicaciones</h2>
          </div>

          <div className="ClientRecordAccountSection__headerContainer">
            <div
              className="HomeTableSection__containerFilters"
              style={{ width: "75%" }}
            >
              <div className="filterContainer">
                <div className="searchPost" style={{ width: "100%" }}>
                  <input
                    className="searchPostInput"
                    placeholder="Buscar por monto"
                    id="searchAdmin"
                    style={{ width: "100%" }}
                  />
                  {/* <div className="btnSearch">
                  <label>Buscar</label>
                </div> */}
                </div>
              </div>

              <div className="filterContainer">
                <select
                  className="selectInput"
                  id={"selectCoin"}
                  value={coinSelected.displayName}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setCoinSelected(e.target.value);
                  }}
                >
                  <option value={null}>
                    {coinSelected.name != null
                      ? coinSelected.name
                      : "Filtrar por denominación"}
                  </option>
                  {!_.isNil(forex) &&
                    forex.map((item, i) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.displayName}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="filterContainer">
                {/* <input type="date" id="dateInput" className="dateInput" /> */}
                <CustomDatePicker id="dateInput" setValue={setDate}/>
              </div>
              <div className="filterContainer">
                <Button className="btnContainer" onClick={getPosts}>
                  Buscar
                </Button>
              </div>
            </div>
            <div
              className="HomeTableSection__containerButtons"
              style={{ width: "25%" }}
            >
              <Button className="BtnFilter" onClick={clearFilter}>
                BORRAR FILTROS
              </Button>
            </div>
          </div>
          <div className="ClientRecordAccountSection__tableContainer"></div>
          <div className="PostMainContainer">
            <CardPost data={posts} />
          </div>
        </div>
      </section>
    </>
  );
};

ProvidersDashboard.propTypes = {
  history: PropTypes.any,
  dispatch: PropTypes.any,
  getAllProvidersAction: PropTypes.any,
  providers: PropTypes.any,
  deleteProviderAction: PropTypes.any,
  isSuccessDeleteProvider: PropTypes.any,
  value: PropTypes.any,
  row: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    providers: _.get(state, "provider.providers", {}),
    isSuccessDeleteProvider: _.get(
      state,
      "provider.deleteProvider.isSuccess",
      {}
    ),
  };
}

const mapDispatchToProps = (dispatch) => ({
  getAllProvidersAction: (params) => dispatch(getAllProvidersAction(params)),
  deleteProviderAction: (params) => dispatch(deleteProviderAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProvidersDashboard);
