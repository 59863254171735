/* eslint-disable no-unused-vars */
import React from "react";
import {
    Route,
    Switch,
    Redirect,
    withRouter,
} from 'react-router-dom';
import PropTypes from "prop-types";
import { connect, useSelector } from 'react-redux';
import _ from 'lodash';
import { PrivateRoute, PublicRoute  } from '../../../utils/routeAccessHelper';
import './BaseLayout.scss';

// BASE LAYOUT

import Footer from './Footer/Footer';
import Sidebar from './Sidebar/Sidebar';
import Header from './Header/Header';
import MainContainer from "./MainContainer/MainContainer";

// HOME

import Admin from "../../../pages/AdminDashboard/HomeModule/Admin";
import AdminCreate from "../../../pages/AdminDashboard/HomeModule/AdminForm";


// NOT FOUND

import NotFoundPage from "../NotFound/NotFoundPage";                  
import CustomAgent from "../../../pages/CustomsAgentDashboard/HomeModule/CustomAgent";
import { Profile } from "../../../pages/ProfileModule/Profile";
import Client from "../../../pages/AdminDashboard/HomeModule/CustomerManagement/Client";
import { ClientRegister } from "../../../pages/AdminDashboard/HomeModule/CustomerManagement/ClientRegister";
import { ClientType } from "../../../pages/AdminDashboard/HomeModule/CustomerManagement/ClientType";
import { seeClientRegister } from "../../../pages/AdminDashboard/HomeModule/CustomerManagement/ClientRegister/seeClientRegister";
import { CustomAgenteRegister } from "../../../pages/CustomsAgentDashboard/HomeModule/CustomAgenteRegister";
import { seeCustomAgentRegister } from "../../../pages/CustomsAgentDashboard/HomeModule/CustomAgentRegister/seeCustomAgentRegister";
import AdminSee from "../../../pages/AdminDashboard/HomeModule/AdminSee";
import Quotation from "../../../pages/QuotationDashboard/Quotation";
import { SeeQuotationRegister } from "../../../pages/QuotationDashboard/QuotationRegister/seeQuotationRegister";
import { QuotationRegister } from "../../../pages/QuotationDashboard/QuotationRegister";
import AerialQuotationViewDetails from "../../../pages/QuotationDashboard/AerialQuotationViewDetails";
import Providers from "../../../pages/ProvidersDashboard/Providers";
import ProviderRegister from "../../../pages/ProvidersDashboard/ProviderRegister";
import ProviderDetailsView from "../../../pages/ProvidersDashboard/ProviderDetailsView";
import PortsDashboard from '../../../pages/PortsDashboard/Ports';
import Operatives from "../../../pages/OpertivesDashboard/Operatives";
import OperativeRegister from "../../../pages/OpertivesDashboard/OperativeRegister";
import { OperativeDetailsView } from "../../../pages/OpertivesDashboard/OperativeDetailsView";
import Records from "../../../pages/RecordsDashboard/Records";
import RecordDocumentation from "../../../pages/RecordsDashboard/RecordsDocumentation";
import Home from "../../../pages/Home/Home";
import {
    adminSidebarOptions,
    operativeSidebarOptions
} from '../BaseLayout/Sidebar/sidebarOptions';
import PortsRegister from "../../../pages/PortsDashboard/PortRegister";
import { RecordDetailsStepper } from "../../../pages/RecordsDashboard/RecordDetails/RecordDetailsStepper";
import RecordRoute from "../../../pages/RecordsDashboard/RecordRoute";
import RecordClosure from "../../../pages/RecordsDashboard/RecordClosure";
import RecordRegister from "../../../pages/RecordsDashboard/RecordRegister";
import RecordsDelay from "../../../pages/RecordsDashboard/RecordsDelay";
import RecordNew from "../../../pages/RecordsDashboard/RecordNew";
import { 
    ADMIN,
    CLIENT_CREATE,
    CLIENT_PROFILE, 
    CUSTOMS_AGENT, 
    CUSTOMS_AGENT_PROFILE, 
    HOME, 
    OPERATIVES, 
    OPERATIVES_CREATE, 
    OPERATIVES_PROFILE, 
    PORTS, 
    PORTS_CREATE, 
    PORTS_EDIT, 
    PROVIDERS, 
    PROVIDER_CREATE, 
    PROVIDER_PROFILE, 
    QUOTATION, 
    QUOTATION_DOCUMENTS, 
    RECORDS, 
    RECORDS_CLOSURE, 
    RECORDS_DELAY, 
    RECORDS_DOCUMENTATION, 
    RECORDS_REGISTER, 
    RECORDS_ROUTE, 
    RECORDS_VIEW,
    MODIFICATION_CURRENCY
} from "../../../routes/Paths";
import { QuotationDocumentsView } from "../../../pages/QuotationDashboard/QuotationDocumentsView";
import ComentStrike from "../../../pages/OpertivesDashboard/ComentsStrikes/ComentStrike";
import { useEffect } from "react";
import { useState } from "react";
// imagenes
import home from '../../../assets/images/navieras/Icono_Inicio_Color.png';
import operativos from '../../../assets/images/navieras/Icono_user_color.png';
import admins from '../../../assets/images/navieras/Icono_admin_color.png';
import proveedores from '../../../assets/images/navieras/Icono_publicaciones_color.png';
import cotizacion from '../../../assets/images/navieras/Icono_cobro_color.png';
import expediente from '../../../assets/images/navieras/Icono_report_color.png';
import forexDem from "../../../assets/images/Icono_CambioDenominacionColor.png"

import chat from "../../../pages/ModificationCurrency/chat";



// white
import operativosWhite from'../../../assets/images/navieras/Icono_user-multiple_white.png';
import adminsWhite from'../../../assets/images/navieras/Icono_user-profile-white.png';
import proveedoresWhite from'../../../assets/images/navieras/Icono_publicaciones_white.png';
import cotizacionWhite from'../../../assets/images/navieras/Icono_cobro_white.png';
import reportWhite from'../../../assets/images/navieras/Icono_report_white.png';
import homeWhite from'../../../assets/images/navieras/Icono_Inicio_white.png';
import ModificationCurrency from "../../../pages/ModificationCurrency/ModificationCurrency";
import forexDemWhite from '../../../assets/images/Icono_CambioDenominacionBco.png'



const BaseLayout = (props) => {

    const user = useSelector(state => state.user.user);
    const [arrayInit, setArrayInit] = useState([]);
    let sidebarAdmin = [
        {
            img: home,
            imgSecond: homeWhite,
            optionName: 'Inicio',
            link: HOME,
        }
    ]
    // console.log(user.userPermission);

    // asignacion de permisos
    useEffect(() => {
        if(!_.isNil(user.userPermission)){
            for (let index = 0; index < user.userPermission.length; index++) {
                if(_.isNil(user.userPermission[index].deletedAt)){
                    switch (user.userPermission[index].permission.displayText) {
                        case "Gestion de usuarios":
                            sidebarAdmin.push(
                                {
                                    img: operativos,
                                    imgSecond: operativosWhite,
                                    optionName: 'Gestión de usuarios',
                                    link: OPERATIVES,
                                }
                            )
                            break;
                        case "Gestion de administradores":
                            sidebarAdmin.push(
                                {
                                    img: admins,
                                    imgSecond: adminsWhite,
                                    optionName: 'Gestión de administrador',
                                    link: ADMIN,
                                }
                            )
                            break;
                        case "Gestion de publicaciones":
                            sidebarAdmin.push(
                                {
                                    img: proveedores,
                                    imgSecond: proveedoresWhite,
                                    optionName: 'Gestión de publicaciones',
                                    link: PROVIDERS,
                                }
                            )
                            break;
                        case "Gestion de cobros":
                            sidebarAdmin.push(
                                {
                                    img: cotizacion,
                                    imgSecond: cotizacionWhite,
                                    optionName: 'Gestión de cobros',
                                    link: QUOTATION,
                                }
                            )
                            break;
                        case "Gestion de reportes":
                            sidebarAdmin.push(
                                {
                                    img: expediente,
                                    imgSecond: reportWhite,
                                    optionName: 'Gestión de reportes',
                                    link: RECORDS,
                                }
                            )
                            break;
                        case "Cambio de denominacion":
                            sidebarAdmin.push(
                                {
                                    img: forexDem,
                                    imgSecond: forexDemWhite,
                                    optionName: 'Cambio de denominacion',
                                    link: MODIFICATION_CURRENCY,
                                }
                            )
                            break;
        
    
                        default:
                            break;
                    }
                }     
            }
        }
    setArrayInit(sidebarAdmin)
    }, [user])

    
    const isAdmin = user.administratorId !== null;
    const isOperative = user.operativeId !== null;


    let sidebarOptions = isAdmin ? adminSidebarOptions : operativeSidebarOptions;

    return (
        <>
            <Sidebar sidebarOptions={arrayInit} />
            <Header isOperative={isOperative} user={user} />
            <MainContainer>
                <Switch>
                    <Route 
                        exact
                        path="/dashboard" 
                        render={() => <Redirect to={HOME} />} 
                    />
                    <PrivateRoute
                        exact
                        path={HOME}
                        component={Home}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={'/dashboard/perfil'}
                        component={Profile}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={'/dashboard/chat'}
                        component={chat}
                        isAllowed={true}
                    />
                    <PrivateRoute
                        exact
                        path={'/dashboard/admin'}
                        component={Admin}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={'/dashboard/admin/crear'}
                        component={AdminCreate}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={'/dashboard/admin/:userId'}
                        component={AdminSee}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={'/dashboard/gestion-clientes'}
                        component={Client}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={'/dashboard/gestion-clientes/tipo-usuario'}
                        component={ClientType}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={CLIENT_CREATE}
                        component={ClientRegister}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={CLIENT_PROFILE}
                        component={seeClientRegister}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PublicRoute
                        exact
                        path={CUSTOMS_AGENT}
                        component={CustomAgent}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={'/dashboard/agentes-aduanales/crear'}
                        component={CustomAgenteRegister}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={CUSTOMS_AGENT_PROFILE}
                        component={seeCustomAgentRegister}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PublicRoute
                        exact
                        path={QUOTATION}
                        component={Quotation}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PublicRoute
                        exact
                        path={'/dashboard/cotizacion/:clientId'}
                        component={Quotation}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={'/dashboard/cotizacion/:id/ver'}
                        component={SeeQuotationRegister}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={'/dashboard/cotizaciones/crear'}
                        component={QuotationRegister}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={QUOTATION_DOCUMENTS}
                        component={QuotationDocumentsView}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={PROVIDERS}
                        component={Providers}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={PROVIDER_CREATE}
                        component={ProviderRegister}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={PROVIDER_PROFILE}
                        component={ProviderDetailsView}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={PORTS}
                        component={PortsDashboard}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={PORTS_EDIT}
                        component={PortsRegister}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={PORTS_CREATE}
                        component={PortsRegister}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={OPERATIVES}
                        component={Operatives}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={OPERATIVES_CREATE}
                        component={OperativeRegister}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={OPERATIVES_PROFILE}
                        component={OperativeDetailsView}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={'/dashboard/operatives/comentarios'}
                        component={ComentStrike}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={RECORDS}
                        component={Records}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={RECORDS_DOCUMENTATION}
                        component={RecordDocumentation}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={RECORDS_VIEW}
                        component={RecordDetailsStepper}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={RECORDS_ROUTE}
                        component={RecordRoute}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={RECORDS_CLOSURE}
                        component={RecordClosure}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={RECORDS_REGISTER}
                        component={RecordRegister}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={'/dashboard/expedientes/nuevo'}
                        component={RecordNew}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={RECORDS_DELAY}
                        component={RecordsDelay}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={MODIFICATION_CURRENCY}
                        component={ModificationCurrency}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    
                    <PublicRoute component={NotFoundPage} />
                </Switch>
            </MainContainer> 
            <Footer />
        </>
    );
};

BaseLayout.propTypes = {
    classNameMain: PropTypes.string,
    isSidebarOpened: PropTypes.any,
    isAuthenticated: PropTypes.any,
    role: PropTypes.any,
    isAdmin: PropTypes.any,
};

const mapStateToProps = (state) => { 
    return { 
        isSidebarOpened: _.get(state.userInterface, 'isSidebarOpened', false),
        isAuthenticated: state.user.isAuthenticated || false,
        role: _.get(state.user,'user.roles[0]', false) === 'administrator' ? true : false,
    }; 
};

// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = dispatch => ({

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BaseLayout));
