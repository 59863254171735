/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import _, { get } from "lodash";
import profile from "../../../../assets/images/perfil.png";
import edit from "../../../../assets/images/navieras/editIcon.png";
import editCash from "../../../../assets/images/navieras/Icono_Editar_Color.png";
import { Button } from "../../../atoms";
import "./ProfileFormStyles.scss";
import {
  getOneAdministratorRecordAction,
  uploadAdministratorAction,
  upsertAdministratorAction,
} from "../../../../redux/actions/adminAction";
import { updatePasswordRequestAction } from "../../../../redux/actions/userActions";
import { addNotification } from "../../../../redux/actions/notificationAction";
import { ADMIN } from "../../../../routes/Paths";
import editPicture from "../../../../assets/images/Icono_Editar.png";
import { url_api } from "../../../../config";
import { getAlluserPermissionAction } from "../../../../redux/actions/userPermissionAction";
import MultipleSelect from "../../../organisms/Checkmarks/CheckMarkTwo";

export const ProfileForm = (props) => {
  let { userId } = useParams();
  const mode = props.type;
  // const data = useSelector(state => state.admin.adminRecord.data);

  const dispatch = useDispatch();
  const history = useHistory();

  const [arrayAdd,setArrayAdd] = useState();
  const [arrayDelete,setArrayDelete] = useState();

  const user = useSelector((state) => state.user.user);
  let currentUser;
  if (user.administratorId != null) currentUser = "administrator";
  else currentUser = "operative";

  //ADD IMG

  const [imageSrc, setImageSrc] = useState(profile);
  const [fileUrl, setFileUrl] = useState([]);
  const [imgBase64, setImgBase64] = useState([]);

  const api = process.env.SERVER_API_DATA_URL;
  //ESTE ES EL QUE SE VA A RECIBIR POR PARAMS
  let mainData;
  if (props.type == "profile") mainData = _.get(props.data, "data", null);
  else if (props.type == "see") mainData = props.data && props.data;
  // const dataUser = user.administratorId != null ? mainData.administrator : mainData.operative;
  const emailDefault = _.get(mainData, "user.email", ""); /* mainData.email; */
  const idUser = _.get(mainData,"user.id",""); 


  const firstNameDefault = _.get(
    mainData,
    "person.firstName",
    ""
  ); /*  mainData.person.firstName; */
  const firstLastNameDefault = _.get(mainData, "person.firstLastName", "");
  const idData = _.get(mainData, "id");
  const primaryPhone = _.get(mainData, "person.primaryPhone", "");
  const positionDefault = _.get(mainData, `${currentUser}.job`, "");
 
  const fechaIngresoDefault = _.get(mainData, "createdAt", null);
  const userRolDefault =
    _.get(mainData, "administrator.isSuperAdmin") === true ? 1 : 2;
  const userRolNameDefault = userRolDefault
    ? userRolDefault === 1
      ? "Super admin"
      : "Admin"
    : "";
  let imgDefault = _.get(mainData, "person.profileImageMedia.fileName", null);
  const idMedia = _.get(mainData, "person.profileImageMedia.id")
  const urlMediaEnd = _.get(mainData, "person.profileImageMedia.url",null)
  const country = _.get(mainData, "user.address.country.name",null)
  const state = _.get(mainData,"user.address.state.name",null)
  const city = _.get(mainData, "user.address.city.name",null)

  const fechaIngreso = fechaIngresoDefault
    ? moment(fechaIngresoDefault).format("yyyy-MM-DD")
    : moment(new Date()).format("yyyy-MM-DD");

  const defaultValues = {
    name: firstNameDefault,
    lastName: firstLastNameDefault,
    position: positionDefault,
    email: emailDefault,
    primaryPhone: primaryPhone,
    country: country,
    state: state,
    city: city,
  };
  const methods = useForm({ defaultValues: defaultValues });
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = methods;

  useEffect(() => {
    dispatch(getAlluserPermissionAction({where: {
      userId: idUser
    }}))
    
  }, [idUser])

  const allUserPermission = useSelector((state) => state.userPermission.permissionUser.data);

  const id = user.id ? user.id : "";
  const [disabled, setDisabled] = useState(props.type === 'see' ? true : false);

  useEffect(() => {
    if (props.type == "create") setDisabled(false);
  }, []);



  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        return resolve({
          src: reader.result,
          title: file.name,
        });
      };
      reader.onerror = (error) => reject(error);
    });
  };
  
  const unpdateInfoFile = async (event) => {

    const data = {
      id: idMedia,
      logo:{

      }
    }
    const urlImage = URL.createObjectURL(event.target.files[0])
    const base64 = await toBase64(event.target.files[0]);
    data.logo = base64
    imgDefault = urlImage;
    dispatch(uploadAdministratorAction(data))

  };

  const handleChangePhoto = (event) =>{
    const inputLogo = document.getElementById('inputLogoFile')
    inputLogo.click()
  }

  const profileImg = fileUrl[fileUrl.length - 1]
    ? fileUrl[fileUrl.length - 1]
    : imgDefault
    ? `${url_api}${'/'}${imgDefault}`
    : imageSrc;


  const setImgProfile = {
    backgroundImage:  `url(${profileImg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  //END ADD IMG


  const onSubmit = async ({ name, lastName, email,primaryPhone }) => {
    const data = {
      id: props.type !== 'create' ? idUser : null,
      user: userId
        ? {
            id: idUser,
            email: emailDefault,
          }
        : {
            email: email,
            password: "1234",
          },
      person: {
        firstName: name,
        firstLastName: lastName,
        primaryPhone: primaryPhone,
      },
      address: {
        countryId: country,
        stateId: state,
        cityId: city,
      },
      userPermission:{
        arrayDelete: arrayDelete,
        arrayAdd: arrayAdd,
      }
    };
    const res = await dispatch(upsertAdministratorAction(data));
    history.push(ADMIN);
    if (props.isSuccessUpsertAdmin && !userId) {
      const res2 = await dispatch(updatePasswordRequestAction({ email }));
      if (props.isSuccessUpdatePassword) {
        dispatch(
          addNotification({
            toastType: "success",
            toastTitle:
              "Se han envido instrucciones para concluir la acción a su email",
          })
        );
        history.push(ADMIN);
      }
    } else if (props.isSuccessUpsertAdmin && userId) history.push(ADMIN);
  };

  return (
    <div className="main-container">
      <form className="form-admin-container" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-admin-profile-left form-admin-profile-cards">
          <div className="picture-container">
            <div className="preContainer">
              <div className="img-profile" 
              // style={setImgProfile}     
              >
                <img src={profileImg} style={{width: "100%", height:"100%"}}/>
              </div>
              <input
                className="input-files"
                id={"inputLogoFile"}
                type="file"
                accept="image/png, image/jpg, image/jpeg"
                placeholder="Subir archivo"
                onChange={unpdateInfoFile}
              />
              <Button
                type="button"
                className="editPicture"
                onClick={handleChangePhoto}
                srcicon={editPicture}
                classNameImg="editImgProfile"
                accept="image/png, image/jpg, image/jpeg"
                id={"inputLogoFile"}
              />
            </div>
          </div>
          <div className="text-container">
            <h2>{`${firstNameDefault} ${firstLastNameDefault}`}</h2>
            <p>{emailDefault}</p>
          </div>
          <div className="bottom-text">
            {/* <p>Fecha de ingreso {fecha}</p> */}
            {props.type !== "create" && <p>ID : {id}</p>}
            <p>Fecha de ingreso: {fechaIngreso}</p>
          </div>
        </div>
        <div className="form-admin-profile-right form-admin-profile-cards" style={{overflowY: "scroll"}}>
          <div className="header-form">
            <h2 style={{color:"#5169C3"}}>Datos principales</h2>
            {props.type !== "create" && (
              <div
                className="edit-profil-button"
                onClick={() => setDisabled(!disabled)}
              >
                {" "} 
                <img src={editCash} />
              </div>
            )}
          </div>
          <div className="InfoPersonalContainer">
            <div className="profileDataContainer">
              <h3 className="labelInfoProfile">Información Personal</h3>
              <div className="sectionOneProfile">
                <div className="inputContainer">
                  <label className="labeInputProfile">Nombre(s)</label>
                  <input
                    disabled={disabled}
                    control={control}
                    name={"name"}
                    {...register("name")}
                    className="input-generic"
                    type="text"
                    placeholder="Nombre(s)"
                  />
                </div>
                <div className="inputContainer">
                  <label className="labeInputProfile">Apellidos</label>
                  <input
                    disabled={disabled}
                    control={control}
                    name={"lastName"}
                    {...register("lastName")}
                    className="input-generic"
                    type="text"
                    placeholder="Apellido(s)"
                  />
                </div>
              </div>
              <div className="sectionTwoProfile">
                <div className="inputContainer">
                  <label className="labeInputProfile">Permisos</label>
                  <MultipleSelect
                    disable={mode}
                    data={allUserPermission}
                    setArrayDelete={setArrayDelete}
                    setArrayAdd={setArrayAdd}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="InfoContactMainContainer">
            <h3 className="labelInfoProfile">Información de contacto</h3>
            <div className="InfoContactContainer">
              <div className="sectionOneProfile">
                <div className="inputContainer">
                  <label className="labeInputProfile">Correo</label>
                  <input
                    disabled={true}
                    control={control}
                    name={"name"}
                    {...register("email")}
                    className="input-generic"
                    type="text"
                    placeholder="Nombre(s)"
                  />
                </div>
                <div className="inputContainer">
                  <label className="labeInputProfile">Teléfono</label>
                  <input
                    disabled={disabled}
                    control={control}
                    name={"primaryPhone"}
                    {...register("primaryPhone")}
                    className="input-generic"
                    type="text"
                    placeholder="Telefono"
                  />
                </div>
                <div className="inputContainer">
                  <label className="labeInputProfile">País</label>
                  <input
                    disabled={true}
                    control={control}
                    name={"name"}
                    {...register("country")}
                    className="input-generic"
                    type="text"
                    placeholder="Pais"
                  />
                </div>
                <div className="inputContainer">
                  <label className="labeInputProfile">Estado</label>
                  <input
                    disabled={true}
                    control={control}
                    name={"name"}
                    {...register("state")}
                    className="input-generic"
                    type="text"
                    placeholder="Estado"
                  />
                </div>
                <div className="inputContainer">
                  <label className="labeInputProfile">Ciudad</label>
                  <input
                    disabled={true}
                    control={control}
                    name={"name"}
                    {...register("city")}
                    className="input-generic"
                    type="text"
                    placeholder="Ciudad"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {(props.type === "create" || !disabled) && (
        <div className="footer-form" style={{display: "flex", flexDirection:"column", alignItems:"center"}}>
          <Button type="submit" style={{ marginTop: "0", background:'transparent linear-gradient(180deg, #79DECA 0%, #5169C3 100%) 0% 0% no-repeat padding-box' }}>
            {props.type == "create" ? "REGISTRAR" : "GUARDAR"}
          </Button>
        </div>
      )}
      </form>
     
    </div>
  );
};

ProfileForm.propTypes = {
  isSuccessUpsertAdmin: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    isSuccessUpsertAdmin: _.get(state, "admin.upsertAdmin.isSuccess", {}),
    isSuccessUpdatePassword: _.get(
      state,
      "user.updatePasswordRequest.isSuccess",
      {}
    ),
    user: _.get(state, "user.user", {}),
  };
};

const mapDispatchToProps = (dispatch) => ({});

connect(mapDispatchToProps, mapStateToProps)(ProfileForm);
