/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { CancelFormBody } from '../../components/molecules/CancelFormBody/CancelFormBody';
import { CardContainer } from '../../components/molecules/CardContainer/CardContainer';
import Modal from '../../components/organisms/Modal/Modal';
import '../../styles/stylesSteppers.scss';
import './OperativeRegister.scss';
import OperativePrincipalData from './OperativePrincipalData';
import OperativeIdentity  from './OperativeIdentity';
// import { OperativePrincipalDataForm } from './OperativePrincipalDataForm';
import { StepperComponent } from '../../components/Stepper/Stepper';
import OperativeDataBank from './OperativeDataBank';
import { useDispatch } from 'react-redux';
import { upsertAdministratorAction } from '../../redux/actions/adminAction';
import { upsertOperativeAction } from '../../redux/actions/operativeAction';
import backButton from '../../assets/images/Icono_ArrowLeft.png';


const OperativeRegister = (props) => {
    const history = useHistory();
    const [stepper, setStepper] = useState(0);
    const [dataFirstStep, setDataFirstStep] = useState();
    const [dataSecondStep, setDataSecondStep] = useState();
    const [infoValidation, setInfoValidation] = useState(false);
    const dispatch = useDispatch();

    const [openModal, setOpenModal] = useState(false)
    const toggleModal = () => {
        setOpenModal(!openModal);
    }



    const steps = [
        {
            label: "Datos Principales",
            step: 0,
            component: <OperativePrincipalData stepper={stepper} setStepper={setStepper} dataFirstStep={setDataFirstStep}/>
        },
        {
            label: "Datos de identidad",
            step: 1,
            component: <OperativeIdentity stepper={stepper} setStepper={setStepper} dataSecondStep={setDataSecondStep}/>
        },
        {
            label: "Datos Tarjeta Bancaria",
            step: 2,
            component: <OperativeDataBank stepper={stepper} setStepper={setStepper} validationStep={setInfoValidation}/>
        }
    ]
    // const currentData = _.get(props, 'location.state', null);

   

    const dataForm = {

    }

    const [render, setRender]= useState(null)
    useEffect(() => {
        setRender(steps[stepper].component)
    }, [stepper])

    
    

    useEffect(() => {
        if(!_.isEmpty(dataFirstStep && dataSecondStep)){
            let dataEndPrincipal = {};
            let dataEndIdentity = {};
            dataEndPrincipal = dataFirstStep;
            dataEndIdentity = dataSecondStep;
            dataEndPrincipal.person.curp = dataEndIdentity.person.curp;
            dataEndPrincipal.person.identificationNumber = dataEndIdentity.person.identificationNumber
            dataEndPrincipal.files = dataEndIdentity.files

            
            dispatch(upsertOperativeAction(dataEndPrincipal))
            history.push('/dashboard/operativos');
        }
   
    }, [infoValidation])
    

    const cancel = () => {
        setOpenModal(true)
    }

    return (
        <>
        {
            openModal &&
            <Modal
                isOpen={toggleModal}
                toggle={toggleModal}
            >
                <CardContainer btnLeftAction={toggleModal} btnRightAction={cancel} body={<CancelFormBody/>}/>
            </Modal>
        }
            
            <div className='main-container' style={{overflowY: "hidden",justifyContent:"flex-start"}}>
                <div className='titleContainerRegister'>
                    <h2 className='titleRegister'>Gestión de usuarios | Nuevo usuario</h2>
                </div>
                <div className='card-container-stepper operative' style={{width: '50%', height: '75%', color:'#ffff'}}>
                <StepperComponent step={stepper} steps={steps} setStepper={setStepper} client={true}/>
                   <div className='CardContainerStepper'>

                    {
                        render
                    }
                   </div>
                </div>
            </div>
        </>
    )
}

export default OperativeRegister;