import _ from 'lodash';
import {
   PERMISSIONS_MODULE_GET_ALL_PERMISSIONS_BEGIN,
   PERMISSIONS_MODULE_GET_ALL_PERMISSIONS_SUCCESS,
   PERMISSIONS_MODULE_GET_ALL_PERMISSIONS_FAILURE,
 } from '../actionTypes';

import initialState from '../initialState';

export default function permissionsReducer(state=initialState.permission, action){
    switch (action.type) {
        case PERMISSIONS_MODULE_GET_ALL_PERMISSIONS_BEGIN:
            return {
                ...state,
                permissions: {
                    
                    isLoading: true,
                    isSuccess: false,
                    err: null, 
                    totalRecords: 0,
                    data: [],
                }
            };
        case PERMISSIONS_MODULE_GET_ALL_PERMISSIONS_SUCCESS:
            return {
                ...state,
                permissions: {
                    
                    data: action.data,
                },
            };
        case PERMISSIONS_MODULE_GET_ALL_PERMISSIONS_FAILURE:
            return {
                ...state,
                permissions: {
                   
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
                },
            };
            // ---

        default:
            return state;
    }
}
