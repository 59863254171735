import _ from 'lodash';
import {
    USER_PERMISSION_MODULE_GET_ALL_USER_PERMISSION_BEGIN,
    USER_PERMISSION_MODULE_GET_ALL_USER_PERMISSION_SUCCESS,
    USER_PERMISSION_MODULE_GET_ALL_USER_PERMISSION_FAILURE,
 } from '../actionTypes';

import initialState from '../initialState';

export default function permissionsReducer(state=initialState.userPermission, action){
    switch (action.type) {
        case USER_PERMISSION_MODULE_GET_ALL_USER_PERMISSION_BEGIN:
            return {
                ...state,
                permissionUser: {
                    
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    totalRecords: 0,
                    data: [],
                }
            };
        case USER_PERMISSION_MODULE_GET_ALL_USER_PERMISSION_SUCCESS:
            return {
                ...state,
                permissionUser: {
                    
                    data: action.data,
                },
            };
        case USER_PERMISSION_MODULE_GET_ALL_USER_PERMISSION_FAILURE:
            return {
                ...state,
                permissionUser: {
                   
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
                },
            };
            // ---

        default:
            return state;
    }
}
