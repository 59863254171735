import React from "react";
import {
    Route,
    Switch,
    Redirect,
    withRouter,
} from 'react-router-dom';
import { PrivateRoute, PublicRoute } from '../../../utils/routeAccessHelper';
import PropTypes from "prop-types";
import {connect } from 'react-redux';
import _ from 'lodash';
import '../../../components/templates/BaseLayout/BaseLayout.scss';
import Footer from './Footer/Footer';
import ClientModuleSidebar from "./Sidebar/ClientModuleSidebar";

import Header from './Header/Header';
import MainContainer from "./MainContainer/MainContainer";

// HOME++

import ClientHomeModule from "../../../pages/ClientDashboard/HomeClient";
// NOT FOUND

import NotFoundPage from "../NotFound/NotFoundPage";


const BaseLayout = (props) => {
    console.log(props.role)
    return (
        <>
            <Header userName={'Cliente'} />
            <ClientModuleSidebar />
            <MainContainer>
                <Switch>
                    <Route 
                        exact
                        path="/dashboard-cliente/" 
                        render={() => <Redirect to="/dashboard-cliente/inicio" />} 
                    />
                    <PrivateRoute 
                        exact
                        path={'/dashboard-cliente/inicio'}
                        component={ClientHomeModule}
                        isAllowed={(props.isAuthenticated && props.role) ? true : false}
                    />
                    <PublicRoute component={NotFoundPage} />
                </Switch>
            </MainContainer> 
            <Footer />
        </>
    );
};

BaseLayout.propTypes = {
    classNameMain: PropTypes.string,
    isSidebarOpened: PropTypes.any,
    isAuthenticated: PropTypes.any,
    role: PropTypes.any,
};

const mapStateToProps = (state) => { 
    return { 
        isSidebarOpened: _.get(state.userInterface, 'isSidebarOpened', false),
        isAuthenticated: state.user.isAuthenticated || false,
        role: _.get(state.user,'user.roles[0]', false) === 'company' ? true : false,
    }; 
};

// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = dispatch => ({

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BaseLayout));
