/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { Button } from '../../../../components/atoms';
// import '../CustomAgent.scss';
// import './ClientRegister.scss';
import '../../../../styles/stylesSteppers.scss'
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import seeButton from '../../../../assets/images/navieras/seeButton.png'
import add from '../../../../assets/images/navieras/plus-icon.png'
import { useDispatch, useSelector, connect } from 'react-redux';
import { deleteAssociation, getAllClientBelongsCustomAgent } from '../../../../redux/actions/customsAgentAction';
import { useHistory, useParams } from 'react-router-dom';
import { CUSTOMS_AGENT } from '../../../../routes/Paths';
import { getAllAvailableClientsAction } from '../../../../redux/actions/clientAction';
import { addNotification } from '../../../../redux/actions/notificationAction';

export const ClientsCustomAgents = ({ stepper, setStepper, toggleModal, setSelectedModal, formData, setFormData, disabled, setClientsAvailables }) => {
    const methods = useForm({ defaultValues: defaultValues });
    const dispatch = useDispatch();
    const offset = 0;
    const limit = 2;
    const history = useHistory();
    const { mode } = useParams();
    const [ isDisabled, setIsDisabled ] = useState(mode === 'ver' ? true : false);

    const clients = useSelector(state => state.customAgent.getClientBelongsCustomAgent);
    const associationDelete = useSelector(state => state.customAgent.deleteAssociation);
    const { register, handleSubmit, control, reset, formState: { errors }, } = methods;

    const [ rightbuttonName, setRightbuttonName ] = useState(mode === 'ver' ? 'ACEPTAR' : 'GUARDAR');
    
    const defaultValues = {
        // name : '',
    };




    const changeStage = () => {
        console.log('cambio de pestania')
        setStepper(stepper + 1)
    }
    const changeStageBack = () => {
        console.log('cambio de pestania')
        setStepper(stepper - 1)
    }


    useEffect(() => {
        const customAgentId = formData.agend.id;
        if (formData) {
            dispatch(getAllClientBelongsCustomAgent({ customAgentId, offset, limit }))
        } if (associationDelete && associationDelete.isSuccess) {
            dispatch(getAllClientBelongsCustomAgent({ customAgentId, offset, limit }))
        }


    }, [associationDelete])




    const deleteAsign = (id) => {
        dispatch(deleteAssociation({id}))
    }


    const seeCustomer = (id,payload) => {
        history.push(
            {
                pathname: `/dashboard/gestion-clientes/${id}/ver`,
                state: payload,
            }
            );
    }

    const preAssingClient = async (id) =>{
        const res =  await dispatch(getAllAvailableClientsAction({customAgendId: id}));
        if(res){
            toggleModal(1, id);
        }else{
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de clientes, favor de intentarlo de nuevo',
            }));
        }
        toggleModal();
        setSelectedModal('assign client');
        setClientsAvailables(props.clients)
    }

    return (
        <>
            <form className='main-container-generic-type'>
                <div className='header-form-generic-type'>
                    <h2>Clientes asignados</h2>
                </div>
                <div className='body-form-generic-type-data'>
                    <div className='button-head-container'>
                        <Button onClick={() => preAssingClient(formData.agend.id)} style={{ margin: 0 }} srcicon={add}>ASIGNAR</Button>
                    </div>
                    <div>
                        {
                            clients && clients.data && clients.data.associationAgendClient
                            && clients.data.associationAgendClient.map((ele, i) => {
                                return (
                                    <div key={i} className='customer-agent-container'>
                                        <div className='customer-agent-row'>
                                            <h2>{ele.client && ele.client.tradename}</h2>
                                            <img onClick={() => seeCustomer(ele.id,ele)} src={seeButton} alt="" />
                                            <button disabled={isDisabled} onClick={() => deleteAsign(ele.id)} style={{ margin: '0' }}>QUITAR ASIGNACION</button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='footer-form-generic-type' style={{ justifyContent: 'space-between' }}>
                    <Button type='button' onClick={() => mode == 'ver' || mode == 'editar' ? changeStageBack() : (toggleModal(), setSelectedModal('cancel'))} className={'button-back'} style={{ width: '40%' }}>CANCELAR</Button>
                    <Button type='button' onClick={() => history.push(CUSTOMS_AGENT)} className={'button-next'} style={{ width: '40%' }}>{rightbuttonName}</Button>
                </div>
            </form>
        </>
    )
}

function mapStateToProps (state) {
    return {
        clients: _.get(state, 'client.clientsAvailableByCustomerAgent', []),
        customsAgents: _.get(state, 'customAgent.customAgents', {}),
    };
}

const mapDispatchToProps = dispatch => ({
});

connect(mapStateToProps,mapDispatchToProps)(ClientsCustomAgents);
