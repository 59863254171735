import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { CancelFormBody } from '../../components/molecules/CancelFormBody/CancelFormBody';
import { CardContainer } from '../../components/molecules/CardContainer/CardContainer';
import Modal from '../../components/organisms/Modal/Modal';
import { StepperComponent } from '../../components/Stepper/Stepper';
import { PrincipalDataQuotation } from './PrincipalDataQuotation';
// import './Quotation.scss';
import '../../styles/stylesSteppers.scss';
import { QuotationAssingClient } from './QuotationAssingClient';
import { QuotationAssingProvider } from './QuotationAssingProvider';
import { QuotationAssingService } from './QuotationAssingService';
import { QuotationDocuments } from './QuotationDocuments';
import { SeeQuotationDocuments } from './QuotationRegister/SeeQuotationDocuments';
import { getAllPortsClientAction } from '../../redux/actions/portAction';
import { useDispatch } from 'react-redux';

export const QuotationRegister = (props) => {
    const history = useHistory()
    const [client, setClient] = useState(null);
    const [clientPorts, setClientPorts] = useState(null);
    const [stepper, setStepper] = useState(0);
    const [formData, setFormData] = useState({});
    const [ modalitySelected, setModalitySelected ] = useState(null);
    const [ countriesSelect, setCountriesSelect ] = useState(null);
    const dispatch = useDispatch();

    const [openModal, setOpenModal] = useState(false)
    const toggleModal = () => {
        setOpenModal(!openModal);
    }

    useEffect(() => {   
        console.log(client)
       
        const getPorts = async () => {
            const res = await dispatch(getAllPortsClientAction({clientId : client}))
            if(res){ 
                setClientPorts(res)
            }
        }
        if(client){
            getPorts();
        }
        
    }, [client])
    
    useEffect(() => {
      console.log("countriesSelect")
      console.log(countriesSelect)
    }, [countriesSelect])
    
    useEffect(() => {
        let countries = [];
        let puertos;
        console.log(clientPorts);
        if(clientPorts!==null && clientPorts.data.length > 0){
            clientPorts && clientPorts.data.map((ele, index) => {
                puertos = 
                    { ...puertos,
                    [ele.countryId]:{
                    countryName: ele.countryName,countryId:ele.countryId}
                    }
                })
                Object.entries(puertos).map(([key, value]) => {
                countries.push(value)
                });
                console.log(countries);
                setCountriesSelect(countries)
        }else{
            console.log('no entro')
        }
        
    }, [clientPorts])
    
    const steps = [
        {
            label: 'Asignar cliente',
            step:0,
            component: <QuotationAssingClient stepper={stepper} setStepper={setStepper} toggleModal={toggleModal} formData={formData} setFormData={setFormData} setClient={setClient}/>
        },
        {
            /// Aqui es agregar tipo de servicio y modalidad
            label: 'Proveedor & tipos',
            step:1,
            component: 
                <QuotationAssingProvider 
                    stepper={stepper} 
                    setStepper={setStepper} 
                    toggleModal={toggleModal}
                    setValue={setModalitySelected}
                    // value={modalitySelected}
                    formData={formData} setFormData={setFormData}
                />
        },
        {
            /// Dependiendo el tipo de modalidad mostrar formularios aqui 
            label: 'Datos de cotizacion',
            step:2,
            component: 
                <PrincipalDataQuotation 
                    stepper={stepper} 
                    setStepper={setStepper} 
                    toggleModal={toggleModal}
                    modality={formData.modalityTypeId}
                    formData={formData} setFormData={setFormData}
                    clientPorts={clientPorts}
                    countriesSelect={countriesSelect}
                />
        },
        {
            label: 'Documentación',
            step:4,
            component: <QuotationDocuments stepper={stepper} setStepper={setStepper} toggleModal={toggleModal} formData={formData} setFormData={setFormData}/>
        },
        // {
        //     label: 'Tipo de servicio',
        //     step:4,
        //     component: <QuotationAssingService stepper={stepper} setStepper={setStepper} toggleModal={toggleModal}/>
        // },
    ] 

    const [render, setRender]= useState(null)
    useEffect(() => {
        setRender(steps[stepper].component)
    }, [stepper])
    
    const renders = steps.map((ele)=>{
        return(
            ele.component
        )
    })
    
    const cancel = ()=>{
        history.push('/dashboard/cotizacion')
    }
    return (
        <>
        {
            openModal &&
            <Modal
                isOpen={toggleModal}
                toggle={toggleModal}
            >
                <CardContainer btnLeftAction={toggleModal} btnRightAction={cancel} body={<CancelFormBody/>}/>
            </Modal>
        }
        <div className='main-container'>
            <div className='stepper-container'>
            <StepperComponent step={stepper} steps={steps} setStepper={setStepper}/>
            </div>
            <div className='card-container-stepper' style={stepper===2 ? {width: '80%'} : {}}>
                {
                render
                }
            </div>
        </div>
        </>
    )
}
