import React from 'react'
import './CancelFormBody.scss'


export const ForexFormBody = () => {
  return (
    <div className='cancel-body-container'>
       <h2>¿ESTÁS SEGURO DE QUERER ACTIVAR EL PRECIO MODIFICADO?</h2>
        <p>Al ejecutar esta acción el valor de las divisas cambiaran y podrán ser visualizadas por los usuarios</p>
    </div>
  )
}
