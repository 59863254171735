import _ from 'lodash';
import {
   FOREX_MODULE_GET_ALL_FOREX_BEGIN,
   FOREX_MODULE_GET_ALL_FOREX_SUCCESS,
   FOREX_MODULE_GET_ALL_FOREX_FAILURE,
 } from '../actionTypes';

import initialState from '../initialState';

export default function permissionsReducer(state=initialState.forex, action){
    switch (action.type) {
        case FOREX_MODULE_GET_ALL_FOREX_BEGIN:
            return {
                ...state,
                allPosts: {
                    
                    isLoading: true,
                    isSuccess: false,
                    err: null, 
                    totalRecords: 0,
                    data: [],
                }
            };
        case FOREX_MODULE_GET_ALL_FOREX_SUCCESS:
            return {
                ...state,
                allForex: {
                    
                    data: action.data,
                },
            };
        case FOREX_MODULE_GET_ALL_FOREX_FAILURE:
            return {
                ...state,
                allPosts: {
                   
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
                },
            };
            // ---

        default:
            return state;
    }
}
