/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button } from './../../../components/atoms';
import '../../../styles/stylesSteppers.scss';
import arrow from './../../../assets/images/navieras/arrowButton.png'
import { useHistory } from 'react-router-dom';
import './RecordsData.scss';
import { containerType } from '../../../constants/dummyData';

const RecordsData = ({ recordId = '002', setStepper, stepper }) => {

    const [ data, setData ] = useState({
        TLFolio: '',
        client: '',
        MBLNumber: '',
        containerNumber: '',
        naviera: '',
        containerQuantity: '',
        team: '',
        teus: '',
        etd: '',
        eta: '',
        sailDate: '',
        portArriveDate: '',
    });

    const changeStage = () => {
        // history.push({
        //     state: {clientType: containerType},
        // });
        setStepper(stepper+1);
        console.warn(data);
    }

    return (
            <div className='main-container-generic-type'>
                <div className='header-form-generic-type'>
                    <h2>Datos del expediente</h2>
                    <h3>ID: {recordId}</h3>
                </div>
                <div className='body-form-generic-type-data record-data' style={{height: '45vh'}}>
                    <div className='input-container-data record-data'>
                        <h3>Datos del cliente</h3>
                        <div className='input-column-container records'>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>TL (Número de folio)</label>
                                </div>
                                <div className='value-container'>
                                    <input 
                                        className="input-generic"
                                        type="text"
                                        placeholder="Agregar..."
                                        value={data.TLFolio}
                                        onChange={(e) => setData({...data, TLFolio: e.target.value})}
                                    />
                                </div>
                            </div>                    
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>Cliente</label>
                                </div>
                                <div className='value-container'>
                                    <input 
                                        className="input-generic"
                                        type="text"
                                        placeholder="Nombre del cliente"
                                        value={data.client}
                                        onChange={(e) => setData({...data, client: e.target.value})}
                                    />
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div className='input-container-data record-data'>
                        <h3>Datos de contenedores</h3>
                        <div className='input-column-container records'>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>Número de MBL</label>
                                </div>
                                <div className='value-container' >
                                    <input 
                                        type='text' 
                                        value={data.MBLNumber}
                                        onChange={(e) => setData({...data, MBLNumber: e.target.value})}
                                        className='input-generic'
                                    />
                                </div>
                            </div>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>Número de contenedores</label>
                                </div>
                                <div className='value-container' >
                                    <input
                                        type='text'
                                        value={data.containerNumber}
                                        onChange={(e) => setData({...data, containerNumber: e.target.value})}
                                        className='input-generic'
                                    />
                                </div>
                            </div>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>Naviera</label>
                                </div>
                                <div className='value-container' >
                                    <input 
                                        type='number' 
                                        value={data.naviera}
                                        onChange={(e) => setData({...data, naviera: e.target.value})}
                                        className='input-generic'
                                    />
                                </div>
                            </div>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>Cantidad de contenedores</label>
                                </div>
                                <div className='value-container' >
                                    <input 
                                        type='number' 
                                        value={data.containerQuantity}
                                        onChange={(e) => setData({...data, containerQuantity: e.target.value})}
                                        className='input-generic'
                                    />
                                </div>
                            </div>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>Equipo</label>
                                </div>
                                <div className='value-container' >
                                    <select className='input-select' value={data.team} onChange={(e) => {
                                        setData({...data, team: parseInt(e.target.value)});
                                    }}>
                                        <option className='option-selected' value={null} selected hidden>Seleccionar equipo</option>
                                        {
                                            containerType &&
                                            containerType.map(ele => {
                                                return <option value={ele.id}> {ele.value}</option>
                                            })
                                        }
                                    </select >
                                </div>
                            </div>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>TEUS</label>
                                </div>
                                <div className='value-container' >
                                    <input 
                                        type='number' 
                                        value={data.teus}
                                        onChange={(e) => setData({...data, teus: e.target.value})}
                                        className='input-generic'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='input-container-data record-data'>
                        <h3>Otros datos</h3>
                        <div className='input-column-container records'>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>ETD</label>
                                </div>
                                <div className='value-container'>
                                    <input 
                                        className="input-generic date"
                                        type="date"
                                        placeholder="Agregar..."
                                        value={data.etd}
                                        onChange={(e) => setData({...data, etd: e.target.value})}
                                    />
                                </div>
                            </div>                    
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>ETA</label>
                                </div>
                                <div className='value-container'>
                                    <input 
                                        className="input-generic date"
                                        type="date"
                                        placeholder="Agregar..."
                                        value={data.eta}
                                        onChange={(e) => setData({...data, eta: e.target.value})}
                                    />
                                </div>
                            </div>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>Fecha del Zarpe</label>
                                </div>
                                <div className='value-container'>
                                    <input 
                                        className="input-generic date"
                                        type="date"
                                        placeholder="Agregar..."
                                        value={data.sailDate}
                                        onChange={(e) => setData({...data, sailDate: e.target.value})}
                                    />
                                </div>
                            </div>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>Fecha del Llegada al puerto</label>
                                </div>
                                <div className='value-container'>
                                    <input 
                                        className="input-generic date"
                                        type="date"
                                        placeholder="Agregar..."
                                        value={data.portArriveDate}
                                        onChange={(e) => setData({...data, portArriveDate: e.target.value})}
                                    />
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div className='footer-form-generic-type records'>
                    <Button onClick={changeStage} className={'button-back'} style={{width: '28%'}}>CANCELAR</Button>
                    <Button onClick={changeStage} className={'button-generate'} style={{width: '28%'}}>GUARDAR</Button>
                    <Button onClick={changeStage} className={'button-next'} srcicon={arrow} style={{width: '30%'}}>SIGUIENTE</Button>
                </div>
            </div>
    )
}

export default RecordsData;
