import React, { useState } from 'react'
import { useEffect } from 'react';
// import './ClientRegister.scss';
import { GlobalFilter } from '../../../../components/atoms';
import '../../../../styles/stylesSteppers.scss';
import './AssignCustomsAgent.scss';

export const AssignCustomsAgent = ({customsAgents = [''],setCustomAgentAssigned, customAgentAssigned}) => {

    const [ searchObj, setSearchObj ] = useState({textSearch: ''});
    const noAvailableAgents = [{tradename: 'No hay clientes disponibles', id:0}];

    const [availableAgents, setAvailableAgents] = useState()
    useEffect(() => {
        let customAgentFilter = [];
        let search = searchObj.textSearch;
        customsAgents.map(ele => {
            if(ele.agentname.toLowerCase().includes(search.toLowerCase())){
                customAgentFilter.push(ele)
            }
        })
        if (customAgentFilter && customAgentFilter.length > 0) setAvailableAgents(customAgentFilter);
        else setAvailableAgents(noAvailableAgents);
    }, [searchObj])

    return (
        <div className='assignCustomsAgent' style={{width: '40vw', height: '12em', display: 'flex', justifyContent: 'center'}}>
            <div 
                className='input-container-data' 
                style={
                    {width: '95%',
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '10px',
                    margin: '20px 0'}}
            >
            <div className='filter-container'>
                <GlobalFilter 
                    filter={searchObj}
                    setFilter={setSearchObj}
                    showTextSearch
                    showDateRangeSearch={false}
                    placeholderSearch={'Buscar agente...'}
                />
            </div>
                <div className='label-container'>
                    <label className='container-label'>Asigna agentes aduanales</label>
                </div>
                <div className='value-container' >
                <select className='input-select' value={customAgentAssigned} onChange={(e) => {
                    setCustomAgentAssigned(parseInt(e.target.value));
                }}>
                    <option className='option-selected' value={null} selected hidden>Seleccionar agente aduanal</option>
                    {
                        availableAgents &&
                        availableAgents.map((ele, i) => {
                            return <option key={i} value={ele.id}> {ele.agentname ? ele.agentname : ele.person ? ele.person.firstName + '' + ele.person.firstLastName : 'Ningún nombre registrado'}</option>
                        })
                    }
                </select >
                </div>
            </div>
        </div>
    )
}
