/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import docIcon from '../../../assets/images/navieras/pdfIcon.png';
import seeIcon from '../../../assets/images/navieras/seeButton.png';
import _ from 'lodash';
import './UploadFile.scss'

export const SeeUploadedFiles = (props) => {
    const deleteFile = () => {
        console.log('ver archivo')
        }
        console.log(props.element)

    // const deleteFile = () => {
    //     let arrayCopy = JSON.parse(JSON.stringify(arrayComplete));
    //     arrayCopy.map( (el) => {
    //         if(files.id === el.id){
    //                 el.file = null;
    //                 el.watch = false;
    //         }
    //     })
    //     setFiles(arrayCopy);
    //     toggleModal();
    // }
    
    return (
        <div className='uploadFileCardContainer'>
            <div  className={'uploadedFileArea'}>
                {
                    <>
                    <div className='deleteFile'> <img src={seeIcon} alt="" onClick={() => deleteFile()}/>   </div>
                    <div className='uploadedFileView'>
                        <img src={docIcon} alt="" />
                    </div>
                    <div className='uploadedFileText'>
                        <p>{_.get(props, 'element.name', 'Archivo')}</p>
                    </div>
                    </>
                }
            </div>
        </div>
    )
}
