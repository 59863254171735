/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button } from '../../components/atoms';    
import { upsertProviderAction } from '../../redux/actions/providerAction';
import '../../styles/stylesSteppers.scss';
import './ProviderPrincipalData.scss';
import arrow from '../../assets/images/navieras/arrowButton.png'
import './ProviderPrincipalDataForm.scss';
import { getAllCountriesAction } from '../../redux/actions/addressAction';
import { useParams } from 'react-router-dom';

export const ProviderContactData = ({
    stepper, 
    setStepper, 
    toggleModal, 
    formData,
    setFormData,
    type,
    setProviderData,
    providerData,
    optionExtra = null,
}) => {

    const { mode } = useParams();
    const dispatch = useDispatch();
    const [ isDisabled, setIsDisabled ] = useState(mode === 'ver' ? true : false);
    
    const contacnameDefault = _.get(formData, 'contacname', '')
    const phoneDefault = _.get(formData, 'phone', '')
    const emailDefault = _.get(formData, 'email', '')
    const providersTurnDefault = _.get(formData, '', '')
    const payFormDefault = _.get(formData, 'paymentmethods', '')
    console.log("🚀 ~ file: ProviderContactData.js:37 ~ payFormDefault", payFormDefault)
    const commentsDefault = _.get(formData, 'comment', '')
    
    const [ paymentmethods, setPaymentmethods ] = useState(payFormDefault);
    useEffect(() => {
        console.warn(paymentmethods)
    }, [paymentmethods])
    
    const defaultValues = {
        contacname: contacnameDefault,
        phone : phoneDefault,
        email : emailDefault,
        providersTurn : providersTurnDefault,
        paymentmethods : payFormDefault /* && payFormDefault == 1 ? payForms[0] : payForms[1] */,
        comments : commentsDefault,
    };

    const methods = useForm({ defaultValues: defaultValues});
    const { register, handleSubmit, control, reset, formState: { errors }, } = methods;

    const countries = useSelector(state => state.address.countries.data);
    const changeStage = ()=>{
        console.log('cambio de pestania')
        // setProviderData({
        //     ...providerData,
        //     ...formData})
        setStepper(stepper+1);
    }
    const changeStageBack = ()=>{
        console.log('cambio de pestania')
        setStepper(stepper-1)
    }
    const idQuotation = 'UP0001';
    const payForms = [
        {
            id: 1,
            name: 'Tarjeta de credito'
        },
        {
            id: 2,
            name: 'Efectivo'
        },
    ]

    const onSubmit = async ({ 
        contacname, 
        phone, 
        email, 
        providersTurn, 
        payForm, 
        comments 
    }) => {
        
        const data = {
            ...formData,
            contacname,
            phone: parseInt(phone),
            email,
            providersTurn,
            paymentmethods: parseInt(paymentmethods),
            comments,
        }
        setFormData(data);
        console.warn(data)
        changeStage();
    }

    return (
        <>
            <form className='main-container-generic-type-quotation' onSubmit={handleSubmit(onSubmit)}>
                <div className={`quotation-options-container ${optionExtra ?'optionExtra' :''}`}>
                    <div className='header-form-generic-type'>
                        <h2>Datos principales</h2>
                        <h3 className='id'>ID: {idQuotation}</h3>
                    </div>
                    <div className='body-form-generic-type-data'>
                    <div className='quotation-form-general-container'>
                            <div className='value-container-principal-data provider'>
                                <div className='input-container provider'>
                                    <div className='label-container'>
                                        <label className='container-label'>Nombre de contacto*</label>
                                    </div>
                                    <div className='value-container'>
                                        <input 
                                            className="input-generic" 
                                            type="text" 
                                            disabled={isDisabled}
                                            placeholder="Agregar..."
                                            {...register('contacname')}
                                        />
                                    </div>
                                </div>
                                <div className='input-container provider'>
                                    <div className='label-container'>
                                        <label className='container-label'>Teléfono*</label>
                                    </div>
                                    <div className='value-container'>
                                        <input  
                                            className="input-generic" 
                                            type="text"
                                            disabled={isDisabled}
                                            placeholder="Agregar..."
                                            {...register('phone')}                                        
                                        />
                                    </div>
                                </div>
                                <div className='input-container provider'>
                                    <div className='label-container'>
                                        <label className='container-label'>Correo*</label>
                                    </div>
                                    <div className='value-container'>
                                        <input  
                                            className="input-generic" 
                                            type="text"
                                            disabled={isDisabled}
                                            placeholder="Agregar..."
                                            {...register('email')}
                                        />
                                    </div>
                                </div>
                                <div className='input-container provider'>
                                    <div className='label-container'>
                                        <label className='container-label'>Giro del proveedor*</label>
                                    </div>
                                    <div className='value-container'>
                                        <input  
                                            className="input-generic" 
                                            type="text"
                                            disabled={isDisabled}
                                            {...register('providersTurn')} 
                                            placeholder="Agregar..."
                                        />
                                    </div>
                                </div>
                                <div className='input-container'>
                                    <div className='label-container'>
                                        <label className='container-label'>Formas de pago*</label>
                                    </div>
                                    <div className='value-container' >
                                        <select required disabled={isDisabled} className='input-select' value={paymentmethods} onChange={(e) => setPaymentmethods(parseInt(e.target.value))}>
                                            <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                            {
                                                payForms &&
                                                payForms.map((ele, i) => {
                                                    return <option key={i} value={ele.id}> {ele.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className='input-container provider'>
                                    <div className='label-container'>
                                        <label className='container-label'>Comentarios</label>
                                    </div>
                                    <div className='value-container'>
                                        <textarea  
                                            className="input-generic textArea" 
                                            type="text"
                                            disabled={isDisabled}
                                            placeholder="Agregar..."
                                            {...register('comments')}                                 
                                        />
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer-form-generic-type' style={{justifyContent: 'space-between'}}>
                    <Button type='button' onClick={toggleModal ? toggleModal : changeStageBack} className={'button-back'} style={{width: '40%'}}>CANCELAR</Button>
                    <Button type='submit' className={'button-next'} srcicon={arrow} style={{width: '40%'}}>SIGUIENTE</Button>
                </div>
            </form>
        </>
    )
}

ProviderContactData.propTypes = {
    stepper: PropTypes.any,
    setStepper: PropTypes.func,
    toggleModal: PropTypes.any,
    setName: PropTypes.func,
    name: PropTypes.any,
    setTradeName: PropTypes.func,
    tradeName: PropTypes.any,
    setLogo: PropTypes.func,
    logo: PropTypes.any,
    register: PropTypes.func,
    handleSubmit: PropTypes.any,
    onSubmit: PropTypes.func,
}
