/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from './../../components/atoms';
import done from './../../assets/images/navieras/done-icon.png';
import './RecordClosure.scss';
import { RECORDS } from './../../routes/Paths';
import { useSelector } from 'react-redux';

const RecordClosure = ({closureDate = '00/00/0000', closureTime = '00:00 hrs'}) => {

    const admin = useSelector(state => state.user.user.person);

    const accept = () => { 
        history.push(RECORDS);
    }

    return (
        <div className='closure-record-container'>
            <div className='closure-record-image-container'>
                <img src={done}/>
            </div>
            <div className='closure-record-info-container'>
                <h1 className='closure-record-title'>EXPEDIENTE CERRADO CON ÉXITO</h1>
                <div className='text-info-container'>
                    <p className='closure-record-info'>{`Fecha de cierre: ${closureDate} - ${closureTime}`}</p>
                    <p className='closure-record-info'>{`Administrador: ${admin.firstName} ${admin.firstLastName}`}</p>
                </div>
                <Button type='button-next' onClick={accept} className={'button-'} style={{width: '65%'}}>ACEPTAR</Button>
            </div>
        </div>
    )
}

export default RecordClosure;
