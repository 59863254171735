import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '../../../../components/atoms';
// import './ClientRegister.scss';
import '../../../../styles/stylesSteppers.scss'
import arrow from '../../../../assets/images/navieras/arrowButton.png'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCountriesAction, getAllStatesAction } from '../../../../redux/actions/addressAction';
import _ from "lodash"

export const PrincipalDataClient = ({ stepper, setStepper, toggleModal, formData, setFormData, type }) => {
    console.log(formData);
    const dispatch = useDispatch();
    const { mode } = useParams();
    const [ isDisabled, setIsDisabled ] = useState(mode === 'ver' ? true : false);
    const creatorId = useSelector(state => state.user.user.id);
    
    const nameDefault = _.get(formData, 'person.firstName', '')
    const firstLastNameDefault = _.get(formData, 'person.firstLastName', '')
    const secondLastNameDefault = _.get(formData, 'person.secondLastName', '')
    const tradenameDefault = _.get(formData, 'tradename', '')
    const businessnameDefault = _.get(formData, 'businessname', '')
    const rfcDefault = _.get(formData, 'rfc', '')
    const phoneDefault = _.get(formData, 'person.primaryPhone', '')
    const emailDefault = _.get(formData, 'person.primaryEmail', '')
    const postcodeDefault = _.get(formData, 'postcode', '')
    const addressDefault = _.get(formData, 'direction', '')
    const countryIdDefault = _.get(formData, 'countryId.id' , null);
    const stateIdDefault = _.get(formData, 'stateId.id' , null);
    const zoneDefault = _.get(formData, 'zone', '');
    const countryNameDefault = _.get(formData, 'countryId.name' , null);
    const stateNameDefault = _.get(formData, 'stateId.name' , null);
    // const cityNameDefault = _.get(formData, '.city.name' , null);
    // const cityIdDefault = _.get(formData, 'city' , null);

    const defaultValues = {
        name : nameDefault,
        firstLastName : firstLastNameDefault,
        secondLastName : secondLastNameDefault,
        tradename : tradenameDefault,
        businessname : businessnameDefault,
        rfc : rfcDefault,
        phone : phoneDefault,
        email : emailDefault,
        zone: zoneDefault,
        postcode : postcodeDefault,
        address : addressDefault
    };

    
    // const cities = useSelector(state => state.address.cities.data);
    const [countrySelected, setCountrySelected] = useState(countryIdDefault);
    const [stateSelected, setStateSelected] = useState(stateIdDefault);
    // const [citySelected, setCitySelected] = useState(null);

    const methods = useForm({ defaultValues: defaultValues});
    const { register, handleSubmit, control, reset, formState: { errors }, } = methods;
    const [country, setClientType] = useState(_.get(formData, 'country', null));
    const [payForm, setPayForm] = useState(_.get(formData, 'paymentmethods', null));

    const countrys = [
        {
            id: 1,
            name: 'Mexico'
        },
        {
            id: 2,
            name: 'USA'
        },
    ]
    const payForms = [
        {
            id: 1,
            name: 'Tarjeta de credito'
        },
        {
            id: 2,
            name: 'Efectivo'
        },
    ]

    useEffect(() => {
        dispatch(getAllCountriesAction());
        if(countryIdDefault){
            dispatch(getAllStatesAction(
                {
                    where: {
                        countryId: countryIdDefault
                }}
                ));
        }
    }, [])
    const countries = useSelector(state => state.address.countries.data);
    const states = useSelector(state => state.address.states.data);
    
    const changeStage = ()=>{
        // console.log(country, 'cambio de pestania')
        setStepper(stepper + 1)
    }
    const changeStageBack = () => {
        // console.log(country, 'cambio de pestania')
        setStepper(stepper - 1)
    }
    // {name,firstLastName,secondLastName,tradename,rfc,phone,email}
    const onSubmit = async ({ name, firstLastName, secondLastName, tradename, businessname, rfc, phone, email, postcode, address, zone }) => {
        // console.log(formData)

        console.log('paises seleccionados');
        console.log(countrySelected);
        console.log(stateSelected);
        
        const data = {
            ...formData,
            tradename,
            businessname: businessname ? businessname : '',
            rfc,
            country: countrySelected,
            state: stateSelected,
            zone,
            direction: address,
            postcode,
            creatorId: creatorId,
            paymentmethods: payForm,
            person: {
                firstName: name,
                // secondName: ,
                firstLastName,
                secondLastName,
                primaryPhone: parseInt(phone),
                primaryEmail: email,
            }
        }
        if(type !=='create'){
            delete data.countryId;
            delete data.stateId;
        }
        // debugger
        setFormData(data);
        // console.log(data);
        changeStage()
    }
    return (
        <form className='main-container-generic-type' onSubmit={handleSubmit(onSubmit)}>
            <div className='header-form-generic-type'>
                <h2>Datos principales</h2>
            </div>
            <div className='body-form-generic-type-data body-form-generic-type-client'>
                {/* -- */}

                {!type &&
                    <div className='input-container'>
                        <div className='label-container'>
                            <label className='container-label'>Nombre(s)*</label>
                        </div>
                        <div className='value-container'>
                            <input disabled={isDisabled} required control={control} name={'name'} {...register('name')} className="input-generic" type="text" placeholder="Nombre(s)" />
                        </div>
                    </div>
                }

                {!type &&
                    <div className='input-container'>
                        <div className='label-container'>
                            <label className='container-label'>Apellido paterno*</label>
                        </div>
                        <div className='value-container'>
                            <input disabled={isDisabled} required control={control} name={'firstLastName'} {...register('firstLastName')} className="input-generic" type="text" placeholder="Apellido paterno" />
                        </div>
                    </div>
                }

                {!type &&
                    <div className='input-container'>
                        <div className='label-container'>
                            <label className='container-label'>Apellido materno*</label>
                        </div>
                        <div className='value-container'>
                            <input disabled={isDisabled} required control={control} name={'secondLastName'} {...register('secondLastName')} className="input-generic" type="text" placeholder="Apellido materno" />
                        </div>
                    </div>
                }
                <div className='input-container'>
                    <div className='label-container'>
                        <label className='container-label'>Nombre comercial*</label>
                    </div>
                    <div className='value-container'>
                        <input disabled={isDisabled} required control={control} name={'tradename'} {...register('tradename')} className="input-generic" type="text" placeholder="Nombre comercial" />
                    </div>
                </div>
                {
                    // type &&
                    <div className='input-container'>
                        <div className='label-container'>
                            <label className='container-label'>Razón social*</label>
                        </div>
                        <div className='value-container'>
                            <input disabled={isDisabled} required control={control} name={'businessname'} {...register('businessname')} className="input-generic" type="text" placeholder="Razón social" />
                        </div>
                    </div>
                }
                <div className='input-container'>
                    <div className='label-container'>
                        <label className='container-label'>RFC*</label>
                    </div>
                    <div className='value-container'>
                        <input disabled={isDisabled} required control={control} name={'rfc'} {...register('rfc')} className="input-generic" type="text" placeholder="RFC" />
                    </div>
                </div>
                <div className='input-container'>
                    <div className='label-container'>
                        <label className='container-label'>Telefono*</label>
                    </div>
                    <div className='value-container'>
                        <input disabled={isDisabled} requiered control={control} name={'phone'} {...register('phone')} className="input-generic" type="text" placeholder="Telefono" />
                    </div>
                </div>
                <div className='input-container'>
                    <div className='label-container'>
                        <label className='container-label'>Correo*</label>
                    </div>
                    <div className='value-container'>
                        <input disabled={isDisabled} required control={control} name={'email'} {...register('email')} className="input-generic" type="text" placeholder="Correo" />
                    </div>
                </div>
                {/* -- */}
                <div className='input-container'>
                    <div className='label-container'>
                        <label disabled={isDisabled} className='container-label'>País*</label>
                    </div>
                    <div className='value-container' >
                    <select disabled={isDisabled} required className='input-select' control={control} value={countrySelected} onChange={(e) => {
                                setCountrySelected(parseInt(e.target.value));
                                dispatch(getAllStatesAction(
                                {where: {
                                        countryId: parseInt(e.target.value)
                                    }}
                                ));
                            }}>
                                <option className='option-selected' value={countryIdDefault} selected hidden> {countryIdDefault !== '' ? countryNameDefault : "Seleccionar país"}</option>
                                {
                                    _.get(countries, 'data') &&
                                    countries.data.map(ele => {
                                        return <option value={ele.id}> {ele.name}</option>
                                    })
                                }
                </select >
                    </div>
                </div>
                <div className='input-container'>
                    <div className='label-container'>
                        <label className='container-label'>Estado*</label>
                    </div>
                    <div className='value-container' >
                    <select disabled={isDisabled} required className='input-select' control={control} value={stateSelected} onChange={(e) => {
                    setStateSelected(parseInt(e.target.value));
                }}>
                    <option className='option-selected' value={stateIdDefault} selected hidden> {stateIdDefault !== '' ? stateNameDefault : "Seleccionar especialidad"}</option>
                    {
                        _.get(states, 'data') &&
                        states.data.map(ele => {
                            return <option value={ele.id}> {ele.name}</option>
                        })
                    }
                </select >
                    </div>
                </div>
                <div className='input-container'>
                    <div className='label-container'>
                        <label className='container-label'>Zona*</label>
                    </div>
                    <div className='value-container'>
                        <input disabled={isDisabled} required control={control} name={'zone'} {...register('zone')} className="input-generic" type="text" placeholder="Zona" />
                    </div>
                </div>
                <div className='input-container'>
                    <div className='label-container'>
                        <label className='container-label'>Código postal*</label>
                    </div>
                    <div className='value-container'>
                        <input disabled={isDisabled} required control={control} name={'postcode'} {...register('postcode')} className="input-generic" type="text" placeholder="Codigo postal" />
                    </div>
                </div>
                <div className='input-container'>
                    <div className='label-container'>
                        <label className='container-label'>Dirección*</label>
                    </div>
                    <div className='value-container'>
                        <input disabled={isDisabled} required control={control} name={'address'} {...register('address')} className="input-generic" type="text" placeholder="Dirección" />
                    </div>
                </div>
                <div className='input-container'>
                    <div className='label-container'>
                        <label className='container-label'>Formas de pago*</label>
                    </div>
                    <div className='value-container' >
                        <select disabled={isDisabled} required className='input-select' value={payForm} onChange={(e) => {
                            setPayForm(parseInt(e.target.value));
                        }}>
                            <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                            {
                                payForms &&
                                payForms.map((ele, i) => {
                                    return <option key={i} value={ele.id}> {ele.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className='footer-form-generic-type' style={{ justifyContent: 'space-between' }}>
                <Button type='button' onClick={toggleModal ? toggleModal : changeStageBack} className={'button-back'} style={{ width: '40%' }}>CANCELAR</Button>
                <Button type='submit' className={'button-next'} srcicon={arrow} style={{ width: '40%' }}>SIGUIENTE</Button>
                {/* <Button type='button' onClick={changeStage} className={'button-next'} srcicon={arrow} style={{width: '40%'}}>SIGUIENTE</Button> */}
            </div>
        </form>
    )
}
