/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button } from '../../../components/atoms';
import './RecordChanges.scss';

const RecordChanges = ({toggleModal}) => {

    const [ changes, setChanges ] = useState('');

    const saveChanges = () => {
        console.warn(changes);
        toggleModal();
    }

    return (
        <div className='changes-modal-container'>
            <div className='changes-title-container'>
                <h3>Cambios</h3>
                <h4>Capture textualmente los cambios:</h4>
            </div>
            <div className='changes-reason'>
                <input 
                    className='reasons-changes-input' 
                    type='textarea' 
                    placeholder='Escriba aquí sus cambios...'
                    value={changes}
                    onChange={(e) => setChanges(e.target.value)}
                />
            </div>
            <div className='footer-form-generic-type modal' style={{justifyContent: 'space-between'}}>
                <Button type='button' onClick={toggleModal} className={'button-back'} style={{width: '40%'}}>CANCELAR</Button>
                <Button type='submit' onClick={saveChanges} className={'button-next'} style={{width: '40%'}}>GUARDAR</Button>
            </div>
        </div>
    )
}

export default RecordChanges;
