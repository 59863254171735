import React, { useState } from "react";
import _ from "lodash";
import { Button } from "../../../components/atoms";

const SeeDataPrinci = (props) => {
  const currentData = _.get(props, "data", null);

  const [disabled, setDisabled] = useState(true);

  const [firstName, setFirstName] = useState(
    !_.isNil(currentData.person.firstName) ? currentData.person.firstName : ""
  );
  const [firstLastName, setFirstLastName] = useState(
    !_.isNil(currentData.person.firstLastName)
      ? currentData.person.firstLastName
      : ""
  );
  const [email, setEmail] = useState(
    !_.isNil(currentData.user.email) ? currentData.email : "Sin información"
  );

  console.log(currentData)

  const [zipCode, setZipCode] = useState(
    !_.isNil(currentData.user.address.zipCode)
      ? currentData.user.address.zipCode
      : "Sin información"
  );

  const [nickname,setNickName] = useState(
    !_.isNil(currentData.nickname)
      ? currentData.nickname
      : "Sin información"
  );

  const [street1, setStreet] = useState(
    !_.isNil(currentData.user.address.street1)
      ? currentData.user.address.street1
      : "Sin información"
  );

  const [primaryPhone, setPrimaryPhone] = useState(
    !_.isNil(currentData.person.primaryPhone)
      ? currentData.person.primaryPhone
      : "Sin información"
  );

  const [outdoorNumber, setOutdoorNumber] = useState(
    !_.isNil(currentData.user.address.outdoorNumber)
      ? currentData.user.address.outdoorNumber
      : "Sin información"
  );

  const [indoorNumber, setIndoorNumber] = useState(
    !_.isNil(currentData.user.address.indoorNumber)
      ? currentData.user.address.indoorNumber
      : "Sin información"
  );

  const changeStage = () => {
    // console.log(country, 'cambio de pestania')
    props.setStepper(props.stepper + 1);
  };

  return (
    <div>
      <div className="header-form">
        <h2>Datos principales</h2>
      </div>
      <div className="body-form">
        <h2 className="TitleDetailsView">Información personal</h2>
        <div className="FirstSection">
          <div className="InputContain">
            <div className="label-container">
              <label style={{color:"#ffff", fontSize:"1vw"}}>Nombre(s)</label>
            </div>
            <div className="value-container">
              <input
                disabled={disabled}
                name={"name"}
                value={firstName}
                className="input-generic"
                type="text"
                placeholder="Nombre(s)"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
          </div>
          <div className="InputContain">
            <div className="label-container">
              <label style={{color:"#ffff", fontSize:"1vw"}}>Apellidos</label>
            </div>
            <div className="value-container">
              <input
                disabled={disabled}
                name={"name"}
                value={firstLastName}
                className="input-generic"
                type="text"
                placeholder="Nombre(s)"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="SecondSection">
          <div className="InputContain">
            <div className="label-container">
              <label style={{color:"#ffff", fontSize:"1vw"}}>Nickname</label>
            </div>
            <div className="value-container">
              <input
                disabled={disabled}
                name={"firstName"}
                value={nickname}
                className="input-generic"
                type="text"
                placeholder="Nombre(s)"
                onChange={(e) => setNickName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <h2 className="TitleDetailsView">Información de contacto</h2>
        <div className="ThridSection">
          <div className="InputContain">
            <div className="label-container">
              <label style={{color:"#ffff", fontSize:"1vw"}}>Correo</label>
            </div>
            <div className="value-container">
              <input
                disabled={disabled}
                name={"email"}
                value={email}
                className="input-generic"
                type="text"
                placeholder="Nombre(s)"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
          </div>
          <div className="InputContain">
            <div className="label-container">
              <label style={{color:"#ffff", fontSize:"1vw"}}>Teléfono</label>
            </div>
            <div className="value-container">
              <input
                disabled={disabled}
                name={"primaryPhone"}
                value={primaryPhone}
                className="input-generic"
                type="text"
                placeholder="Nombre(s)"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="FourSection">
          <div className="InputContain">
            <div className="label-container">
              <label style={{color:"#ffff", fontSize:"1vw"}}>Codigo postal</label>
            </div>
            <div className="value-container">
              <input
                disabled={disabled}
                name={"zipCode"}
                value={zipCode}
                className="input-generic"
                type="text"
                placeholder="Nombre(s)"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
          </div>
          <div className="InputContain">
            <div className="label-container">
              <label style={{color:"#ffff", fontSize:"1vw"}}>Calle</label>
            </div>
            <div className="value-container">
              <input
                disabled={disabled}
                name={"street"}
                value={street1}
                className="input-generic"
                type="text"
                placeholder="Nombre(s)"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="FiveSection">
          <div className="InputContain">
            <div className="label-container">
              <label style={{color:"#ffff", fontSize:"1vw"}}>No. exterior</label>
            </div>
            <div className="value-container">
              <input
                disabled={disabled}
                name={"outdoorNumber"}
                value={outdoorNumber}
                className="input-generic"
                type="text"
                placeholder="Nombre(s)"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
          </div>
          <div className="InputContain">
            <div className="label-container">
              <label style={{color:"#ffff", fontSize:"1vw"}}>No. interior (opcional)</label>
            </div>
            <div className="value-container">
              <input
                disabled={disabled}
                name={"indoorNumber"}
                value={indoorNumber}
                className="input-generic"
                type="text"
                placeholder="Nombre(s)"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div
            className="footer-form-generic-type operative"
              >
                <Button
                  type="submit"
                  onClick={changeStage}
                  // className={"buttonNextStep"}
                  style={{ width: "40%", backgroundColor:"#5169C3" }}
                >
                  SIGUIENTE
                </Button>
              </div>
        </div>
    </div>
  );
};

export default SeeDataPrinci;
