import React, { useState } from "react";
import "../../styles/stylesSteppers.scss";
import "../ProvidersDashboard/ProviderPrincipalData.scss";
import "./OperativePrincipalDataForm.scss";
import "../../components/templates/BaseLayout/Profile/ProfileFormStyles.scss";
import "./OperativeDataBank.scss";
import { Button } from "../../components/atoms";

const OperativeDataBank = (props,{ stepper, setStepper,validationStep }) => {
  const [validation, setValidation] = useState(true)

  const validationSend = () => {
    props.validationStep(true)
  }




  return (
    <div className="main-container-generic-type-quotation operative">
      <div className="headerIdentity">
        <h2>Datos de tarjeta bancaria</h2>
      </div>
      <div className="ContainerInputDataBank">
        <div className="InputContainer">
          <div className="LeftContainer">
            <label>Nombre de la cuenta</label>
            <input className="input-generic "/>
          </div>
          <div className="RightContainer">
            <label>Número de la tarjeta</label>
            <input className="input-generic "/>
          </div>
        </div>
        
      </div>
      <div className="BtnContainer">
          <Button
            onClick={validationSend}
            style={{ width: "40%", backgroundColor:"#5169C3" }}
          >
            ANTERIOR
          </Button>
          <Button
               className={"buttonNextStep"}
            onClick={validationSend}
            style={{ width: "40%", backgroundColor:"#5169C3" }}
          >
            REGISTRAR
          </Button>

        </div>
    </div>
  );
};

export default OperativeDataBank;
