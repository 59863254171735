/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button } from './../../../components/atoms';
import '../../../styles/stylesSteppers.scss';
import arrow from './../../../assets/images/navieras/arrowButton.png'
import { useHistory } from 'react-router-dom';
import './RecordsData.scss';

const PaymentData = ({ recordId = '002', setStepper, stepper }) => {
    const [ status, setStatus ]= useState(null);
    const history = useHistory();
    const [ creditDays, setCreditDays ] = useState('15 días');
    const [ payLimitDate, setPayLimitDate] = useState('2022-11-15')
    const [ pays, setPays ] = useState({
        pay: null,
        navierasPay: null,
        agentPay: null,
        localCharges: null,
    });
    const [ payDates, setPayDates ] = useState({
        payDate: '',
        navierasPayDate: '',
        agentPayDate: '',
        localChargesDate: '',
    });


    const changeStage = ()=>{
        history.push({
                state: {clientType: status},
            });
        setStepper(stepper+1);
        console.warn(pays, payDates);
    }

    return (
        <div className='main-container-generic-type'>
            <div className='header-form-generic-type'>
                <h2>Datos de pago</h2>
                <h3>ID: {recordId}</h3>
            </div>
            <div className='body-form-generic-type-data record-data' style={{height: '45vh'}}>
                <div className='input-row-container records-payment-data'>
                    <div className='credit-left-container'>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Días de crédito</label>
                            </div>
                            <div className='value-container arrival'>
                                <input disabled value={creditDays} type='text' className='input-generic date'/>
                                <p>*Esta información es un dato que se encontra en los datos principales del cliente.</p>
                            </div>
                        </div>
                    </div>
                    <div className='input-container'>
                        <div className='label-container'>
                            <label className='container-label'>Fecha límite de pago</label>
                        </div>
                        <div className='value-container'>
                            <input disabled value={payLimitDate} type='date' className='input-generic date'/>
                        </div>
                    </div>
                </div>
                <div className='input-row-container'>
                    <div className='title-container'><h3>Monto de pagos</h3></div>
                    <div className='title-container'><h3>Fechas de pagos</h3></div>
                    <div className='input-container'>
                        <div className='label-container'>
                            <label className='container-label'>Pago</label>
                        </div>
                        <div className='value-container' >
                            <input value={pays.pay} onChange={(e) => setPays({...pays, pay: e.target.value})} className="input-generic" type="number" min={0} placeholder="Agregar..."/>
                        </div>
                    </div>
                    <div className='input-container'>
                        <div className='label-container'>
                            <label className='container-label'>Fecha de pago</label>
                        </div>
                        <div className='value-container' >
                            <input value={payDates.payDate} onChange={(e) => setPayDates({...payDates, payDate: e.target.value})} type='date' className='input-generic date'/>
                        </div>
                    </div>
                    <div className='input-container'>
                        <div className='label-container'>
                            <label className='container-label'>Pago de naviera</label>
                        </div>
                        <div className='value-container'>
                            <input value={pays.navierasPay} onChange={(e) => setPays({...pays, navierasPay: e.target.value})} className="input-generic" type="number" min={0} placeholder="Agregar..."/>
                        </div>
                    </div>
                    <div className='input-container'>
                        <div className='label-container'>
                            <label className='container-label'>Fecha de pago de naviera</label>
                        </div>
                        <div className='value-container'>
                            <input value={payDates.navierasPayDate} onChange={(e) => setPayDates({...payDates, navierasPayDate: e.target.value})} type='date' className='input-generic date'/>
                        </div>
                    </div>
                    <div className='input-container'>
                        <div className='label-container'>
                            <label className='container-label'>Pago al agente</label>
                        </div>
                        <div className='value-container' >
                            <input value={pays.agentPay} onChange={(e) => setPays({...pays, agentPay: e.target.value})} className="input-generic" type="number" min={0} placeholder="Agregar..."/>
                        </div>
                    </div>
                    <div className='input-container'>
                        <div className='label-container'>
                            <label className='container-label'>Fecha de pago al agente</label>
                        </div>
                        <div className='value-container' >
                            <input value={payDates.agentPayDate} onChange={(e) => setPayDates({...payDates, agentPayDate: e.target.value})} type='date' className='input-generic date'/>
                        </div>
                    </div>
                    <div className='input-container'>
                        <div className='label-container'>
                            <label className='container-label'>Cargos locales</label>
                        </div>
                        <div className='value-container' >
                            <input value={pays.localCharges} onChange={(e) => setPays({...pays, localCharges: e.target.value})} className="input-generic" type="number" min={0} placeholder="Agregar..."/>
                        </div>
                    </div>
                    <div className='input-container'>
                        <div className='label-container'>
                            <label className='container-label'>Fecha de cargos locales</label>
                        </div>
                        <div className='value-container' >
                            <input value={payDates.localChargesDate} onChange={(e) => setPayDates({...payDates, localChargesDate: e.target.value})} type='date' className='input-generic date'/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-form-generic-type records'>
                <Button onClick={changeStage} className={'button-back'} style={{width: '28%'}}>CANCELAR</Button>
                <Button onClick={changeStage} className={'button-generate'} style={{width: '28%'}}>GUARDAR</Button>
                <Button onClick={changeStage} className={'button-next'} srcicon={arrow} style={{width: '30%'}}>SIGUIENTE</Button>
            </div>
        </div>
    )
}

export default PaymentData;
