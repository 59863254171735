/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../components/atoms';
import './Quotation.scss';
import '../../styles/stylesSteppers.scss';
import arrow from '../../assets/images/navieras/arrowButton.png'
import { useForm } from 'react-hook-form';
import AerialQuotationForm from './AerialQuotationForm';
import LandQuotationForm from './LandQuotationForm';
import MaritimeFCLQuotationForm from './MaritimeFCLQuotationForm';
import dropdown from '../../assets/images/navieras/icon-dropdown.png';
import MaritimeLCLQuotationForm from './MaritimeLCLQuotationForm';
import { useEffect } from 'react';

export const PrincipalDataQuotation = ({
    stepper, 
    setStepper, 
    toggleModal,
    modality,
    formData,
    setFormData,
    clientPorts,
    countriesSelect
}) => {
    const methods = useForm({ defaultValues: defaultValues});
    const defaultValues = {
        // name : '',
    };
    const { register, handleSubmit, control, reset, formState: { errors }, } = methods;
    const [ clientType, setClientType ] = useState(null);
    
    
    let modalitySelected;
    console.log('desde principal data    ');
    console.log(clientPorts);
    
    

    if (modality == 1) modalitySelected = 'Aérea';
    else if (modality == 2) modalitySelected = 'Terrestre';
    else if (modality == 3) modalitySelected = 'Marítimo FCL';
    else if (modality == 4) modalitySelected = 'Marítimo LCL';

    const date = '17/09/22';
    const [ principalDataAerialForm, setPrincipalDataAerialForm ] = useState(
        {
            originCountryId: '',
            originStateId: '',
            destinyCountryId: '',
            destinyStateId: '',
            invoice: '',
            name: '',
            bussines: '',
            modality: '',
            delivery: '',
            cbms: '',
            weight: '',
            factor: '',
            validity: '',
            total: '',
            originLocalCharges: '',
            originLandFreight: '',
            originSeaFreight: '',
            destinyLocalChargesBl: '',
            destinyLocalChargesCbm: '',
            destinyLandFreight: '',
            destinySeaFreight: '',
            coordination: '',
            agency: '',
            officeMx: '',
            insurance: ''
        }
    );
    const [ principalDataLandForm, setPrincipalDataLandForm ] = useState(
        {
            
            invoice: "",
            name: "",
            bussines: "",
            platform: "",
            service: "",
            validity: "",
            total: "",
            originCountryId: "",
            originStateId: "",
            destinyCountryId: "",
            destinyStateId: "",
            landFreight: "",
            overweight: "",
            maneuvers: "",
            dropOff: ""
        }
    );
    const [ principalDataMaritimeForm, setPrincipalDataMaritimeForm ] = useState(
        {
            invoice: "",
            name: "",
            bussines: "",
            intercom: "",
            modality: "",
            pickup: "",
            delivery: "",
            cbms: "",
            weight: "",
            factor: "",
            originCountryId: "",
            originStateId: "",
            originPortId: "",
            originCharges: "",
            destinyCountryId: "",
            destinyStateId: "",
            destinyPortId: "",
            destinyCharges: "",
            destinySeaFreight: "",
            destinyLandFreight: "",
            destinyLocalChargesBl: "",
            destinyLocalChargesCbm: "",
            coordination: "",
            officeMx: null,
            agency: "",
            insurance: "",
            validity: "",
            total: "",
            lcl: "",
            fcl: "",
            other: "",
            containerType: "",
            containersNumber: "",
            delayDays: "",
            storageDays: "",
            destinyLocalChargesContainer: "",
            servicest3: "",
            aditionalService: "",
            officeMxTlp: null,
        }
    );
    const form = {
        1: principalDataAerialForm,
        2: principalDataLandForm,
        3: principalDataMaritimeForm,
        4: principalDataMaritimeForm,
    }
    const changeStage = ()=>{
        console.log(clientType, 'data final')
        // setStepper(stepper+1)
        // console.log(principalDataAerialForm);
        let dataForm = form[formData.modalityTypeId]
        console.log(form)
        console.log(dataForm);
        const datos = { 
            ...formData,
            modalityTypeId : formData.modalityTypeId!==4 ? formData.modalityTypeId : 3,
            quotationData: {
                ...dataForm
            }
        }
        setFormData(datos);
        // console.log(datos)
        setStepper(stepper+1)
    }
    const changeStageBack = ()=>{
        console.log(clientType, 'cambio de pestania')
        setStepper(stepper-1)
    }
    const [quotationData, setQuotationData] = useState();

    
  return (
    <form className='main-container-generic-type'>
        <div className='header-form-generic-type'>
            <h2>Datos de cotización</h2>
        </div>
        <div className='title-modality-container'>
            <div className='header-data-container'>
                <div className='label-container'>
                    <label className='modality-title'>Modalidad:</label>
                </div>
                <div className='value-container'>
                    <p className='container-label'>{modalitySelected}</p>
                </div>
            </div>
            <div className='header-data-container'>
                <div className='label-container'>
                    <label className='modality-title'>Fecha:</label>
                </div>
                <div className='value-container'>
                    <p className='container-label'>{date}</p>
                </div>
            </div>
        </div>
        <div className='body-form-generic-type-data' style={{display: 'flex', height: '45vh'}}>
            <div className='body-data'>
                {
                    modality == 1 &&
                    <AerialQuotationForm principalDataAerialForm={principalDataAerialForm} setPrincipalDataAerialForm={setPrincipalDataAerialForm}/>
                }
                {
                    modality == 2 && 
                    <LandQuotationForm principalDataLandForm={principalDataLandForm} setPrincipalDataLandForm={setPrincipalDataLandForm}/>
                }
                {   
                    modality == 3 && 
                    <MaritimeFCLQuotationForm countriesSelect={countriesSelect} clientPorts={clientPorts} principalDataMaritimeForm={principalDataMaritimeForm} setPrincipalDataMaritimeForm={setPrincipalDataMaritimeForm}/>
                }
                {   
                    modality == 4 && 
                    <MaritimeLCLQuotationForm countriesSelect={countriesSelect} clientPorts={clientPorts} principalDataMaritimeForm={principalDataMaritimeForm} setPrincipalDataMaritimeForm={setPrincipalDataMaritimeForm}/>
                }
            </div>
        </div>
        <div className='dropdown-container'>
            <img src={dropdown}/>
        </div>
        <div className='footer-form-generic-type' style={{justifyContent: 'space-between'}}>
            <Button type='button' onClick={toggleModal ? toggleModal : changeStageBack} className={'button-back'} style={{width: '40%'}}>CANCELAR</Button>
            <Button type='button' onClick={changeStage} className={'button-next'} srcicon={arrow} style={{width: '40%'}}>SIGUIENTE</Button>
        </div>
    </form>
  )
}

PrincipalDataQuotation.propTypes = {
    stepper: PropTypes.any,
    setStepper: PropTypes.func,
    toggleModal: PropTypes.any,
    modality: PropTypes.any,
}
