/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import { MenuItem } from "@material-ui/core";
import "../../styles/tableViewStyles.scss";
import "./Records.scss";
import {
  DataTable,
  ActionMenu,
  GlobalFilter,
  Button,
  Link,
} from "../../components/atoms";
import {
  getAllAdministratorsAction,
  deleteAdministratorAction,
} from "../../redux/actions/adminAction";
import Modal from "../../components/organisms/Modal/Modal";
import { CardContainer } from "../../components/molecules/CardContainer/CardContainer";
import { DeleteBody } from "../../components/molecules/CancelFormBody/DeleteBody";
import trash from "../../assets/images/navieras/trash-can.png";
import view from "../../assets/images/navieras/view.png";
import { RECORDS_CLOSURE, RECORDS_REGISTER } from "../../routes/Paths";
import { BaseModalBody } from "../../components/molecules/ModalBody/BaseModalBody";
import { closeRecordError } from "../../components/molecules/ModalBody/modalBodyVaribles";
import {
  deleteReportsAction,
  getAllReportTypeAction,
  getAllReportsAction,
} from "../../redux/actions/reportsAction";
import CustomDatePicker from "../../components/molecules/InputDate/InputDate";

const Records = (props) => {
  const history = useHistory();
  const [searchObj, setSearchObj] = useState({});
  const [searchCondition, setSearchCondition] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [itemSelected, setItemSelected] = useState(true);
  const [clientSelected, setClientSelected] = useState({});
  const [reportSelected, setReportSelected] = useState("");
  const [coinSelected, setCoinSelected] = useState("");
  const [startDate2,setStartDate2] = useState();
  const [endDate2,setEndDate] = useState();




  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllReportTypeAction());
    dispatch(getAllReportsAction());
  }, []);

  const reportType = useSelector((state) => state.reports.allReportType.data);
  const reports = useSelector((state) => state.reports.allReports.data);

  const toggleModal = ({ type, id }) => {
    if (openModal === true) {
      setItemSelected(null);
    }
    {
      setItemSelected({ type, id });
    }
    setOpenModal(!openModal);
  };

  useEffect(() => {
    props.getAllAdministratorsAction(searchCondition);
  }, [props.isSuccessDeleteAdmin, searchCondition]);

  const onClickActionMenu = (actionSelected, payload, data) => {
    switch (actionSelected) {
      case "view":
        history.push({
          pathname: `${RECORDS_REGISTER.replace(":id", payload.id)}`,
          state: { data: data },
        });
        break;
      case "deleteRecord":
       
        history.push(RECORDS_CLOSURE);
        break;
      default:
        break;
    }
  };

  const deleteUser = () => {
    dispatch(deleteReportsAction({ id: itemSelected.id }));
    setOpenModal(false);
    dispatch(getAllReportsAction());
  };

  const linkTo = () => {
    history.push("/dashboard/expedientes/nuevo");
  };

  const columns = React.useMemo(() => [
    {
      id: "record.id",
      Header: "ID",
      disableSortBy: true,
      disableFilters: true,
      filter: "text",
      width: "8%",
      accessor: (d) => ({
        id: _.get(d, "id", ""),
      }),
      Cell: function cell({ value: { id }, row }) {
        return <span>{`${id}`}</span>;
      },
    },
    {
      id: "record.name",
      Header: "Tipo",
      disableSortBy: true,
      disableFilters: true,
      filter: "text",
      width: "10%",
      accessor: (d) => ({
        firstName: _.get(d, "typeReport.displayText", ""),
      }),
      Cell: function cell({ value: { firstName }, row }) {
        return <span>{firstName}</span>;
      },
    },
    {
      id: "record.description",
      Header: "Fecha Inicio",
      disableSortBy: true,
      disableFilters: true,
      width: "10%",
      accessor: (d) => ({
        startDate: _.get(d, "startDate", ""),
      }),
      Cell: function cell({ value: { startDate }, row }) {
        return <spam>{startDate}</spam>;
      },
    },
    {
      id: "record.setSail",
      Header: "Fecha Fin",
      disableSortBy: true,
      disableFilters: true,
      width: "10%",
      accessor: (d) => ({
        endDate: _.get(d, "endDate", false),
      }),
      Cell: function cell({ value: { endDate }, row }) {
        return <spam>{endDate}</spam>;
      },
    },
    {
      id: "options.name",
      Header: "Acciones",
      filter: "text",
      disableFilters: true,
      width: "10%",
      accessor: (d) => ({
        id: d.id,
        startDate: _.get(d, "startDate", false),
        endDate: _.get(d, "endDate", false),
        data: d,
      }),
      Cell: function cell({ value: { id, data }, row }) {
        return (
          <ActionMenu id={id}>
            <MenuItem
              onClick={() => onClickActionMenu("view", { id }, { data })}
            >
              <img src={view} /> Ver
            </MenuItem>
            <MenuItem onClick={() => toggleModal({ type: "eliminar", id })}>
              <img src={trash} /> Eliminar
            </MenuItem>
          </ActionMenu>
        );
      },
    },
  ]);

  const getNewData = (searchCondition) => {
    // TODO: Restrucutrar vs Filtros*
    setSearchCondition(searchCondition);
  };

  const getPosts = () => {
    let value = document.getElementById("searchAdmin").value;
    let typeReport = document.getElementById("selectCoin").value;
    let startDate = startDate2;
    let endDate = endDate2;
    if (typeReport == "Filtrar por tipo") {
        typeReport = "";
    }


    const condition = {
      where: {
        startDate: startDate,
        endDate: endDate,
        id: value,
        typeReport: typeReport
      },
    };

    dispatch(getAllReportsAction(condition));
  };

  return (
    <>
      {openModal && itemSelected.type === "eliminar" && (
        <Modal isOpen={toggleModal} toggle={toggleModal}>
          <CardContainer
            btnLeftAction={toggleModal}
            btnRightAction={() => deleteUser()}
            body={<DeleteBody user={"reporte"} />}
          />
        </Modal>
      )}
      {openModal && itemSelected.type === "closeRecordError" && (
        <Modal isOpen={toggleModal} toggle={toggleModal}>
          <CardContainer
            noCancelButton
            btnRightAction={toggleModal}
            btnRightText={"ACEPTAR"}
            body={<BaseModalBody data={closeRecordError} />}
          />
        </Modal>
      )}
      <section className="ClientRecordAccountSection">
        <div className="ClientRecordAccountSection__container">
          <div className="TitleContainer">
            <h2 className="TitleUsers">Gestión de reportes | Listado</h2>
          </div>
          <div className="ClientRecordAccountSection__headerContainer">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <div className="filterContainer">
                <div className="searchPost" style={{ width: "100%" }}>
                  <input
                    className="searchPostInput"
                    placeholder="Buscar por ID"
                    id="searchAdmin"
                    style={{borderRadius: " 30px 30px 30px 30px", width:"90%"}}
                  />
                  {/* <div
                    className="btnSearch"
                    // onClick={getPosts}
                  >
                    <label>Buscar</label>
                  </div> */}
                </div>
              </div>
              <div style={{ display: "flex", width: "25%" }}>
                <select
                  className="input-genericRecord"
                  placeholder="Filtrar por tipo"
                  id={"selectCoin"}
                  name={"reportType"}
                  style={{ width: "90%" }}
                  value={reportType.displayText}
                  onChange={(e) => {
                    setReportSelected(e.target.value);
                  }}
                >
                  <option value={null} selected hidden>
                    {reportSelected != ""
                      ? reportType.displayText
                      : "Filtrar por tipo"}
                  </option>
                  {_.get(reportType, "data") &&
                    reportType.data.map((item, i) => {
                      return <option key={i}>{item.displayText}</option>;
                    })}
                </select>
              </div>
              <CustomDatePicker setValue={setStartDate2}/>
              <p
                style={{ color: "#ffff", marginLeft: "1%", marginRight: "1%" }}
              >
                a
              </p>
              <CustomDatePicker setValue={setEndDate}/>
              <Button className="btnRecord" onClick={getPosts}>
                Buscar
              </Button>
            </div>
          </div>
          <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button className="btnRecord" onClick={linkTo}>
                Nuevo
              </Button>
            </div>
          <div className="ClientRecordAccountSection__tableContainer">
            <DataTable
              columns={columns}
              // data={{...props.companies}}
              data={{
                data: _.get(reports, "data.rows", []),
                // totalRecords: _.get(props.admins, 'totalRecords', 0),
              }}
              getData={getNewData}
              searchObj={searchObj}
            />
          </div>
        </div>
      </section>
    </>
  );
};

Records.propTypes = {
  history: PropTypes.any,
  dispatch: PropTypes.any,
  getAllAdministratorsAction: PropTypes.any,
  admins: PropTypes.any,
  deleteAdministratorAction: PropTypes.any,
  isSuccessDeleteAdmin: PropTypes.any,
  value: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    admins: _.get(state, "admin.admins", {}),
    isSuccessDeleteAdmin: _.get(state, "admin.deleteAdmin.isSuccess", {}),
  };
}

const mapDispatchToProps = (dispatch) => ({
  getAllAdministratorsAction: (params) =>
    dispatch(getAllAdministratorsAction(params)),
  deleteAdministratorAction: (params) =>
    dispatch(deleteAdministratorAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Records);
