/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React from 'react';
import {
    MapContainer,
    TileLayer,
    Marker,
} from 'react-leaflet';
import { ChangeView } from './ChangeView';
import 'leaflet/dist/leaflet.css';

const MapView = ({ position, zoom = 2 }) => {

    // const [ center, setCenter] = useState(position);

    // useEffect(() => {
    //     setCenter(position)
    // }, [position]);

    return (
        <MapContainer 
            center={position} zoom={zoom} 
            style={{width: '100%', height: '100%'}} 
            className={'map-container'}
            scrollWheelZoom={false}
        >
            <ChangeView center={position} zoom={zoom} />
            <TileLayer 
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={{lat: `${position[0]}`, lng: `${position[1]}`}} />
        </MapContainer>
    )
}

export default MapView;
