/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from '../../../components/atoms/Button';
import plusIcon from '../../../assets/images/navieras/plus-icon.png';
import './AssingForms.scss';
import './AssignClient.scss';
import { CUSTOMER_CREATE } from '../../../routes/Paths';
import { GlobalFilter } from '../../atoms';

export const AssignClient = ({clients = [], setClientAssigned, clientAssigned}) => {
    const [ searchObj, setSearchObj ] = useState({textSearch: ''});
    const noAvailableClients = [{tradename: 'No hay clientes disponibles', id:0}];
    const [availableClients, setAvailableClients] = useState()

    useEffect(() => {
        console.warn('clientes disponibles:',clients)
    }, clients)


    useEffect(() => {
        let clientFilter = [];
        let search = searchObj.textSearch;
        _.get(clients, 'rows', []).map(ele => {
            if(ele && ele.tradename && ele.tradename.toLowerCase().includes(search.toLowerCase())){
                clientFilter.push(ele)
            }
        })
        if (clientFilter && clientFilter.length > 0) setAvailableClients(clientFilter);
        else setAvailableClients(noAvailableClients);
    }, [searchObj])

    const selectClient = (e) => {
        setClientAssigned(parseInt(e.target.value));
    }

    const history = useHistory();
    return (
        <div className='assign-form' style={{width: '45vw', height: '14em', display: 'flex', justifyContent: 'center'}}>
            <div className='assign-form-button-container'>
                <Button onClick={() => history.push(CUSTOMER_CREATE)} srcicon={plusIcon}>CREAR</Button>
            </div>
            <div className='filter-container'>
                <GlobalFilter 
                    filter={searchObj}
                    setFilter={setSearchObj}
                    showTextSearch
                    showDateRangeSearch={false}
                    placeholderSearch={'Buscar cliente...'}
                />
            </div>
            <div className='input-container-data-form'>
                <div className='label-container'>
                    <label className='container-label'>Seleccionar cliente</label>
                </div>
                <div className='value-container' >
                <select className='input-select' value={clientAssigned} onChange={(e) => {
                    selectClient(e);
                }}>
                    <option className='option-selected' value={''}  selected hidden>Seleccionar cliente</option>
                    {
                        availableClients &&
                        availableClients.map((ele, i) => {
                            return <option key={i} value={ele.id}>{ele.tradename}</option>
                        })
                    }
                </select>
                </div>
            </div>
        </div>
    )
}

AssignClient.propTypes = {
    clients: PropTypes.array,
}
