import React, { useState, useEffect } from 'react';
import { Button } from '../../../../../components/atoms';
import _ from 'lodash';
// import './ClientRegister.scss';
import '../../../../../styles/stylesSteppers.scss';
import arrow from '../../../../../assets/images/navieras/arrowButton.png';
import { useForm } from 'react-hook-form';
import Modal from '../../../../../components/organisms/Modal/Modal';
import { AssignCustomsAgent } from '../AssignCustomsAgent';
import { CardContainer } from '../../../../../components/molecules/CardContainer/CardContainer';
import seeButton from '../../../../../assets/images/navieras/seeButton.png';
import add from '../../../../../assets/images/navieras/plus-icon.png';
import { upsertCostumerCustomAgentAction } from '../../../../../redux/actions/CostumerCustomAgent';
import { addNotification } from '../../../../../redux/actions/notificationAction';
// import { getCustomAgentBelongsClient } from '../../../../../redux/actions/clientAction';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAssociation, getAllCustomAgentBelongsClient, getAllAvailableCustomAgentsAction } from '../../../../../redux/actions/customsAgentAction';
import { useParams } from 'react-router-dom';



export const CustomsAgents = ({ stepper, setStepper, toggleModal, formData, setFormData }) => {

    const [openAssignAgentModal, setOpenAssignAgentModal] = useState(false);
    // const [itemSelected, setItemSelected] = useState(true);
    const [customAgentAssigned, setCustomAgentAssigned] = useState(null);

    const { mode } = useParams();
    const [ isDisabled, setIsDisabled ] = useState(mode === 'ver' ? true : false);

    const methods = useForm({ defaultValues: defaultValues });
    const dispatch = useDispatch();
    const offset = 0;
    const limit = 2;
    const customAgents = useSelector(state => state.customAgent.getCustomAgentBelongsClient);
    const associationDelete = useSelector(state => state.customAgent.deleteAssociation);
    const customAgentsAvailable = useSelector(state => state.customAgent.customAgentsAvailableByClient);
    
    const clientId = formData.id;
    console.log('Forma', formData.id);

    const toggleAssignModal = () => {
        setOpenAssignAgentModal(!openAssignAgentModal);
    }

    const preAssingCustomAgent = async (id) => {
        const res =  await dispatch(getAllAvailableCustomAgentsAction({clientId: id}));
        if(res){
            toggleAssignModal(1, id);
        }else{
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de agentes aduanales, favor de intentarlo de nuevo',
            }));
        }
    }

    const defaultValues = {
        // name : '',
    };
    const { register, handleSubmit, control, reset, formState: { errors }, } = methods;
    const changeStage = () => {
        console.log('cambio de pestania')
        setStepper(stepper + 1)
    }
    const changeStageBack = () => {
        console.log('cambio de pestania')
        setStepper(stepper - 1)
    }
    // const datos = [
    //     {
    //         id: 1,
    //         name: 'Grupo Bolt'
    //     },
    //     {
    //         id: 2,
    //         name: 'Importadira GCR'
    //     },
    //     {
    //         id: 3,
    //         name: 'Mexbuy'
    //     },
    // ]

    useEffect(() => {
        if (formData) {
            dispatch(getAllCustomAgentBelongsClient({ clientId, offset, limit }))
        } if (associationDelete && associationDelete.isSuccess) {
            dispatch(getAllCustomAgentBelongsClient({ clientId, offset, limit }))
        }
    }, [associationDelete])


    const deleteAsign = (id) => {
        dispatch(deleteAssociation({ id }))
    }
    const seeCustomer = (id) => {
        console.log('se elimino este id:', id)
    }

    const assignAgent = async () =>{
        if (customAgentAssigned) {
            const data = { 
                idClient : clientId,
                idAgend: customAgentAssigned,
            }
            console.log(data)
            let res = await dispatch(upsertCostumerCustomAgentAction(data));
            if(res){
                setOpenAssignAgentModal(false);
                dispatch(getAllCustomAgentBelongsClient({ clientId, offset, limit }))
            } 
        } else {
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Debe seleccionar algún agente aduanal',
            }));
        }
    }

    return (
        <>
            {
                openAssignAgentModal /* && itemSelected[0] === 1 */ &&
                <Modal
                    isOpen={toggleAssignModal}
                    toggle={toggleAssignModal}
                >
                    <CardContainer btnLeftAction={toggleAssignModal} btnRightAction={()=>assignAgent()} title={'Agente Aduanal'} body={<AssignCustomsAgent customsAgents={!_.isEmpty(customAgentsAvailable) && _.get(customAgentsAvailable,'data.rows', [])} setCustomAgentAssigned={setCustomAgentAssigned} customAgentAssigned={customAgentAssigned} />}/>
                </Modal>
            }
            <form className='main-container-generic-type'>
                <div className='header-form-generic-type'>
                    <h2>Agentes aduanales asignados</h2>
                </div>
                <div className='body-form-generic-type-data'>
                    <div className='button-head-container'>
                        <Button disabled={isDisabled} style={{ margin: 0 }} srcicon={add} onClick={() => /* {toggleAssignModal(),  */preAssingCustomAgent(clientId)}>ASIGNAR</Button>
                    </div>
                    <div>
                        {
                            customAgents && customAgents.data && customAgents.data.associationClientAgent &&
                            customAgents.data.associationClientAgent.map((ele) => {
                                return (
                                    <div className='customer-agent-container'>
                                        <div className='customer-agent-row'>
                                            <h2>{ele.customagendclient.customsBroker}</h2>
                                            <img onClick={() => seeCustomer(ele.id)} src={seeButton} alt="" />
                                            <button disabled={isDisabled} onClick={() => deleteAsign(ele.id)} style={{ margin: '0' }}>QUITAR ASIGNACION</button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='footer-form-generic-type' style={{ justifyContent: 'space-between' }}>
                    <Button type='button' onClick={toggleModal ? toggleModal : changeStageBack} className={'button-back'} style={{ width: '40%' }}>CANCELAR</Button>
                    <Button type='button' onClick={changeStage} className={'button-next'} srcicon={arrow} style={{ width: '40%' }}>SIGUIENTE</Button>
                </div>
            </form>
        </>
    )
}
