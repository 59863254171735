/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button } from '../../components/atoms';
// import './Quotation.scss';
import './QuotationAssingProvider.scss';
import '../../styles/stylesSteppers.scss';
import arrow from '../../assets/images/navieras/arrowButton.png'
import dropdown from '../../assets/images/navieras/icon-dropdown.png';
import { AssignProvider } from '../../components/molecules/AssingForms/AssignProvider';
import { TypeOfModality } from '../../components/molecules/AssingForms/TypeOfModality';
import { TypeOfService } from '../../components/molecules/AssingForms/TypeOfService';
import {
    getAllProvidersAction,
} from '../../redux/actions/providerAction';
import { useDispatch, useSelector } from 'react-redux';
import { addNotification } from '../../redux/actions/notificationAction';

export const QuotationAssingProvider = ({stepper, setStepper, toggleModal, setValue, value, formData, setFormData}) => {

    const changeStage = ()=>{
        if((provider&& modality&&service)!==null){
            const data = {
                ...formData,
                providerId: provider,
                modalityTypeId: modality,
                serviceImport: service===1 ? true : false,
                serviceExport: service===2 ? true : false,
            }
            setFormData(data);
            console.log(data);
            setStepper(stepper+1)
        }else{
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Complete los campos antes de avanzar',
            }));
        }
    }
    const changeStageBack = ()=>{
        console.log('cambio de pestania')
        setStepper(stepper-1)
    }
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllProvidersAction());
    }, [])
    const providers = useSelector(state => state.provider.providers);
    const idQuotation = 'C0001';
    const [provider, setProvider]= useState(null);
    const [modality, setModality]= useState(null);
    const [service, setService]= useState(null);
    return (
        <>
            <form className='main-container-generic-type-quotation'>
                <div className='header-form-generic-type'>
                    <h2>Proveedor</h2><h3 className='id'>ID Cotización: {idQuotation}</h3>
                </div>
                <div className='quotation-options-container'>
                    <div className='body-form-generic-type-data' style={{height: '30vh'}}>
                        <AssignProvider provider={provider} setProvider={setProvider} providers={_.get(providers, "data", {})}/>
                    </div>
                    <div className='header-form-generic-type'>
                        <h2>Tipo de modalidad</h2>
                    </div>
                    <div className='body-form-generic-type-data' style={{height: '30vh'}}>
                        <TypeOfModality modality={modality} setModality={setModality} />
                    </div>
                    <div className='header-form-generic-type'>
                        <h2>Tipo de servicio</h2>
                    </div>
                    <div className='body-form-generic-type-data' style={{height: '30vh'}}>
                        <TypeOfService service={service} setService={setService}/>
                    </div>
                </div>
                <div className='dropdown-container'>
                    <img src={dropdown}/>
                </div>
                <div className='footer-form-generic-type' style={{justifyContent: 'space-between'}}>
                    <Button type='button' onClick={toggleModal ? toggleModal : changeStageBack} className={'button-back'} style={{width: '40%'}}>CANCELAR</Button>
                    <Button type='button' onClick={changeStage} className={'button-next'} srcicon={arrow} style={{width: '40%'}}>SIGUIENTE</Button>
                </div>
            </form>
        </>
    )
}

QuotationAssingProvider.propTypes = {
    stepper: PropTypes.any,
    setStepper: PropTypes.func,
    toggleModal: PropTypes.any,
    setValue: PropTypes.func,
}
