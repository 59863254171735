/* eslint-disable no-unused-vars */
import _ from 'lodash';
import {
    COMPANY_MODULE_GET_ALL_COMPANIES_BEGIN,
    COMPANY_MODULE_GET_ALL_COMPANIES_SUCCESS,
    COMPANY_MODULE_GET_ALL_COMPANIES_FAILURE,
    COMPANY_MODULE_UPSERT_COMPANY_BEGIN,
    COMPANY_MODULE_UPSERT_COMPANY_SUCCESS,
    COMPANY_MODULE_UPSERT_COMPANY_FAILURE,
    COMPANY_MODULE_GET_ONE_COMPANY_RECORD_BEGIN,
    COMPANY_MODULE_GET_ONE_COMPANY_RECORD_SUCCESS,
    COMPANY_MODULE_GET_ONE_COMPANY_RECORD_FAILURE,
    COMPANY_MODULE_GET_ALL_COMPANY_ACCOUNTS_BEGIN,
    COMPANY_MODULE_GET_ALL_COMPANY_ACCOUNTS_SUCCESS,
    COMPANY_MODULE_GET_ALL_COMPANY_ACCOUNTS_FAILURE,
    COMPANY_MODULE_CONNECT_WITH_STORE_BEGIN,
    COMPANY_MODULE_CONNECT_WITH_STORE_SUCCESS,
    COMPANY_MODULE_CONNECT_WITH_STORE_FAILURE,
    COMPANY_MODULE_UPLOAD_COMPANY_ACCOUNT_FILE_BEGIN,
    COMPANY_MODULE_UPLOAD_COMPANY_ACCOUNT_FILE_SUCCESS,
    COMPANY_MODULE_UPLOAD_COMPANY_ACCOUNT_FILE_FAILURE,
 } from '../actionTypes';

import initialState from '../initialState';

export default function clientReducer(state=initialState.company, action){
    switch (action.type) {
        case COMPANY_MODULE_GET_ALL_COMPANIES_BEGIN:
            return {
                ...state,
                companies: {
                    ...state.companies,
                    loadingGetAllClients: true,
                    errorGetAllClients: null,
                    totalRecords: 0,
                    data: [],
                }
            };
        case COMPANY_MODULE_GET_ALL_COMPANIES_SUCCESS:
            return {
                ...state,
                companies: {
                    ...state.companies,
                    loadingGetAllClients: false,
                    errorGetAllClients: null,
                    totalRecords: action.data.totalRecords,
                    data: action.data,
                }
            };
        case COMPANY_MODULE_GET_ALL_COMPANIES_FAILURE:
            return {
                ...state,
                companies: {
                    ...state.companies,
                    loadingGetAllClients: false,
                    errorGetAllClients: action.err,
                    totalRecords: 0,
                    data: [],
                }
            };
        case COMPANY_MODULE_UPSERT_COMPANY_BEGIN:
            return {
                ...state,
                upsertCompany: {
                    ...state.upsertCompany,
                    loading: true,
                    isSuccess: false,
                    err: null,
                    data: {},
                }
            };
        case COMPANY_MODULE_UPSERT_COMPANY_SUCCESS:
            return {
                ...state,
                upsertCompany: {
                    ...state.upsertCompany,
                    loading: false,
                    isSuccess: true,
                    err: null,
                    data: action.company,
                }
            };
        case COMPANY_MODULE_UPSERT_COMPANY_FAILURE:
            return {
                ...state,
                upsertCompany: {
                    ...state.upsertCompany,
                    loading: false,
                    isSuccess: false,
                    err: action.err,
                    data: {},
                }
            };
        case COMPANY_MODULE_GET_ONE_COMPANY_RECORD_BEGIN:
            return {
                ...state,
                companyRecord: {
                    ...state.companyRecord,
                    loading: true,
                    isSuccess: false,
                    err: null,
                    data: null,
                }
            };
        case COMPANY_MODULE_GET_ONE_COMPANY_RECORD_SUCCESS:
            return {
                ...state,
                companyRecord: {
                    ...state.companyRecord,
                    loading: false,
                    isSuccess: true,
                    err: null,
                    data: action.companyRecord,
                }
            };
        case COMPANY_MODULE_GET_ONE_COMPANY_RECORD_FAILURE:
            return {
                ...state,
                companyRecord: {
                    ...state.companyRecord,
                    loading: false,
                    isSuccess: false,
                    err: action.err,
                    data: null,
                }
            };
        case COMPANY_MODULE_GET_ALL_COMPANY_ACCOUNTS_BEGIN:
            return {
                ...state,
                companyAccounts: {
                    ...state.companyAccounts,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    totalRecords: 0,
                    data: [],
                }
            };
        case COMPANY_MODULE_GET_ALL_COMPANY_ACCOUNTS_SUCCESS:
            return {
                ...state,
                companyAccounts: {
                    ...state.companyAccounts,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    totalRecords: action.totalRecords,
                    data: action.data,
                }
            };
        case COMPANY_MODULE_GET_ALL_COMPANY_ACCOUNTS_FAILURE:
            return {
                ...state,
                companyAccounts: {
                    ...state.companyAccounts,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
                }
            };
        case COMPANY_MODULE_CONNECT_WITH_STORE_BEGIN:
            return {
                ...state,
                companyConnect: {
                    ...state.companyConnect,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    data: null,
                }
            };
        case COMPANY_MODULE_CONNECT_WITH_STORE_SUCCESS:
            return {
                ...state,
                companyConnect: {
                    ...state.companyConnect,
                    isLoading: false,
                    isSuccess: true,
                    err: action.err,
                    data: action.companyConnect,
                }
            };
        case COMPANY_MODULE_CONNECT_WITH_STORE_FAILURE:
            return {
                ...state,
                companyConnect: {
                    ...state.companyConnect,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    data: null,
                }
            };
        case COMPANY_MODULE_UPLOAD_COMPANY_ACCOUNT_FILE_BEGIN:
            return {
                ...state,
                companyUploadFile: {
                    ...state.companyUploadFile,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    data: null,
                }
            };
        case COMPANY_MODULE_UPLOAD_COMPANY_ACCOUNT_FILE_SUCCESS:
            return {
                ...state,
                companyUploadFile: {
                    ...state.companyUploadFile,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    data: null,
                }
            };
        case COMPANY_MODULE_UPLOAD_COMPANY_ACCOUNT_FILE_FAILURE:
            return {
                ...state,
                companyUploadFile: {
                    ...state.companyUploadFile,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    data: null,
                }
            };

        default:
            return state;
    }
}
