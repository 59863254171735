import React, { useState } from 'react';
import './QuotationForms.scss';
import {
    modality,
    delivery,
    containerType,
    sino
} from './optionsQuotation';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCountriesAction, getAllStatesAction } from '../../redux/actions/addressAction';

const AerialQuotationForm = ({principalDataAerialForm, setPrincipalDataAerialForm}) => {

    const dispatch = useDispatch();
    const changeData = (ele, value)=>{
        const data = { 
            ...principalDataAerialForm,
            [value] : ele,
        }
        setPrincipalDataAerialForm(data)
    }
    
    // useEffect(() => {
    //     console.log(principalDataAerialForm);
    // }, [principalDataAerialForm])
    useEffect(() => {
        dispatch(getAllCountriesAction());
        if(_.get(principalDataAerialForm,"id")){
            dispatch(getAllStatesAction(
                {where: {
                        countryId: principalDataAerialForm.originCountryId
                    }, target: "Origen",}
                ));
            dispatch(getAllStatesAction(
                {where: {
                        countryId: principalDataAerialForm.destinyCountryId
                    }, target: "Destino",}
                ));
        }
    }, [])
    const countries = useSelector(state => state.address.countries.data);
    const statesOrigen = useSelector(state => state.address.statesOrigen.data);
    const statesDestino = useSelector(state => state.address.statesDestino.data);
    const [disabled, setDisabled] = useState(true);
    return (
        <div className='quotation-form-general-container'>
            <div className='value-container-principal-data'>
                <p className='data-section-title'>Datos principales</p>
                <div className='data-section-container'>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Folio*</label>
                        </div>
                        <div className='value-container'>
                            <input 
                                className="input-generic" 
                                type="text" 
                                placeholder="Agregar"
                                value={principalDataAerialForm.invoice}
                                onChange={(e) => changeData(e.target.value, "invoice")}
                            />
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Nombre*</label>
                        </div>
                        <div className='value-container'>
                            <input  /* control={control} name={'bussinesName'} {...register('bussinesName')} */ 
                                className="input-generic" 
                                type="text" 
                                placeholder="Agregar"
                                value={principalDataAerialForm.name}
                                onChange={(e) => changeData(e.target.value, "name")}
                            />
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Empresa*</label>
                        </div>
                        <div className='value-container'>
                            <input 
                                className="input-generic" 
                                type="text" 
                                placeholder="Agregar"
                                value={principalDataAerialForm.bussines}
                                onChange={(e) => changeData(e.target.value, "bussines")}
                            />
                        </div>
                    </div>           
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Modalidad*</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataAerialForm.modality} 
                                onChange={(e) => changeData(e.target.value, "modality")}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    modality &&
                                    modality.map((ele, i) => {
                                        return <option key={i} value={ele.name}>{ele.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>     
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Entrega</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataAerialForm.delivery} onChange={(e) => changeData(e.target.value, "delivery")}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    delivery &&
                                    delivery.map((ele, i) => {
                                        return <option key={i} value={ele.name}>{ele.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>     
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>CBMS*</label>
                        </div>
                        <div className='value-container'>
                            <input 
                                className="input-generic" 
                                type="text" 
                                placeholder="Agregar"
                                value={principalDataAerialForm.cbms}
                                onChange={(e) => changeData(e.target.value, "cbms")}
                            />
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>KG*</label>
                        </div>
                        <div className='value-container'>
                            <input 
                                className="input-generic" 
                                type="text" 
                                placeholder="Agregar"
                                value={principalDataAerialForm.weight}
                                onChange={(e) => changeData(e.target.value, "weight")}
                            />
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Factor a usar*</label>
                        </div>
                        <div className='value-container'>
                            <input 
                                className="input-generic" 
                                type="text" 
                                placeholder="Agregar"
                                value={principalDataAerialForm.factor}
                                onChange={(e) => changeData(e.target.value, "factor")}
                            />                    
                        </div>
                        </div>
                    </div>
                </div>
                <div className='value-container-origin-data'>
                <p className='data-section-title'>Datos de origen</p>
                <div className='data-section-container'>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>País*</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataAerialForm.originCountryId} onChange={(e) => {
                                changeData(parseInt(e.target.value), "originCountryId");
                                dispatch(getAllStatesAction(
                                    {where: 
                                        {
                                            countryId: parseInt(e.target.value)
                                        }, 
                                    target: "Origen",
                                    }
                                    ));
                                }}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    _.get(countries, 'data') &&
                                    countries.data.map(ele => {
                                        return <option value={ele.id}>{ele.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>     
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Estado*</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataAerialForm.originStateId} onChange={(e) => changeData(parseInt(e.target.value), "originStateId")}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    _.get(statesOrigen, 'data') &&
                                    statesOrigen.data.map(ele => {
                                        return <option  value={ele.id}>{ele.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>     
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Cargos locales</label>
                        </div>
                        <div className='value-container'>
                            <input 
                                className="input-generic" 
                                type="text" 
                                placeholder="Agregar"
                                value={principalDataAerialForm.originLocalCharges}
                                onChange={(e) => changeData(e.target.value, "originLocalCharges")}
                            />                    
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Flete Terrestre</label>
                        </div>
                        <div className='value-container'>
                            <input 
                                className="input-generic" 
                                type="text" 
                                placeholder="Agregar"
                                value={principalDataAerialForm.originLandFreight}
                                onChange={(e) => changeData(e.target.value, "originLandFreight")}
                            />                                        
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Flete Marítimo</label>
                        </div>
                        <div className='value-container'>
                            <input 
                                className="input-generic" 
                                type="text" 
                                placeholder="Agregar"
                                value={principalDataAerialForm.originSeaFreight}
                                onChange={(e) => changeData(e.target.value, "originSeaFreight")}
                            />                    
                        </div>
                    </div>
                </div>
            </div>
            <div className='value-container-destiny-data'>
                <p className='data-section-title'>Datos de destino</p>
                <div className='data-section-container'>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>País*</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataAerialForm.destinyCountryId} onChange={(e) => {
                                changeData(parseInt(e.target.value), "destinyCountryId");
                                dispatch(getAllStatesAction(
                                    {where: 
                                        {
                                            countryId: parseInt(e.target.value)
                                        }, 
                                    target: "Destino",
                                    }
                                    ));
                                }}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    _.get(countries, 'data') &&
                                    countries.data.map(ele => {
                                        return <option value={ele.id}>{ele.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>     
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Estado*</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataAerialForm.destinyStateId} onChange={(e) => changeData(parseInt(e.target.value), "destinyStateId")}>
                            <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    _.get(statesDestino, 'data') &&
                                    statesDestino.data.map(ele => {
                                        return <option value={ele.id}>{ele.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>     
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Cargos locales por BL (total)</label>
                        </div>
                        <div className='value-container'>
                            <input 
                                className="input-generic" 
                                type="text" 
                                placeholder="Agregar"
                                value={principalDataAerialForm.destinyLocalChargesBl}
                                onChange={(e) => changeData(e.target.value, "destinyLocalChargesBl")}
                            />                    
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Cargos locales por CBM (total)</label>
                        </div>
                        <div className='value-container'>
                            <input 
                                className="input-generic" 
                                type="text" 
                                placeholder="Agregar"
                                value={principalDataAerialForm.destinyLocalChargesCbm}
                                onChange={(e) => changeData(e.target.value, "destinyLocalChargesCbm")}
                            />                    
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Flete Terrestre</label>
                        </div>
                        <div className='value-container'>
                            <input 
                                className="input-generic" 
                                type="text" 
                                placeholder="Agregar"
                                value={principalDataAerialForm.destinyLandFreight}
                                onChange={(e) => changeData(e.target.value, "destinyLandFreight")}
                            />                    
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Flete Marítimo</label>
                        </div>
                        <div className='value-container'>
                            <input 
                                className="input-generic" 
                                type="text" 
                                placeholder="Agregar"
                                value={principalDataAerialForm.destinySeaFreight}
                                onChange={(e) => changeData(e.target.value, "destinySeaFreight")}
                            />                    
                        </div>
                    </div>
                    </div>
                </div>
            <div className='value-container-other'>
                <p className='data-section-title'>Otros</p>
                <div className='data-section-container'>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Coordinación</label>
                        </div>
                        <div className='value-container'>
                            <input 
                                className="input-generic" 
                                type="text" 
                                placeholder="Agregar"
                                value={principalDataAerialForm.coordination}
                                onChange={(e) => changeData(e.target.value, "coordination")}
                            />                    
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Despacho en México</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataAerialForm.officeMx}  onChange={(e) => {changeData(e.target.value, "officeMx");
                            e.target.value === 'true' ? setDisabled(false) : setDisabled(true)
                        }}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    sino &&
                                    sino.map((ele, i) => {
                                        return <option key={i} value={ele.value}>{ele.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>     
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Agencia</label>
                        </div>
                        <div className='value-container'>
                            <input 
                                className="input-generic" 
                                type="text" 
                                placeholder="Agregar"
                                disabled={disabled}
                                value={principalDataAerialForm.agency}
                                onChange={(e) => changeData(e.target.value, "agency")}
                            />  
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Seguro*</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataAerialForm.insurance}  onChange={(e) => changeData(e.target.value, "insurance")}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    sino &&
                                    sino.map((ele, i) => {
                                        return <option key={i} value={ele.value}>{ele.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>     
                </div>
                <div className='value-container-total-amount'>
                    <div className='input-container-total'>
                        <div className='label-container'>
                            <p className='container-label'>Total</p>
                        </div>
                        <div className='value-container'>
                            <input 
                                className="input-generic" 
                                type="text" 
                                placeholder="$"
                                value={principalDataAerialForm.total}
                                onChange={(e) => changeData(e.target.value, "total")}
                            />                      
                        </div>
                    </div>
                    <div className='input-container-total'>
                        <div className='label-container'>
                            <p className='container-label'>Vigencia</p>
                        </div>
                        <div className='value-container'>
                            <input  
                                className="input-generic date" 
                                type="date"
                                value={principalDataAerialForm.validity}
                                onChange={(e) => changeData(e.target.value, "validity")}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AerialQuotationForm;


        