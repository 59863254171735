/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Modal from '../../components/organisms/Modal/Modal';
import Button from '../../components/atoms/Button.js';
import refresh from '../../assets/images/navieras/refresh.png';
import './RecordRoute.scss';
import RecordDeviations from './RecordModals/RecordDeviations';
import RecordChanges from './RecordModals/RecordChanges';
import RecordDelays from './RecordModals/RecordDelays';
import RecordManualCapture from './RecordModals/RecordManualCapture';
import MapView from '../../components/molecules/Map';
import VerticalStepper from '../../components/Stepper/VerticalStepper';

const RecordRoute = () => {
    
    const [ openModal, setOpenModal ] = useState(false);
    const [ modalType, setModalType ] = useState('');
    const [ receptionDate, setReceptionDate ] = useState(null);
    const [ deliveryDate, setDeliveryDate ] = useState('04/11/2022');
    const [ originPlace, setOriginPlace ] = useState('Ningbo, China');
    const [ destinyPlace, setDestinyPlace ] = useState('Ensenada, México');
    const [ recordId, setRecordId ] = useState('0001');
    const [ deliveryHistory, setDeliveryHistory ] = useState([
        {
            country: {name: 'China'},
            state: {name: 'Ningbo'},
            status:'Vaciando para ser cargada',
            date: '02/11/2022',
            lat: '29.8683',
            lng: '121.54399',
        },
        {
            country: {name: 'China'},
            state: {name: 'Hong Kong'},
            status: 'Lista para ser cargada',
            date: '03/11/2022',
            lat: '22.27832',
            lng: '114.1746',
        },
        {
            country: {name: 'China'},
            state: {name: 'Beijing'},
            status: 'Cargado a bordo',
            date: '04/11/2022',
            lat: '39.9075',
            lng: '116.3972',
        },
    ]);
    const [ lat, setLat ] = useState(!_.isEmpty(deliveryHistory) ? deliveryHistory[deliveryHistory.length-1].lat : '0');
    const [ lng, setLng ] = useState(!_.isEmpty(deliveryHistory) ? deliveryHistory[deliveryHistory.length-1].lng : '0');
    
    const toggleModal = () => {
        setOpenModal(!openModal);
    }

    const selectPosition = (i) => {
        setLat(deliveryHistory[i].lat);
        setLng(deliveryHistory[i].lng);
    }

    let horizontalCheckpointClassName = 'circle-boarder';
    let connectLineClassName = 'line-between';
    if (receptionDate != null) {
        horizontalCheckpointClassName = 'circle-boarder active';
        connectLineClassName = 'line-between active';
    }

    return (
        <>
            {
                openModal &&
                <Modal
                    isOpen={toggleModal}
                    toggle={toggleModal}
                >
                    {
                        modalType == 'deviations' &&
                        <RecordDeviations toggleModal={toggleModal} />
                    }
                    {
                        modalType == 'changes' &&
                        <RecordChanges toggleModal={toggleModal} />
                    }
                    {
                        modalType == 'delays' &&
                        <RecordDelays toggleModal={toggleModal} />
                    }
                    {
                        modalType == 'capture' &&
                        <RecordManualCapture 
                            setDeliveryHistory={setDeliveryHistory}
                            deliveryHistory={deliveryHistory}
                            toggleModal={toggleModal}
                            setLatPosition={setLat}
                            setLngPosition={setLng}
                        />
                    }
                </Modal>
            }
            <div className='route-record-general-container'>
                <div className='route-record-map-container'>
                    <MapView position={[ lat, lng ]} />
                </div>
                <div className='route-record-data-container'>
                    <div className='data-record-card-container'>  
                        <div className='header-form-generic-type'>
                            <h2>Ruta</h2>
                            <h4>ID Expediente: {recordId}</h4>
                        </div>
                        <div className='body-route-declaration-container'>
                            <div className='places-container'>
                                <div className='place-date-container'>
                                    <h4 className='place-text'>{originPlace}</h4>
                                    <p className='date-text'>{deliveryDate}</p>
                                </div>
                                <div className='place-date-container'>
                                    <h4 className='place-text'>{destinyPlace}</h4>
                                    <p className='date-text'>{receptionDate}</p>
                                </div>
                            </div>
                            <div className='circle-pointers-container'>
                                <div className='circle-boarder active'><div className='circle'></div></div>
                                <div className={connectLineClassName}></div>
                                <div className={horizontalCheckpointClassName}><div className='circle'></div></div>
                            </div>
                        </div>
                        <div className='buttons-container'>
                            <button className={'refresh-button'}><img src={refresh} /></button>
                            <Button onClick={() => {toggleModal(), setModalType('capture')}}>CAPTURAR MANUAL</Button>
                        </div>
                        <VerticalStepper array={deliveryHistory} onClick={selectPosition} />
                        <div className='footer-record-route' style={{justifyContent: 'space-between'}}>
                            <label>Cambios</label>
                            <div className='button-radio' onClick={() => {toggleModal(), setModalType('changes')}}></div>
                            <label>Desvíos</label>
                            <div className='button-radio' onClick={() => {toggleModal(), setModalType('deviations')}}></div>
                            <label>Retrasos</label>
                            <div className='button-radio' onClick={() => {toggleModal(), setModalType('delays')}}></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RecordRoute;
