import React, { useEffect, useState } from "react";
import "./RecordNew.scss";
import { Button } from "../../components/atoms";
import { useDispatch, useSelector } from "react-redux";
import { getAllReportTypeAction, upsertReportAction } from "../../redux/actions/reportsAction";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

const RecordNew = () => {
  const dispatch = useDispatch();
  const history = useHistory()
  const [typeReportId, setTypeReportId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate,setEndDate] = useState("")

  useEffect(() => {
    dispatch(getAllReportTypeAction());
  }, []);

  const reportType = useSelector((state) => state.reports.allReportType.data);

  const methods = useForm({});

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = async () => {
    const data = {
      typeReportId: typeReportId,
      startDate: startDate,
      endDate: endDate
    };

    dispatch(upsertReportAction(data))
    history.push('/dashboard/expedientes')
    
  };

  console.log(typeReportId,startDate,endDate);
  

  return (
    <div className="RecordNewMainContainer">
      <div className="RecordHeadContainer">
        <h2 style={{ fontWeight: "300", color: "#ffff", fontFamily: "Inter" }}>
          Gestión de reportes | Nuevo
        </h2>
      </div>
      <div className="RecordNewContainer">
        <div className="RecordNewReport">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="TitleContainerNew">
              <h2>Nuevo Reporte</h2>
            </div>
            <div className="inputContainer">
              <label className="labelRecordNew">
                Seleccionar tipo de reporte a generarse:{" "}
              </label>
              <select
                className="input-generic-record"
                placeholder="Filtrar por tipo"
                name={"reportType"}
                value={typeReportId.displayText}
                onChange={(e) => {
                  setTypeReportId(e.target.value);
                }}
              >
                <option value={null} selected hidden>
                  {typeReportId != null
                    ? typeReportId.displayText
                    : "Filtrar por tipo"}
                </option>
                {_.get(reportType, "data") &&
                  reportType.data.map((item, i) => {
                    return (
                      <option key={i} value={item.id}>
                        {item.displayText}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="inputContainer">
              <label className="labelRecordNew">
                Seleccionar tipo de reporte a generarse:{" "}
              </label>
              <div className="datesContainer">
                <input type="date" className="dateInput" 
                    onChange={(e) => setStartDate(e.target.value)}
                />
                <p
                  style={{
                    color: "#ffff",
                    fontSize: "14px",
                    fontFamily: "Open Sans",
                  }}
                ></p>
                <input
                  type="date"
                  className="dateInput"
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
            <div className="btnContainer">
              <Button className="btnNewReport">GENERAR REPORTE</Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RecordNew;
