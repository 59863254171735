import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Button } from "../../../components/atoms";
// import "../../styles/stylesSteppers.scss";
// import "../ProvidersDashboard/ProviderPrincipalData.scss";
// import "./OperativePrincipalDataForm.scss";
// import "../../components/templates/BaseLayout/Profile/ProfileFormStyles.scss";
// import "./OperativeIdentity.scss";
// import "../../QuotationDashboard/QuotationForms.scss"
import "./SeedataIdentity.scss"
import profileDefault from "../../../assets/images/perfil.png";
import { url_api } from "../../../config";

const SeeDataIdentity = (props) => {
  const currentData = _.get(props, "data", null);

  const [disabled, setDisabled] = useState(true);

  const [inefront, setIneFront] = useState();
  const [ineBack, setIneBack] = useState();
  const [comprobante, setComprobante] = useState();

  const [curp, setCurp] = useState(
    !_.isNil(currentData.person.curp) ? currentData.person.curp : "Sin datos"
  );

  const [identificationNumber, setIdentificationNumber] = useState(
    !_.isNil(currentData.person.identificationNumber)
      ? currentData.person.identificationNumber
      : "Sin datos"
  );

  const changeStage = () => {
    // console.log(country, 'cambio de pestania')
    props.setStepper(props.stepper + 1);
  };

  useEffect(() => {
    const userMediaData = _.get(currentData, "user.userMedia", "nada");
    console.log(userMediaData);

    if (!_.isEmpty(userMediaData)) {

      userMediaData.forEach((element,index) => {
        switch (index) {
          case 0:
            console.log("lo metio",element.media.fileName);
            setIneFront(element.media.fileName);
            break;
          case 1:
            console.log("lo metio");

            setIneBack(element.media.fileName);
            break;
          case 2:
            console.log("lo metio");

            setComprobante(element.media.fileName);
            break;

          default:
            break;
        }
      });
    }
  }, [currentData]);

  const changeStageBack = () => {
    // console.log(country, 'cambio de pestania')
    props.setStepper(stepper - 1);
  };

  // const setProfile = `${url_api}${imgDefault}`


  return (
    <div>
      <div className="headerIdentityContainer">
        <h2>Datos de identidad</h2>
      </div>
      <div
        className="gridContainer"
        style={{
          display: "grid",
          gridTemplateColumns: " 1fr 1fr",
          overflowY: "scroll",
        }}
      >
        <div
          className="inputContainer"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <label>CURP</label>
          <input
            className="input-generic"
            disabled={true}
            //   required
            //   control={control}
            value={curp}
            name={"curp"}
            type="text"
            placeholder="Agregar..."
            onChange={(e) => setCurp(e.target.value)}
          />
        </div>
        <div
          className="inputContainer"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <label>Datos de identidad</label>
          <input
            className="input-generic"
            disabled={true}
            //   required
            //   control={control}
            value={identificationNumber}
            name={"curp"}
            type="text"
            placeholder="Agregar..."
            onChange={(e) => setCurp(e.target.value)}
          />
        </div>
        <div
          className="inputContainer"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <label>Ine frente</label>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              style={{ width: "50%" }}
              src={
                !_.isNil(inefront) ? `${url_api}/${inefront}` : profileDefault
              }
            />
          </div>
        </div>
        <div
          className="inputContainer"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <label>Ine trasera</label>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              style={{ width: "50%" }}
              src={!_.isNil(ineBack) ? `${url_api}/${ineBack}` : profileDefault}
            />
          </div>
        </div>
        <div
          className="inputContainer"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <label>Comprobante de domiclio</label>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              style={{ width: "50%" }}
              src={
                !_.isNil(comprobante)
                  ? `${url_api}/${comprobante}`
                  : profileDefault
              }
            />
          </div>
        </div>
      </div>
      <div
            className="footer-form-generic-type operative"
              >
                <Button
                  type="submit"
                  onClick={changeStageBack}
                  // className={"buttonNextStep"}
                  style={{ width: "40%", backgroundColor:"#5169C3" }}
                >
                  ANTES
                </Button>
              </div>
    </div>
  );
};

export default SeeDataIdentity;
