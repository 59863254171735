import request from "../../utils/request";
import { addNotification } from './notificationAction';

import {
    USER_PERMISSION_MODULE_GET_ALL_USER_PERMISSION_BEGIN,
    USER_PERMISSION_MODULE_GET_ALL_USER_PERMISSION_SUCCESS,
    USER_PERMISSION_MODULE_GET_ALL_USER_PERMISSION_FAILURE,
} from '../actionTypes';

// ACTIONS TO REDUCER

export const getAllUserPermissionBegin = () => ({
    type:  USER_PERMISSION_MODULE_GET_ALL_USER_PERMISSION_BEGIN,
});

export const getAllUserPermissionSuccess = (data) => ({
    type: USER_PERMISSION_MODULE_GET_ALL_USER_PERMISSION_SUCCESS,
    data,
});

export const getAllUserPermissionFailure = (err) => ({
    type: USER_PERMISSION_MODULE_GET_ALL_USER_PERMISSION_FAILURE,
    err,
});


// DISPATCH TO ACTIONS

export function getAlluserPermissionAction(data){
    return (dispatch, getState) => {
        dispatch(getAllUserPermissionBegin());
        return request({
            partialUrl: `/api/v1/user-permission/get-all-user-permissions-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log('this is data response');
            console.log(data.data);
            dispatch(getAllUserPermissionSuccess(data.data));
            return data.data
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
            }));
            return dispatch(getAllUserPermissionFailure(err));
        });
    };
}

