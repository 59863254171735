// import _ from 'lodash';
import {
    CUSTOM_AGENT_MODULE_GET_ALL_CUSTOM_AGENTS_BEGIN,
    CUSTOM_AGENT_MODULE_GET_ALL_CUSTOM_AGENTS_SUCCESS,
    CUSTOM_AGENT_MODULE_GET_ALL_CUSTOM_AGENTS_FAILURE,
    CUSTOM_AGENT_MODULE_UPSERT_CUSTOM_AGENT_BEGIN,
    CUSTOM_AGENT_MODULE_UPSERT_CUSTOM_AGENT_SUCCESS,
    CUSTOM_AGENT_MODULE_UPSERT_CUSTOM_AGENT_FAILURE,
    GET_ALL_CLIENTS_BELONGS_CUSTOM_AGENT_BEGIN,
    GET_ALL_CLIENTS_BELONGS_CUSTOM_AGENT_SUCCESS,
    GET_ALL_CLIENTS_BELONGS_CUSTOM_AGENT_FAILURE,
    DELETE_ASSOCIATION_BEGIN,
    DELETE_ASSOCIATION_SUCCESS,
    DELETE_ASSOCIATION_FAILURE,
    GET_ALL_CUSTOM_AGENT_BELONGS_CLIENT_BEGIN,
    GET_ALL_CUSTOM_AGENT_BELONGS_CLIENT_SUCCESS,
    GET_ALL_CUSTOM_AGENT_BELONGS_CLIENT_FAILURE,
    GET_ALL_AVAILABLE_CUSTOM_AGENT_BY_CLIENTS_BEGIN,
    GET_ALL_AVAILABLE_CUSTOM_AGENT_BY_CLIENTS_SUCCESS,
    GET_ALL_AVAILABLE_CUSTOM_AGENT_BY_CLIENTS_FAILURE,
} from '../actionTypes';

import initialState from '../initialState';

export default function customAgentReducer(state = initialState.customAgent, action) {
    switch (action.type) {
        case CUSTOM_AGENT_MODULE_GET_ALL_CUSTOM_AGENTS_BEGIN:
            return {
                ...state,
                customAgents: {
                    ...state.customAgents,
                    loadingGetAllCustomAgents: true,
                    errorGetAllCustomAgents: null,
                    totalRecords: 0,
                    data: [],
                }
            };
        case CUSTOM_AGENT_MODULE_GET_ALL_CUSTOM_AGENTS_SUCCESS:
            return {
                ...state,
                customAgents: {
                    data: action.data.data,
                    totalRecords: action.data.totalRecords,
                }
            };
        case CUSTOM_AGENT_MODULE_GET_ALL_CUSTOM_AGENTS_FAILURE:
            return {
                ...state,
                customAgents: {
                    ...state.customAgents,
                    loadingGetAllCustomAgents: false,
                    errorGetAllCustomAgents: action.err,
                    totalRecords: 0,
                    data: [],
                }
            };
        case CUSTOM_AGENT_MODULE_UPSERT_CUSTOM_AGENT_BEGIN:
            return {
                ...state,
                upsertCustomAgent: {
                    ...state.upsertCustomAgent,
                    loading: true,
                    isSuccess: false,
                    err: null,
                    data: {},
                }
            };
        case CUSTOM_AGENT_MODULE_UPSERT_CUSTOM_AGENT_SUCCESS:
            return {
                ...state,
                upsertCustomAgent: {
                    ...state.upsertCustomAgent,
                    loading: false,
                    isSuccess: true,
                    err: null,
                    data: action.customAgent,
                }
            };
        case CUSTOM_AGENT_MODULE_UPSERT_CUSTOM_AGENT_FAILURE:
            return {
                ...state,
                upsertCustomAgent: {
                    ...state.upsertCustomAgent,
                    loading: false,
                    isSuccess: false,
                    err: action.err,
                    data: {},
                }
            };

        case GET_ALL_CLIENTS_BELONGS_CUSTOM_AGENT_BEGIN:
            return {
                ...state,
                getClientBelongsCustomAgent: {
                    ...state.getClientBelongsCustomAgent,
                    loading: true,
                    isSuccess: false,
                    err: null,
                    data: {},
                }
            };
        case GET_ALL_CLIENTS_BELONGS_CUSTOM_AGENT_SUCCESS:
            return {
                ...state,
                getClientBelongsCustomAgent: {
                    ...state.getClientBelongsCustomAgent,
                    loading: false,
                    isSuccess: true,
                    err: null,
                    data: action.data.data,
                }
            };
        case GET_ALL_CLIENTS_BELONGS_CUSTOM_AGENT_FAILURE:
            return {
                ...state,
                getClientBelongsCustomAgent: {
                    ...state.getClientBelongsCustomAgent,
                    loading: false,
                    isSuccess: false,
                    err: action.err,
                    data: {},
                }
            };

        case DELETE_ASSOCIATION_BEGIN:
            return {
                ...state,
                deleteAssociation: {
                    ...state.deleteAssociation,
                    loading: true,
                    isSuccess: false,
                    err: null,
                    data: {},
                }
            };
        case DELETE_ASSOCIATION_SUCCESS:
            return {
                ...state,
                deleteAssociation: {
                    ...state.deleteAssociation,
                    loading: false,
                    isSuccess: true,
                    err: null,
                    data: action.data.data,
                }
            };
        case DELETE_ASSOCIATION_FAILURE:
            return {
                ...state,
                deleteAssociation: {
                    ...state.deleteAssociation,
                    loading: false,
                    isSuccess: false,
                    err: action.err,
                    data: {},
                }
            };

        case GET_ALL_CUSTOM_AGENT_BELONGS_CLIENT_BEGIN:
            return {
                ...state,
                getCustomAgentBelongsClient: {
                    ...state.getCustomAgentBelongsClient,
                    loading: true,
                    isSuccess: false,
                    err: null,
                    data: {},
                }
            };
        case GET_ALL_CUSTOM_AGENT_BELONGS_CLIENT_SUCCESS:
            return {
                ...state,
                getCustomAgentBelongsClient: {
                    ...state.getCustomAgentBelongsClient,
                    loading: false,
                    isSuccess: true,
                    err: null,
                    data: action.data.data,
                }
            };
        case GET_ALL_CUSTOM_AGENT_BELONGS_CLIENT_FAILURE:
            return {
                ...state,
                getCustomAgentBelongsClient: {
                    ...state.getCustomAgentBelongsClient,
                    loading: false,
                    isSuccess: false,
                    err: action.err,
                    data: {},
                }
            };
            case GET_ALL_AVAILABLE_CUSTOM_AGENT_BY_CLIENTS_BEGIN:
            return {
                ...state,
                customAgentsAvailableByClient: {
                    loading: true,
                    isSuccess: false,
                    err: null,
                    data: {},
                }
            };
        case GET_ALL_AVAILABLE_CUSTOM_AGENT_BY_CLIENTS_SUCCESS:
            return {
                ...state,
                customAgentsAvailableByClient: {
                    loading: false,
                    isSuccess: true,
                    err: null,
                    data: action.data.data,
                }
            };
        case GET_ALL_AVAILABLE_CUSTOM_AGENT_BY_CLIENTS_FAILURE:
            return {
                ...state,
                customAgentsAvailableByClient: {
                    loading: false,
                    isSuccess: false,
                    err: action.err,
                    data: {},
                }
            };
        default:
            return state;
    }
}
