import request from "../../utils/request";
import { addNotification } from './notificationAction';

import {
    POSTS_MODULES_GET_ALL_POSTS_BEGIN,
    POSTS_MODULES_GET_ALL_POSTS_SUCCESS,
    POSTS_MODULES_GET_ALL_POSTS_FAILURE,
} from '../actionTypes';

// ACTIONS TO REDUCER

export const getAllPostsBegin = () => ({
    type:  POSTS_MODULES_GET_ALL_POSTS_BEGIN,
});

export const getAllPostsSuccess = (data) => ({
    type: POSTS_MODULES_GET_ALL_POSTS_SUCCESS,
    data,
});

export const getAllPostsFailure = (err) => ({
    type: POSTS_MODULES_GET_ALL_POSTS_FAILURE,
    err,
});


// DISPATCH TO ACTIONS

export function getAllPostsAction(data){
    return (dispatch, getState) => {
        dispatch(getAllPostsBegin());
        return request({
            partialUrl: `/api/v1/posts/get-all-posts-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log('this is data response');
            console.log(data.data);
            dispatch(getAllPostsSuccess(data.data));
            return data.data
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
            }));
            return dispatch(getAllPostsFailure(err));
        });
    };
}

