import React, { useEffect, useState } from "react";
import "../../../components/templates/BaseLayout/Profile/ProfileFormStyles.scss";
import "../OperativesDetailsView.scss";
import "./ComentStrike.scss";
import profile from "../../../assets/images/perfil.png";
import ComponentComent from "../../../components/organisms/Coments/ComponentComent";
import { useDispatch, useSelector } from "react-redux";
import { getAllStrikesAction } from "../../../redux/actions/operativeAction";
import ShowStrikes from "./ShowStrikes";
import _ from 'lodash';

const ComentStrike = (props) => {
  const dispatch = useDispatch();
  const currentData = _.get(props, "location.state", null);
  const idCustomer = _.get(currentData, "id", null);
  console.log(idCustomer);

  const [arrayIsStrike, setArrayIsStrike] = useState([]);
  const [changeComent,setChangeComent] = useState(false);

  useEffect(() => {
    dispatch(getAllStrikesAction({ where: { receptorId: idCustomer } }));
  }, []);

  useEffect(() => {
    if(changeComent===true){
      dispatch(getAllStrikesAction({ where: { receptorId: idCustomer } }));
      setChangeComent(false)
      setArrayIsStrike([])
    }
    
  }, [changeComent])
  

  const strikes = useSelector((state) => state.operative.strikes.data);
  console.log(strikes, "strik");

  useEffect(() => {
    strikes.forEach((element) => {
      if (element.receptorId === idCustomer) {
        if (element.isStrike === true) {
          setArrayIsStrike((strikes) => [...strikes, element]);
        }
      }
      
    });
  }, [strikes]);

  console.log(arrayIsStrike,"los quita?")

  const [fileUrl, setFileUrl] = useState([]);
  const [imageSrc, setImageSrc] = useState(profile);
  const api = process.env.SERVER_API_DATA_URL;

  const [firstName, setFirstName] = useState(
    !_.isNil(currentData.person.firstName) ? currentData.person.firstName : ""
  );
  const [firstLastName, setFirstLastName] = useState(
    !_.isNil(currentData.person.firstLastName)
      ? currentData.person.firstLastName
      : ""
  );
  const [email, setEmail] = useState(
    !_.isNil(currentData.person.primaryEmail)
      ? currentData.person.primaryEmail
      : ""
  );

  const imgDefault = _.get(currentData, "media.media.url", null);

  const profileImg = fileUrl[fileUrl.length - 1]
    ? fileUrl[fileUrl.length - 1]
    : imgDefault
    ? `${api}/${imgDefault}`
    : imageSrc;

  const setImgProfile = {
    backgroundImage: `url(${profileImg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div className="main-container">
      <div className="form-admin-container" style={{ height: "90%" }}>
        <div className="form-admin-profile-left form-admin-profile-cards">
          <div className="picture-container">
            <div className="preContainer">
              <div className="img-profile" style={setImgProfile}></div>
            </div>
          </div>
          <div className="text-container">
            <h2 className="nameComentStrike">{`${firstName}${" "} ${firstLastName}`}</h2>
            <p className="emailComentStrike">{email}</p>
          </div>
          <div className="StrikesContainer">
            <h3 className="TitleStrikes">Strikes</h3>
            {_.isEmpty(arrayIsStrike) ? (
              <p>Sin strikes</p>
            ) : (
              <ShowStrikes numberOfStrikes={arrayIsStrike} />
            )}
          </div>
        </div>
        <div
          className="form-admin-profile-right form-admin-profile-cards"
          style={{ overflowY: "scroll" }}
        >
          <div className="header-form">
            <h2 className="TitleStrikesComents">Comentarios</h2>
          </div>
          <ComponentComent data={strikes} setChangeComent={setChangeComent}/>
        </div>
      </div>
    </div>
  );
};

export default ComentStrike;
