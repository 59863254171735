import React from 'react'
import { Button, InputPassword } from '../../../components/atoms'
import { ButtonComponent } from '../../../components/atoms/buttonComponent'
import email from '../../../assets/images/icon-email.png';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updatePasswordRequestAction } from '../../../redux/actions/userActions';
import { addNotification } from '../../../redux/actions/notificationAction';
export const FormRecoverPass = () => {
    const dispatch = useDispatch()
    const { register, handleSubmit } = useForm();

    const onSubmitRecoveryPassForm = async (formData) => {
        // console.log('fomr',formData)
        const res = await dispatch(updatePasswordRequestAction(formData));
        if(res){
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se han envido instrucciones para concluir la acción a su email',
            }));
        }
    }


    return (
        <>
            <form
                name='login'
                onSubmit={handleSubmit(onSubmitRecoveryPassForm)}
                className='form-logIn'>

                <p className='txt-login'>INGRESA TU CORREO</p>
                <div className='instructions-container'>
                    <p className='txt-instructions'>Ingrese su correo electrónico asociado con su cuenta y le enviaremos un correo electrónico con instrucciones para restablecer su contraseña.</p>
                </div>
                <div className={'log-in'}>

                    <InputPassword
                        className='email-input'
                        type={'email'}
                        inputName={'usuario'}
                        {...register('email')}
                        placeholder={'Usuario'}
                        icon={email}
                    />
                    <Button type='submit' className={"btnRecoveryPassword"}>CAMBIAR CONTRASEÑA</Button>
                    {/* <ButtonComponent text={'CAMBIAR CONTRASEÑA'} to={"/app/recuperar-password/:token"} /> */}
                </div>

            </form>
        </>
    )
}
