import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from 'lodash';
import './Input.scss';

const Input = (props) => {

    // const isValid = !_.isNil(errors) ? _.isNil(errors[name]) : true;
    // const errorMessage = !isValid && errors[name].message ? errors[name].message : '';

    // const [value, setValue] = useState('');    
    const {className = 'inputStyle',
    placeholder,
    type = 'text',
    inputName,
    errors,
    name,
    value,
    // setValue,
    // onChange,
    register, } = props;

    const id = props.id ? props.id : null;

    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        console.log("inputValue", inputValue)
    }, [inputValue])
    
    return (
        <>
            <input
                type={type}
                placeholder={placeholder}
                className={className}
                name={inputName}
                value={inputValue}
                id={props.id ? props.id : null}
                onChange={(e) => {
                    setInputValue(e.target.value, id);
                }}
            />

        </>
    );
}

Input.propTypes = {
    type: PropTypes.any,
    placeholder: PropTypes.string,
    errors: PropTypes.any,
    name: PropTypes.string,
    className: PropTypes.string,
    inputName: PropTypes.string,
    onChange: PropTypes.any,
};

export default Input;
