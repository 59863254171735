import _ from 'lodash';
import {
    COSTUMER_CUSTOM_AGENT_MODULE_UPSERT_COSTUMER_CUSTOM_AGENT_BEGIN,
    COSTUMER_CUSTOM_AGENT_MODULE_UPSERT_COSTUMER_CUSTOM_AGENT_SUCCESS,
    COSTUMER_CUSTOM_AGENT_MODULE_UPSERT_COSTUMER_CUSTOM_AGENT_FAILURE,
 } from '../actionTypes';

import initialState from '../initialState';

export default function costumerCustomAgentReducer(state=initialState.costumerCustomAgent, action){
    switch (action.type) {
       
        case COSTUMER_CUSTOM_AGENT_MODULE_UPSERT_COSTUMER_CUSTOM_AGENT_BEGIN:
            return {
                ...state,
                upsertCostumerCustomAgent: {
                    ...state.upsertCostumerCustomAgent,
                    loading: true,
                    isSuccess: false,
                    err: null,
                    data: {},
                }
            };
        case COSTUMER_CUSTOM_AGENT_MODULE_UPSERT_COSTUMER_CUSTOM_AGENT_SUCCESS:
            return {
                ...state,
                upsertCostumerCustomAgent: {
                    ...state.upsertCostumerCustomAgent,
                    loading: false,
                    isSuccess: true,
                    err: null,
                    data: action.costumerCustomAgent,
                }
            };
        case COSTUMER_CUSTOM_AGENT_MODULE_UPSERT_COSTUMER_CUSTOM_AGENT_FAILURE:
            return {
                ...state,
                upsertCostumerCustomAgent: {
                    ...state.upsertCostumerCustomAgent,
                    loading: false,
                    isSuccess: false,
                    err: action.err,
                    data: {},
                }
            };
        default:
            return state;
    }
}
