import React, { useEffect, useState } from 'react';
import {connect, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { MenuItem }from '@material-ui/core';
import plus from '../../../../assets/images/navieras/plus-icon.png'
import '../../../../styles/tableViewStyles.scss';
import {
    Button,
    DataTable,
    ActionMenu,
    GlobalFilter,
    Link,
} from '../../../../components/atoms';
import {
    getAllAdministratorsAction,
    deleteAdministratorAction,
} from '../../../../redux/actions/adminAction';
import Modal from '../../../../components/organisms/Modal/Modal';
import { CardContainer } from '../../../../components/molecules/CardContainer/CardContainer';
import { AssignCustomsAgent } from './AssignCustomsAgent';
import { DeleteBody } from '../../../../components/molecules/CancelFormBody/DeleteBody';
import { deleteClientAction, getAllClientsAction } from '../../../../redux/actions/clientAction';
import { upsertCostumerCustomAgentAction } from '../../../../redux/actions/CostumerCustomAgent';
import { addNotification } from '../../../../redux/actions/notificationAction';
import { getAllCustomAgentsAction, getAllAvailableCustomAgentsAction } from '../../../../redux/actions/customsAgentAction';
import view from '../../../../assets/images/navieras/view.png';
import trash from '../../../../assets/images/navieras/trash-can.png';
import agency from '../../../../assets/images/navieras/agency.png';
import edit from '../../../../assets/images/navieras/edit-icon.png';
import file from '../../../../assets/images/navieras/icon-file.png';
import { RECORDS } from '../../../../routes/Paths';

const ClientDashboard = (props) => {
    const history = useHistory();
    const [searchObj,  setSearchObj] = useState({});
    const [searchCondition, setSearchCondition] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [itemSelected, setItemSelected] = useState(true);
    const [itemDeleted, setItemDeleted] = useState(true);

    console.log('item seleccionado', itemSelected)

    const toggleModal = (type, id) => {
        if(openModal===true){
            setItemSelected('null')
            setOpenModal(false);
        }{
            console.log('set datos', type, id);
            setItemSelected([type, id]);
            setOpenModal(true);
        }
    }
    useEffect(()=>{
        props.getAllClientsAction(searchCondition);
        // props.getAllAdministratorsAction(searchCondition);
    },[itemDeleted, searchCondition]);

    useEffect(() => {
        console.log(searchObj)
    }, [searchObj])
    
    useEffect(() => {
     console.log(props.clients);
    }, [props.clients])
    

    const onClickActionMenu = (actionSelected, payload) => {
        switch (actionSelected) {
            case 'showRecord':
                console.log('payload',payload)
                history.push({
                        pathname: `/dashboard/gestion-clientes/${payload.id}/ver`,
                        state: payload
                    });
                break;
            case 'editRecord':
                history.push({
                        pathname: `/dashboard/gestion-clientes/${payload.id}/editar`,
                        state: payload
                    });
                break;
            case 'quotation':
                history.push({
                        pathname: `/dashboard/cotizacion/${payload.id}`
                    });
                break;
            case 'deleteRecord':
                props.deleteAdministratorAction({id: payload.id});
                break;
            default:
                break;
        }
    }
    const deleteUser = async ()  =>{
        const res = await props.deleteClientAction({id: itemSelected.id})
        if(res){
            setOpenModal(false)
            setItemDeleted(itemSelected)
            setItemSelected(null)
        }
        // console.log(`props.deleteAdministratorAction({id: ${itemSelected.id}})`);
    }
    const columns = React.useMemo(
        () => [
            {
                id: 'id',
                Header: 'ID',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '10%', 
                accessor: d => ({
                    id: _.get(d, 'id', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        id,
                    },
                    row
                }) {
                    // return (<Link to={`/app/horarios/${id}`}>{id}</Link>); 
                    return(<span>{`${id}`}</span>)
                }
            },
            {
                id: 'tradename',
                Header: 'Nombre comercial',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '15%', 
                accessor: d => ({
                    tradename: _.get(d, 'tradename', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        tradename,
                    },
                    row
                }) {
                    // return (<Link to={`/app/horarios/${id}`}>{id}</Link>); 
                    return(<span>{`${tradename}`}</span>)
                }
            },
            {
                id: 'businessname',
                Header: 'Razon social',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '15%', 
                accessor: d => ({
                    businessname: _.get(d, 'businessname', ''),
                    isfisica: _.get(d, 'isfisica', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        businessname,
                        isfisica,
                    },
                    row
                }) {
                    // return (<Link to={`/app/horarios/${id}`}>{id}</Link>); 
                    
                    return(<span>{`${businessname}`}</span>)
                }
            },
            {
                id: 'rfc',
                Header: 'RFC',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '15%', 
                accessor: d => ({
                    rfc: _.get(d, 'rfc', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        rfc,
                    },
                    row
                }) {
                    // return (<Link to={`/app/horarios/${id}`}>{id}</Link>); 
                    return(<span>{`${rfc}`}</span>)
                }
            },
            {
                id: 'primaryEmailprimaryEmail',
                Header: 'Email',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '15%', 
                accessor: d => ({
                    primaryEmail: _.get(d, 'person.primaryEmail', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        primaryEmail,
                    },
                    row
                }) {
                    // return (<Link to={`/app/horarios/${id}`}>{id}</Link>); 
                    return(<span>{`${primaryEmail}`}</span>)
                }
            },
            // {
            //     id: 'nombre',
            //     Header: 'NOMBRE',
            //     disableSortBy: true,
            //     disableFilters: true,
            //     filter: 'text',
            //     width: '15%', 
            //     accessor: d => ({
            //         firstName: _.get(d, 'person.firstName', ''),
            //         secondName: _.get(d, 'person.secondName', ''),
            //         firstLastName: _.get(d, 'person.firstLastName', ''),
            //         secondLastName: _.get(d, 'person.secondLastName', ''),
            //     }),
            //     Cell: function cell ({ 
            //         value: {
            //             firstName,
            //             secondName,
            //             firstLastName,
            //             secondLastName,
            //         },
            //         row
            //     }) {
            //         // return (<Link to={`/app/horarios/${id}`}>{id}</Link>); 
            //         return(<span>{`${firstName} ${secondName} ${firstLastName} ${secondLastName}`}</span>)
            //     }
            // },
            {
                id: 'clientType',
                Header: 'Tipo de cliente',
                disableSortBy: true,
                disableFilters: true,
                width: '15%',
                accessor: d => ({
                    isfisica:  _.get(d, 'isfisica', null),
                }),
                Cell: function cell ({ value: { isfisica }, row }) {
                    let type = isfisica ? "Persona Física" : "Persona Moral";
                    return(<span>{`${type}`}</span>)
                }
            },
            {
                id: 'options.name',
                Header: 'Acciones',
                filter: "text",
                disableFilters: true,
                width: '10%',
                accessor: d => ({
                    id: d.id,
                    item: d
                }),
                Cell: function cell ({ value: { id, item }, row }) {
                    return (
                        <ActionMenu id={id} >
                            <MenuItem onClick={ () => onClickActionMenu('showRecord', item)}><img src={view}/> Ver registro</MenuItem>
                            <MenuItem onClick={ () => onClickActionMenu('editRecord', item)}><img src={edit}/> Editar registro</MenuItem>
                            <MenuItem onClick={ () => onClickActionMenu('quotation', item)}><img src={view}/> Cotizaciones</MenuItem>
                            <MenuItem onClick={ () => history.push(RECORDS)}><img src={file}/> Ver expedinetes</MenuItem>
                            <MenuItem onClick={ () => toggleModal(2, id)}><img src={trash}/> Eliminar</MenuItem>
                            <MenuItem onClick={ () => preAssingCustomAgent(id)}><img src={agency}/> Asignar agente aduanal</MenuItem>
                        </ActionMenu>
                    ); 
                }
            },
        ],
    );

    const dispatch = useDispatch();
    const preAssingCustomAgent = async (id) => {
        const res =  await props.getAllAvailableCustomAgentsAction({clientId: id});
        if(res){
            toggleModal(1, id);
        }else{
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de agentes aduanales, favor de intentarlo de nuevo',
            }));
        }
    }
    
    const getNewData = (searchCondition) => {
        // TODO: Restrucutrar vs Filtros*
        setSearchCondition(searchCondition);
    };

    const [customAgentAssigned, setCustomAgentAssigned] = useState(null);

    const assignAgent = async () =>{
        if(customAgentAssigned){
            const data = { 
                idClient : itemSelected[1],
                idAgend: customAgentAssigned,
            }
            console.log(data)
            let res = await props.upsertCostumerCustomAgentAction(data);
            if(res){
                setOpenModal(false);
            } 
        }else{
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Debe seleccionar algún agente aduanal',
            }));
        }
    }
    return (
        <>
         {
            openModal && itemSelected[0] === 1 &&
            <Modal
                isOpen={toggleModal}
                toggle={toggleModal}
            >
                <CardContainer btnLeftAction={toggleModal} btnRightText={'ASIGNAR'} btnRightAction={()=>assignAgent()} title={'Agentes Aduanales'} body={<AssignCustomsAgent customsAgents={!_.isEmpty(props.customAgentsAvailable) && _.get(props,'customAgentsAvailable.data.rows', [])} setCustomAgentAssigned={setCustomAgentAssigned} customAgentAssigned={customAgentAssigned} />}/>
            </Modal>
        }
        {
            openModal && itemSelected[0] === 2 &&
            <Modal
                isOpen={toggleModal}
                toggle={toggleModal}
            >
                <CardContainer btnLeftAction={toggleModal} btnRightAction={()=>deleteUser()} body={<DeleteBody user={'cliente'}/>}/>
            </Modal>
        }
        <section className='ClientRecordAccountSection' >
            <div className='ClientRecordAccountSection__container'>
                <div className='ClientRecordAccountSection__headerContainer'>
                    <div className='HomeTableSection__containerFilters'>
                        <GlobalFilter 
                            filter={searchObj}
                            setFilter={setSearchObj}
                            showTextSearch
                            showDateRangeSearch={false}
                            // classNameDate={'containerFilters__date'}
                            // classNameSearch={'containerFilters__search'}
                            placeholderSearch={'Buscar por ID o Nombre...'}
                        />
                    </div>
                    <div className='HomeTableSection__containerButtons'>
                        <Link
                        className={'linkClient'}
                        to={`/dashboard/gestion-clientes/tipo-usuario`}>
                            <Button className={'containerButtons__clientButton'} srcicon={plus}>CREAR</Button>        
                        </Link>
                    </div>
                </div>
                <div className='ClientRecordAccountSection__tableContainer'>
                    <DataTable
                        columns={columns}
                        // data={{...props.companies}}
                        data={{
                            data: _.get(props, 'clients.data', []),
                            totalRecords: _.get(props, 'clients.totalRecords', 0),
                        }}
                        getData={getNewData}
                        searchObj={searchObj}
                    />
                </div>
            </div>
        </section>
        </>
    );
}

ClientDashboard.propTypes = {
    history: PropTypes.any,
    dispatch: PropTypes.any,
    getAllAdministratorsAction: PropTypes.any,
    admins: PropTypes.any,
    deleteAdministratorAction: PropTypes.any,
    isSuccessDeleteAdmin: PropTypes.any,
    upsertCostumerCustomAgentAction: PropTypes.any,
    getAllCustomAgentsAction: PropTypes.any,
    getAllAvailableCustomAgentsAction: PropTypes.any,
};

function mapStateToProps (state) {
    return {
        clients: _.get(state, 'client.clients', {}),
        customAgentsAvailable: _.get(state, 'customAgent.customAgentsAvailableByClient', []),
        customsAgents: _.get(state, 'customAgent.customAgents', {}),
        isSuccessDeleteAdmin: _.get(state, 'admin.deleteAdmin.isSuccess', {}),
    };
}

const mapDispatchToProps = dispatch => ({
    deleteClientAction: params => dispatch(deleteClientAction(params)),
    getAllClientsAction: params => dispatch(getAllClientsAction(params)),
    getAllAdministratorsAction: params => dispatch(getAllAdministratorsAction(params)),
    deleteAdministratorAction: params => dispatch(deleteAdministratorAction(params)),
    upsertCostumerCustomAgentAction: params => dispatch(upsertCostumerCustomAgentAction(params)),
    getAllCustomAgentsAction: params => dispatch(getAllCustomAgentsAction(params)),
    getAllAvailableCustomAgentsAction: params => dispatch(getAllAvailableCustomAgentsAction(params)),
});

export default connect(mapStateToProps,mapDispatchToProps)(ClientDashboard);

