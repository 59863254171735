import {
    CUSTOMSAGENT,
    HOME,
    ADMIN,
    CUSTOMERMANAGEMENT,
    QUOTATION,
    PROVIDERS,
    OPERATIVES,
    RECORDS,
    PORTS,
} from '../../../../routes/Paths';

import home from '../../../../assets/images/navieras/Icono_Inicio_Color.png';
import operativos from '../../../../assets/images/navieras/Icono_user_color.png';
import admins from '../../../../assets/images/navieras/Icono_admin_color.png';
import proveedores from '../../../../assets/images/navieras/Icono_publicaciones_color.png';
import cotizacion from '../../../../assets/images/navieras/Icono_cobro_color.png';
import expediente from '../../../../assets/images/navieras/Icono_report_color.png';
import aduanales from '../../../../assets/images/navieras/Icon-aduanales.png';
import clientes from '../../../../assets/images/navieras/Icon-clientes.png';
import puertos from '../../../../assets/images/navieras/puertos.png';


// white
import operativosWhite from'../../../../assets/images/navieras/Icono_user-multiple_white.png';
import adminsWhite from'../../../../assets/images/navieras/Icono_user-profile-white.png';
import proveedoresWhite from'../../../../assets/images/navieras/Icono_publicaciones_white.png';
import cotizacionWhite from'../../../../assets/images/navieras/Icono_cobro_white.png';
import reportWhite from'../../../../assets/images/navieras/Icono_report_white.png';
import homeWhite from'../../../../assets/images/navieras/Icono_Inicio_white.png';

export const adminSidebarOptions = [
    {
        img: home,
        imgSecond: homeWhite,
        optionName: 'Inicio',
        link: HOME,
    },
    {
        img: operativos,
        imgSecond: operativosWhite,
        optionName: 'Gestión de usuarios',
        link: OPERATIVES,
    },
    {
        img: admins,
        imgSecond: adminsWhite,
        optionName: 'Gestión de administrador',
        link: ADMIN,
    },
    {
        img: proveedores,
        imgSecond: proveedoresWhite,
        optionName: 'Gestión publicaciones',
        link: PROVIDERS,
    },
    {
        img: cotizacion,
        imgSecond: cotizacionWhite,
        optionName: 'Gestión de cobros',
        link: QUOTATION,
    },
    {
        img: expediente,
        imgSecond: reportWhite,
        optionName: 'Gestión de reportes',
        link: RECORDS,
    },
];

export const operativeSidebarOptions = [
    {
        img: home,
        optionName: 'Inicio',
        link: HOME,
    },
    {
        img: operativos,
        imgSecond: operativosWhite,
        optionName: 'Gestión de usuarios',
        link: OPERATIVES,
    },
    {
        img: proveedores,
        imgSecond: proveedoresWhite,
        optionName: 'Gestión publicaciones',
        link: PROVIDERS,
    },
];