import React from "react";
import _ from "lodash";
import "./CardPost.scss";
import imgCard from "../../../assets/images/AVATAR.png";
import ratingCard from "../../../assets/images/Icono_ionic-ios-star.png";
import country from "../../../assets/images/USD.png";
import { url_api } from "../../../config";
import { useState } from "react";

const CardPost = (props) => {
  const [date, setDate] = useState();
  console.log(props.data);
  const currentData = _.get(props, "data.data.rows", null);
  console.log(currentData);

  // const [name,setName] = useState(
  //   !_.isNil(currentData.user.person.firstName)
  //   ? currentData.user.person.firstName : "Sin Info"
  // )

  return (
    <>
      {!_.isNil(currentData) &&
        currentData.map((item, i) => (
          <div className="cardPostMainContainer">
            <div className="topContainerCard">
              <div className="sectionOneContainer">
                <img
                  className="imgCard"
                  src={
                    item.user.person.profileImageMedia
                      ? `${url_api}${"/"}${item.user.person.profileImageMedia.fileName}`
                      : imgCard
                  }
                />
                <div className="ratingContainer">
                  <img src={ratingCard} className="ratingCard" />
                  <p className="dftTitle">{item.user.customer.buyerRating ? item.user.customer.buyerRating : 'Sin calificación'}</p>
                </div>
              </div>
              <div className="sectionTwoContainer">
                <h1 className="nameTitlePost">{`${
                  item.user.person.firstName
                }${" "}${item.user.person.firstLastName}`}</h1>
                <p className="addressTitlePost">
                {/* {`${item.user.address.street1}${", "}${item.user.address.city.name}${", "}${item.user.address.state.name}${", "}${item.user.address.country.name}`} */}
                </p>
                <p style={{ fontSize: "8px", color: "aliceblue" }}>
                  {item.createdAt}
                </p>
              </div>
            </div>
            <div className="bottomContainerCard">
              <div className="sectionOneContainerBottom">
                <img className="coinCountry" src={`https://cash.beesoftware.dev/${item.forex.displayName}.png`} />
                <p className="coinTitle">{item.forex.displayName}</p>
              </div>
              <div className="sectionTwoContainerBottom">
                <p className="dftTitle">Oferta {item.amountOffer}</p>
                <p className="dftTitle">Cambiar a MXN </p>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default CardPost;
