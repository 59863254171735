/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import "../../styles/stylesSteppers.scss";
import "../../styles/tableViewStyles.scss";
import {
  Button,
  DataTable,
} from "../../components/atoms";
import {
  getAllQuotationsAction,
  deleteQuotationAction,
} from "../../redux/actions/quotationAction";
import Modal from "../../components/organisms/Modal/Modal";
import "./Quotation.scss";
import { CardContainer } from "../../components/molecules/CardContainer/CardContainer";
import { DeleteBody } from "../../components/molecules/CancelFormBody/DeleteBody";
import CardCharges from "../../components/organisms/cardCharges/CardCharges";
import { getAllChargesAction } from "../../redux/actions/chargesAction";
import { getAllCommissionAction } from "../../redux/actions/commissionAction";
import CustomDatePicker from "../../components/molecules/InputDate/InputDate";
import { addNotification } from "../../redux/actions/notificationAction";


const QuotationDashboard = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchObj, setSearchObj] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [itemSelected, setItemSelected] = useState(true);
  const [firstDate,setFirstDate] = useState()
  const [secondDate,setSecondDate] = useState();


  console.log("soy primer date",firstDate)
  console.log("soy segunda date",secondDate)


  const toggleModal = ({ type, id }) => {
    if (openModal === true) {
      setItemSelected(null);
    }
    {
      setItemSelected({ type, id });
    }
    setOpenModal(!openModal);
  };

  useEffect(() => {
    dispatch(getAllChargesAction());

    dispatch(getAllCommissionAction());
  }, []);

  const charges = useSelector((state) => state.charges.allCharges.data);

  const commission = useSelector(
    (state) => state.commission.allCommission.data
  );

  const commissions = _.get(commission, "data.commissions", []);

  const columns = React.useMemo(() => [
    {
      id: "Nombre del usuario",
      Header: "Nombre del usuario",
      disableSortBy: true,
      disableFilters: true,
      filter: "text",
      width: "8%",
      accessor: (d) => ({
        firstName: _.get(d, "firstName", ""),
        firstLastName: _.get(d, "fistLastName", ""),
        secondLastName: _.get(d, "secondLastName"),
      }),
      Cell: function cell({
        value: { firstName, firstLastName, secondLastName },
        row,
      }) {
        return (
          <span>{`${firstName}${" "}${firstLastName}${" "}${secondLastName}`}</span>
        );
      },
    },
    {
      id: "movimiento",
      Header: "Movimiento",
      disableSortBy: true,
      disableFilters: true,
      filter: "text",
      width: "18%",
      accessor: (d) => ({
        tradename: _.get(d, "transaction", ""),
      }),
      Cell: function cell({ value: { tradename }, row }) {
        return <span>{tradename}</span>;
      },
    },
    {
      id: "Monto",
      Header: "Monto",
      disableSortBy: true,
      disableFilters: true,
      width: "18%",
      accessor: (d) => ({
        provider: _.get(d, "amountOffer", ""),
      }),
      Cell: function cell({ value: { provider }, row }) {
        return <spam>{provider}</spam>;
      },
    },
    {
      id: "Denominacion",
      Header: "Divisa",
      disableSortBy: true,
      disableFilters: true,
      width: "25%",
      // accessor: d => ({
      //     modality: _.get(modality(d), ''),
      // }),
      // Cell: function cell ({ value: { modality }, row }) {
      //     return (<spam >{modality}</spam>);
      // },
      accessor: (d) => ({
        displayName: _.get(d, "displayName", ""),
      }),
      Cell: function cell({ value: { displayName }, row }) {
        return <spam>{displayName}</spam>;
      },
    },
    {
      id: "Data",
      Header: "Fecha",
      disableSortBy: true,
      disableFilters: true,
      width: "25%",
      accessor: (d) => ({
        date: _.get(d, "date", ""),
      }),
      Cell: function cell({ value: { date }, row }) {
        let fechaSinHora = date.substring(0, 10);
        return <spam>{fechaSinHora}</spam>;
      },
    },
  ]);

  const getNewData = (searchCondition) => {
    // TODO: Restrucutrar vs Filtros*
    // setSearchCondition(searchCondition);
  };

  const getCommisions = () => {
    //   dispatch(addNotification({
//     toastType: 'success',
//     toastTitle: 'Se ha ejecutado la acción con éxito',
// }));
    const condition = {
      where: {
        startDate: firstDate,
        endDate: secondDate
        
      }
    }

    if(firstDate && secondDate){
      dispatch(getAllCommissionAction(condition));
    }else{
      dispatch(addNotification({
        toastType: 'error',
        toastTitle: 'Para la busqueda se tienen que añadir ambas fechas',
      }));  
    }
  }

  return (
    <>
      {openModal && itemSelected.type === "eliminar" && (
        <Modal isOpen={toggleModal} toggle={toggleModal}>
          <CardContainer
            btnLeftAction={toggleModal}
            btnRightAction={() => deleteQuote()}
            body={<DeleteBody user={"cotización"} />}
          />
        </Modal>
      )}
      <section className="ClientRecordAccountSection">
        <div className="ClientRecordAccountSection__container">
          <div className="chargesHeadContainer">
            <h2 style={{ color: "#ffff", fontSize: "16px", fontWeight: "300" }}>
              Gestion de cobros
            </h2>
            <div className="featuredInformationContainer">
              <CardCharges data={charges} />
            </div>
            <h3
              style={{ color: "#79DECA", fontWeight: "300", fontSize: "16px" }}
            >
              Total de comisiones
            </h3>
            <div className="commissionsContainer">
              <CustomDatePicker setValue={setFirstDate}/>
              <p style={{  color: "#ffff", fontSize: "20px" }}>
                a
              </p>
              <CustomDatePicker setValue={setSecondDate}/>
              <div className="totalContainer">
                <label style={{ color: "#D8D8D8", fontSize: "18px" }}>
                  Total generado
                </label>
                <p style={{ color: "#ffff", fontSize: "25px" }}>${commissions.length}</p>
              </div>
              <Button onClick={getCommisions} className="btnContainer">Buscar</Button>
            </div>
       
          </div>
          <div className="ClientRecordAccountSection__tableContainer">
            <DataTable
              columns={columns}
              data={{
                data: _.get(commission, "data.commissions", []),
                totalRecords: _.get(props.quotations, "totalRecords", 0),
              }}
              getData={getNewData}
              searchObj={searchObj}
            />
          </div>
        </div>
      </section>
    </>
  );
};

QuotationDashboard.propTypes = {
  history: PropTypes.any,
  dispatch: PropTypes.any,
  getAllQuotationsAction: PropTypes.any,
  quotations: PropTypes.any,
  deleteQuotationAction: PropTypes.any,
  isSuccessDeleteQuotation: PropTypes.any,
  value: PropTypes.any,
  row: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    quotations: _.get(state, "quotation.quotations", {}),
    isSuccessDeleteQuotation: _.get(
      state,
      "quotation.deleteQuotation.isSuccess",
      {}
    ),
  };
}

const mapDispatchToProps = (dispatch) => ({
  getAllQuotationsAction: (params) => dispatch(getAllQuotationsAction(params)),
  deleteQuotationAction: (params) => dispatch(deleteQuotationAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuotationDashboard);
