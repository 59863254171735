import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { MenuItem } from "@material-ui/core";
import plus from "../../../assets/images/navieras/plus-icon.png";
import "../../../styles/tableViewStyles.scss";
import {
  Button,
  DataTable,
  ActionMenu,
  GlobalFilter,
  Link,
} from "../../../components/atoms";
import {
  getAllAdministratorsAction,
  deleteAdministratorAction,
} from "../../../redux/actions/adminAction";
import Modal from "../../../components/organisms/Modal/Modal";
import { CardContainer } from "../../../components/molecules/CardContainer/CardContainer";
import { DeleteBody } from "../../../components/molecules/CancelFormBody/DeleteBody";
import view from "../../../assets/images/iconEyeShow.png";
import trash from "../../../assets/images/Icono_block_color.png";
import edit from "../../../assets/images/navieras/Icono_Editar_Color.png";
import { getAlluserPermissionAction } from "../../../redux/actions/userPermissionAction";
import PositionedMenu from "../../../components/organisms/PermissionDiv/PermissionFloat";

const AdminDashboard = (props) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const [searchObj, setSearchObj] = useState({});
  const [searchCondition, setSearchCondition] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);
  const [itemDeleted, setItemDeleted] = useState(true);
  const [classPermissions, setClassPermissions] = useState("mas");

  useEffect(() => {
    props.getAllAdministratorsAction(searchCondition);
  }, [itemDeleted, searchCondition]);



  const mainData =  _.get(props,"data","")
  const userId = _.get(mainData,"user.id")
 

  const userPermissions = useSelector(state => state.userPermission.permissionUser.data);
  console.log("soy los props de user", userPermissions);

  const onClickActionMenu = (actionSelected, payload) => {
    switch (actionSelected) {
      case "showRecord":
        history.push({
          pathname: `/dashboard/admin/${payload.id}`,
          payload: payload,
        });
        break;
      case "deleteRecord":
        props.deleteAdministratorAction({ id: payload.id });
        break;
      default:
        break;
    }
  };


  const changeShowMore = () => {
    if(classPermissions==='mas'){
        setClassPermissions("menos");
    }else{
        setClassPermissions("mas");
    }
   
  };

  const obtenerAdmin = () => {
    let value = document.getElementById("searchAdmin").value
    console.log(value)
    const condition = {
      where: {
        "customerId": value,
      }
    }
    // setSearchCondition({where: value})
    props.getAllAdministratorsAction(condition);
  }

  const columns = React.useMemo(() => [
    {
      id: "id",
      Header: "ID",
      disableSortBy: true,
      disableFilters: true,
      filter: "text",
      width: "14%",
      accessor: (d) => ({
        id: _.get(d, "id", ""),
      }),
      Cell: function cell({ value: { id }, row }) {
        // return (<Link to={`/app/horarios/${id}`}>{id}</Link>);
        return <span>{`${id}`}</span>;
      },
    },
    {
      id: "nombre",
      Header: "Nombre(s)",
      disableSortBy: true,
      disableFilters: true,
      filter: "text",
      width: "14%",
      accessor: (d) => ({
        firstName: _.get(d, "person.firstName", ""),
      }),
      Cell: function cell({ value: { firstName }, row }) {
        // return (<Link to={`/app/horarios/${id}`}>{id}</Link>);
        return <span>{`${firstName}`}</span>;
      },
    },
    {
      id: "apellido",
      Header: "Apellido(s)",
      disableSortBy: true,
      disableFilters: true,
      width: "14%",
      accessor: (d) => ({
        firstLastName: _.get(d, "person.firstLastName", ""),
      }),
      Cell: function cell({ value: { firstLastName }, row }) {
        return <span>{`${firstLastName}`}</span>;
      },
    },
    {
      id: "telefono",
      Header: "Teléfono",
      disableSortBy: true,
      disableFilters: true,
      width: "14%",
      accessor: (d) => ({
        primaryPhone: _.get(d, "person.primaryPhone", ""),
      }),
      Cell: function cell({ value: { primaryPhone }, row }) {
        return <span>{`${primaryPhone}`}</span>;
      },
    },
    {
      id: "correo",
      Header: "Correo",
      disableSortBy: true,
      disableFilters: true,
      width: "14%",
      accessor: (d) => ({
        primaryEmail: _.get(d, "person.primaryEmail", ""),
      }),
      Cell: function cell({ value: { primaryEmail }, row }) {
        return <span>{`${primaryEmail}`}</span>;
      },
    },
    {
      id: "permisos",
      Header: "Permisos",
      disableSortBy: true,
      disableFilters: true,
      width: "10%",
      accessor: (d) => ({
        permission: _.get(d,"user.userPermission",[1,2]), 
      }),
      Cell: function cell({ value: { permission }, row }) {
        let arrayEnd = []
        let arrayPer = []
        let firstPermiso;
        if(!_.isNil(permission)){

          for (let index = 0; index < permission.length; index++) {
            if(_.isNil(permission[index].deletedAt)){
              arrayPer.push(permission[index].permissionId)
            }
          }
          for (let index = 0; index < arrayPer.length; index++) {
              // console.log(arrayPer[index],"id")
              switch (arrayPer[index]) {
                case 1:
                  arrayEnd.push('Gestión de usuarios')
                  if(index===0){
                    firstPermiso = 'Gestion de usuarios'
                  }
              break;
              case 2:
                  arrayEnd.push('Gestión de publicaciones')
                  if(index===0){
                    firstPermiso = 'Gestion de publicaciones'
                  }
              break;
              case 3:
                  arrayEnd.push('Gestión de cobros')
                  if(index===0){
                    firstPermiso = 'Gestion de cobros'
                  }
              break;
              case 4:
                  arrayEnd.push('Gestión de reportes')
                  if(index===0){
                    firstPermiso = 'Gestion de reportes'
                  }
              break;
              case 5:
                  arrayEnd.push('Gestión de administradores')
                  if(index===0){
                    firstPermiso = 'Gestion de administradores'
                  }
              break;
              default:
                  break;
              }
            
          }
        }
        
        return (
          <>
            <div style={{display:"flex", justifyContent:"center"}}>    
                  <p>{firstPermiso},gestio...</p>
                 
                  {/* <p className="showMoKre" onClick={changeShowMore}>{`Ver ${classPermissions}`}</p> */}
                  <PositionedMenu data={arrayEnd}/>
            </div>
          
          </>
        );
      },
    },
    {
      id: "options.name",
      Header: "",
      filter: "text",
      disableFilters: true,
      width: "4%",
      accessor: (d) => ({
        id: _.get(d, "user.id", ""),
        item: d,
      }),
      Cell: function cell({ value: { id, item }, row }) {
        return (
          <ActionMenu id={id}>
            <MenuItem onClick={() => onClickActionMenu("showRecord", item)}>
              <img src={view} /> Ver registro
            </MenuItem>
            <MenuItem onClick={() => toggleModal({ type: "eliminar", id })}>
              <img src={trash} /> Eliminar
            </MenuItem>
          </ActionMenu>
        );
      },
    },
  ]);

  const getNewData = (searchCondition) => {
    // TODO: Restrucutrar vs Filtros*
    console.log(searchCondition)
    setSearchCondition(searchCondition);
  };

  const deleteUser = async () => {

   
    const res = await props.deleteAdministratorAction({
      userId: itemSelected.id,
    });
    if (res) {
      setOpenModal(false);
      setItemDeleted(itemSelected);
      setItemSelected(null);
    }
  };
  const toggleModal = ({ type, id }) => {
    if (openModal === true) {
      setItemSelected(null);
    }
    {
      setItemSelected({ type, id });
    }
    setOpenModal(!openModal);
  };
  return (
    <>
      {openModal && itemSelected.type === "eliminar" && (
        <Modal isOpen={toggleModal} toggle={toggleModal}>
          <CardContainer
            btnLeftAction={toggleModal}
            btnRightAction={() => deleteUser()}
            body={<DeleteBody user={"administrador"} />}
          />
        </Modal>
      )}
      <section className="ClientRecordAccountSection">
        <div className="ClientRecordAccountSection__container">
          <div className="TitleContainer">
            <h2 className="TitleUsers">Gestión de administradores | Listado</h2>
          </div>
          <div className="ClientRecordAccountSection__headerContainer">
            <div className="HomeTableSection__containerFilters">
              <div className="searchPost">
                <input
                  className="searchPostInputBtn"
                  placeholder="Buscar ID,nombre..."
                  id="searchAdmin"
                />
                <div className="btnSearch" onClick={obtenerAdmin}>
                  <label style={{cursor: "pointer"}}>Buscar</label>
                </div>
              </div>
            </div>
            <div className="HomeTableSection__containerButtons">
              <Link className={"linkClient"} to={`/dashboard/admin/crear`}>
                <Button className={"containerButtons__clientButton"}>
                  CREAR
                </Button>
              </Link>
            </div>
          </div>
          <div className="ClientRecordAccountSection__tableContainer">
            <DataTable
              columns={columns}
              // data={{...props.companies}}
              data={{
                data: _.get(props.admins, "data", []),
                totalRecords: _.get(props.admins, "totalRecords", 0),
              }}
              getData={getNewData}
              searchObj={searchObj}
            />
          </div>
        </div>
      </section>
    </>
  );
};

AdminDashboard.propTypes = {
  history: PropTypes.any,
  dispatch: PropTypes.any,
  getAllAdministratorsAction: PropTypes.any,
  admins: PropTypes.any,
  deleteAdministratorAction: PropTypes.any,
  isSuccessDeleteAdmin: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    admins: _.get(state, "admin.admins", {}),
    isSuccessDeleteAdmin: _.get(state, "admin.deleteAdmin.isSuccess", {}),

  };
}

const mapDispatchToProps = (dispatch) => ({
  getAllAdministratorsAction: (params) =>
    dispatch(getAllAdministratorsAction(params)),
  deleteAdministratorAction: (params) =>
    dispatch(deleteAdministratorAction(params)),
  getAllUserPermissionAction: (params) =>
    dispatch(getAlluserPermissionAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
