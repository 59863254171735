/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button } from './../../../components/atoms';
import '../../../styles/stylesSteppers.scss';
import arrow from './../../../assets/images/navieras/arrowButton.png'
import { useHistory } from 'react-router-dom';
import { Checkbox } from '@material-ui/core';
import './RecordsData.scss';

const DocumentsAndCustomAgent = ({ recordId = '002', setStepper, stepper, customsAgents=[''], customsPorts=[''] }) => {
    const [ status, setStatus ]= useState(null);
    const history = useHistory();

    const noAvailableAgents = [{person: {firstName: 'No hay agentes disponibles', firstLastName: ''}, id:0}];
    let availableAgents;

    if (customsAgents && customsAgents.length > 0) availableAgents = customsAgents;
    else availableAgents = noAvailableAgents;

    const noAvailablePorts = [{port: {portName: 'No hay agentes disponibles'}, id:0}];
    let availablePorts;

    if (customsPorts && customsPorts.length > 0) availablePorts = customsPorts;
    else availablePorts = noAvailablePorts;
    
    const changeStage = ()=>{
        history.push(
            {
                // pathname: `/dashboard/gestion-clientes/crear`,
                state: {clientType: status},
            }
            )
        setStepper(stepper+1)
    }

    return (
        <div className='main-container-generic-type'>
            <div className='header-form-generic-type'>
                <h2>Documentos</h2>
                <h3>ID: {recordId}</h3>
            </div>
            <div className='body-form-generic-type-data record-data records checkboxes' style={{height: '45vh'}} >
                <form className='input-container-data records checkboxes'>
                    <div className='input-row-container'>
                        <div className='checkbox-records-container'>
                            <label className='checkbox-records-label'>HBL</label>
                            <Checkbox className={'checkbox-records'} onChange={console.warn('checked once')} value={''} />
                        </div>
                        <div className='checkbox-records-container'>
                            <label className='checkbox-records-label'>MBL</label>
                            <Checkbox className={'checkbox-records'} />
                        </div>
                        <div className='checkbox-records-container'>
                            <label className='checkbox-records-label'>EIR</label>
                            <Checkbox className={'checkbox-records'} onChange={() => console.warn('checkeaaado')} />
                        </div>
                        <div className='checkbox-records-container'>
                            <label className='checkbox-records-label'>TELEX</label>
                            <Checkbox type={'checkbox'} className={'checkbox-records'} />
                        </div>
                        <div className='checkbox-records-container'>
                            <label className='checkbox-records-label'>RELEASE</label>
                            <Checkbox type={'checkbox'} className={'checkbox-records'} />
                        </div>
                    </div>
                    <div className='input-row-container'>
                        <div className='checkbox-records-container'>
                            <label className='checkbox-records-label'>Póliza de seguros</label>
                            <Checkbox type={'checkbox'} className={'checkbox-records'} />
                        </div>
                        <div className='checkbox-records-container'>
                            <label className='checkbox-records-label'>Corte de demoras</label>
                            <Checkbox type={'checkbox'} className={'checkbox-records'} />
                        </div>
                        <div className='checkbox-records-container'>
                            <label className='checkbox-records-label'>Otro documento</label>
                            <Checkbox type={'checkbox'} className={'checkbox-records'} />
                        </div>
                    </div>
                </form>
            
            <div className='header-form-generic-type'>
                <h2>Agente Aduanal</h2>
            </div>
            <div className='body-form-generic-type records'>
                <div className='input-container-data records'>
                    <div className='input-row-container'>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Agente Aduanal</label>
                            </div>
                            <div className='value-container' >
                                <select className='input-select' value={status} onChange={(e) => {
                                    setStatus(parseInt(e.target.value));
                                }}>
                                    <option className='option-selected' value={null} selected hidden>Seleccionar tipo de cliente</option>
                                    {
                                        availableAgents &&
                                        availableAgents.map((ele, i) => {
                                            return <option key={i} value={ele.id}> {ele.agentname ? ele.agentname : ele.person ? ele.person.firstName + '' + ele.person.firstLastName : 'Ningún nombre registrado'}</option>
                                        })
                                    }
                                </select >
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Teléfono</label>
                            </div>
                            <div className='value-container'>
                                <input disabled name={'phone'} className="input-generic" type="text" placeholder="---"/>
                            </div>
                        </div>                   
                    </div>
                    <div className='input-row-container'>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Correo</label>
                            </div>
                            <div className='value-container'>
                                <input disabled name={'name'} className="input-generic" type="text" placeholder="---"/>
                            </div>
                        </div> 
                        <div className='input-column-container'>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>POL</label>
                                </div>
                                <div className='value-container' >
                                <select className='input-select' value={status} onChange={(e) => {
                                    setStatus(parseInt(e.target.value));
                                }}>
                                    <option className='option-selected' value={null} selected hidden>Seleccionar tipo de cliente</option>
                                    {
                                        customsPorts &&
                                        customsPorts.map((ele, i) => {
                                            return <option key={i} value={ele.id}> {ele.name ? ele.name : 'Ningún nombre registrado'}</option>
                                        })
                                    }
                                </select >
                            </div>
                            </div> 
                        </div>
                    </div>
                    <div className='input-row-container'>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>POD</label>
                            </div>
                            <div className='value-container' >
                                <select className='input-select' value={status} onChange={(e) => {
                                    setStatus(parseInt(e.target.value));
                                }}>
                                    <option className='option-selected' value={null} selected hidden>Seleccionar tipo de cliente</option>
                                    {
                                        customsPorts &&
                                        customsPorts.map((ele, i) => {
                                            return <option key={i} value={ele.id}> {ele.name ? ele.name : 'Ningún puerto registrado'}</option>
                                        })
                                    }
                                </select >
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
            </div>
            <div className='footer-form-generic-type records'>
                <Button onClick={changeStage} className={'button-back'} style={{width: '28%'}}>CANCELAR</Button>
                <Button onClick={changeStage} className={'button-generate'} style={{width: '28%'}}>GUARDAR</Button>
                <Button onClick={changeStage} className={'button-next'} srcicon={arrow} style={{width: '30%'}}>SIGUIENTE</Button>
            </div>
        </div>
    )
}

export default DocumentsAndCustomAgent;
